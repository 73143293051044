function sigmoid(x, c, h, dx) {
  const result = 1 / (1 + Math.exp(-c * (x - dx))) + h;

  return result;
}

function dsigmoid(x, c, dx) {
  const numerator = c * Math.exp(c * (dx + x));
  const denominator = Math.pow(Math.exp(c * dx) + Math.exp(c * x), 2);
  const result = numerator / denominator;

  return result;
}

function generateSigmoidPointsFixed(step, c = 15) {
  const points = [];

  let ts = 100;
  while (ts / 2 <= step) {
    ts = ts * 5;
  }

  const npoints = ts / step + 1;
  for (let i = 0; i < npoints; i++) {
    let x = i / (npoints - 1); // Normalizando para o intervalo [0, 1]
    const y = sigmoid(x, c, 0, 0.5);
    x = x * ts;
    points.push({ x, y });
  }

  return points;
}

function generateSigmoidPointsAdaptive(
  numPoints,
  start,
  c = 15,
  h,
  dx,
  method
) {
  // method:
  // 0 = Agressive
  // 1 = Normal
  // 2 = conservative

  const points = [];
  let step = 0;
  let derivative = 0;
  for (let i = 0; i < numPoints; i++) {
    const x = i / (numPoints - 1) + start; // Normalizando para o intervalo [0, 1]
    const y = sigmoid(x, c, h, dx);

    // Calculando a derivada no ponto atual
    derivative = dsigmoid(x, c, dx);

    // Adicionando o ponto aos pontos resultantes
    points.push({ x, y });

    // Ajustando o tamanho do passo com base na magnitude da derivada
    step = 1 / Math.abs(derivative) / 120;

    switch (method) {
      case "agressive":
        if (((x > 0.36) & (x < 0.69)) | ((x > 1.36) & (x < 1.69))) {
          i += step - 0.2;
        } else {
          i += step + 1;
        }
        break;

      case "normal":
        if (((x > 0.36) & (x < 0.69)) | ((x > 1.36) & (x < 1.69))) {
          i += step - 0.5;
        } else {
          i += step + 0.3;
        }
        break;

      case "conservative":
        if (((x > 0.36) & (x < 0.69)) | ((x > 1.36) & (x < 1.69))) {
          i += step - 0.7;
        } else {
          i += step - 0.5;
        }
        break;
    }
  }

  return points;
}

function drawTimePass(simulation) {
  // method:
  // 0 = Agressive
  // 1 = Normal
  // 2 = conservative
  // 3 = fixed
  let method = simulation["timestep_profile"] ? simulation["timestep_profile"].value : "normal";
  let fixedstep = simulation["fixed_timestep"];
  let XValues = [];
  let YValues = [];
  if (method == "fixed") {
    const sigmoidPointsFixed = generateSigmoidPointsFixed(fixedstep, 15);
    XValues = sigmoidPointsFixed.map((point) => point.x);
    YValues = sigmoidPointsFixed.map((point) => point.y);
  } else {
    const numPoints = 15; // Número de pontos desejado
    const sigmoidPoints1 = generateSigmoidPointsAdaptive(
      numPoints,
      0,
      15,
      0,
      0.5,
      method
    );
    const sigmoidPoints2 = generateSigmoidPointsAdaptive(
      numPoints,
      1,
      15,
      1,
      1.5,
      method
    );

    const xValues1 = sigmoidPoints1.map((point) => point.x * 50);
    const yValues1 = sigmoidPoints1.map((point) => point.y * 50);
    const xValues2 = sigmoidPoints2.map((point) => point.x * 50);
    const yValues2 = sigmoidPoints2.map((point) => point.y * 50);
    xValues2.shift();
    yValues2.shift();
    XValues = xValues1.concat(xValues2);
    YValues = yValues1.concat(yValues2);
  }
  return [XValues, YValues];
}

export { drawTimePass };
