// Main import of React
import { useEffect, useContext } from "react";

// Local SubComponents & utils
import { ActionFabEditableGrid } from "../../common";
import { headersRheometryModel } from "../../../data/headersForGrids";
import {
  createRows,
  mergedObject,
  renameCurrentRheologicModel,
  roundRheoPropsValues,
} from "../../utils";
import { addTableRows } from "../../utils/functionsToAddRows";
import { duplicateRowRheologicProperties } from "../../utils/functionToDuplicateRows";
import { deleteTableRows } from "../../utils/functionsToDeleteRows";

// Requests
import { calcRheometryChart } from "../../../services/ChartsRequests";

// Context
import { UpdatedJsonContext } from "../../contexts/UpdatedJsonContext";
import { VariableRheologyContext } from "../../contexts/VariableRheologyContext";

// Import Component Style
import "./VariableGrid.css";

export default function VariableGrid(props) {
  // UpdatedJson and ActiveRheoData
  const { updatedJson, activeRheoData, setActiveRheoData } =
    useContext(UpdatedJsonContext);

  // Set state to populate rheometer readings error
  const { setRheoReadingsError} = useContext(VariableRheologyContext);

  // Current Rheologic Model
  const currentRheologicModel = props.currentRheologicModel;
  const currentModel = renameCurrentRheologicModel(currentRheologicModel);

  // Original rheologic data
  const rheologicData = props.rheologicData;

  // Defining Tags and Tags Items - updatedJson
  const tags = ["rheologic_data"];
  const tagsItems = [currentModel];

  //  State for rheometer readings checkbox
  const isChecked = props.isChecked;

  // ActiveTab
  const activeTab = props.activeTab;

  const rowsRheometry = props.rowsRheometry;
  const setRowsRheometry = props.setRowsRheometry;

  // Rheometry Json
  const rheometryJson = props.rheometryJson;

  // Constants to check if values of roation and readings exists
  const checkEmptyReadingsFields = props.checkEmptyReadingsFields;
  const checkEmptyRotationFields = props.checkEmptyRotationFields;

  const useViscosimeterReadings = props.useViscosimeterReadings;

  // Defining height for Grid Fab Container
  const gridContainerHeight = "30vh";

  // Case mode
  const caseMode = props.caseMode;

  // Function responsible for reset rheological parameters values
  function resetRheologicProperties(rheological_parameters) {
    const rows = rowsRheometry;
    const filterActiveRow = rows.filter(
      (item) => item.eid === activeRheoData.eid
    );
    const updatedRows = rows.map((row) => {
      if (row.eid === activeRheoData.eid) {
        return mergedObject(
          filterActiveRow,
          roundRheoPropsValues(rheological_parameters)
        )[0];
      }
      return row;
    });
    setRowsRheometry(updatedRows);
  }

  // Define useEffect policy
  useEffect(() => {
    if (
      !checkEmptyReadingsFields &&
      !checkEmptyRotationFields &&
      activeTab !== "constants" &&
      rowsRheometry &&
      Object.keys(updatedJson).length > 0
    ) {
      setRheoReadingsError()
      calcRheometryChart(rheometryJson).then((res) => {
        if (Object.keys(res.data).length > 0 && useViscosimeterReadings) {
          resetRheologicProperties(res.data.rheological_parameters);
        } else {
          setRheoReadingsError(res)
        }
      });
    }
  }, [updatedJson, currentModel]);

  // Function responsible for active rheologic data after click on view bttn action
  function viewBttnOnClick(rowId) {
    setActiveRheoData(rheologicData[rowId - 1]);
  }

  return (
    <>
      <div className="conditional-grid__container">
        <ActionFabEditableGrid
          isChecked={isChecked}
          metaData={headersRheometryModel(activeTab, currentRheologicModel)}
          tagsItems={tagsItems[0]}
          tags={tags[0]}
          rows={rowsRheometry ? rowsRheometry : []}
          oldRows={createRows(
            rheologicData,
            headersRheometryModel(activeTab, currentRheologicModel)
          )}
          dataJson={rheologicData}
          setRows={setRowsRheometry}
          addTableRows={addTableRows}
          deleteTableRows={deleteTableRows}
          duplicateRow={duplicateRowRheologicProperties}
          gridContainerHeight={gridContainerHeight}
          viewBttnOnClick={viewBttnOnClick}
          caseMode={caseMode}
        />
      </div>
    </>
  );
}
