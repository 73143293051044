// Main import of React
import { React } from "react";
import { useHistory } from "react-router";

// Style import
import "./style/NotFound.css";

function NotFound() {
  let history = useHistory();

  return (
    <>
      <div className="not-found__container">
        <div className="bttn-error__container">
        <button
          className="bttn-style-error"
          type="button"
          onClick={() => history.goBack()}
        >
          <p className="bttn-text-error">Voltar</p>
        </button>


        </div>
      </div>
    </>
  );
}

export default NotFound;
