// FormInput = Component to render form input

// #  Main Imports
import React, { useState } from "react";

// # Import Component Style
import "./formInput.css";

function FormInput(props) {
  const [focused, setFocused] = useState(false);
  const {
    label,
    errorMessage,
    onChange,
    /*     onKeyDown, */
    id,
    placeholder,
    disabled,
    rows,
    cols,
    pattern,
    min = "",
    max = "",
    maxlength,
    ...inputProps
  } = props;

  const handleFocus = (e) => {
    setFocused(true);
  };

  /*    function enforceMinMax(e, min, max) {
    if (e.target.value !== "") {
      if (parseInt(e.target.value) < parseInt(min)) {
        e.target.value = min;
      }
      if (parseInt(e.target.value) > parseInt(max)) {
        e.target.value = max;
      }
    }
  }  */

  if (inputProps.type === "select") {
    return (
      <div className="formInput">
        <label className="label-input-component">{placeholder}</label>
        <select
          data-testid = "select-input-component"
          {...inputProps}
          onChange={(e) => onChange(e)}
          className="select-input-component"
          disabled={disabled}
        >
          {inputProps.options.map((option, index) => (
            <option
              key={index}
              data-testid = "select-option-input"
              value={option.value}
              disabled={option.disabled}
              selected={option.selected}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  } else if (inputProps.type === "textarea" ) {
    return (
      <div className="formInput">
        <label className="label-input-component">{label}</label>
        <textarea
          data-testid = "textarea-input-component"
          {...inputProps}
          onChange={onChange}
          /*           focused={focused.toString()} */
          placeholder={placeholder}
          disabled={disabled}
          rows={rows}
          cols={cols}
          maxLength={maxlength}
          required
          className="input-component textarea"
        />

        {inputProps.value
          ? (() => {
              if (
                inputProps.value.length < 3 ||
                inputProps.value.length > maxlength -1
              ) {
                return (
                  <p className="p-error__textarea">
                    {" "}
                    {`Este campo deve conter 3 a ${
                      errorMessage.match(/\d/g)[1] +
                      errorMessage.match(/\d/g)[2] +
                      0
                    } caracteres!`}
                  </p>
                );
              } else {
                return "";
              }
            })()
          : ""}
      </div>
    );
  } else {
    return (
      <div className="formInput">
        <label className="label-input-component">{label}</label>
        <input
          data-testid = "input-component"
          {...inputProps}
          onChange={onChange}
          onFocus={handleFocus}
          placeholder={placeholder}
          disabled={disabled}
          pattern={pattern}
          required
          className="input-component"
        />
        {inputProps.value !== " "
          ? (() => {
              if (inputProps.value < Number(min) || inputProps.value > Number(max)) {
                return <p className="p-input-component">{errorMessage}</p>;
              } else {
                return "";
              }
            })()
          : ""}
      </div>
    );
  }
}

export default FormInput;
