import axios from "axios";

//Define API BASE_URL
const { REACT_APP_API_MONGO } = process.env; 

// Create axios instance, with specif request props(headers, auth, body, etc...)
export default axios.create({
  baseURL: REACT_APP_API_MONGO ,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

