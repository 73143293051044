// # Main import of React
import React, { useEffect } from "react";
import { useQuery } from "react-query";

// #  Local SubComponents & utils
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import {
  DropletLoadingSpinner,
  PageAndContainerTitle,
  VideoCardComponent,
  TextAndBackgroundImage
} from "../components/common";
import background from "../images/background.jpg"


// Routes for Tutorials
import { tutorialRoutes } from "../data/routes";
import { getAllVideos } from "../services/VideosRequests";
// Style import
import "./style/TutorialsAndNotifications.css";

function Tutorials() {
  //Define States for Videos - React Query
  const {
    data: video,
    isLoading: loadingvideo,
    error: errorvideo,
  } = useQuery("getAllVideos", () => getAllVideos());

  // Define useEffect policy
  useEffect(() => {
    if (errorvideo) {
      console.log("Alguma coisa deu errada");
      return <h1>Ops! Algum erro inesperado aconteceu</h1>;
    }
    if (video === null) {
      return <h1>Informações não encontradas!</h1>;
    }
    if (loadingvideo) {
      return <DropletLoadingSpinner />;
    }
  }, []);

/*   const tutorialsData = [
    {
      url: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
      videoTitle: "Como criar um novo Projeto?",
      description: `Clique em um poço na árvore de Unidades Operacionais, Campos e Poços e selecione um poço. Em seguida será carregada a página de Projetos, com os projetos vinculados ao poço selecionado.Então, passe o cursor sobre o botão flutuante azul com o sinal de "+" e clique no botão de "+". Finalmente, preencha os campos de nome e descrição do Projeto a ser criado.`,
    },
    {
      url: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
      videoTitle: "Como criar um novo Caso?",
      description: `Navegue até um projeto dentro do qual deseja-se criar o Caso. Então, passe o cursor sobre o botão flutuante azul com sinal de + e clique no botão de "+". Finalmente, preencha os campos de nome e a descrição do Caso a ser criado e clique no botão "Salvar"`,
    },
  ]; */
  // Page Render
  return (
    <Route path={[tutorialRoutes]}>
      {/* {video ? (
        <div className="tutorials__container">
          <header>
            <PageAndContainerTitle text="Tutoriais" level="1" />
          </header>
          <main>
            {video.map((item, index) => {
              return <VideoCardComponent data={item} index={index} />;
            })}
          </main>
        </div>
      ) : ( */}
        <div className="tutorials__empty">
        <PageAndContainerTitle text="Tutoriais" level="1" />
        <TextAndBackgroundImage
          text="Tutoriais em desenvolvimento."
          image={background}
        />
        </div>
{/*       )} */}
     
    </Route>
  );
}

// Page Export
export default Tutorials;
