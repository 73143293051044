// Function Respondible for merge rheologic properties and rheometer readings using original rheologic data
export function mergeRheologicData(
    rheologicData,
    rheologicPropertiesRows,
    rheometerReadingsRows,
    currentModel,
    setUpdatedRheologicData,
    activeRheoData  
    ) {

    // Makes a deep copy of rheologic data to avoid modifying it directly
    let mergedData = rheologicData;

    if(rheologicPropertiesRows.length > 0 && rheologicPropertiesRows !== null && rheologicPropertiesRows !== undefined) {
      // Adds rheology properties to rheologic data
      rheologicPropertiesRows.forEach((properties) => {
        let copyRheoProperties = properties
          mergedData.forEach((item, index) => {
            if (item.eid === properties.eid) {
              mergedData[index]["rheologic_data_item"][currentModel] =
              Object.keys(copyRheoProperties).filter(objKey =>
                !objKey.includes("rheometry")).reduce((newObj, key) =>
                {
                    newObj[key] = properties[key];
                    return newObj;
                }, {});
                mergedData[index]["rheologic_data_item"]["rheometry_temperature"] = copyRheoProperties["rheometry_temperature"] ? copyRheoProperties["rheometry_temperature"] : "0";
                mergedData[index]["rheologic_data_item"]["rheometry_pressure"] = copyRheoProperties["rheometry_pressure"] ? copyRheoProperties["rheometry_pressure"] : "0";
      
            }
          });
      });
    }

    if(rheometerReadingsRows.length > 0 && rheometerReadingsRows !== null && rheometerReadingsRows !== undefined) {
      // Adds rheometry readings to rheologic data based on "activeEid"
      let reometerReadingsArray = [];
      rheometerReadingsRows.forEach((reading) => {
        mergedData.forEach((item) => {
          if (item.eid === reading.activeEid) {
            if (!item.rheologic_data_item.rheometer_readings) {
              item.rheologic_data_item.rheometer_readings = [];
            }
            reometerReadingsArray.push({
              rheometer_reading_item: {
                reading: reading.reading,
                rotation: reading.rotation,
              },
              eid:reading.eid
            })
            item.rheologic_data_item.rheometer_readings = reometerReadingsArray;
          }
        });
      });
    }

     if(rheometerReadingsRows.length === 0){
      mergedData.forEach((item) => {
        if (item.eid === activeRheoData.eid) {
            item.rheologic_data_item.rheometer_readings = [];
          }
          
        
      });
    }

    setUpdatedRheologicData(mergedData)
  }