// Import axios from specific API definition
import axios from "./apis/i3d_sqlAPI";

// Import Authorization Header
import {authorizationHeader} from "./LoginServices";

// GET Method Request for videos
async function getAllVideos() {
  try {
    const response = await axios.get(`videos/`, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data.data ;
  } catch (error) {
    console.log(error.message);
  }
};


// POST Method Request for video By ID Proeject
async function createVideoRequest(data) {
  try {
    const response = await axios.post(`video/`, JSON.stringify(data), {
      headers: {
        Authorization: authorizationHeader,
      },  
    });
    return response.data.data;
  } catch (error) {
    console.log(error.message);
  }
};

// GET Method Request for video By ID
async function getVideoByIdRequest(id){
  try {
    const response = await axios.get(`video/${id}/`, {
      headers: {
        Authorization: authorizationHeader,
      },  
    });
    return response.data.data;
  } catch (error) {
    console.log(error.message);
  }
};


// PATCH Method Request for Video By ID
async function upadateVideoByIdRequest( id, data ){
  try {
    const response = await axios.patch(`video/${id}/`, JSON.stringify(data), {
      headers: {
        Authorization: authorizationHeader,
      },  
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};

// DELETE Method Request by Video_id
async function deleteVideoByIdRequest(id, signal){
  try {
    const response = await axios.delete(`Video/${id}/`, {
      headers: {
        Authorization: authorizationHeader,
      },
      signal,
    });
    return response.data;
  } catch (error) {
    console.log("Não foi possivel deletar esse vídeo!");
  }
};



export {getAllVideos,getVideoByIdRequest,deleteVideoByIdRequest,upadateVideoByIdRequest,createVideoRequest};
