import React, { createContext, useState, useEffect } from "react";

//Functions to  create rows - Density
import { createRows } from "../utils";

import { headersDensityTemperatureAndPressure } from "../../data/headersForGrids";

// Function to initialize the rowsDensity state
export const initRowsDensity = (densityJsonProfile, densityHeader) => {
    return createRows(
        densityJsonProfile,
        headersDensityTemperatureAndPressure(densityHeader)
    );
};

// Create Context
const ControlDensityContext = createContext();

// Hook
export default function useControlDensity() {
    // Control Density States
    const [rowsDensity, setRowsDensity] = useState([]);
    return { rowsDensity, setRowsDensity };
}

// Specific Context Provider
function ControlDensityProvider({ children, densityJsonProfile, densityHeader }) {
    const { rowsDensity, setRowsDensity } = useControlDensity();
    useEffect(() => {
        if (densityJsonProfile && densityHeader) {
            setRowsDensity(initRowsDensity(densityJsonProfile, densityHeader));
        }
    }, [densityJsonProfile, densityHeader]);

    return (
        <ControlDensityContext.Provider
            value={{ rowsDensity, setRowsDensity }}
        >
            {children}
        </ControlDensityContext.Provider>
    );
}

export { ControlDensityContext, ControlDensityProvider };