// Function to calculate the value min and max of inital and final measured depth
function minMaxMdInitialAndFinal(
  internal_geometry,
  external_geometry,
  scenery,
  mdInitial = null,
  mdFinal = null
) {
  // Variable to store the simulation mode
  const simulation_mode = scenery.simulation_mode.value;
  // Measured depth minimum of Internal geometry
  const minMdIg = Math.min(
    ...internal_geometry.casing.map((casing) =>
      Number(casing.casing_element_item.measured_depth) - Number(casing.casing_element_item.segment_length)
    ),
    ...internal_geometry.work_column.map((workColumn) =>
      Number(workColumn.pipe_element_item.measured_depth) - Number(workColumn.pipe_element_item.segment_length)
    )
  );

  // Measured depth maximum of Internal geometry
  const maxMdIg = Math.max(
    ...internal_geometry.casing.map((casing) =>
      Number(casing.casing_element_item.measured_depth)
    ),
    ...internal_geometry.work_column.map((workColumn) =>
      Number(workColumn.pipe_element_item.measured_depth)
    )
  );

  // Measured depth minimum of External geometry
  const minMdEg = Math.min(
    ...external_geometry.open_hole.map((hole) =>
      Number(hole.open_hole_element_item.measured_depth) - Number(hole.open_hole_element_item.segment_length)
    ),
    ...external_geometry.last_casing.map((casing) =>
      Number(casing.last_casing_element_item.measured_depth) - Number(casing.last_casing_element_item.segment_length)
    )
  );

  // Measured depth maximum of External geometry
  const maxMdEg = Math.max(
    ...external_geometry.open_hole.map((hole) =>
      Number(hole.open_hole_element_item.measured_depth)
    ),
    ...external_geometry.last_casing.map((casing) =>
      Number(casing.last_casing_element_item.measured_depth)
    )
  );

  // Variables to store the values min and max of inital and final measured depth
  let minMdFinal = 0;
  let minMdInitial = 0;
  let maxMdInitial = 0;
  let maxMdFinal = 0;
  let minMDAnular = Math.max(minMdIg, minMdEg);
  let maxMDAnular = Math.min(maxMdIg, maxMdEg);

  // Variables to store the limits of the value attribute in firstInputSection and secondInputSection - SimulationSubPages
  let initialMdAnnular = maxMDAnular;
  let finalMdAnnular = minMDAnular;
  let initialMdColumn = minMdIg;
  let finalMdColumn = maxMdIg;

  if (mdFinal === null || mdInitial === null) {
    if (simulation_mode === "annular") {
      minMdInitial = minMDAnular;
      maxMdInitial = maxMDAnular;
      minMdFinal = minMDAnular;
      maxMdFinal =  maxMDAnular;
    } else if (simulation_mode === "column") {
      minMdInitial = minMdIg;
      maxMdInitial = maxMdIg;
      minMdFinal = minMdIg;
      maxMdFinal = maxMdIg;
    } else {
      throw new Error("Simulation mode not available yet");
    }
  } else {
    if (simulation_mode === "annular") {
      minMdInitial = Math.max(mdFinal, minMDAnular);
      maxMdInitial = maxMDAnular;
      minMdFinal = minMDAnular;
      maxMdFinal = Math.min(mdInitial, maxMDAnular);
    } else if (simulation_mode === "column") {
      minMdInitial = minMdIg;
      maxMdInitial = Math.min(mdFinal, maxMdIg);
      minMdFinal = Math.max(mdInitial, minMdIg);
      maxMdFinal = maxMdIg;
    } else {
      throw new Error("Simulation mode not available yet");
    }
  }

  return { minMdInitial, maxMdInitial, minMdFinal, maxMdFinal, initialMdAnnular,finalMdAnnular, initialMdColumn, finalMdColumn };
}

export { minMaxMdInitialAndFinal };
