import React, { createContext, useContext, useState } from "react";

// Create Context Object
const FluidContext = createContext();

// Hook
export default function useFluid() {
  // Fluid States
  const [fluidBody, setFluidBody] = useState([]);

  // Fluid eid
  const [selectedFluid, setSelectedFluid]= useState()


  return { fluidBody, setFluidBody, selectedFluid, setSelectedFluid};
}

// Specific Context Provider
function FluidProvider({ children }) {
  const { fluidBody, setFluidBody,selectedFluid, setSelectedFluid } = useFluid();


  return (
    <FluidContext.Provider
      value={{ fluidBody, setFluidBody, selectedFluid, setSelectedFluid }}
    >
      {children}
    </FluidContext.Provider>
  );
}

export { FluidContext, FluidProvider };