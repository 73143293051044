import React, { createContext, useState } from "react";

// Create Context Object
const HandleErrorContext = createContext();

// Hook
export default function useHandleError() {
    
// Handle Error States
  const [error, setError] = useState([]);

  return { error, setError };
}

// Specific Context Provider
function HandleErrorProvider({ children }) {
  const { error, setError } = useHandleError();

  return (
    <HandleErrorContext.Provider value={{ error, setError }}>
      {children}
    </HandleErrorContext.Provider>
  );
}

export { HandleErrorContext, HandleErrorProvider };
