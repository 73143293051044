import React, { createContext,  useState } from 'react';

// Create Context Object
const ActionFabGridContext = createContext();

// Hook
export default function useModal() {
  // ActiveRowId states
  const [activeRowId, setActiveRowId] = useState("");
    
  return { activeRowId, setActiveRowId};
}

// Specific Context Provider
function ActionFabGridProvider({ children }) {

  const { activeRowId, setActiveRowId } = useModal();

  return (
    <ActionFabGridContext.Provider value={{ activeRowId, setActiveRowId }}>
      {children}
    </ActionFabGridContext.Provider>
  );
}


export { ActionFabGridContext, ActionFabGridProvider };