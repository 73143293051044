// Main import of React
import { React, useState, useContext, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { loadCaptchaEnginge, validateCaptcha } from "react-simple-captcha";

// Local components import
import {
  FormContainer,
  FormInput,
  PageAndContainerTitle,
} from "../components/common";
import petrobrasLogo from "../images/petrobrasLogo.svg";
import lmmp from "../images/LMMP_white.png";
import greo from "../images/GReo_white.png";

import { Captcha } from "../components/common";

// Requests
import { Login } from "../services/LoginServices";

// Context
import { ToastContext } from "../components/contexts/ToastContext";
import useAuth from "../components/contexts/useAuth";

// Style import
import "./style/Login.css";

function LoginPage() {
  // Toast Context
  const { toastList, setToastList, showToast } = useContext(ToastContext);
  
  // Get Token from useAuth
  const {token} = useAuth();

  const [values, setValues] = useState({});

  const history = useHistory();

  useEffect(() => {
    if(!token) {
      loadCaptchaEnginge(6);
    }
  }, []);

  if (token) {
    return <Redirect to="/home"/>
  }

  async function handleSubmit() {
    let user_captcha = document.getElementById("user_captcha_input").value;

    try {
      if (validateCaptcha(user_captcha) == true) {
        await Login(values.user, values.password);
        history.push(`/home`);
        window.location.reload(true);
      } else {
        alert("Captcha está diferente da imagem");
        document.getElementById("user_captcha_input").value = "";
      }
    } catch (error) {
      console.log(error.message);
      // Toast
      showToast(error.response.data, toastList, setToastList);
    }
  }

  /*  function handleKeyDown(e) {
    if (e.key === "Enter") {
      handleSubmit();
    }
  } */

  function onChange(e) {
    setValues({ ...values, [e.target.name]: e.target.value });
  }

  const firstInputSection = [
    {
      id: 1,
      name: "user",
      type: "text",
      placeholder: `Nome do usuário`,
      disabled: false,
      label: "Usuário",
      required: true,
    },
    {
      id: 2,
      name: "password",
      type: "password",
      placeholder: `Senha`,
      disabled: false,
      label: "Senha",
      required: true,
      /*     onKeyDown: handleKeyDown, */
    },
  ];

  return (
    <>
      <div className="login__container">
        <div className="first-login__container">
          <fom className="form__container">
            <div className="form__container-informations">
              <PageAndContainerTitle text="INTERFACES 3D" level="1" />
              <FormContainer>
                {firstInputSection.map((input) => (
                  <FormInput
                    key={input.id}
                    {...input}
                    value={values[input.name]}
                    onChange={onChange}
                  />
                ))}
              </FormContainer>
              <Captcha doSubmit={handleSubmit} />
            </div>
          </fom>
          <div className="logos__container">
            <img src={petrobrasLogo} alt="logo_1" />
          </div>
        </div>
        <footer className="second-login__container">
          <img src={lmmp} alt="logo_2" className="side-footer-img-lmmp" />
          <img src={greo} alt="logo_3" className="side-footer-img-greo" />
        </footer>
      </div>
    </>
  );
}

export default LoginPage;
