/* Tree Component: POÇOWEB tree
filtered by the search, and allowing the user to navigate the tree elements, opening the
folders and cliclikng the wells to select them */

// # Main Import
import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

// # Contexts
import { SearchContext } from "../../contexts/SearchContext";

// # Import Component Style
import "./TreeComponent.css";

function TreeComponent(props) {
  const history = useHistory();

  const { wordValue, setWordValue } = useContext(SearchContext);

  const [show, setShow] = useState(true);

  const name = props.name;
  const data = props.data;
  const sign = show ? (
    <i className="fas fa-folder-open"></i>
  ) : (
    <i className="fas fa-folder"></i>
  );


  useEffect(() => {
    if (wordValue) {
      setShow(true)
    }
  }, [wordValue]);

  const toggleMenu = () => {
    setShow(!show);
  };

/*   const redirectToProjectsByWell = (well) => {
    history.push(`/well/${well}/projects`);
  };
 */
  const treeWrap = data.map((item, index) => {
    if (item.info === null) {
      return (
        <div key={index} className="tree-child__container">
          <button className="bttn-tree-child__container">
            <i className="far fa-folder"></i>
          </button>
          <Link
            className="tree-item-text"
            to={`/well/${item.name}/projects`}
          >
            {item.name}
          </Link>
        </div>
      );
    } else {
      return (
        <div key={index}>
          <TreeComponent name={item.name} data={item.info} />
        </div>
      );
    }
  });

  return (
    <div className="tree-header__container">
      <div className="tree-header__container-bttn-text">
        <button onClick={toggleMenu} className="bttn-tree-header__container">
          {sign}

          <p className="tree-header-text">{name}</p>
        </button>
      </div>
      { show && treeWrap}
    </div>
  );
}

export default TreeComponent;

