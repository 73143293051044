
// # Import Component Style
import "./formContainer.css";

// Form Container Component definition
function FormContainer(props) {

    // Container Available Props
    const borderWidth = props.borderWidth;
    const borderStyle = props.borderStyle;
    const borderColor = props.borderColor;
    const backgroundColor = props.backgroundColor;
    const borderRadius = props.borderRadius;
    const mode = props.mode;
    const columns = props.columns;
    const rows = props.rows;
    const margin = props.margin;
    const gap = props.gap;
    const padding = props.padding
    const fitContent = props.fitContent;

    // Build variable style from props
    const style = {
        borderWidth: borderWidth,
        borderStyle: borderStyle,
        borderColor: borderColor,
        borderRadius: borderRadius,
        gridTemplateColumns: columns,
        gridTemplateRows: rows,
        margin: margin,
        gap: gap,
       }
    if (fitContent) {
        style["maxWidth"]= "max-content";
       }
    if (borderStyle === "none") {
    }
    else {style["padding"]=  padding; }
    


    // Render
    return (  
       
            <div className={"form-container-"+mode} 
                 style={style}
                 >
                {props.children}
            </div>
        
    )
}

FormContainer.defaultProps = {
    borderColor: "#17365c",
    borderWidth:"0px",
    borderRadius: "0rem",
    borderStyle: "none",
    margin:"1em 0em",
    fitContent: false,
    mode:"",
    gap: "1em",
}

export default FormContainer ;