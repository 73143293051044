
  // Accordion Data - Pump Sequence
  export function accordionDataForPumpSequence (id) {
    const accordionData = [
        {
          title: "Cadastro de Fluidos",
          id: "1",
          route: `/case/${id}/pumpsequence/fluids`,
        },
        {
          title: "Fluido no Poço",
          id: "2",
          route: `/case/${id}/pumpsequence/well-fluid`,
        },
        {
          title: "Sequência de Bombeio",
          id: "3",
          route: `/case/${id}/pumpsequence/pumped-sequence`,
        }
      ];
      
      return accordionData
} 


  // Accordion Data - Thermal Profile
export function accordionDataForThermalProfile (id) {
    const accordionData = [
        {
          title: "Temperatura na superfície",
          id: "1",
          route: `/case/${id}/thermal_profile/surface-temperature`,
        },
        {
          title: "Gradiente geotérmico",
          id: "2",
          route: `/case/${id}/thermal_profile/geothermal-gradient`,
        },
      ];
      
      return accordionData
} 

  // Accordion Data - Simulation
  export function accordionDataForSimulation (id) {
    const accordionData = [
        {
          title: "Simulação Parcial",
          id: "1",
          route: `/case/${id}/simulation/partial-simulation`,
        },
        {
          title: "Condições de Contorno",
          id: "2",
          route: `/case/${id}/simulation/boundary-conditions`,
        },
        {
          title: "Movimentação de Coluna",
          id: "3",
          route: `/case/${id}/simulation/column-movement`,
        },
        {
          title: "Parâmetros Avançados",
          id: "4",
          route: `/case/${id}/simulation/advanced-params`,
        },
      ];
      
      return accordionData
} 


  // Accordion Data - Geometry
  export function accordionDataForGeometry (id) {
    const accordionData = [
        {
          title: "Geometria Externa",
          id: "1",
          route: `/case/${id}/geometry/external-geometry`,
        },
        {
          title: "Geometria Interna",
          id: "2",
          route: `/case/${id}/geometry/internal-geometry`,
        },
        {
          title: "Centralização",
          id: "3",
          route: `/case/${id}/geometry/centralization`,
        },
        {
          title: "Trajetória",
          id: "4",
          route: `/case/${id}/geometry/trajectory`,
        },
      ];

      return accordionData;
    } 
