// Main import of React
import { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useQuery } from "react-query";

import { Route } from "react-router";

// #  Local SubComponents & utils
import {
  PlotlyChart,
  ActionFabEditableGrid,
  Tabs,
  DropletLoadingSpinner,
  FormContainer,
  FormInput
} from "../components/common";

import { mergedObject, reload } from "../components/utils";

// Requests
import { calcCentralizationChart } from "../services/ChartsRequests";

// draw Geometry utils
import {
  draw3DTrajectory,
  drawCentralization,
  getGeometryLimits,
  drawInternalGeometry,
  drawExternalGeometry,
} from "../components/utils";

// Data to populate headers of grids - Geometry
import {
  headersOpenRole,
  headersCasingAndWorkColumn,
  headersCentralization,
  headersTrajectory,
} from "../data/headersForGrids";

//Functions to  create rows - Geometry
import { createRows } from "../components/utils";

//Functions to add rows - Geometry
import { addTableRows } from "../components/utils/functionsToAddRows";

//Functions to delete rows - Geometry
import { deleteTableRows } from "../components/utils/functionsToDeleteRows";

//Functions to  duplicate rows - Geometry
import {
  duplicateRowsCasingAndWorkColumn,
  duplicateRowOpenRole,
  duplicateRowsCentralization,
  duplicateRowTrajectory,
} from "../components/utils/functionToDuplicateRows";

// # Context
import { ModalContext } from "../components/contexts/ModalContext";
import { UpdatedJsonContext } from "../components/contexts/UpdatedJsonContext";
import { HistoryContext } from "../components/contexts/HistoryContext";

// Hooks
import useChartSize from "../hooks/useChartSize";

function GeometryGraphs(props) {
  let json = props.json;

  const scenerySimMode = json.scenery.simulation_mode.value;

  // Variables to obtain the path name
  const history = props.history;
  const pathName = history.location.pathname.split("/")[4];

  // Updated Rows and Tags
  const { updatedJson, tag , trajectoryBody, setTrajectoryBody} = useContext(UpdatedJsonContext);

  /*   const countGridLines = props.countGridLines;

  const textAndLineNumbers = props.textAndLineNumbers; */

  const [rLimits, zLimits] = getGeometryLimits(
    mergedObject(json["internal_geometry"], updatedJson),
    mergedObject(json["external_geometry"], updatedJson),
    json["scenery"]
  );

  // Define centralization chart data state
  const[centralizationChartData, setCentralizationChartData] = useState()

  // Variable to access the new width and height of the chart
  const chartSize = useChartSize(".plotly__container", pathName);

  function deleteKey (obj, prop) {
    let res = Object.assign({}, obj)
    delete res[prop]
    return res
  }

  // Define useEffect policy
  useEffect(() => {
    if(!trajectoryBody) {
      setTrajectoryBody(deleteKey(json["well_trajectory"], "trajectory"))
    }
    if (json && scenerySimMode !== "column" && pathName === "centralization") {
      calcCentralizationChart(mergedObject(json, updatedJson)).then((res) => {
        if (res) {
          setCentralizationChartData(res);
        }
      });
    }
    reload();
  }, [updatedJson, tag, json]);

  // Layout for External Geometry Graphic
  let layoutExternalGeometry = {
    title: "<b>Relação de Diâmetros<b>",

    xaxis: {
      range: rLimits,

      showgrid: true,
      title: "<b>Raio (pol)<b>",
      color: "black",
    },

    yaxis: {
      range: zLimits,
      title: "<b>Prof. Medida (m)<b>",
      color: "black",
    },

    width: chartSize ? chartSize.width : 600,
    height: 700,

    shapes: drawExternalGeometry(
      Object.keys(updatedJson).length > 0
        ? mergedObject(json["external_geometry"], updatedJson)
        : json["external_geometry"],
      json["scenery"],
      [rLimits, zLimits],
      tag
    ),
  };

  // Layout for Internal Geometry Graphic
  const layoutInternalGeometry = {
    title: "<b>Relação de Diâmetros<b>",

    xaxis: {
      range: rLimits,

      showgrid: true,
      title: "<b>Raio (pol)<b>",
      color: "black",
    },

    yaxis: {
      range: zLimits,
      title: "<b>Prof. Medida (m)<b>",
      color: "black",
    },

    width: chartSize ? chartSize.width : 600,
    height: 700,

    shapes: drawInternalGeometry(
      Object.keys(updatedJson).length > 0
        ? mergedObject(json["internal_geometry"], updatedJson)
        : json["internal_geometry"],
      json["scenery"],
      [rLimits, zLimits],
      tag
    ),
  };

  // Layout for Centralization Graphic
  const layoutCentralization = {
    font: {
      size: 18,
      color: "black",
      xref: "x",
      yref: "y",
      x: 0.05,
    },
    annotations: [
      {
        x: centralizationChartData ? -(centralizationChartData.OD / 2) : -10,
        y: centralizationChartData
          ? 1.25 * (centralizationChartData.OD / 2)
          : 10,
        xref: "x",
        yref: "y",
        text: centralizationChartData
          ? "Standoff Mín.: " +
            Math.round(centralizationChartData.SO * 100) / 100 +
            "%"
          : "Standoff Mín.: 0%",
        showarrow: false,
      },
      {
        x: centralizationChartData ? centralizationChartData.OD / 2 : 10,
        y: centralizationChartData
          ? 1.25 * (centralizationChartData.OD / 2)
          : 10,
        xref: "x",
        yref: "y",
        text: centralizationChartData
          ? "Ângulo: " + Math.round(centralizationChartData.SA * 10) / 10 + "°"
          : "Ângulo: 0°",
        showarrow: false,
        autorange: true,
      },
    ],

    xaxis: {
      range: centralizationChartData
        ? [
            -1.2 * (centralizationChartData.OD / 2),
            1.2 * (centralizationChartData.OD / 2),
          ]
        : "",
      visible: true,
      zeroline: true,
      tickvals: [0],
      ticktext: ["270°"],
    },

    yaxis: {
      range: centralizationChartData
        ? [
            -1.2 * (centralizationChartData.OD / 2),
            1.3 * (centralizationChartData.OD / 2),
          ]
        : "",
      visible: true,
      tickvals: [0],
      ticktext: ["180°"],
    },
    width: 400,
    height: 400,

    shapes: centralizationChartData
      ? drawCentralization(
          centralizationChartData.OD,
          centralizationChartData.ID,
          centralizationChartData.EX,
          centralizationChartData.EY
        )
      : "",
  };

  // Layout for Trajectory
  const layoutTrajectory = {
    width: chartSize ? chartSize.width : 700,
    height: 700,
    margin: {
      l: 1,
      r: 1,
      b: 1,
      t: 1,
      pad: 4,
    },
    font: {
      size: 16,
      color: "black",
    },
    paper_bgcolor: "rgba(0,0,0,0)",
    scene: {
      aspectmode: "data",
      xaxis: { title: "x (m)" },
      yaxis: { title: "y (m)" },
      zaxis: { title: "z (m)" },
       camera: {
         center: {
           x: 0,
           y: 0,
           z: 0,
       },
         eye: {
          x: 2.5,
          y: 1,
          z: 1,
         },
       },
    },
  };

  // Defining constants for Standoff Angle Chart
  const measuredDepth =
     updatedJson.centralization
      ? updatedJson.centralization.map(
          (item) => item.centralization_point.measured_depth
        )
      : json.centralization.map(
          (item) => item.centralization_point.measured_depth
        );
  const standOffAngle =
      updatedJson.centralization
      ? updatedJson.centralization.map(
          (item) => item.centralization_point.standoff
        )
      : json.centralization.map((item) => item.centralization_point.standoff);

  

  // Data and Layout for Standoff Angle Chart
  const dataStandOffAngle = [
    {
      x: standOffAngle,
      y: measuredDepth,
      mode: "lines",
      type: "scatter",
      name: "Centralização",
    },
  ];

  const layoutStandOffAngle = {
    width: 250,
    height: 600,
    font: {
      size: 16,
      color: "black",
    },

    xaxis: {
      tickangle: 0,
      ticks: "outside",
      range: [Math.min(...standOffAngle)*0.99, Math.max(...standOffAngle)*1.01],
      showgrid: true,
      showline: true,
      mirror: "ticks",
      linecolor: "black",

      side: "top",
      title: {
        text: "Standoff [%]",
      },
    },
    yaxis: {
      range: [Math.max(...measuredDepth), Math.min(...measuredDepth)],
      showline: true,
      mirror: "ticks",
      linecolor: "black",

      title: {
        text: "Comprimento [m]",
      },
    },
  };

  return (
    <div className="plotly__container">
      <>
        <Route exact path="/case/:id/geometry/external-geometry">
          {layoutExternalGeometry ? (
            <div className="plotly__chart">
              <PlotlyChart layout={layoutExternalGeometry} /> 
            </div>
          ) : (
            <DropletLoadingSpinner spinnerPosition={"40%"} />
          )}
        </Route>
        <Route exact path="/case/:id/geometry/internal-geometry">
          {layoutInternalGeometry ? (
            <div className="plotly__chart">
              <PlotlyChart layout={layoutInternalGeometry} />
            </div>
          ) : (
            <DropletLoadingSpinner spinnerPosition={"40%"} />
          )}
        </Route>
        <Route exact path="/case/:id/geometry/centralization">
          {scenerySimMode !== "column" &&
          layoutCentralization &&
          centralizationChartData  ? (
            <div className="plotly__chart-centralization">
              <PlotlyChart layout={layoutCentralization} />
              <PlotlyChart
                layout={layoutStandOffAngle}
                data={dataStandOffAngle}
              />
            </div>
          ) : (
            <DropletLoadingSpinner spinnerPosition={"40%"} />
          )}
        </Route>
        <Route exact path="/case/:id/geometry/trajectory">
          {layoutTrajectory ? (
            <div className="plotly__chart">
              <PlotlyChart
                data={draw3DTrajectory(mergedObject(json, updatedJson), trajectoryBody ? trajectoryBody : json["well_trajectory"] )}
                layout={layoutTrajectory}
              />
            </div>
          ) : (
            <DropletLoadingSpinner spinnerPosition={"40%"} />
          )}
        </Route>
      </>
    </div>
  );
}

function GeometryAccordionChild(props) {
  
  // Hook gives access to the history instance
  const history = useHistory();

  // Query params of route
  const caseMode = history.location.search.includes("?view=true");

  // Defining height for Grid Fab Container
  const gridContainerHeight = "55vh";
  const gridContainerHeightCentTraj = "68vh";

  const tags = [
    "last_casing",
    "open_hole",
    "work_column",
    "casing",
    "centralization",
    "trajectory",
  ];
  const tagsItems = [
    "last_casing_element_item",
    "open_hole_element_item",
    "pipe_element_item",
    "casing_element_item",
    "centralization_point",
    "trajectory_point",
  ];

  /* Props to populate  External Geometry */
  const lastCasing = props.casePropsById.external_geometry.last_casing;
  const openRole = props.casePropsById.external_geometry.open_hole;

  /* Props to populate  Internal Geometry */
  const workColumn = props.casePropsById.internal_geometry.work_column;
  const casing = props.casePropsById.internal_geometry.casing;

  /* Props to populate  Centralization */
  const centralization = props.casePropsById.centralization;

  const scenerySimMode = props.casePropsById.scenery.simulation_mode.value;

  /* Props to populate  Trajectory */
  const trajectory = props.casePropsById.well_trajectory.trajectory.filter(
    (n) => n
  );

  /* Props to populate Well Trajectory */
  const wellTrajectory = props.casePropsById.well_trajectory

  // States to Update Inital Azymuth and Initial Inclination - Geometry
  const [values, setValues] = useState(wellTrajectory);

  /* States to control External Geometry Grid with FAB */
  const [rowsLastCasing, setRowsLastCasing] = useState(
    createRows(lastCasing, headersCasingAndWorkColumn)
  );
  const [rowsOpenRole, setRowsOpenRole] = useState(
    createRows(openRole, headersOpenRole)
  );

  /* States to control Internal Geometry Grid with FAB */
  const [rowsWorkColumn, setRowsWorkColumn] = useState(
    createRows(workColumn, headersCasingAndWorkColumn)
  );
  const [rowsCasing, setRowsCasing] = useState(
    createRows(casing, headersCasingAndWorkColumn)
  );

  /* States to control Centralization Grid with FAB */
  const [rowsCentralization, setRowsCentralization] = useState(
    createRows(centralization, headersCentralization)
  );

  /* States to control Trajectory Grid with FAB */
  const [rowsTrajectory, setRowsTrajectory] = useState(
    createRows(trajectory, headersTrajectory)
  );

  // State to control Tabs Component
  const [activeGeometryTab, setActiveGeometryTab] = useState("");

  // State to setIsModalOpen
  const { setIsModalOpen } = useContext(ModalContext);

  // State to Update Simulation Partial and Advanced parameters - simulation
  const {trajectoryBody, setTrajectoryBody, updatedJson, setUpdatedJson} = useContext(UpdatedJsonContext);

  /* State to set History Location Pathname and possible changes at onChange function */
  const { setHasChange } = useContext(HistoryContext);

  // First Line Dependecy Structure
  let firstLineDependencyStructure = {
    initalValueMd: {
      // MD - Work Column (Internal Geometry) - Air Gap
      "Coluna de Trabalho": Number(props.casePropsById.scenery.air_gap) * -1,
      // MD - Casing (Internal Geometry) - Work Column (Min Value)
      Revestimento: Math.max(
        Math.max(
          ...rowsWorkColumn.map((item) => Number(item.measured_depth)),
          Number(props.casePropsById.scenery.air_gap) * -1
        )
      ),
      // MD - Last Casing (External Geometry) - Riser MD
      "Revestimento anterior": Number(props.casePropsById.scenery.water_depth),
      // MD - Open Role (External Geometry) - Last Casing (Min Value)
      "Poço aberto": Math.max(
        Math.max(...rowsLastCasing.map((item) => Number(item.measured_depth))),
        Number(props.casePropsById.scenery.water_depth)
      ),
    },
    initialValueOd: {
      // OD - Work Column (Internal Geometry) - Riser ID
      "Coluna de Trabalho": Number(props.casePropsById.scenery.riser_id),
    },
    
    tab: activeGeometryTab,
  };

  // Define useEffect policy
  useEffect(() => {
    if (history.location.pathname.endsWith("/external-geometry")) {
      setActiveGeometryTab("Revestimento anterior");
    } else if (history.location.pathname.endsWith("/internal-geometry")) {
      setActiveGeometryTab("Coluna de Trabalho");
    }
  }, []);

  /*Tabs Data*/
  const dataTabSequenciaExternalGeometry = [
    {
      id: "Revestimento anterior",
      description: "Revestimento anterior",
      name: "Revestimento anterior",
    },
    {
      id: "Poço aberto",
      description: "Poço aberto",
      name: "Poço aberto",
    },
  ];

  const dataTabSequenciaInternalGeometry = [
    {
      id: "Coluna de Trabalho",
      description: "Coluna de Trabalho",
      name: "Coluna de Trabalho",
    },
    {
      id: "Revestimento",
      description: "Revestimento",
      name: "Revestimento",
    },
  ];

  // InputSection - Initial Azymuth and Initial Inclination
  const inputSection = [
    {
      id: 1,
      name: "initial_azymuth",
      value: values["initial_azymuth"],
      type: "number",
      placeholder: "Valor",
      errorMessage: `O valor deve ser maior que 0.` ,
      label: "Azimute Inicial",
      required: true,
      min: "0",
      max:  "> 0" ,
    },
    {
      id: 2,
      name: "initial_inclination",
      value: values["initial_inclination"],
      type: "number",
      placeholder: "Valor",
      errorMessage:`O valor deve ser maior que 0.`,
      label: "Inclinação Inicial",
      required: true,
      min: "0",
      max:  "> 0" ,
    },
  ];

  // Function to change Initial Azymuth and Initial Inclination values
  function onChange(e) {
    let changedGridValues = Object.keys(updatedJson).length > 0 ? updatedJson : { trajectory: trajectory }
    setValues({...values, [e.target.name]: e.target.value });
    setTrajectoryBody({...trajectoryBody, [e.target.name]: e.target.value })
    setUpdatedJson({...changedGridValues})
    setHasChange(true);
  }
  
  /*Function to change id Tab*/
  const handleClick = (id) => {
    setActiveGeometryTab(id);
  };

  return (
    <>
      <div key={1}>
        <Route exact path="/case/:id/geometry/external-geometry">
          <Tabs
            data={dataTabSequenciaExternalGeometry}
            activeTab={activeGeometryTab}
            setActiveTab={setActiveGeometryTab}
            callback={handleClick}
          >
            <div key={"Revestimento anterior"}>
              <ActionFabEditableGrid
                metaData={headersCasingAndWorkColumn}
                tagsItems={tagsItems[0]}
                tags={tags[0]}
                rows={rowsLastCasing ? rowsLastCasing : []}
                oldRows={createRows(lastCasing, headersCasingAndWorkColumn)}
                dataJson={lastCasing}
                setRows={setRowsLastCasing}
                addTableRows={addTableRows}
                deleteTableRows={deleteTableRows}
                duplicateRow={duplicateRowsCasingAndWorkColumn}
                gridContainerHeight={gridContainerHeight}
                dependencyStructure={firstLineDependencyStructure}
              />
            </div>
            <div key={"Poço aberto"}>
              <ActionFabEditableGrid
                metaData={headersOpenRole}
                tagsItems={tagsItems[1]}
                tags={tags[1]}
                rows={rowsOpenRole}
                oldRows={createRows(openRole, headersOpenRole)}
                dataJson={openRole}
                setRows={setRowsOpenRole}
                addTableRows={addTableRows}
                deleteTableRows={deleteTableRows}
                duplicateRow={duplicateRowOpenRole}
                gridContainerHeight={gridContainerHeight}
                dependencyStructure={firstLineDependencyStructure}
              />
            </div>
          </Tabs>
        </Route>
      </div>
      <div key={2}>
        <Route exact path="/case/:id/geometry/internal-geometry">
          <Tabs
            data={dataTabSequenciaInternalGeometry}
            activeTab={activeGeometryTab}
            setActiveTab={setActiveGeometryTab}
            callback={handleClick}
          >
            <div key={"Coluna de Trabalho"}>
              <ActionFabEditableGrid
                metaData={headersCasingAndWorkColumn}
                tags={tags[2]}
                tagsItems={tagsItems[2]}
                rows={rowsWorkColumn ? rowsWorkColumn : []}
                oldRows={createRows(workColumn, headersCasingAndWorkColumn)}
                dataJson={workColumn}
                setRows={setRowsWorkColumn}
                addTableRows={addTableRows}
                deleteTableRows={deleteTableRows}
                duplicateRow={duplicateRowsCasingAndWorkColumn}
                gridContainerHeight={gridContainerHeight}
                dependencyStructure={firstLineDependencyStructure}
              />
            </div>
            <div key={"Revestimento"}>
              <ActionFabEditableGrid
                metaData={headersCasingAndWorkColumn}
                tags={tags[3]}
                tagsItems={tagsItems[3]}
                rows={rowsCasing}
                oldRows={createRows(casing, headersCasingAndWorkColumn)}
                dataJson={casing}
                setRows={setRowsCasing}
                addTableRows={addTableRows}
                deleteTableRows={deleteTableRows}
                duplicateRow={duplicateRowsCasingAndWorkColumn}
                gridContainerHeight={gridContainerHeight}
                dependencyStructure={firstLineDependencyStructure}
              />
            </div>
          </Tabs>
        </Route>
      </div>
      {scenerySimMode !== "column" && (
        <div key={3}>
          <Route exact path="/case/:id/geometry/centralization">
            <ActionFabEditableGrid
              metaData={headersCentralization}
              tags={tags[4]}
              tagsItems={tagsItems[4]}
              rows={rowsCentralization ? rowsCentralization : []}
              oldRows={createRows(centralization, headersCentralization)}
              dataJson={centralization}
              setRows={setRowsCentralization}
              addTableRows={addTableRows}
              deleteTableRows={deleteTableRows}
              duplicateRow={duplicateRowsCentralization}
              gridContainerHeight={gridContainerHeightCentTraj}
            />
          </Route>
        </div>
      )}
      <div key={4}>
        <Route exact path="/case/:id/geometry/trajectory">
         <FormContainer mode="space-between">
            {inputSection.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={input.value}
                onChange={onChange}
                disabled={caseMode}
                className="first-inputs"
              />
            ))} 
          </FormContainer> 
          <ActionFabEditableGrid
            metaData={headersTrajectory}
            tags={tags[5]}
            tagsItems={tagsItems[5]}
            rows={rowsTrajectory ? rowsTrajectory : []}
            oldRows={createRows(trajectory, headersTrajectory)}
            dataJson={trajectory}
            setRows={setRowsTrajectory}
            callback={setIsModalOpen}
            addTableRows={addTableRows}
            deleteTableRows={deleteTableRows}
            duplicateRow={duplicateRowTrajectory}
            gridContainerHeight={gridContainerHeight}
          />
        </Route>
      </div>
    </>
  );
}

export { GeometryGraphs, GeometryAccordionChild };
