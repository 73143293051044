// Definition of variables width and height
const chartWidth = 500;
const chartHeight = 300;

// Json result graphs
export const resultsData = [
    {
        "name": "Gráficos",
        "icon": "",
        "tree_info_results": [
            {
                "name": "Séries Consolidadas",
                "icon": "",
                "info": [
                    {
                        "name": "Densidade na saída",
                        "icon": "",
                        "info": null,
                        "graph_results": {
                            "id": 1,
                            "data": [
                                {
                                    "x": [
                                        1,
                                        100,
                                        200,
                                        300,
                                        400,
                                        500,
                                        600,
                                        700,
                                        800
                                    ],
                                    "y": [
                                        10,
                                        10,
                                        5,
                                        5,
                                        8,
                                        8,
                                        10,
                                        10,
                                        5
                                    ],
                                    "name": "Rotação da Coluna",
                                    "type": "line",
                                    "mode": "line",
                                    "marker": {
                                        "color": "#0000AA"
                                    }
                                }
                            ],
                            "layout": {
                                "width": { "small": chartWidth, "medium": 1.5 * chartWidth, "large": 3 * chartWidth },
                                "height": { "small": chartHeight, "medium": 1.5 * chartHeight, "large": 3 * chartHeight },
                                "plot_bgcolor": "#ffffff",
                                "title": "<b>Densidade na saída</b>",
                                "font": {
                                    "family": "Arial",
                                    "size": "16",
                                    "color": "black"
                                },
                                "xaxis": {
                                    "title": "<b>Tempo (s)</b>",
                                    "zeroline": false
                                },
                                "yaxis": {
                                    "title": "<b>Rotação (RPM)</b>",
                                    "zeroline": false
                                }
                            }
                        }
                    },
                    {
                        "name": "Volume no poço por fluido",
                        "icon": "",
                        "info": null,
                        "graph_results": {
                            "id": 2,
                            "data": [
                                {
                                    "x": [
                                        1,
                                        100,
                                        200,
                                        300,
                                        400,
                                        500,
                                        600,
                                        700,
                                        800
                                    ],
                                    "y": [
                                        10,
                                        10,
                                        5,
                                        5,
                                        8,
                                        8,
                                        10,
                                        10,
                                        5
                                    ],
                                    "name": "Rotação da Coluna",
                                    "type": "line",
                                    "mode": "line",
                                    "marker": {
                                        "color": "#0000AA"
                                    }
                                }
                            ],
                            "layout": {
                                "width": { "small": chartWidth, "medium": 1.5 * chartWidth, "large": 3 * chartWidth },
                                "height": { "small": chartHeight,"medium": 1.5*chartHeight,"large": 3*chartHeight },
                                "plot_bgcolor": "#ffffff",
                                "title": "<b>Volume no poço</b>",
                                "font": {
                                    "family": "Arial",
                                    "size": "16",
                                    "color": "black"
                                },
                                "xaxis": {
                                    "title": "<b>Tempo (s)</b>",
                                    "zeroline": false
                                },
                                "yaxis": {
                                    "title": "<b>Rotação (RPM)</b>",
                                    "zeroline": false
                                }
                            }
                        }
                    }
                ]
            },
            {
                "name": "Campos 2D",
                "icon": "",
                "info": [
                    {
                        "name": "Fração dos fluidos",
                        "icon": "",
                        "info": null,
                        "graph_results": {
                            "id": 3,
                            "data": [
                                {
                                    "x": [
                                        1,
                                        2,
                                        3,
                                        4,
                                        5
                                    ],
                                    "y": [
                                        10,
                                        20,
                                        15,
                                        25,
                                        30
                                    ],
                                    "name": "Fração dos fluídos 2D",
                                    "type": "scatter",
                                    "mode": "markers",
                                    "marker": {
                                        "color": "#00FF00"
                                    }
                                }
                            ],
                            "layout": {
                                "width": { "small": chartWidth, "medium": 1.5 * chartWidth, "large": 3 * chartWidth },
                                "height": { "small": chartHeight,"medium": 1.5*chartHeight,"large": 3*chartHeight },
                                "plot_bgcolor": "#ffffff",
                                "title": "<b>Fração dos fluidos</b>",
                                "font": {
                                    "family": "Arial",
                                    "size": "16",
                                    "color": "black"
                                },
                                "xaxis": {
                                    "title": "<b>Eixo X</b>",
                                    "zeroline": false
                                },
                                "yaxis": {
                                    "title": "<b>Eixo Y</b>",
                                    "zeroline": false
                                }
                            }
                        }
                    },
                    {
                        "name": "Pressão",
                        "icon": "",
                        "info": null,
                        "graph_results": {
                            "id": 4,
                            "data": [
                                {
                                    "x": [
                                        1,
                                        2,
                                        3,
                                        4,
                                        5
                                    ],
                                    "y": [
                                        5,
                                        8,
                                        10,
                                        12,
                                        15
                                    ],
                                    "name": "G. Pressão",
                                    "type": "bar"
                                }
                            ],
                            "layout": {
                                "width": { "small": chartWidth, "medium": 1.5 * chartWidth, "large": 3 * chartWidth },
                                "height": { "small": chartHeight,"medium": 1.5*chartHeight,"large": 3*chartHeight },
                                "plot_bgcolor": "#ffffff",
                                "title": "<b>Gráfico de Pressão</b>",
                                "font": {
                                    "family": "Arial",
                                    "size": "16",
                                    "color": "black"
                                },
                                "xaxis": {
                                    "title": "<b>Eixo X</b>",
                                    "zeroline": false
                                },
                                "yaxis": {
                                    "title": "<b>Eixo Y</b>",
                                    "zeroline": false
                                }
                            }
                        }
                    },
                    {
                        "name": "Velocidade axial",
                        "icon": "",
                        "info": null,
                        "graph_results": {
                            "id": 5,
                            "data": [
                                {
                                    "x": [
                                        1,
                                        2,
                                        3,
                                        4,
                                        5
                                    ],
                                    "y": [
                                        5,
                                        8,
                                        10,
                                        12,
                                        15
                                    ],
                                    "name": "V. axial",
                                    "type": "bar"
                                }
                            ],
                            "layout": {
                                "width": { "small": chartWidth, "medium": 1.5 * chartWidth, "large": 3 * chartWidth },
                                "height": { "small": chartHeight,"medium": 1.5*chartHeight,"large": 3*chartHeight },
                                "plot_bgcolor": "#ffffff",
                                "title": "<b>Velocidade axial</b>",
                                "font": {
                                    "family": "Arial",
                                    "size": "16",
                                    "color": "black"
                                },
                                "xaxis": {
                                    "title": "<b>Eixo X</b>",
                                    "zeroline": false
                                },
                                "yaxis": {
                                    "title": "<b>Eixo Y</b>",
                                    "zeroline": false
                                }
                            }
                        }
                    },
                    {
                        "name": "Reynolds ( turbulência)",
                        "icon": "",
                        "info": null,
                        "graph_results": {
                            "id": 6,
                            "data": [
                                {
                                    "x": [
                                        1,
                                        2,
                                        3,
                                        4,
                                        5
                                    ],
                                    "y": [
                                        5,
                                        8,
                                        10,
                                        12,
                                        15
                                    ],
                                    "name": "G. Reynolds",
                                    "type": "bar",
                                    "mode": "line",
                                    "marker": {
                                        "color": "#0000AA"
                                    }
                                }
                            ],
                            "layout": {
                                "width": { "small": chartWidth, "medium": 1.5 * chartWidth, "large": 3 * chartWidth },
                                "height": { "small": chartHeight,"medium": 1.5*chartHeight,"large": 3*chartHeight },
                                "plot_bgcolor": "#ffffff",
                                "title": "<b>Gráfico Reynolds</b>",
                                "font": {
                                    "family": "Arial",
                                    "size": "16",
                                    "color": "black"
                                },
                                "xaxis": {
                                    "title": "<b>Eixo X</b>",
                                    "zeroline": false
                                },
                                "yaxis": {
                                    "title": "<b>Eixo Y</b>",
                                    "zeroline": false
                                }
                            }
                        }
                    }
                ]
            },
            {
                "name": "Campos 3D",
                "icon": "",
                "info": [
                    {
                        "name": "Fração dos fluidos",
                        "icon": "",
                        "info": null,
                        "graph_results": {
                            "id": 7,
                            "data": [
                                {
                                    "x": [
                                        1,
                                        100,
                                        200,
                                        300,
                                        400,
                                        500,
                                        600,
                                        700,
                                        800
                                    ],
                                    "y": [
                                        10,
                                        10,
                                        5,
                                        5,
                                        8,
                                        8,
                                        10,
                                        10,
                                        5
                                    ],
                                    "name": "Rotação da Coluna",
                                    "type": "line",
                                    "mode": "line",
                                    "marker": {
                                        "color": "#0000AA"
                                    }
                                }
                            ],
                            "layout": {
                                "width": { "small": chartWidth, "medium": 1.5 * chartWidth, "large": 3 * chartWidth },
                                "height": { "small": chartHeight,"medium": 1.5*chartHeight,"large": 3*chartHeight },
                                "plot_bgcolor": "#ffffff",
                                "title": "<b>Fração dos fluídos 3D</b>",
                                "font": {
                                    "family": "Arial",
                                    "size": "16",
                                    "color": "black"
                                },
                                "xaxis": {
                                    "title": "<b>Tempo (s)</b>",
                                    "zeroline": false
                                },
                                "yaxis": {
                                    "title": "<b>Rotação (RPM)</b>",
                                    "zeroline": false
                                }
                            }
                        }
                    }
                ]
            }
        ]
    }
]