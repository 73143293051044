// Import axios from specific API definition
import axios from "./apis/i3d_sqlAPI";

// Import Authorization Header
import {authorizationHeader} from "./LoginServices";

// GET Method Request for Notifications - By User
async function getNotificationsByUser() {
    try {
      const response = await axios.get(`notifications/user/`, {
        headers: {
          Authorization: authorizationHeader,
        },
      });
      return response.data.data ;
    } catch (error) {
      console.log(error.message);
    }
};

export{getNotificationsByUser}