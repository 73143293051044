// Utils
import { decimalNumByKey, treatInteger } from "../components/utils";


function autoCalculation_measured_depth(newArr,rowID, field, dependencyStructure) {
  // Active Tab
  let tab = dependencyStructure.tab;

  // Decimal number considering field
  let decimalNum = decimalNumByKey[field]

  if (field === "measured_depth" && rowID > 0) {
    newArr[rowID]["segment_length"] = treatInteger(Number(newArr[rowID][field]) - Number(newArr[rowID - 1][field]), decimalNum)
  } else if (field === "measured_depth" && rowID === 0) {
    newArr[rowID]["segment_length"] =
      treatInteger(Number(newArr[rowID][field]) -
      Number(dependencyStructure.initalValueMd[tab]),decimalNum);
  }
  newArr.map((row, index) => {
    if (index > rowID) {
      newArr[index]["measured_depth"] =
        treatInteger(Number(newArr[index - 1]["measured_depth"]) +
        Number(newArr[index]["segment_length"]), decimalNum)
    }
  });

  return newArr;
}
function autoCalculation_segment_length(newArr, rowID, field, dependencyStructure) {
  // Active Tab
  let tab = dependencyStructure.tab;

  // Decimal number considering field
  let decimalNum = decimalNumByKey[field]

  if (field === "segment_length" && rowID > 0) {
    newArr[rowID]["measured_depth"] = treatInteger(
      Number(newArr[rowID - 1]["measured_depth"]) +
      Number(newArr[rowID][field]), decimalNum);
  } else if (field === "segment_length" && rowID === 0) {
    newArr[rowID]["measured_depth"] = treatInteger(
      Number(dependencyStructure.initalValueMd[tab]) +
      Number(newArr[rowID][field]),decimalNum);
  }
  newArr.map((row, index) => {
    if (index > rowID) {
      newArr[index]["measured_depth"] =
        treatInteger(Number(newArr[index - 1]["measured_depth"]) +
        Number(newArr[index]["segment_length"]), decimalNum);
    }
  });
  return newArr;
}

function autoCalculation_trajectory_field(newArr, rowID, field) {
  let segment_length = [];

  // Decimal number considering field
  let decimalNum = decimalNumByKey[field]

  if (rowID == 0) {
    segment_length[rowID] = treatInteger(Number(Number(newArr[rowID]["measured_depth"])), decimalNum);

    newArr[rowID]["tvd"] = treatInteger(Number(Number(newArr[rowID]["measured_depth"])), decimalNum);

    newArr.map((row, index) => {
      if (index > rowID) {
        segment_length[index] = treatInteger(Number(newArr[index]["measured_depth"]) - Number(newArr[index - 1]["measured_depth"]), decimalNum)
      }
    });
  }

  if (field === "measured_depth") {
    if (rowID > 0) {
      segment_length[rowID] = treatInteger(Number(newArr[rowID]["measured_depth"]) - Number(newArr[rowID - 1]["measured_depth"]), decimalNum)

      newArr[rowID]["tvd"] = treatInteger(
        Number(
          Number(newArr[rowID - 1]["tvd"]) + 
          Number(segment_length[rowID]) * Math.cos((Number(newArr[rowID - 1]["inclination"]) * Math.PI) / 180)), decimalNum)
    }

    if (newArr[rowID]["tvd"] <= 0) {
      newArr[rowID]["z"] = 0.0;
    } else {
      newArr[rowID]["z"] = treatInteger(Number(-Number(newArr[rowID]["tvd"])), decimalNum);
    }
  }

  newArr.map((row, index) => {
    if (index > rowID) {
      segment_length[index] = treatInteger(
        Number(newArr[index]["measured_depth"]) -
        Number(newArr[index - 1]["measured_depth"]), decimalNum)

      newArr[index]["tvd"] = treatInteger(Number(
          (
            Number(newArr[index - 1]["tvd"]) +
            Number(segment_length[index]) *
            Math.cos((Number(newArr[index - 1]["inclination"]) * Math.PI) / 180)
          )), decimalNum);

      newArr[index]["x"] = treatInteger(Number(
          (
            Number(newArr[index - 1]["x"]) +
            Number(segment_length[index]) *
            Math.cos((Number(newArr[index - 1]["azimuth"]) * Math.PI) / 180.0) *
            Math.sin(
              (Number(newArr[index - 1]["inclination"]) * Math.PI) / 180.0
            ))), decimalNum);

      newArr[index]["y"] = treatInteger(Number(
          (
            Number(newArr[index - 1]["y"]) +
            Number(segment_length[index]) *
            Math.sin((Number(newArr[index - 1]["azimuth"]) * Math.PI) / 180.0) *
            Math.sin(
              (Number(newArr[index - 1]["inclination"]) * Math.PI) / 180.0
            ))), decimalNum);

      if (Number(newArr[index]["tvd"]) <= 0) {
        newArr[index]["z"] = 0.0;
      } else {
        newArr[index]["z"] = treatInteger(Number(-Number(newArr[index]["tvd"])), decimalNum);
      }
    }
  });

  return newArr;
}




/* External Geometry and Internal Geometry - Last Casing, Casing and Work Column */
export const headersCasingAndWorkColumn = {
  header: [
    { key: "id", label: "#", editable: false, datatype: "number" },
    {
      key: "measured_depth",
      label: "MD (m)",
      editable: true,
      decimalNum: decimalNumByKey["measured_depth"],
      datatype: "number",
      minValue: "0",
      autoCalculation: autoCalculation_measured_depth,
    },
    {
      key: "segment_length",
      label: "Comp. do Segmento (m)",
      editable: true,
      decimalNum: decimalNumByKey["segment_length"],
      datatype: "number",
      minValue: "0",
      autoCalculation: autoCalculation_segment_length,
    },
    {
      key: "outside_diameter",
      label: "OD (pol)",
      editable: true,
      decimalNum: decimalNumByKey["outside_diameter"],
      minValue: "0",
      datatype: "number",
    },
    {
      key: "inside_diameter",
      label: "ID  (pol)",
      editable: true,
      decimalNum: decimalNumByKey["inside_diameter"],
      minValue: "0",
      datatype: "number",
    },
  ],
};

/* External Geometry */

export const headersOpenRole = {
  header: [
    { key: "id", label: "#", editable: false, datatype: "number" },
    {
      key: "measured_depth",
      label: "MD (m)",
      editable: true,
      decimalNum: decimalNumByKey["measured_depth"],
      datatype: "number",
      minValue: "0",
      autoCalculation: autoCalculation_measured_depth,
    },
    {
      key: "segment_length",
      label: "Comp. do Segmento (m)",
      editable: true,
      decimalNum: decimalNumByKey["segment_length"],
      minValue: "0",
      datatype: "number",
      autoCalculation: autoCalculation_segment_length,
    },
    {
      key: "inside_diameter",
      label: "ID (pol)",
      editable: true,
      decimalNum: decimalNumByKey["inside_diameter"],
      minValue: "0",
      datatype: "number",
    },
  ],
};

/*Trajectory*/

export const headersTrajectory = {
  header: [
    { key: "id", label: "#", editable: false, datatype: "number" },
    {
      key: "measured_depth",
      label: "MD (m)",
      editable: true,
      decimalNum: decimalNumByKey["measured_depth"],
      datatype: "number",
      minValue: "0",
      autoCalculation: autoCalculation_trajectory_field,
    },
    {
      key: "inclination",
      label: "Inclinação (°)",
      editable: true,
      decimalNum: decimalNumByKey["inclination"],
      datatype: "number",
      minValue: "0",
      autoCalculation: autoCalculation_trajectory_field,
    },
    {
      key: "azimuth",
      label: "Azimute (°)",
      editable: true,
      decimalNum: decimalNumByKey["azimuth"],
      datatype: "number",
      minValue: "0",
      autoCalculation: autoCalculation_trajectory_field,
    },
    {
      key: "tvd",
      label: "TVD (m)",
      editable: true,
      decimalNum: decimalNumByKey["tvd"],
      datatype: "number",
      minValue: "0",
      autoCalculation: autoCalculation_trajectory_field,
    },
    {
      key: "x",
      label: "",
      editable: false,
      datatype: "number",
      minValue: "0",
      autoCalculation: autoCalculation_trajectory_field,
    },
    {
      key: "y",
      label: "",
      editable: false,
      datatype: "number",
      minValue: "0",
      autoCalculation: autoCalculation_trajectory_field,
    },
    {
      key: "z",
      label: "",
      editable: false,
      datatype: "number",
      minValue: "0",
      autoCalculation: autoCalculation_trajectory_field,
    },
  ],
};

/*Centralization*/

export const headersCentralization = {
  header: [
    { key: "id", label: "#", editable: false, datatype: "number" },
    {
      key: "measured_depth",
      label: "MD (m)",
      editable: true,
      decimalNum: decimalNumByKey["measured_depth"],
      datatype: "number",
      minValue: "0",
    },
    {
      key: "standoff",
      label: "Standoff (%)",
      editable: true,
      decimalNum: decimalNumByKey["standoff"],
      datatype: "number",
      maxValue: "100",
      minValue: "0",
    },
    {
      key: "standoff_angle",
      label: "Ângulo (°)",
      editable: true,
      decimalNum: decimalNumByKey["standoff_angle"],
      datatype: "number",
      maxValue: "360",
      minValue: "0",
    },
  ],
};

/* Pump Sequence*/
export const headersFluids = {
  header: [
    { key: "id", label: "#", editable: false, datatype: "number" },
    { key: "color", label: "Cor", editable: false, datatype: "text" },
    { key: "fluid_name", label: "Fluido", editable: false, datatype: "text" },
  ],
};

export function headersAnnularAndDisplacement(json) {
  let newOption = [];

  if (json.length > 0) {
    for (let index = 0; index < json.length; index += 1) {
      let option = { fid: json[index].eid, label: json[index].fluid_element_item.fluid_name };
      newOption.push(option);
    }
  }

  const header = {
    header: [
      { key: "id", label: "#", editable: false, datatype: "number" },
      {
        key: "fluid_id",
        label: "Fluido",
        editable: true,
        datatype: "select",
        options: newOption,
      },
      {
        key: "volume",
        label: "Volume (bbl)",
        editable: true,
        decimalNum: decimalNumByKey["volume"],
        datatype: "number",
        minValue: "0",
      },
      {
        key: "flow_rate",
        label: "Vazão (bbl/min)",
        editable: true,
        decimalNum: decimalNumByKey["flow_rate"],
        datatype: "number",
        minValue: "0",
      },
    ],
  };
  return header;
}

export function headersDensityTemperatureAndPressure(densityType) {
  if (densityType === "temperature-profile") {
    const header = {
      header: [
        { key: "id", label: "#", editable: false },
        {
          key: "temperature",
          label: "Temperatura (ºC)",
          editable: true,
          decimalNum: decimalNumByKey["temperature"],
          datatype: "number",
          minValue: "0",
        },
        {
          key: "value",
          label: "Densidade(ppg)",
          editable: true,
          decimalNum: decimalNumByKey["value"],
          datatype: "number",
          minValue: "0",
        },
      ],
    };

    return header;
  } else if (densityType === "pressure-profile" ) {
    const header = {
      header: [
        { key: "id", label: "#", editable: false },
        {
          key: "pressure",
          label: "Pressão(psi)",
          editable: true,
          decimalNum: decimalNumByKey["pressure"],
          datatype: "number",
          minValue: "0",
        },
        {
          key: "value",
          label: "Densidade(ppg)",
          editable: true,
          decimalNum: decimalNumByKey["value"],
          datatype: "number",
          minValue: "0",
        },
      ],
    };
    return header;
  } else {
    const header = {
      header: [
        { key: "id", label: "#", editable: false },
        {
          key: "temperature",
          label: "Temperatura (ºC)",
          editable: true,
          decimalNum: decimalNumByKey["temperature"],
          datatype: "number",
          minValue: "0",
        },
        {
          key: "pressure",
          label: "Pressão(psi)",
          editable: true,
          decimalNum: decimalNumByKey["pressure"],
          datatype: "number",
          minValue: "0",
        },
        {
          key: "value",
          label: "Densidade(ppg)",
          editable: true,
          decimalNum: decimalNumByKey["value"],
          datatype: "number",
          minValue: "0",
        },
      ],
    };
    
    return header;
  }
}

export const headersDensityTemperature = {
  header: [
    { key: "id", label: "#", editable: false },
    {
      key: "value",
      label: "Densidade(kg/m³)",
      editable: true,
      decimalNum: decimalNumByKey["value"],
      datatype: "number",
      minValue: "0",
    },
    {
      key: "temperature",
      label: "Temperatura (ºC)",
      editable: true,
      decimalNum: decimalNumByKey["temperature"],
      datatype: "number",
      minValue: "0",
    },
  ],
};

export const headersDensityPressure = {
  header: [
    { key: "id", label: "#", editable: false },
    {
      key: "value",
      label: "Densidade(kg/m³)",
      editable: true,
      decimalNum: decimalNumByKey["value"],
      datatype: "number",
      minValue: "0",
    },
    {
      key: "pressure",
      label: "Pressão(psi)",
      editable: true,
      decimalNum: decimalNumByKey["pressure"],
      datatype: "number",
      minValue: "0",
    },
  ],
};

export const headersRheometry = {
  header: [
    { key: "id", label: "ID", editable: false, datatype: "number" },
    {
      key: "rotation",
      label: "Rotação (RPM)",
      editable: true,
      decimalNum: decimalNumByKey["rotation"],
      datatype: "number",
      minValue: "0",
    },
    {
      key: "reading",
      label: "Grau (º)",
      editable: true,
      decimalNum: 2,
      datatype: "number",
      minValue: "1"
    },
  ],
};

export function headersRheometryModel(tab, model) {
    const headerTemperatureAndPressure = {
      "constants": [
        { key: "id", label: "#", editable: false },
        {
          key: "rheometry_temperature",
          label: "T",
          editable: true,
          decimalNum: decimalNumByKey["rheometry_temperature"],
          datatype: "number",
          minValue: "0",
        }
      ],
      "temperature": [
        { key: "id", label: "#", editable: false },
        {
          key: "rheometry_temperature",
          label: "T",
          editable: true,
          decimalNum: decimalNumByKey["rheometry_temperature"],
          datatype: "number",
          minValue: "0",
        }
      ],
      "pressure": [
        { key: "id", label: "#", editable: false },
        {
          key: "rheometry_pressure",
          label: "P",
          editable: true,
          decimalNum: decimalNumByKey["rheometry_pressure"],
          datatype: "number",
          minValue: "0",
        }
      ],
      "pressure_temperature": [
        { key: "id", label: "#", editable: false },
        {
          key: "rheometry_temperature",
          label: "T",
          editable: true,
          decimalNum: decimalNumByKey["rheometry_temperature"],
          datatype: "number",
          minValue: "0",
        },
        {
          key: "rheometry_pressure",
          label: "P",
          editable: true,
          decimalNum:  decimalNumByKey["rheometry_pressure"],
          datatype: "number",
          minValue: "0",
        }

      ]
    };

  const headerModel = {
    "herschel_bulkley_model_subject": [
      {
        key: "tau_y",
        label: "τy",
        editable: true,
        decimalNum:  decimalNumByKey["tau_y"],
        datatype: "number",
        minValue: "0",
      },
      {
        key: "k",
        label: "k",
        editable: true,
        decimalNum: decimalNumByKey["k"],
        datatype: "number",
        minValue: "0",
      },
      {
        key: "n",
        label: "n",
        editable: true,
        decimalNum: decimalNumByKey["n"],
        datatype: "number",
        minValue: "0",
      }
    ],
    "bingham_model_subject": [
      {
        key: "tau_y",
        label: "τy",
        editable: true,
        decimalNum: decimalNumByKey["tau_y"],
        datatype: "number",
        minValue: "0",
      },
      {
        key: "plastic_viscosity",
        label: "η",
        editable: true,
        decimalNum: decimalNumByKey["plastic_viscosity"],
        datatype: "number",
        minValue: "0",
      }
    ],
    "powerlaw_model_subject": [
      {
        key: "k",
        label: "k",
        editable: true,
        decimalNum: decimalNumByKey["k"],
        datatype: "number",
        minValue: "0",
      },
      {
        key: "n",
        label: "n",
        editable: true,
        decimalNum: decimalNumByKey["n"],
        datatype: "number",
        minValue: "0",
      }

    ],
    "newtonian_model_subject": [
      {
        key: "viscosity",
        label: "μ",
        editable: true,
        decimalNum:decimalNumByKey["viscosity"],
        datatype: "number",
        minValue: "0",
      },

    ]
  }

  const headersRheometryModel = {
    header: tab ? headerTemperatureAndPressure[tab].concat(headerModel[model]): headerTemperatureAndPressure["constants"].concat(headerModel[model]) ,
    model: model === "powerlaw_model_subject" ? "power_law" : model.replace("_model_subject", "")
  }
  return headersRheometryModel
}

/* Thermic Profile - Surface And Formation Tempeture*/
export const headersSurfaceAndFormationTempeture = {
  header: [
    { key: "id", label: "#", editable: false, datatype: "number" },
    {
      key: "tvd",
      label: "Profundidade (m)",
      editable: true,
      decimalNum: decimalNumByKey["tvd"],
      datatype: "number",
      minValue: "0",
    },
    {
      key: "temperature",
      label: "Temperatura (ºC)",
      editable: true,
      decimalNum: decimalNumByKey["temperature"],
      datatype: "number",
      minValue: "0",
    },
  ],
};

/*Simulation*/

export const headerRotationColumn = {
  header: [
    { key: "id", label: "#", editable: false, datatype: "number" },
    { key: "time", label: "Tempo (s)", editable: true,  decimalNum: decimalNumByKey["time"], datatype: "number", minValue: "0" },
    {
      key: "value",
      label: "Rotação (RPM)",
      editable: true,
      decimalNum: 2,
      datatype: "number",
      minValue: "0"
    },
  ],
};

export const headerReciprocationColumn = {
  header: [
    { key: "id", label: "#", editable: false, datatype: "number" },
    { key: "time", label: "Tempo (s)", editable: true,  decimalNum: decimalNumByKey["time"], datatype: "number", minValue: "0" },
    {
      key: "value",
      label: "Reciprocação (RPM)",
      editable: true,
      decimalNum: 2,
      datatype: "number",
      minValue: "0"
    },
  ],
};

export const headerEntranceFlow = {
  header: [
    { key: "id", label: "#", editable: false, datatype: "number" },
    {
      key: "time",
      label: "Tempo (s)",
      editable: true,
      decimalNum: decimalNumByKey["time"],
      datatype: "number",
      minValue: '0'
    },
    {
      key: "value",
      label: "Vazão (bpm)",
      editable: true,
      decimalNum: decimalNumByKey["value"],
      datatype: "number",
      minValue: '0'
    },
  ],
};

export const headerOutPressure = {
  header: [
    {
      key: "id",
      label: "#",
      editable: false,
      decimalNum: 2,
      datatype: "number",
    },
    {
      key: "time",
      label: "Tempo (s)",
      editable: true,
      decimalNum: decimalNumByKey["time"],
      datatype: "number",
      minValue: '0'
    },
    {
      key: "value",
      label: "Pressão (psi)",
      editable: true,
      decimalNum: decimalNumByKey["value"],
      datatype: "number",
      minValue: '0'
    },
  ],
};


/*Notifications*/

export const headerNotifications = {
  header: [
    {
      key: "created_at",
      label: "Data de ocorrência",
      editable: false,
      datatype: "text",
    },
    {
      key: "description",
      label: "Descrição",
      editable: false,
      datatype: "text",
    },
    {
      key: "object",
      label: "Propriedade modificada",
      editable: false,
      datatype: "text",
    },
    {
      key: "object_id",
      label: "Nome do Objeto",
      editable: false,
      datatype: "number",
    },
    {
      key: "user",
      label: "Usuário",
      editable: false,
      datatype: "text",
    },
  ],
};