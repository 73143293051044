// ExpandableGrid = Component to render header and body of tables

// #  Local SubComponents & utils
import { useContext } from "react";
import { useHistory } from "react-router";
import { CheckboxActionIcon, ColorPicker, FixedInput } from "../index";
import ViewActionIcon from "../../fluids/viewActionIcon";

//Contexts
import { UpdatedJsonContext } from "../../contexts/UpdatedJsonContext";

// # Import Component Style
import "./FullyEditableGrid.css";

// # Expandable Action Column React Component Construction
function ActionColumn(props) {
  const id = props.id;

  const rowSelected = props.rowSelected;

  const rows = props.rows;
  const setRows = props.setRows;
  
  //  State for rheometer readings checkbox
  const isChecked = props.isChecked;

  const viewBttnOnClick = props.viewBttnOnClick

  const viewBttn =
    Object.keys(rows[0]).toString().includes("rheometry") && !isChecked;

  // ActiveRowId for ActionFabGrid - CheckboxAction
  const setActiveRowId = props.setActiveRowId;

  return (
    <td className={viewBttn ? "grid__bttn--column": "expandable-grid__actions--column"}>
      <CheckboxActionIcon
        rows={rows}
        rowID={id}
        setRows={setRows}
        rowSelected={rowSelected}
        setActiveRowId={setActiveRowId}
        className={"action_icon_button"}
      />
      <ViewActionIcon
        rows={rows}
        rowID={id}
        isChecked={isChecked}
        viewBttn={viewBttn}
        viewBttnOnClick={viewBttnOnClick}
      />
    </td>
  );
}

function CellInput(props) {

  return props.type === "select" ? (
    <select
      value={props.value.fid}
      className="select-input-component"
      onChange={props.onChange}
      disabled={props.disabled}
    >
      <option disabled selected value>
        Escolha um fluido
      </option>
      {props.options.map((option) => (
        <option
          value={option.fid}
          onChange={props.onChange}
          /* selected={props.value.fid} */
        >
          {option.label}
        </option>
      ))}
    </select>
  ) : (
    <FixedInput
      className={
        props.isChecked === false
          ? "editable-grid-cell-input-unselected"
          : "editable-grid-cell-input"
      }
      type={props.type}
      min={props.minValue ? props.minValue : " "}
      max={props.maxValue ? props.maxValue : " "}
      value={props.value}
      disabled={props.disabled}
      decimalNum={props.decimalNum}
      onChange={props.isChecked === false ? null : props.onChange}
    />
  );
}

// # Expandable Row React Component Construction
function NonEditableRow(props) {
  const metaData = props.metaData;

  const rows = props.rows;
  const setRows = props.setRows;

  const rowdata = props.rowData;

  // ActiveRowId for ActionFabGrid - CheckboxAction
  const setActiveRowId = props.setActiveRowId;

  /* Map just to find the keys of each header */
  const rowOrder = metaData.header.map((index) => index.key);

  return (
    // Case Row assembled by Action Icons and Other Fields
    <tr
      key={"casegrid__row_" + rowdata.id}
      className="case__row"
      id={["case_" + rowdata.id]}
    >
      {/* Action Column */}
      <ActionColumn
        id={rowdata.id}
        rowSelected={rowdata.selected}
        rows={rows}
        setRows={setRows}
        setActiveRowId={setActiveRowId}
      />
      {/* Regular Column $TODO: Separate Columns and Expandable Columns */}
      {rowOrder.map((key) => (
        <td
          key={"casegrid__row" + rowdata.id + "_column" + key}
          className={"non-editable-grid-cell-input"}
        >
          {String(rowdata[key]).startsWith("#") ? (
            <ColorPicker color={rowdata[key]} />
          ) : (
            rowdata[key]
          )}
        </td>
      ))}
    </tr>
  );
}

// # Editable Row React Component Construction
function EditableRow(props) {
  const { setChangedGrid } = useContext(UpdatedJsonContext);

  const history = useHistory();

  // Query params
  const queryParams = history.location.search;

  const metaData = props.metaData;

  const rows = props.rows;
  const setRows = props.setRows;
  const disabled = props.disabled;

  const rowdata = props.rowData;
  const rowID = props.rowID;

  // States to control checkbox - Rotation, Reciprocation, Rheometry */
  const isChecked = props.isChecked;

  // ActiveRowId for ActionFabGrid - CheckboxAction
  const setActiveRowId = props.setActiveRowId;

  // First Line Dependecy Structure
  let dependencyStructure = props.dependencyStructure;

  // setIsModalOpen for Edit Fluid Page
  const setIsModalOpen = props.setIsModalOpen;

  const viewBttnOnClick = props.viewBttnOnClick

  const changedField =
    (
      field,
      datatype,
      options = null,
      autoCalculation = null,
      dependencyStructure = null
    ) =>
    (e) => {
      let newArr = [...rows]; // copying the old datas array
      // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
      if (datatype === "select") {
        let selectedOption = options.filter(
          (item) => item.fid === e.target.value.toString()
        );
        newArr[rowID][field] = selectedOption[0];
        /* newArr[rowID][field] = {
          fid: selectedOption[0].fid,
          label: selectedOption[0].label,
        }; */
        // replace e.target.value with whatever you want to change it to  (String)
      } else {
        e.target.placeholder = e.target.value;
        newArr[rowID][field] = e.target.placeholder; // replace e.target.value with whatever you want to change it to  (String)
        // Function to automatically correct other grid fields on data entry
        if (autoCalculation !== null) {
          newArr = autoCalculation(newArr, rowID, field, dependencyStructure);
        }
      }
      setRows(newArr);
      setChangedGrid(true);
    };

  // Function responsible for redirect to Edit Fluid Page
  function redirectToEditFluid(fluidId) {
    history.push(history.location.pathname + `/edit/${fluidId}${queryParams}`);
    setIsModalOpen(true);
  }

  return (
    // Case Row assembled by Action Icons and Other Fields
    <tr
      key={"casegrid__row_" + rowdata.id}
      className={rowdata.color ? "case__row-fluid" : "case__row"}
      id={["case_" + rowdata.id]}
    >
      {/* Action Column */}
      <ActionColumn
        id={rowdata.id}
        rowSelected={rowdata.selected}
        rows={rows}
        setRows={setRows}
        setActiveRowId={setActiveRowId}
        disabled={disabled}
        isChecked={isChecked}
        viewBttnOnClick={viewBttnOnClick}
      />
      {/* diferenciates from editable to non-editable header columns */}
      {metaData.header.map((column) => (
        <td
          key={"casegrid__row" + rowdata.id + "_column" + column.key}
          className={isChecked === false ? "td-unselected" : "td-selected"}
          onClick={rowdata.color ? () => redirectToEditFluid(rowdata.id) : ""}
        >
          {String(rowdata[column.key]).startsWith("#") ? (
            <ColorPicker color={rowdata[column.key]} />
          ) : column.editable ? (
            <CellInput
              value={rowdata[column.key]}
              key={"cell_input_" + rowdata.id + "_column" + column.key}
              type={column.datatype}
              onChange={changedField(
                column.key,
                column.datatype,
                column.options,
                column.autoCalculation,
                dependencyStructure
              )}
              maxValue={column.maxValue}
              minValue={column.minValue}
              decimalNum={column.decimalNum}
              disabled={disabled}
              options={column.datatype === "select" ? column.options : ""}
              isChecked={isChecked}
            />
          ) : (
            rowdata[column.key]
          )}
        </td>
      ))}
    </tr>
  );
}

// # Fully Editable Grid React Component Construction
function FullyEditableGrid(props) {
  // Only show headers with labels
  const headers = props.metaData.header.filter((item) => item.label);
  const metaData = { header: headers };
  const setRows = props.setRows;
  const rows = props.rows;
  const disabled = props.disabled;

  const isSelectAllChecked = props.isSelectAllChecked;
  const setSelectAllChecked = props.setSelectAllChecked;


  const viewBttnOnClick = props.viewBttnOnClick

  // States to control checkbox - Rotation, Reciprocation, Rheometry */
  const isChecked = props.isChecked;

  // ActiveRowId for ActionFabGrid - CheckboxAction
  const setActiveRowId = props.setActiveRowId;

  // First Line Dependecy Structure
  let dependencyStructure = props.dependencyStructure;

  // setIsModalOpen for Edit Fluid Page
  const setIsModalOpen = props.setIsModalOpen;

  const setRowEid = props.setRowEid;

  /*  Function that controls all checkboxes(Selected/ Unselected) and change the state*/
  function selectAllRows() {
    let updatedList = [];
    setSelectAllChecked(!isSelectAllChecked);
    if (isSelectAllChecked === true) {
      updatedList = rows.map((item) => {
        return { ...item, selected: false };
      });
      setRows(updatedList);
    } else {
      updatedList = rows.map((item) => {
        // Logic for PumpSequenceSubpages - keep one fluid after delete all rows
        if (rows[0]["color"] && rows.length > 0) {
          let listEids = rows.map((item) => ({ eid: item.eid }));
          setRowEid(listEids);
        }
        return { ...item, selected: true };
      });
      setRows(updatedList);
    }
  }

  return (
    // Logo assembled by Icon and Name
    <div
      key="fully-editable-grid__container"
      className="fully-editable-grid__container"
    >
      <table
        key="fully-editable-grid__table"
        className="fully-editable-grid__table"
      >
        <thead
          key="fully-editable-grid__header"
          className="fully-editable-grid__header"
        >
          <tr>
            {/*  Header Checkbox */}
            <th>
              <input
                type="checkbox"
                className="checkbox__header"
                checked={isSelectAllChecked}
                onClick={selectAllRows}
              />
            </th>
            {metaData.header.map((columnLabel) => (
              <th key={columnLabel.key}>{columnLabel.label}</th>
            ))}
          </tr>
        </thead>
        <tbody className="body__container">
          {/* Show if grid is empty */}
          {rows.length === 0 ? (
            <div className="fully-editable-grid__container--empty"></div>
          ) : (
            rows.map((row, index) =>
              row.editable ? (
                <EditableRow
                  metaData={metaData}
                  rowData={row}
                  rowID={index}
                  rows={rows}
                  setRows={setRows}
                  setActiveRowId={setActiveRowId}
                  disabled={disabled}
                  isChecked={isChecked}
                  dependencyStructure={dependencyStructure}
                  setIsModalOpen={setIsModalOpen}
                  viewBttnOnClick={viewBttnOnClick}
                />
              ) : (
                <NonEditableRow
                  metaData={metaData}
                  rowData={row}
                  rowID={index}
                  rows={rows}
                  setRows={setRows}
                  setActiveRowId={setActiveRowId}
                />
              )
            )
          )}
        </tbody>
      </table>
    </div>
  );
}

export default FullyEditableGrid;
