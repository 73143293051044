// CardCases = Card composed of Image Area, Action Button area, Description Text Box, Fluid List Component, Created by / date and Modified by / date

// # Main Import
import React from "react";

// # Import Component Style
import "./CardCase.css";

// # Import Card Component and Card Buttons Action
import Card from "../card";
import CardButtonsAction from "../cardButtonsAction";

function CardCases(props) {
  const card_data = props.data;
  const buttons = props.actionButtom;

  return (
    <Card data={card_data}>
      <div className="card-body-cases" key={card_data.fields.title_case}>
        <div className="card-container-cases">
          <div className="card-container-bttn-text">
            <div className="card-buttons">
              {buttons.actions.map((action, index) => (
                <CardButtonsAction
                  rowID={index}
                  className={"action_icon_button"}
                  tooltip={action.tooltip}
                  icon={action.icon}
                  key={action.key}
                  routeURL={action.route}
                  callback={action.callback}
                  backgroundColor={action.backgroundColor}
                  color={action.color}
                  setId={action.setId}
                  id={card_data.pk}
                  loked={action.locked}
                  edit={card_data.fields.edit}
                  caseProps={card_data.fields}
                  queryParams={action.queryParams}
                />
              ))}
            </div>
            <textarea
              className="card-description-cases"
              disabled
              rows={4}
              value={card_data.fields.description}
            />
          </div>
          <h3>Fluidos:</h3>
          <ul className="card-description-fluids" disabled>
            {/*            {fluids.map((item) => (
              <li className="card-fluids">{item}</li>
            ))}  */}
            <p>{card_data.fields.case_fluid}</p>
          </ul>
        </div>
        <div className="card-container-cases-informations-user">
          {/*           <img src={card_data.img} class="card-image-cases" alt="well" /> */}
          <h3>Criado por:</h3>
          <p className="card-informations-user">
            {card_data.fields.user.name} {""} em {""}{" "}
            {card_data.fields.created_at}
          </p>
          <h3>Última alteração:</h3>
          <p className="card-informations-user">
            {card_data.fields.updated_at}
          </p>
        </div>
      </div>
    </Card>
  );
}

export default CardCases;
