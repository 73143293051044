//Main Import
import { useState, useContext, useEffect } from "react";

//Import Component Style
import "./editFluidSecondStyle.css";

//Local SubComponents, utils and data
import {
  ActionFabEditableGrid,
  FormInput,
  StyledButton,
  ConditionalPlotly,
  DropletLoadingSpinner,
  PageAndContainerTitle
} from "../../components/common";
import { mergedObject } from "../../components/utils";

// To draw Fluid Density - Temperatue and Pressure
import {
  drawFluidDensityPressure,
  drawFluidDensityTemperature,
  drawFluidDensityPressureTemperature,
  getFluidDensityLimits,
  getFluidDensityAndTemperatureLimits,
} from "../../components/utils/drawFluidDensity";

import { headersDensityTemperatureAndPressure } from "../../data/headersForGrids";

//Functions to  create rows - Density
import { createRows } from "../../components/utils";

//Function to add rows - Density
import { addTableRowsDensity } from "../../components/utils/functionsToAddRows";
//Function to delete rows - Density
import { deleteTableRows } from "../../components/utils/functionsToDeleteRows";
//Function to duplicate rows - Density
import { duplicateRowDensity } from "../../components/utils/functionToDuplicateRows";

// Contexts
import { UpdatedJsonContext } from "../../components/contexts/UpdatedJsonContext";
import {
  ControlDensityContext,
  initRowsDensity,
} from "../../components/contexts/ControlDensityGridContext";

// # Requests
import { updateFluidsIdRequest } from "../../services/FluidsRequest";

// Hook
import useDensityProfileValidation from "../../hooks/useDensityProfileValidation";

function EditFluidSecondPage(props) {
  // Function to validate Density Profile
  const { validateDensityProfile } = useDensityProfileValidation();

  // State to control form values  */
  const valuesEditFluid = props.valuesEditFluid;
  const setValuesEditFluid = props.setValuesEditFluid;

  // Page Title
  const editFluidTitle = props.editFluidTitle

  /* OnChange function  */
  const onChange = props.onChange;

  const updateFluid = props.updateFluid;

  /* State to control displayed Page  */
  const setPage = props.setPage;

  // Data to populate density props
  const densityProps = valuesEditFluid["fluid_element_item"].density;

  /* State to control Save Button */
  const changedValues = props.changedValues;
  const setChangedValues = props.setChangedValues;

  // caseId
  const caseId = props.caseId;

  // Grid - Header
  const densityHeader =
    valuesEditFluid["fluid_element_item"]["density"].density_dependence;

  // Grid - Rows
  const densityJsonProfile = valuesEditFluid["fluid_element_item"]["density"]
    .density_profile
    ? valuesEditFluid["fluid_element_item"]["density"].density_profile
    : [];

  // Defining height for Grid Fab Container
  const gridContainerHeight = "30vh";

  // UpdatedJson and Tags
  const { updatedJson, setUpdatedJson } = useContext(UpdatedJsonContext);

  // Control Density Context
  // const { rowsDensity, setRowsDensity } = useContext(ControlDensityContext);
  
  /* State to control Second Fluid Edit Page Grid with FAB */
  const [rowsDensity, setRowsDensity] = useState(
    createRows(densityJsonProfile,headersDensityTemperatureAndPressure("pressure-temperature-profile")));

  const tags = ["density_profile"];
  const tagsItems = ["density_profile_element_item"];

  /* Data to populate density input - select */
  const densityDependenceInput = [
    {
      id: 1,
      name: "density_dependence",
      value:
        valuesEditFluid["fluid_element_item"]["density"].density_dependence,
      type: "select",
      placeholder: "Densidade varia com:",
      options: [
        {
          label: "Pressão",
          value: "pressure-profile",
        },

        {
          label: "Temperatura",
          value: "temperature-profile",
        },

        {
          label: "Pressão e Temperatura",
          value: "pressure-temperature-profile",
        },
      ],
      required: true,
    },
  ];

  // Data and Limits to draw Fluid Density Chart
  const [xLimits, yLimits] = getFluidDensityLimits(
    Object.keys(updatedJson).length > 0 ? updatedJson : densityProps
  );

  const [xDensityAndPressureLimits, yDensityAndPressureLimits] =
    getFluidDensityAndTemperatureLimits(
      Object.keys(updatedJson).length > 0 ? updatedJson : densityProps
    );

  // Layout and Data to populate Density Fluid Chart - Pressure and Temperature
  const dataPlotlyPressureWithTemperature = drawFluidDensityPressureTemperature(
    Object.keys(updatedJson).length > 0 ? updatedJson : densityProps,
    densityProps["density_value"]);

  const layout_fluid_density_pressure_temperature = {
    width: 600,
    height: 300,
    plot_bgcolor: "#f2f0f3",
    title: "<b>Densidade x Pressão</b>",
    font: {
      family: "Arial",
      size: 14,
      color: "black",
    },
    xaxis: {
      title: "<b>Pressão (psi)</b>",
      range: [xDensityAndPressureLimits[0] * 0.9, xDensityAndPressureLimits[1] * 1.1 ],
      zeroline: false,
    },
    yaxis: {
      title: "<b>Densidade (kg/m³)</b>",
      range: [yDensityAndPressureLimits[0] * 0.9, yDensityAndPressureLimits[1] * 1.1 ],
      zeroline: false,
    },
    legend: {
      font: {
        family: "Arial",
        size: 11,
        color: "black",
      },
    },
  };

  // Layout and Data to populate Density Fluid Chart - Pressure
  const dataPlotlyPressure = drawFluidDensityPressure(
    Object.keys(updatedJson).length > 0 ? updatedJson : densityProps,
    densityProps["density_value"]);

  const layout_fluid_density_pressure = {
    width: 600,
    height: 300,
    showlegend: false,
    plot_bgcolor: "#f2f0f3",
    title: "<b>Densidade x Pressão</b>",
    font: {
      family: "Arial",
      size: 14,
      color: "black",
    },
    xaxis: {
      title: "<b>Pressão (psi)</b>",
      range: [xDensityAndPressureLimits[0] * 0.9, xDensityAndPressureLimits[1] * 1.1 ],
      zeroline: false,
    },
    yaxis: {
      title: "<b>Densidade (kg/m³)</b>",
      range: [yDensityAndPressureLimits[0] * 0.9, yDensityAndPressureLimits[1] * 1.1 ],
      zeroline: false,
    },
  };

  // Layout and Data to populate Density Fluid Chart - Temperature
  const dataPlotlyTempeture = drawFluidDensityTemperature(
    Object.keys(updatedJson).length > 0 ? updatedJson : densityProps,
    densityProps["density_value"]);



  const layout_fluid_density_temperature = {
    width: 600,
    height: 300,
    showlegend: false,
    plot_bgcolor: "#f2f0f3",
    title: "<b>Densidade x Temperatura</b>",
    font: {
      family: "Arial",
      size: 14,
      color: "black",
    },

    xaxis: {
      title: "<b>Tempertura (ºC)</b>",
      range: [xLimits[0] * 0.9, xLimits[1] * 1.1 ],
      zeroline: false,
    },
    yaxis: {
      title: "<b>Densidade (kg/m³)</b>",
      range: [yLimits[0] * 0.9, yLimits[1] * 1.1 ],
      zeroline: false,
    },
  };
  
  // Function to set the "pressure" and "temperature" field in fluid editing
  function setDefaultPressureAndTemperatureValue() {

    // Returns updatedJson without applying default values ​​if it is an empty object or array
    if (Object.keys(updatedJson).length === 0 || (Array.isArray(updatedJson) && !updatedJson.length)) {
      return updatedJson; 
    }
  
    const defaultTemperatureValue = "25"; 
    const defaultPressureValue = "14.69";
    const isTemperatureProfile = densityHeader === "temperature-profile";
    const isPressureProfile = densityHeader === "pressure-profile";
  
    const defaultValueSecondPageGrid = {
      density_profile: updatedJson.density_profile.map((item) => {
        const { density_profile_element_item } = item;
        const { temperature, pressure } = density_profile_element_item;
  
        const updatedValues = {
          ...item,
          density_profile_element_item: {
            ...density_profile_element_item,
            temperature: isPressureProfile ? defaultTemperatureValue : temperature,
            pressure: isTemperatureProfile ? defaultPressureValue : pressure,
          },
        };
        return updatedValues;
      }),
    };
    return defaultValueSecondPageGrid;
  }

  function comboOnchange (e) {
    let changedGridValue = {};
    changedGridValue = mergedObject(valuesEditFluid, updatedJson);
    setValuesEditFluid(changedGridValue);
    onChange(e);
  }

  async function handleSubmit() {
    if (
      !validateDensityProfile(
        rowsDensity,
        valuesEditFluid["fluid_element_item"]["density"].density_dependence
      )
    ) {
      return;
    }
    const newUpdatedJson = setDefaultPressureAndTemperatureValue();
    let changedGridValue = mergedObject(valuesEditFluid, newUpdatedJson);
    const response = await updateFluidsIdRequest(caseId, {
      ...changedGridValue,
    });
    setValuesEditFluid(changedGridValue);
    setUpdatedJson([]);
    setChangedValues(false);
    setPage(0);
  }
  /* Function to close modal  */
  function cancelUpdate() {
    setUpdatedJson([]);
    setChangedValues(false);
    setPage(0);
  }

  // Buttons to render using StyledButton component
  const bttnsForEditFluidSecondPage = [
    {
      type: "button",
      callback: cancelUpdate,
      color: "#fc7508f5",
      icon: "fas fa-backward",
      text: "Voltar",
    },
    {
      type: "button",
      callback: handleSubmit,
      icon: "fas fa-save",
      color:
        Object.keys(updatedJson).length > 0 || changedValues
          ? "#1b5e20"
          : "#a59c9cfd",
      disabled:
        Object.keys(updatedJson).length > 0 || changedValues ? false : true,
      text:
        Object.keys(updatedJson).length > 0 || changedValues
          ? "Salvar"
          : "Salvo",
      margin_left: "1rem",
    },
  ];

  return densityJsonProfile && rowsDensity && densityHeader ? (
    <>
      <div className="second-modal__container-fluids">
      <PageAndContainerTitle text={editFluidTitle} level="1" />
        <form>
          <div className="density_dependence__container">
            {densityDependenceInput.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={input.value}
                onChange={comboOnchange}
              />
            ))}
          </div>
          <div className="conditional_plotly__container">
            <ConditionalPlotly
              variableDensity={densityHeader}
              dataPlotlyPressure={dataPlotlyPressure}
              dataPlotlyTempeture={dataPlotlyTempeture}
              dataPlotlyPressureWithTemperature={
                dataPlotlyPressureWithTemperature
              }
              layoutPressure={layout_fluid_density_pressure}
              layoutTemperature={layout_fluid_density_temperature}
              layoutTemperatureWithPressure={
                layout_fluid_density_pressure_temperature
              }
            />

            <div className="action-fab-grid-density">
              <ActionFabEditableGrid
                metaData={headersDensityTemperatureAndPressure(densityHeader)}
                tagsItems={tagsItems[0]}
                tags={tags[0]}
                rows={rowsDensity ? rowsDensity : []}
                oldRows={createRows(
                  densityJsonProfile,
                  headersDensityTemperatureAndPressure(densityHeader)
                )}
                dataJson={densityJsonProfile}
                setRows={setRowsDensity}
                addTableRows={addTableRowsDensity}
                deleteTableRows={deleteTableRows}
                duplicateRow={duplicateRowDensity}
                gridContainerHeight={gridContainerHeight}
              />
            </div>
          </div>

          <div className="bttn-footer-edit-fluid-container">
            {bttnsForEditFluidSecondPage.map((item, index) => (
              <StyledButton data={item} key={index} />
            ))}
          </div>
        </form>
      </div>
    </>
  ) : (
    <DropletLoadingSpinner />
  );
}

export default EditFluidSecondPage;
