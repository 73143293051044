// #  Main Imports
import { useHistory } from "react-router-dom";
import { useEffect, useContext } from "react";

// #  Local SubComponents & utils
import { FormInput, StyledButton, PageAndContainerTitle } from "../../common";

// Requests
import { updateProjectsByIdRequest } from "../../../services/ProjectsRequests";

// Context
import { ToastContext } from "../../contexts/ToastContext";

// # Import Component Style
import "./FormEditProject.css";

const FormEditProject = (props) => {
  const history = useHistory();

  // Toast Context
  const { toastList, setToastList, showToast } = useContext(ToastContext);

  const projectById = props.projectById;
  const setProjectById = props.setProjectById;

  const valuesForEditProject = props.valuesForEditProject;
  const setValuesForEditProject = props.setValuesForEditProject;

  // Define useEffect policy                                                                                                                                          
  useEffect(() => {
    setValuesForEditProject({
      name: projectById.name,
      description: projectById.description,
    });
  }, [projectById.description, projectById.name, setValuesForEditProject]);

  const inputsEditProject = [
    {
      id: 1,
      name: "name",
      type: "textarea",
      errorMessage: "Este campo deve conter 3 a 150 caracteres!",
      maxlength: "150",
      pattern: "^[a-zA-Z0-9_ ]{3,150}$",
      label: "Nome",
      required: true,
      disabled: false,
      rows: 2,
      cols: 18,
    },
    {
      id: 2,
      name: "description",
      type: "textarea",
      errorMessage: "Este campo deve conter 3 a 500 caracteres!",
      pattern: "^[a-zA-Z0-9_ ]{3,500}$",
      maxlength: "500",
      label: "Descrição",
      disabled: false,
      required: true,
      rows: 8,
      cols: 18,
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      valuesForEditProject.name.length >= 3 &&
      valuesForEditProject.description.length >= 3
    ) {
      const response = await updateProjectsByIdRequest(
        projectById.id,
        valuesForEditProject
      );
      setValuesForEditProject({
        name: valuesForEditProject.name,
        description: valuesForEditProject.description,
      });
      setProjectById({
        ...projectById,
        name: valuesForEditProject.name,
        description: valuesForEditProject.description,
      });
      showToast(response, toastList, setToastList) 
    }
  };

  const onChange = (e) => {
    setValuesForEditProject({
      ...valuesForEditProject,
      [e.target.name]: e.target.value,
    });
  };

  const redirectToProjects = () => {
    history.push(`/well/${projectById.well_id}/projects`);
  };

  // Buttons to render using StyledButton component
  const bttnsForNewProject = [
    {
      type: "button",
      callback: redirectToProjects,
      color: "#c62828",
      icon: "fas fa-times",
      text: "Cancelar",
    },
    {
      type: "submit",
      callback: handleSubmit,
      color: "#1b5e20",
      icon: "fas fa-save",
      text: "Salvar",
    },
  ];

  return /* projectForEditId && */ projectById ? (
    <div className="container-form-edit-project">
      <PageAndContainerTitle text="Editar Projeto" level="3_forms" />
      <form className="form-edit-project">
        {inputsEditProject.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={valuesForEditProject[input.name]}
            onChange={onChange}
          />
        ))}

        <div className="bttn-edit-project__container">
          {bttnsForNewProject.map((item) => (
            <StyledButton data={item} />
          ))}
        </div>
      </form>
    </div>
  ) : (
    " "
  );
};

export default FormEditProject;
