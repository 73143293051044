import {
  drawBaseSintetica,
  drawPasta,
  drawEspacador,
} from "./functionsToDrawPumpSequence";
import { drawAllGeometry, drawSeaDepth } from "./drawGeometry";

/* Function to draw all PumpSequence */
function drawAllPumpSequence(
  internal_geometry,
  external_geometry,
  scenery = null,
  pumpSequenceChartData = null
) {
  let shapeAllGeometries = [];

  if (
    pumpSequenceChartData !== undefined &&
    pumpSequenceChartData !== null &&
    pumpSequenceChartData.length > 0
  ) {
    for (let i = 0; i < pumpSequenceChartData.length; i++) {
      let shapeFluid = {
        type: "rect",
        x0: pumpSequenceChartData[i]["points"][0][0],
        y0: pumpSequenceChartData[i]["points"][0][1] * -1,
        x1: pumpSequenceChartData[i]["points"][2][0],
        y1: pumpSequenceChartData[i]["points"][2][1] * -1,
        layer: "below",
        line: {
          color: pumpSequenceChartData[i]["color"],
          width: 1,
        },
        fillcolor: pumpSequenceChartData[i]["color"],
        opacity: 1,
      };
      shapeAllGeometries.push(shapeFluid);
    }
  }

  shapeAllGeometries.push(
    ...drawAllGeometry(internal_geometry, external_geometry, scenery)
  );
  return shapeAllGeometries;
}

/* Function to draw Geometry with Initial and Final depth*/
function drawAllGeometryWithInitialAndFinalMd(
  internal_geometry,
  external_geometry,
  scenery = null,
  simulation,
  partialSimChartData = null
) {
  let shapeAllGeometries = [];
  let dataAllGeometries = [];

  const initialMD = simulation["initial_measured_depth"];
  const finalMD = simulation["final_measured_depth"];
  const mode = scenery["simulation_mode"]["label"];
  // mode == "Coluna" || "Anular"
  const SupY = 0;
  const InfY = 10 ** 9;

  let refX = 0

  if (
    partialSimChartData !== undefined &&
    partialSimChartData !== null &&
    partialSimChartData.length > 0
  ) {

    const points = partialSimChartData[0]["points"];
    const xpoints = points.map(([x]) => parseFloat(x));
    refX = Math.max(...xpoints.map(Math.abs));

    for (let i = 0; i < partialSimChartData.length; i++) {
      let shapeFluid = {
        type: "rect",
        x0: partialSimChartData[i]["points"][0][0],
        y0: partialSimChartData[i]["points"][0][1] * -1,
        x1: partialSimChartData[i]["points"][2][0],
        y1: partialSimChartData[i]["points"][2][1] * -1,
        layer: "below",
        line: {
          color: "rgb(155, 155, 155)",
          width: 1,
        },
        fillcolor: "rgb(155, 155, 155)",
        opacity: 0.5,
      };
      shapeAllGeometries.push(shapeFluid);
    }
  }
  
  function setLineSupY () {
    if(mode === "Coluna") {
      return Number(initialMD)
    } else if (mode === "Anular") {
      return Number(finalMD)
    }
  }
  
  function setLineInfY () {
    if(mode === "Coluna") {
      return Number(finalMD)
    } else if (mode === "Anular") {
      return Number(initialMD)
    }
  }
  
  const lineSupY = setLineSupY();
  const lineInfY = setLineInfY();
  
  // Inf. Rect
  const rectInf = {
    type: "rect",
    xref: "paper",
    yref: "y",
    y0: InfY,
    y1: lineInfY,
    x0: 0,
    x1: 1,
    fillcolor: "rgb(155, 155, 155)",
    opacity: 0.5,
    line: {
      width: 0,
    },
  };
  
  //Sup. Rect
  const rectSup = {
    type: "rect",
    xref: "paper",
    yref: "y",
    y0: SupY,
    y1: lineSupY,
    x0: 0,
    x1: 1,
    fillcolor: "rgb(155, 155, 155)",
    opacity: 0.5,

    line: {
      width: 0,
    },
  };

  //Inf. Line
  const lineInf = {
    x:[-100,100],
    y: [lineInfY, lineInfY],
    mode:'lines',
    type:'scatter',
    name:mode === "Coluna" ? "Saída" :"Entrada" ,
    opacity: 1,
    line: {
      color: mode === "Coluna" ? "rgb(255, 0, 0)" :"rgb(0, 0, 255)" ,
      width: 3,
      dash: "dot",
    },
  };

  //Sup. Line
  const lineSup = {
    x:[-100,100],
    y: [lineSupY, lineSupY],
    mode:'lines',
    type:'scatter',
    name:mode === "Coluna" ? "Entrada" : "Saída",
    opacity: 1,
    line: {
      color: mode === "Coluna" ? "rgb(0, 0, 255)":"rgb(255, 0, 0)" ,
      width: 3,
      dash: "dot",
    },
  };

  shapeAllGeometries.push(
    ...drawAllGeometry(internal_geometry, external_geometry, scenery),
    rectInf,
    rectSup,
  );

  dataAllGeometries.push(lineSup,lineInf);

  return [dataAllGeometries,shapeAllGeometries];
}

export { drawAllPumpSequence, drawAllGeometryWithInitialAndFinalMd };
