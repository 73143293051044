// Main import of React
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { useQuery } from "react-query";

// Local SubComponents & utils
import {
  CardCases,
  CarouselComponent,
  PageAndContainerTitle,
  TextAndBackgroundImage,
  DropletLoadingSpinner,
} from "../components/common";
import background from "../images/background.jpg";
import { reload } from "../components/utils";

// Requests
import {
  getCasesByProjectRequest,
  updateLockCase,
  copyCaseById,
} from "../services/CasesRequests";
import { getProjectsByIdRequest } from "../services/ProjectsRequests";

// Import Component Style
import "./style/ProjectsCases.css";
import { darkColors, lightColors } from "../components/common";

// Context
import { ModalContext } from "../components/contexts/ModalContext";
import { SearchContext } from "../components/contexts/SearchContext";
import { ProjectAndCasesContext } from "../components/contexts/ProjectAndCasesContext";
import { HandleErrorContext } from "../components/contexts/HandleErrorContext";
import { ToastContext } from "../components/contexts/ToastContext";

function NewCase(props) {
  const { id } = useParams();

  const { setIsModalOpen, setCaseId } = useContext(ModalContext);
  const { wordValue, setWordValue } = useContext(SearchContext);
  const { toastList, setToastList, showToast } = useContext(ToastContext);

  // Handle Error Context
  const { setError } = useContext(HandleErrorContext);

  // State to set CurrentLevel values for Cases Page*/
  const { setProjectById } = useContext(ProjectAndCasesContext);

  // Define States for Projects - Using React Query
  const {
    data: cases,
    isLoading: loadingCases,
    error: errorCases,
  } = useQuery("casesById", () => getCasesByProjectRequest(id, setError));
  // Define States for Project - Using React Query
  const {
    data: project,
    isLoading: loadingProject,
    error: errorPoject,
  } = useQuery("projectById", () => getProjectsByIdRequest(id, setError));

  useEffect(() => {
    setWordValue("");
    setProjectById(project);
  }, [project, setProjectById]);

  if (errorCases || errorPoject) {
    console.log("Alguma coisa deu errada");
    return <h1>Ops! Algum erro inesperado aconteceu</h1>;
  }

  if (cases === null || project === null) {
    return <h1>Informações não encontradas!</h1>;
  }

  if (loadingCases || loadingProject) {
    return <DropletLoadingSpinner />;
  }

  // Function for edit Case by ID
  async function clickEditButton(id, locked) {
    if (locked === true) {
      const blockCase = await updateLockCase(id, {
        locked: false,
        user_locked: null,
      });
      showToast(blockCase, toastList, setToastList);
    } else {
      const unlockCase = await updateLockCase(id, { locked: true });
      showToast(unlockCase, toastList, setToastList);
    }
    reload()
  }

  // Function for duplicate a Case by ID
  async function caseCopy(caseId) {
    await copyCaseById(caseId);
    reload();
  }

  // Set Actions Buttons Card
  function actionButtonCard(caseData) {
    const buttonsAction = {
      actions: [
        {
          tooltip: caseData.fields.locked ? "Desbloquear Caso" : "Editar Caso",
          icon: caseData.fields.locked ? "fas fa-lock" : "fas fa-edit",
          key: "edit_case_button_",
          route: "/case/",
          callback: () => clickEditButton(caseData.pk, caseData.fields.locked),
          backgroundColor: darkColors.yellow,
          color: darkColors.white,
          setId: " ",
          locked: caseData.fields.locked,
          edit: " ",
          queryParams: ""
        },
        {
          tooltip: "Deletar Caso",
          icon: "fas fa-trash",
          key: "delete_case_button_",
          route: `/project/${id}/cases`,
          callback: setIsModalOpen,
          backgroundColor: darkColors.red,
          color: darkColors.white,
          setId: setCaseId,
          locked: false,
          edit: caseData.fields.edit,
        },
        {
          tooltip: "Visualizar Caso",
          icon: "fas fa-eye",
          key: "edit_case_button_",
          route: "/case/",
          backgroundColor: darkColors.turquoise,
          color: darkColors.white,
          setId: "",
          locked: false,
          edit: caseData.fields.edit,
          queryParams: "?view=true"
        },
        {
          tooltip: "Duplicar",
          icon: "fas fa-copy",
          key: "duplicate_case_button_",
          route: " ",
          callback: caseCopy,
          backgroundColor: darkColors.blue,
          color: lightColors.white,
          setId: "",
          locked: false,
          edit: caseData.fields.edit,
        },
      ],
    };
    return buttonsAction;
  }

  return cases.length > 0 ? (
    <div className="projects-cases__container">
      <PageAndContainerTitle text="Casos" level="1" />
      <CarouselComponent>
        {cases
          .filter((item) => {
            if (wordValue === "") {
              return item;
            } else if (
              item.fields.name.toLowerCase().includes(wordValue.toLowerCase())
            ) {
              return item;
            }
          })
          .map((item, index) => (
            <div className={item.title_case} key={index}>
              <CardCases data={item} actionButtom={actionButtonCard(item)} />
            </div>
          ))}
      </CarouselComponent>
    </div>
  ) : (
    <div className="projects-cases__empty ">
      <PageAndContainerTitle text="Casos" level="1" />
      <TextAndBackgroundImage
        text="Para criar um caso preencha o formulário ao lado."
        image={background}
      />
    </div>
  );
}

export default NewCase;
