// Main import of React
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Pages
import {
  Home,
  Welcome,
  GeneralInfo,
  NewProjects,
  Projects,
  Cases,
  NewCase,
  Scenery,
  SlidingStructure,
  Tutorials,
  LoginPage,
  Notifications,
  NotFound,
  Results
} from "./pages";

// Components
import { Toast, ProtectedRoute } from "./components/common";

// Popups - Modal
import {
  CaseDeletionConfirmation,
  ProjetcDeletionConfirmation,
  EditFluid,
  DeleteRowConfirmation,
  AlertPageChanges,
} from "./pages/popups";

import {
  SimulationAccordionChild,
  SimulationGraphs,
} from "./pages/SimulationSubPages";

// Data for Pump Sequence page
import {
  PumpSequenceAccordionChild,
  PumpSequenceGraphs,
} from "./pages/PumpSequenceSubPages";

// Data for Thermal Profile page
import {
  ThermalProfileAccordionChild,
  ThermalProfileGraphs,
} from "./pages/ThermalProfileSubPages";

// Data for Geometry page
import {
  GeometryAccordionChild,
  GeometryGraphs,
} from "./pages/GeometrySubPages";

// Routes for Geometry, Pump Sequence and Thermal Profile Page
import {
  pumpSequenceRoutes,
  thermalProfileRoutes,
  simulationRoutes,
  geometryRoutes,
} from "./data/routes";

// Buttons for Geometry, Pump Sequence and Thermal Profile Page
import {
  bttnsForPumpSequence,
  BttnsForThermalProfile,
  bttnsForGeometry,
  BttnsForSimulation
} from "./components/utils/functionsToRedirectPages";

// Accordion Data for Geometry, Pump Sequence and Thermal Profile Page
import {
  accordionDataForPumpSequence,
  accordionDataForThermalProfile,
  accordionDataForSimulation,
  accordionDataForGeometry,
} from "./data/accordionData";

// Local components import
import { Modal } from "./components/common";

// Contexts
import { SearchProvider } from "./components/contexts/SearchContext";
import { ModalProvider } from "./components/contexts/ModalContext";
import { ProjectAndCasesProvider } from "./components/contexts/ProjectAndCasesContext";
import { AccordionSmallFrameProvider } from "./components/contexts/AccordionSmallFrameContext";
import { ActionFabGridProvider } from "./components/contexts/ActionFabGridContext";
import { FormsProvider } from "./components/contexts/FormsContext";
import { HistoryProvider } from "./components/contexts/HistoryContext";
import { UpdatedJsonProvider } from "./components/contexts/UpdatedJsonContext";
import { FluidProvider } from "./components/contexts/FluidContext";
import { ToastProvider } from "./components/contexts/ToastContext";
import { HandleErrorProvider } from "./components/contexts/HandleErrorContext";
import { ResultsProvider } from "./components/contexts/ResultsContext";

// Style import
import "./App.css";

function App() {
  // Render React Components in Here
  return (
    <div>
      <Router>
        <HandleErrorProvider>
          <ToastProvider>
            <ModalProvider>
              <FluidProvider>
                <HistoryProvider>
                  <ProjectAndCasesProvider>
                      <SearchProvider>
                        <FormsProvider>
                          <AccordionSmallFrameProvider>
                            <ActionFabGridProvider>
                              <UpdatedJsonProvider>
                                <ResultsProvider>
                                <div name="app" className="App">
                                  <div name="main" className="main">
                                    <Home />
                                    {/* Main page Route */}
                                    <Switch>
                                      <Route exact path="/">
                                        <LoginPage />
                                      </Route>

                                      {/* Welcome Route */}
                                      <ProtectedRoute exact path="/home">
                                        <Welcome />
                                      </ProtectedRoute>

                                      {/* About Route */}
                                      <ProtectedRoute
                                        exact
                                        path="/about"
                                      ></ProtectedRoute>

                                      {/* Tutorial Route */}
                                      <ProtectedRoute path="/:params*/tutorials">
                                        <Tutorials />
                                      </ProtectedRoute>
                                      {/* Notifications Route */}
                                      <ProtectedRoute path="/:params*/notifications">
                                        <Notifications />
                                      </ProtectedRoute>
                                      {/* Projects Route */}
                                      <ProtectedRoute
                                        exact
                                        path="/well/:id/projects"
                                      >
                                        <Projects />
                                      </ProtectedRoute>

                                      <ProtectedRoute
                                        exact
                                        path="/well/:id/new_project"
                                      >
                                        <NewProjects />
                                      </ProtectedRoute>
                                      {/* Cases Route */}
                                      <ProtectedRoute
                                        exact
                                        path="/project/:id/cases"
                                      >
                                        <Cases />
                                      </ProtectedRoute>

                                      <ProtectedRoute
                                        exact
                                        path="/project/:id/new_case"
                                      >
                                        <NewCase />
                                      </ProtectedRoute>
                                      {/* General Info Route */}
                                      <ProtectedRoute
                                        exact
                                        path="/case/:id/general_info"
                                      >
                                        <GeneralInfo />
                                      </ProtectedRoute>
                                      {/* Scenery Info Route */}
                                      <ProtectedRoute
                                        exact
                                        path="/case/:id/scenery"
                                      >
                                        <Scenery />
                                      </ProtectedRoute>
                                      {/* Geometry  */}
                                      <ProtectedRoute path="/case/:id/geometry">
                                        <SlidingStructure
                                          Graphs={GeometryGraphs}
                                          Child={GeometryAccordionChild}
                                          routes={geometryRoutes}
                                          bttns={bttnsForGeometry}
                                          accordionData={
                                            accordionDataForGeometry
                                          }
                                        />
                                      </ProtectedRoute>
                                      {/* Pump Sequence Route */}
                                      <ProtectedRoute path="/case/:id/pumpsequence">
                                        <SlidingStructure
                                          Graphs={PumpSequenceGraphs}
                                          Child={PumpSequenceAccordionChild}
                                          routes={pumpSequenceRoutes}
                                          bttns={bttnsForPumpSequence}
                                          accordionData={
                                            accordionDataForPumpSequence
                                          }
                                        />
                                      </ProtectedRoute>
                                      {/* Thermal Profile Route */}
                                      <ProtectedRoute path="/case/:id/thermal_profile">
                                        <SlidingStructure
                                          Graphs={ThermalProfileGraphs}
                                          Child={ThermalProfileAccordionChild}
                                          routes={thermalProfileRoutes}
                                          bttns={BttnsForThermalProfile}
                                          accordionData={
                                            accordionDataForThermalProfile
                                          }
                                        />
                                      </ProtectedRoute>
                                      {/* Simulation Params Route */}
                                      <ProtectedRoute path="/case/:id/simulation">
                                        <SlidingStructure
                                          Graphs={SimulationGraphs}
                                          Child={SimulationAccordionChild}
                                          routes={simulationRoutes}
                                          bttns={BttnsForSimulation}
                                          accordionData={
                                            accordionDataForSimulation
                                          }
                                        />
                                      </ProtectedRoute>
                                      {/* Results Route */}
                                      <ProtectedRoute path="/case/:id/results">
                                        <Results/>
                                      </ProtectedRoute>
                                      <Route exact path="*">
                                        <NotFound />
                                      </Route>
                                    </Switch>
                                    {/*  Modal for Projects - Route */}
                                    <Route
                                      exact
                                      path={[
                                        "/well/:id/projects",
                                        "/well/:id/new_project",
                                      ]}
                                    >
                                      <Modal>
                                        <ProjetcDeletionConfirmation />
                                      </Modal>
                                    </Route>
                                    {/*  Modal for Cases - Route */}
                                    <Route
                                      exact
                                      path={[
                                        "/project/:id/cases",
                                        "/project/:id/new_case",
                                      ]}
                                    >
                                      <Modal>
                                        <CaseDeletionConfirmation />
                                      </Modal>
                                    </Route>
                                    <Route
                                      exact
                                      path={
                                        "/case/:id/pumpsequence/fluids/edit/:id"
                                      }
                                    >
                                      <Modal>
                                        <EditFluid />
                                      </Modal>
                                    </Route>
                                    <Route
                                      exact
                                      path={[
                                        "/case/:id/general_info",
                                        "/case/:id/scenery",
                                        ...pumpSequenceRoutes,
                                        ...thermalProfileRoutes,
                                        ...geometryRoutes,
                                        ...simulationRoutes
                                      ]}
                                    >
                                      <Modal>
                                        <AlertPageChanges />
                                      </Modal>
                                    </Route>

                                    {/* Not Found Page Route */}
                                  </div>
                                  <Toast />
                                </div>
                                </ResultsProvider>
                              </UpdatedJsonProvider>
                            </ActionFabGridProvider>
                          </AccordionSmallFrameProvider>
                        </FormsProvider>
                      </SearchProvider>
                  </ProjectAndCasesProvider>
                </HistoryProvider>
              </FluidProvider>
            </ModalProvider>
          </ToastProvider>
        </HandleErrorProvider>
      </Router>
    </div>
  );
}

export default App;
