// Requests
import { deleteTagByCaseId } from "../../services/TagsRequests";

export async function deleteTableRows(
  rows,
  setRows,
  setChangedGrid,
  setSelectAllChecked,
  caseID = null,
  rowEid = null,
  tags = null,
  showToast = null,
  toastList = null,
  setToastList = null,
  setChangedValues = null,
  activeRheoData = null,
  setUpdatedRheologicData = null,
  setUpdatedJson = null
) {
  let newData = [];
  let unselectedRows = [];
  let selectedRows = [];

  // UnselectedRows
  unselectedRows = rows.filter((item) => item.selected === false);

  // SelectedRows
  selectedRows = rows.filter((item) => item.selected === true);

  // Keep only one row for Fluids and Rheologic Data grids
  if ((tags === "fluids" || tags === "rheologic_data") && selectedRows.length === rows.length) {
    unselectedRows.push(rows[0]);
  }

  // Request for delete one row Rheologic Data grids
  if (tags === "rheologic_data") {
    let rheologicDataEid = JSON.stringify(rowEid);
    const response = await deleteTagByCaseId(
      caseID,
      "rheologic_data",
      rheologicDataEid
    );
    setUpdatedRheologicData(response.data);
    setUpdatedJson([]);
    setChangedGrid(false);
    showToast(response, toastList, setToastList);
    setChangedValues(false);
  }

  for (
    let unselectedRow = 0;
    unselectedRow < unselectedRows.length;
    unselectedRow += 1
  ) {
    let columns = Object.keys(unselectedRows[unselectedRow]);
    let leftRow = {};
    if (tags === "rheologic_data") {
      leftRow = {
        id: unselectedRow + 1,
        selected: false,
      };
    } else {
      leftRow = {
        id: unselectedRow + 1,
        activeEid: activeRheoData ? activeRheoData.eid : null,
        eid: null,
        selected: false,
      };
    }
    // Loop over other columns
    columns.map((column) => {
      if (!(column == "id" || column === "selected" || column === "eid")) {
        leftRow[column] = unselectedRows[unselectedRow][column];
      }
      if (
        tags === "rheologic_data" &&
        !(column === "id" || column === "selected")
      ) {
        leftRow[column] = unselectedRows[unselectedRow][column];
      }
    });
    newData = [...newData, leftRow];
  }
  setSelectAllChecked(false);
  setRows(newData);
  setChangedGrid(true);
}
