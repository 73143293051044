export const treeInfo = [
  {
    name: "UO-GREO",
    icon: "",
    info: [
      {
        name: "Campo - Gávea 5",
        icon: "",
        info: [
          { name: "poço 001", icon: "", info: null },
          { name: "poço 002", icon: "", info: null },
          { name: "poço 003", icon: "", info: null },
          { name: "poço 004", icon: "", info: null },
          { name: "poço 005", icon: "", info: null },

        ],
      },
    ],
  },
  {
    name: "UO-LMMP",
    icon: "",
    info: [
      {
        name: "Campo - Gávea 6",
        icon: "",
        info: [
          { name: "poço 011", icon: "", info: null },
          { name: "poço 012", icon: "", info: null },
          { name: "poço 013", icon: "", info: null },
          { name: "poço 014", icon: "", info: null },
          { name: "poço 015", icon: "", info: null },

        ],
      },
    ],
  },
  {
    name: "UO-CENPES",
    icon: "",
    info: [
      {
        name: "Campo - Fundão 1",
        icon: "",
        info: [
          { name: "poço 016", icon: "", info: null },
          { name: "poço 017", icon: "", info: null },
          { name: "poço 018", icon: "", info: null },
          { name: "poço 019", icon: "", info: null },
          { name: "poço 020", icon: "", info: null },

        ],
      },
    ],
  },
];

export const treeInfoResults = [
  {
    name: "Séries Consolidadas",
    icon: "",
    info: [
      { name: "Densidade na saída", icon: "", info: null },
      { name: "Volume no poço por fluido", icon: "", info: null },
    ],
  },
  {
    name: "Campos 2D",
    icon: "",
    info: [
      { name: "Fração dos fluidos", icon: "", info: null },
      { name: "Pressão", icon: "", info: null },
      { name: "Velocidade axial", icon: "", info: null },
      { name: "Reynolds ( turbulência)", icon: "", info: null },
    ],
  },
  {
    name: "Campos 3D",
    icon: "",
    info: [{ name: "Fração dos fluidos", icon: "", info: null }],
  },
];
