// # SideBar = a collapsible React Navigation Menu Component, that is composed of: header, body and footer

// # Main Import

import { Link } from "react-router-dom";
import SearchBar from "../searchBar/SearchBar";

import lmmp from "../../../images/lmmp.png"
import greo from "../../../images/greo.png";

// # Import Component Style
import "./sideBar.css";

// Hook
import useCloseAuto from "../../../hooks/useCloseAuto";
import useAuth from "../../contexts/useAuth";

// added fotterIcons for testing
const fotterIcons = [
  { name: "Logout", iconClassName: "fas fa-sign-out-alt", path: "/",tooltip: "Sair"},
  {
    name: "Tutoriais",
    iconClassName: "fas fa-graduation-cap",
    path: "/tutorials",tooltip: "Tutoriais"
  },
  {
    name: "Notificações",
    iconClassName: "far fa-bell",
    path: "/notifications",
    tooltip: "Notificações"
  },
];

const SideBar = (props) => {
  const history = props.history.location.pathname
  const pathNameParts =  history.split("/");
  const pathName = pathNameParts[pathNameParts.length - 2];
  
  // Destructuring the useCloseAuto hook
  const { isOpen, setIsOpen} = useCloseAuto(pathName);

  // Get Token from useAuth
  const {handleLogout} = useAuth();

  const toggle = () => setIsOpen(!isOpen);

  const placeholderSearchBox = props.placeholderSearchBox;

  return (
    <div className="container">
      <div style={{ width: isOpen ? "18.75rem" : "65px" }} className="sidebar">
        <div className="top_section">
          <h1
            style={{ display: isOpen ? "block" : "none" }}
            className="title-side-bar"
          >
            INTERFACES 3D
          </h1>
          <div
            style={{
              marginLeft: isOpen ? "4.0625rem" : "0rem",
              backgroundColor: isOpen ? "transparent" : "#17365c",
              color: isOpen ? "#17365c" : "#fff",
              padding: isOpen ? "0.5rem 0rem" : "0.5rem 0.8rem",
            }}
            className="bars"
          >
            {isOpen ? (
              <i className="far fa-times-circle" onClick={toggle}></i>
            ) : (
              <i className="fas fa-bars" onClick={toggle}></i>
            )}
          </div>
        </div>
        <h2 className="top-section__text">
        vBeta
        </h2>
        <div
          className="search-bar-container"
          style={{ display: isOpen ? "block" : "none" }}
        >
          <SearchBar placeholderSearchBox={placeholderSearchBox} />
        </div>
        <div
          className="main-menu"
          style={{ display: isOpen ? "block" : "none" }}
        >
          {isOpen ? (
            <ul className="main-menu__item">{props.children}</ul>
          ) : (
            <ul>""</ul>
          )}
        </div>
        <div
          style={{
            display: isOpen ? "flex" : "none",
          }}
          className="side-menu-footer-logo"
        >
          <img src={lmmp} alt="logo_1" className="side-footer-img" />
          <img src={greo} alt="logo_2" className="side-footer-img" />
        </div>
        <div
          style={{
            flexDirection: isOpen ? "row" : "column",
            alignItems: isOpen ? "baseline" : "center",
          }}
          className="side-footer-bttn"
        >
          {fotterIcons.map((item, index) => (
            (item.path !== "/") ? 
            <Link key={index} to={ history.includes("/notifications") ? history.replace('/notifications','') + item.path : history.replace('/tutorials','')  + item.path}>
              <button key={item.name} className={"avatar"} title={item.name} tooltip={item.tooltip}>
                <i className={item.iconClassName}></i>
              </button>
            </Link> : 
            <Link key={index} to={item.path}>
              <button key={item.name} className={"avatar"} 
              title={item.name} tooltip={item.tooltip}
              onClick={handleLogout}>
                <i className={item.iconClassName}></i>
              </button>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
