// #  Main Imports
import React from "react";
import { useContext } from "react";
import { useHistory } from "react-router";

// #  Local SubComponents & utils
import {
  FormInput,
  StyledButton,
  FormContainer,
  PageAndContainerTitle,
} from "../../common";
import { 
  minMaxMdInitialAndFinal, 
/*   resetLimitInitialPartialSimulation, 
  resetLimitFinalPartialSimulation,  */
  resetPartialLimitsAndNodesSimulation,
  mergedObject
} from "../../utils";

// # Requests
import { updateTagByCaseId } from "../../../services/TagsRequests";


// # Import Component Style
import "./FormScenery.css";
import { HistoryContext } from "../../contexts/HistoryContext";

// Form Declaration
function FormScenery(props) {

  /* State to set History Location Pathname and possible changes at onChange function */
  const { hasChange } = useContext(HistoryContext)
  
  // Form Props
  const values = props.values;
  const casePropsById = props.casePropsById;

  const simulationMode = props.simulationMode;
  const environmentType = props.environmentType;
  const operationType = props.operationType;

  const handleSubmit = props.handleSubmit;
  const cancelRequest = props.cancelRequest;
  const onChange = props.onChange;
  const id = props.id;

  // Hook gives access to the history instance
  const history = useHistory();

  // Query params of route
  const caseMode = history.location.search.includes("?view=true");

  // Variables to store of initial_measured_depth and final_measured_depth 
  const mdFinal = casePropsById["simulation"]["final_measured_depth"];
  const mdInitial = casePropsById["simulation"]["initial_measured_depth"];
  
  // Parameter passage to minMaxMdInitialAndFinal
   const { initialMdAnnular,finalMdAnnular, initialMdColumn, finalMdColumn } =
   minMaxMdInitialAndFinal(
    casePropsById["internal_geometry"], 
    casePropsById["external_geometry"],
    casePropsById["scenery"],
    );
/* 
    const limitMdInitial = resetLimitInitialPartialSimulation(values["scenery"].simulation_mode.value, initialMdAnnular, initialMdColumn);
    const limitMdFinal = resetLimitFinalPartialSimulation(values["scenery"].simulation_mode.value, finalMdAnnular, finalMdColumn); */
    // Function to update the final md if the user changes the simulation mode
/*     async function updateFinalMdLimite (e, limitMdFinal) {
      e.preventDefault();
      const response = await updateTagByCaseId(id, "final_measured_depth", limitMdFinal)} */


  // Input Sections Metadata
  const firstInputSection = [
    {
      id: 1,
      name: "environment_type",
      value: values["scenery"].environment_type === null ? "sea_floating_platform" : values["scenery"].environment_type.value,
      type: "select",
      placeholder: "Ambiente",
      options: environmentType,
      required: true,
    },
  ];

  const secondInputData = [
    {
      id: 2,
      name: "water_depth",
      value: values["scenery"].environment_type.value === "land_land_platform" ? 0 : values["scenery"].water_depth,
      type: "number",
      disabled: values["scenery"].environment_type.value === "land_land_platform" ? true : false,
      label: "Lâmina d'Água [m]",
      errorMessage: "O valor deve ser um número maior que 0",
/*       pattern: "^[A-Za-z0-9]{3,16}$", */
      required: true,
      min: "0",
      max: "> 0"
    },
  ];

  const thirdInputData = [
    {
      id: 4,
      name: "air_gap",
      value: values["scenery"].air_gap,
      type: "number",
      label: "Air Gap [m]",
      errorMessage: "O valor deve ser um número maior que 0",
/*       pattern: "^[A-Za-z0-9]{3,16}$", */
      required: true,
      min: "0",
      max: "> 0"
    },
  ];

  const fourthInputData = [
    {
      id: 6,
      name: "riser_od",
      value: values["scenery"].riser_od,
      type: "number",
      label: "OD [pol]",
      errorMessage: `O valor deve ser um número maior que ${values["scenery"].riser_id}`,
/*       pattern: "^[A-Za-z0-9]{3,16}$", */
      required: true,
      min:  values["scenery"].riser_id,
      max: "> 0"
    },
  ];

  const fifthInputData = [
    {
      id: 8,
      name: "riser_id",
      value: values["scenery"].riser_id,
      type: "number",
      label: "ID [pol]",
      errorMessage: "O valor deve ser um número maior que 0",
/*       pattern: "^[A-Za-z0-9]{3,16}$", */
      required: true,
      min: "0",
      max: "> 0"
    },
  ];

  const sixthInputData = [
    {
      id: 10,
      name: "operation_type",
      value: values["scenery"].operation_type === null ? "casing_cementing" : values["scenery"].operation_type.value ,
      type: "select",
      placeholder: "Operação",
      options: operationType,
      disabled: false,
      required: true,
    },
  ];

  const seventhInputData = [
    {
      id: 11,
      name: "simulation_mode",
      value: values["scenery"].simulation_mode.value,
      type: "select",
      placeholder: "Modo de Simulação",
      options: simulationMode,
      disabled: false,
      required: true,
    },
  ];


  // Buttons to render using StyledButton component
  const bttns = [
    {
      type: "button",
      callback: cancelRequest,
      color: "#c62828",
      icon: "fas fa-times",
      text: "Cancelar",
    },
    {
      type: "button",
      callback: (e) => {
        if (JSON.stringify(values["scenery"]) ===
        JSON.stringify(casePropsById["scenery"])) {
         return ""
        } else{
          handleSubmit(e);
        }
      },
      color:
        hasChange
          ? "#1b5e20"
          : "#a59c9cfd",
      disabled:
         hasChange
          ? false
          : true,
      icon: "fas fa-save",
      text:  hasChange
        ? "Salvar"
        : "Salvo",
    },
  ];

  return (
      // Outer Form Container
      <FormContainer>
        {/* Scenery form main title */}
        <PageAndContainerTitle text="Cenário" level="1" />
        {/* Form */}
        <form>
          {/* First Section Form Container */}
          <FormContainer mode="grid" fitContent="true">
            {firstInputSection.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={input.value}
                onChange={onChange}
                disabled={caseMode}
                className="first-inputs"
              />
            ))}
          </FormContainer>

          {/* Second Section Form Container */}
          <FormContainer
            mode="space-between"
            borderColor="#17365c"
            borderStyle="solid"
            borderWidth="1px"
            borderRadius="0.8rem"
            padding="2em"
            margin="3em 0em"
          >
            {secondInputData.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={input.value}
                onChange={onChange}
                disabled={caseMode}
                className="input-second"
              />
            ))}

            {thirdInputData.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={input.value}
                onChange={onChange}
                disabled={caseMode}
                className="input-second"
              />
            ))}
          </FormContainer>
          {/* Third Section Form Container */}
          <PageAndContainerTitle text="Riser" level="2" />
          <FormContainer
            mode="space-between"
            borderColor="#17365c"
            borderStyle="solid"
            borderWidth="1px"
            borderRadius="0.8rem"
            padding="2em"
          >
            {fourthInputData.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={input.value}
                onChange={onChange}
                disabled={caseMode}
                className="input-second"
              />
            ))}

            {fifthInputData.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={input.value}
                onChange={onChange}
                disabled={caseMode}
                className="input-second"
              />
            ))}
          </FormContainer>
          {/* Fourth Section Form Container */}
          <FormContainer mode="space-between" margin="3em 0em">
            {sixthInputData.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={input.value}
                disabled={caseMode}
                onChange={onChange}
              />
            ))}

            {seventhInputData.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={input.value}
                disabled={caseMode}
                onChange={onChange}
              />
            ))}
          </FormContainer>
          {/* Form Buttons Container */}
          {!caseMode && (
          <FormContainer mode="space-between" margin="3em 0em">
            {bttns.map((item, index) => (
              <StyledButton data={item} key={index} />
            ))}
          </FormContainer>
          )}
        </form>
      </FormContainer>
    
  );
}

// Export
export default FormScenery;
