import { mergedObject } from "./mergeObjects";

function getGeometryLimits(internal_geometry, external_geometry, scenery) {
 
  let minZ = scenery ? -scenery.air_gap: 0;
  let maxZ = minZ;
  let maxR = scenery ? 1.2 *scenery.riser_od/2 : 15;
  let minR = -maxR;

  if (
    internal_geometry !== undefined ||
    internal_geometry !== null ||
    internal_geometry.casing.length > 0
  ) {
    for (let index = 0; index < internal_geometry.casing.length; index += 1) {
      maxR = Math.max(
        (1.2 *
          internal_geometry.casing[index].casing_element_item
            .outside_diameter) /
          2,
        maxR
      );
      maxZ = Math.max(
        1.2 *
          internal_geometry.casing[index].casing_element_item.measured_depth,
        maxZ
      );
    }
  }

  if (
    internal_geometry !== undefined ||
    internal_geometry !== null ||
    internal_geometry.work_column.length > 0
  ) {
    for (
      let index = 0;
      index < internal_geometry.work_column.length;
      index += 1
    ) {
      maxR = Math.max(
        (1.2 *
          internal_geometry.work_column[index].pipe_element_item
            .outside_diameter) /
          2,
        maxR
      );
      maxZ = Math.max(
        1.2 *
          internal_geometry.work_column[index].pipe_element_item.measured_depth,
        maxZ
      );
    }

    if (
      external_geometry !== undefined ||
      external_geometry !== null ||
      external_geometry.last_casing.length > 0
    )
      for (
        let index = 0;
        index < external_geometry.last_casing.length;
        index += 1
      ) {
        maxR = Math.max(
          (1.2 *
            external_geometry.last_casing[index].last_casing_element_item
              .outside_diameter) /
            2,
          maxR
        );
        maxZ = Math.max(
          1.2 *
            external_geometry.last_casing[index].last_casing_element_item
              .measured_depth,
          maxZ
        );
      }
  }

  if (
    external_geometry !== undefined ||
    external_geometry !== null ||
    external_geometry.open_hole.length > 0
  ) {
    for (
      let index = 0;
      index < external_geometry.open_hole.length;
      index += 1
    ) {
      maxR = Math.max(
        (1.2 *
          external_geometry.open_hole[index].open_hole_element_item.inside_diameter)/2,
        maxR
      );
      maxZ = Math.max(
        1.2 *
          external_geometry.open_hole[index].open_hole_element_item.measured_depth,
        maxZ
      );
    }
  }

  maxZ = maxZ;
  minR = -maxR;

  let rLimits = [-15, 15];
  if (minR !== NaN && maxR !== NaN) {
    rLimits = [minR, maxR];
  }
  const zLimits = [maxZ, minZ];

  return [rLimits, zLimits];
}

function drawSeaDepth(scenery,chartLimits=null,tag=null) {
  const rLimits = chartLimits ? chartLimits[0] : [-150,150];
  let shapesSeaDepth = [];

  const graphWithOpacityColor = ["open_hole","last_casing","work_column","casing"];

  const riserPositive = {
    type: "rect",
    x0: scenery.riser_id / 2,
    y0: 0,
    x1: scenery.riser_od / 2,
    y1: scenery.water_depth,
    line: {
      color: graphWithOpacityColor.includes(tag) ? "#9d9d9d" : "#000000",
    },
    fillcolor: graphWithOpacityColor.includes(tag) ? "#e7e4e4" : "#808080",
  };

  const riserNegative = {
    type: "rect",
    x0: -scenery.riser_id / 2,
    y0: 0,
    x1: -scenery.riser_od / 2,
    y1: scenery.water_depth,
    line: {
      color: graphWithOpacityColor.includes(tag) ? "#9d9d9d" : "#000000",
    },
    fillcolor: graphWithOpacityColor.includes(tag) ? "#e7e4e4" : "#808080",
  };

  const oceanPositive = {
    type: "rect",
    x0: scenery.riser_od / 2,
    y0: 0,
    x1: rLimits[1]*5,
    y1: scenery.water_depth,
    layer: "below",
    line: {
      color: "transparent",
    },
    fillcolor: graphWithOpacityColor.includes(tag) ? "#a7f7ff" : "#01e7ff",
  };

  const oceanNegative = {
    type: "rect",
    x0: -scenery.riser_od / 2,
    y0: 0,
    x1: rLimits[0]*5,
    y1: scenery.water_depth,
    layer: "below",
    line: {
      color: "transparent",
    },
    fillcolor: graphWithOpacityColor.includes(tag) ? "#a7f7ff" : "#01e7ff",
  };
  shapesSeaDepth.push(
    oceanNegative,
    oceanPositive,
    riserNegative,
    riserPositive
  );

  return shapesSeaDepth;
}

function drawInternalGeometry(internal_geometry, scenery = null,chartLimits=null, tag=null) {
  let shapesInternalGeometry = [];
  let allShapesInternal = [];

  if (
    internal_geometry.casing !== undefined &&
    internal_geometry.casing !== null &&
    internal_geometry.casing.length > 0
  ) {
    /* Data for x0 , x1 */
    const outside_diameter = internal_geometry.casing.map(
      (index) => index.casing_element_item.outside_diameter
    );
    const inside_diameter = internal_geometry.casing.map(
      (index) => index.casing_element_item.inside_diameter
    );

    /* Data for y0 , y1 */
    const y1 = internal_geometry.casing.map(
      (index) => index.casing_element_item.measured_depth
    );
    const segment_length = internal_geometry.casing.map(
      (index) => index.casing_element_item.segment_length
    );
    const y0 = y1.map((n, i) => n - segment_length[i]);

    for (let index = 0; index < internal_geometry.casing.length; index += 1) {
      const internalShape = {
        type: "rect",
        x0: -inside_diameter[index] / 2,
        y0: y0[index],
        x1: inside_diameter[index] / 2,
        y1: y1[index],
        line: {
          color: tag === "casing"|| tag === null  ? "#000000" : "#9d9d9d",
          dash: "dashdot",
          width: 1,
        },
      };
      const espNegative = {
        type: "rect",
        x0: -inside_diameter[index] / 2,
        y0: y0[index],
        x1: -outside_diameter[index] / 2,
        y1: y1[index],
        line: {
          color: tag === "casing"|| tag === null  ? "#000000" : "#9d9d9d",
          width: 2,
        },
        fillpattern: "/",
        fillcolor: tag === "casing"|| tag === null  ? "#808080" : "#e7e4e4",
        hoverinfo: "Revestimento",
      };

      const espPositive = {
        type: "rect",
        x0: inside_diameter[index] / 2,
        y0: y0[index],
        x1: outside_diameter[index] / 2,
        y1: y1[index],
        line: {
          color: tag === "casing"|| tag === null  ? "#000000" : "#9d9d9d",
          width: 2,
        },
        fillpattern: "/",
        fillcolor: tag === "casing"|| tag === null  ? "#808080" : "#e7e4e4",
        hoverinfo: "Revestimento",
      };

      shapesInternalGeometry.push(internalShape, espNegative, espPositive);
    }
  }

  if (
    internal_geometry.work_column !== undefined &&
    internal_geometry.work_column !== null &&
    internal_geometry.work_column.length > 0
  ) {
    /* Data for x0 , x1 */
    const outside_diameter = internal_geometry.work_column.map(
      (index) => index.pipe_element_item.outside_diameter
    );
    const inside_diameter = internal_geometry.work_column.map(
      (index) => index.pipe_element_item.inside_diameter
    );

    /* Data for y0 , y1 */
    const y1 = internal_geometry.work_column.map(
      (index) => index.pipe_element_item.measured_depth
    );
    const segment_length = internal_geometry.work_column.map(
      (index) => index.pipe_element_item.segment_length
    );
    const y0 = y1.map((n, i) => n - segment_length[i]);

    for (
      let index = 0;
      index < internal_geometry.work_column.length;
      index += 1
    ) {
      const internalShape = {
        type: "rect",
        x0: -inside_diameter[index] / 2,
        y0: y0[index],
        x1: inside_diameter[index] / 2,
        y1: y1[index],
        line: {
          color: tag === "work_column"|| tag === null  ? "#000000" : "#9d9d9d",
          dash: "dashdot",
          width: 1,
        },
      };
      const espNegative = {
        type: "rect",
        x0: -inside_diameter[index] / 2,
        y0: y0[index],
        x1: -outside_diameter[index] / 2,
        y1: y1[index],
        line: {
          color: tag === "work_column"|| tag === null  ? "#000000" : "#9d9d9d",
          width: 2,
        },
        fillpattern: "/",
        fillcolor: tag === "work_column"|| tag === null  ? "#808080" : "#e7e4e4",
        hoverinfo: "Coluna de Trabalho",
      };

      const espPositive = {
        type: "rect",
        x0: inside_diameter[index] / 2,
        y0: y0[index],
        x1: outside_diameter[index] / 2,
        y1: y1[index],
        line: {
          color: tag === "work_column"|| tag === null  ? "#000000" : "#9d9d9d",
          width: 2,
        },
        fillpattern: "/",
        fillcolor: tag === "work_column"|| tag === null  ? "#808080" : "#e7e4e4",
        hoverinfo: "Coluna de Trabalho",
      };

      shapesInternalGeometry.push(internalShape, espNegative, espPositive);
    }
  }

  allShapesInternal.push(...shapesInternalGeometry) 

  if (scenery !== undefined && scenery !==null) {
    let shapeSeaDepth = drawSeaDepth(scenery,chartLimits, tag)
    allShapesInternal.push(...shapeSeaDepth);
  }

  return allShapesInternal;
}

function drawExternalGeometry(external_geometry, scenery = null,chartLimits=null, tag=null) {
  let shapesExternalGeometry = [];
  let allShapesExternal = [];

  if (
    external_geometry.open_hole !== undefined &&
    external_geometry.open_hole !== null &&
    external_geometry.open_hole.length > 0
  ) {
    /* Data for open_hole (line) - x0, x1, y0, y1*/
    const y1 = external_geometry.open_hole.map(
      (index) => index.open_hole_element_item.measured_depth
    );
    const segment_length = external_geometry.open_hole.map(
      (index) => index.open_hole_element_item.segment_length
    );
    const lineX = external_geometry.open_hole.map(
      (index) => index.open_hole_element_item.inside_diameter / 2
    );
    const y0 = y1.map((n, i) => n - segment_length[i]);

    for (
      let index = 0;
      index < external_geometry.open_hole.length;
      index += 1
    ) {
      const externalShape = {
        type: "rect",
        x0: -lineX[index],
        y0: y0[index],
        x1: lineX[index],
        y1: y1[index],
        line: {
          color: tag === "open_hole"|| tag === null  ? "#000000" : "#9d9d9d",
          dash: "dashdot",
          width: 1,
        },
      };
      const espPositive = {
        type: "line",
        x0: lineX[index],
        y0: y0[index],
        x1: lineX[index],
        y1: y1[index],
        line: {
          color: tag === "open_hole"|| tag === null  ? "#124429" : "#93C092",
          width: 3,
        },
      };
      const espNegative = {
        type: "line",
        x0: -lineX[index],
        y0: y0[index],
        x1: -lineX[index],
        y1: y1[index],
        line: {
          color: tag === "open_hole"|| tag === null  ? "#124429" : "#93C092",
          width: 3,
        },
      };
      shapesExternalGeometry.push(externalShape, espNegative, espPositive);
    }
  }

  if (
    external_geometry.last_casing !== undefined &&
    external_geometry.last_casing !== null &&
    external_geometry.last_casing.length > 0
  ) {
    /* Data for x0 , x1 */
    const outside_diameter = external_geometry.last_casing.map(
      (index) => index.last_casing_element_item.outside_diameter
    );
    const inside_diameter = external_geometry.last_casing.map(
      (index) => index.last_casing_element_item.inside_diameter
    );

    /* Data for y0 , y1 */
    const y1 = external_geometry.last_casing.map(
      (index) => index.last_casing_element_item.measured_depth
    );
    const segment_length = external_geometry.last_casing.map(
      (index) => index.last_casing_element_item.segment_length
    );
    const y0 = y1.map((n, i) => n - segment_length[i]);

    for (
      let index = 0;
      index < external_geometry.last_casing.length;
      index += 1
    ) {
      const externalShape = {
        type: "rect",
        x0: -inside_diameter[index] / 2,
        y0: y0[index],
        x1: inside_diameter[index] / 2,
        y1: y1[index],
        line: {
          color: tag === "last_casing"|| tag === null  ? "#000000" : "#9d9d9d",
          dash: "dashdot",
          width: 1,
        },
      };
      const espNegative = {
        type: "rect",
        x0: -inside_diameter[index] / 2,
        y0: y0[index],
        x1: -outside_diameter[index] / 2,
        y1: y1[index],
        line: {
          color: tag === "last_casing"|| tag === null  ? "#000000" : "#9d9d9d",
          width: 2,
        },
        fillpattern: "/",
        fillcolor: tag === "last_casing"|| tag === null  ? "#808080" : "#e7e4e4",
        hoverinfo: "Revestimento",
      };

      const espPositive = {
        type: "rect",
        x0: inside_diameter[index] / 2,
        y0: y0[index],
        x1: outside_diameter[index] / 2,
        y1: y1[index],
        line: {
          color: tag === "last_casing" || tag === null ? "#000000" : "#9d9d9d",
          width: 2,
        },
        fillpattern: "/",
        fillcolor: tag === "last_casing"|| tag === null  ? "#808080" : "#e7e4e4",
        hoverinfo: "Revestimento",
      };

      shapesExternalGeometry.push(externalShape, espNegative, espPositive);
    }
  }

  allShapesExternal.push(...shapesExternalGeometry);
  
  if (scenery !== undefined && scenery !==null) {
    let shapeSeaDepth = drawSeaDepth(scenery,chartLimits,tag)
    allShapesExternal.push(...shapeSeaDepth);
  }

  return allShapesExternal;
}

/* Function to draw all Geometry */
function drawAllGeometry(internal_geometry, external_geometry, scenery) {
  let chartLimits = getGeometryLimits(
    internal_geometry,
    external_geometry,
    scenery
    );
  let shapeAllGeometries = [];
  shapeAllGeometries.push(
    ...drawInternalGeometry(internal_geometry),
    ...drawExternalGeometry(external_geometry),
    ...drawSeaDepth(scenery),
  );
  return shapeAllGeometries;
}

function drawCentralization(DE, DI, Ex, Ey) {
  let shapesCentralization = [];

  const outerCircle = {
    type: "circle",

    xref: "x",
    yref: "y",

    x0: DE / 2,
    x1: -DE / 2,
    y0: DE / 2,
    y1: -DE / 2,

    line: {
      color: "#000000",
    },
  };

  const innerCircle = {
    type: "circle",

    xref: "x",
    yref: "y",

    x0: - Ex - DI / 2,
    y0: - Ey - DI / 2,
    x1: - Ex + DI / 2,
    y1: - Ey + DI / 2,
    line: {
      color: "#000000",
    },
  };

  const lineStandoff = {
    type: "line",

    xref: "x",
    yref: "y",

    x0: 0,
    y0: 0,
    x1: -Ex,
    y1: -Ey,
    line: {
      color: "#000000",
      width: 2,
    },
  };

  const lineOuterRadius = {
    type: "line",

    xref: "x",
    yref: "y",

    x0: -Ex,
    y0: -Ey,
    x1: -Ex + DI / 2,
    y1: -Ey,
    line: {
      color: "#AAAAAA",
      width: 2,
      dash: "dashdot",
    },
    name: "Raio Externo",
  };

  const lineInnerRadius = {
    type: "line",

    xref: "x",
    yref: "y",

    x0: 0,
    y0: 0,
    x1: -DE / 2,
    y1: 0,
    line: {
      color: "#AAAAAA",
      width: 2,
      dash: "dashdot",
    },
    name: "Raio Interno",
  };


  shapesCentralization.push(
    outerCircle,
    innerCircle,
    lineStandoff,
    lineOuterRadius,
    lineInnerRadius
  );

  return shapesCentralization;
}


function linspace(startValue, stopValue, cardinality) {
  var arr = [];
  var step = (stopValue - startValue) / (cardinality - 1);
  for (var i = 0; i < cardinality; i++) {
    arr.push(startValue + step * i);
  }
  return arr;
}

function generateMeshgridWithNoise(average, amplitude, size) {
  const [numPointsX, numPointsY] = size;
  const seed = generateSeed(numPointsX, numPointsY); // Generate a random seed for each point
  const grid = [];

  for (let i = 0; i < numPointsX; i++) {
    const row = [];
    for (let j = 0; j < numPointsY; j++) {
      const pointSeed = seed[i][j];
      const rng = createRandomGenerator(pointSeed); // Create a random number generator with the point's seed
      const noise = amplitude * (rng() * 2 - 1); // Generate random noise between -amplitude and amplitude
      const value = average + noise;
      row.push(value);
    }
    grid.push(row);
  }

  return grid;
}

function generateSeed(numPointsX, numPointsY) {
  const seed = [];

  for (let i = 0; i < numPointsX; i++) {
    const row = [];
    for (let j = 0; j < numPointsY; j++) {
      const timestamp = new Date().getTime().toString(); // Get current timestamp
      const randomNum = Math.random().toString().slice(2, 8); // Get a random number between 0 and 1, and extract a portion of it
      const pointSeed = timestamp + randomNum; // Combine timestamp and random number to create the seed for the current point
      row.push(pointSeed);
    }
    seed.push(row);
  }

  return seed;
}

function createRandomGenerator(seed) {
  let state = seed;

  return function random() {
    let x = Math.sin(state++) * 10000;
    return x - Math.floor(x);
  };
}

/* Function to draw all Graphic3D */
function draw3DTrajectory(json,trajectoryBody) {
  // {
  //   "trajectory_point": {
  //     "measured_depth": 0,
  //     "azimuth": 0,
  //     "tvd": 0,
  //     "y": 0,
  //     "x": 0,
  //     "z": 0,
  //     "inclination": 0
  //   },
  //   "eid": "case0/trajectory1"
  // },

  let initialAzimuth = parseFloat(0)
  let initialInclination = parseFloat(0)


  const initialX = parseFloat(0)
  const initialY = parseFloat(0)
  let initialZ = parseFloat(json["scenery"].air_gap)
  
  if( trajectoryBody) {
    initialZ = trajectoryBody["initial_tvd"] ? -1 * parseFloat(trajectoryBody["initial_tvd"]) : -1 * parseFloat(json["well_trajectory"]["initial_tvd"])
    initialAzimuth = trajectoryBody["initial_azymuth"] ? parseFloat(trajectoryBody["initial_azymuth"]) : parseFloat(json["well_trajectory"]["initial_azymuth"])
    initialInclination = trajectoryBody["initial_inclination"] ? parseFloat(trajectoryBody["initial_inclination"]) : parseFloat(json["well_trajectory"]["initial_inclination"])
  } else {
    initialZ = parseFloat(json["well_trajectory"]["initial_tvd"])
    initialAzimuth = parseFloat(json["well_trajectory"]["initial_azymuth"])
    initialInclination =  parseFloat(json["well_trajectory"]["initial_inclination"])
  }

  if(initialZ === undefined ||initialZ === null || initialZ === "0" || isNaN(initialZ)) {
    initialZ = parseFloat(json["scenery"].air_gap)
  } 
  
  if(initialAzimuth === undefined ||initialAzimuth === null || initialAzimuth === "0" || isNaN(initialAzimuth)) {
    initialAzimuth = parseFloat(0)
  } 
  
  if(initialInclination === undefined ||initialInclination === null || initialInclination === "0" || isNaN(initialInclination)) {
    initialInclination = parseFloat(0)
  } 
  
  const trajectory = json["well_trajectory"]["trajectory"];
  // const startingPoint = {"trajectory_point": {"measured_depth": "0", "azimuth": "0", "inclination": "0",
  // "tvd": "0", "y": "0", "x": "0", "z": "0"}}
  // trajectory.splice(0,0,startingPoint)
  const water_depth = parseFloat(json["scenery"]["water_depth"]);
  let x = trajectory.map((index) => initialX + (parseFloat(index.trajectory_point.x) - initialX)*Math.cos(initialAzimuth*Math.PI/180.0)
  - (parseFloat(index.trajectory_point.y) - initialY)*Math.sin(parseFloat(initialAzimuth*Math.PI/180.0)));
  const y = trajectory.map((index) => initialY + (parseFloat(index.trajectory_point.x) - initialX)*Math.sin(initialAzimuth*Math.PI/180)
  + (parseFloat(index.trajectory_point.y) - initialY)*Math.cos(parseFloat(initialAzimuth*Math.PI/180.0)));
  const z = trajectory.map((index) => initialZ + (parseFloat(index.trajectory_point.z) - initialZ)*Math.cos(initialInclination*Math.PI/180.0)
  - (parseFloat(index.trajectory_point.x) - initialX)*Math.sin(initialInclination*Math.PI/180.0));
  
  for (let index = 0; index < x.length ; index+=1) {
    x[index] = (parseFloat(x[index]) - initialX)*Math.cos(initialInclination*Math.PI/180.0) - (parseFloat(z[index]) - initialZ)*Math.sin(initialInclination*Math.PI/180.0) + initialX;                   
  }
  
  const abs_z = trajectory.map((index) => Math.abs(parseFloat(index.trajectory_point.z))/3);

  const colorscale_ocean = [
    [0, 'rgb(0, 0, 150)'],   // Red
    [0.5, 'rgb(10, 10, 150)'], // Green
    [1, 'rgb(50, 50, 150)']    // Blue
  ];
  const colorscale_seabed = [
    [0, 'rgb(124, 108, 128)'],   // Red
    [0.5, 'rgb(144, 128, 128)'], // Green
    [1, 'rgb(194, 178, 128)']    // Blue
  ];

  // Function to check if the value is NaN or null
  const isNullOrNaN = (value) => value === null || isNaN(value);

  let filteredX = x.map(value => (isNullOrNaN(value) ? 0 : value));
  let filteredY = y.map(value => (isNullOrNaN(value) ? 0 : value));
  let filteredZ = z.map(value => (isNullOrNaN(value) ? 0 : value));
  let filteredAbsZ = abs_z.map(value => (isNullOrNaN(value) ? 0 : value));
  
  const amplitude = Math.max(...filteredAbsZ) ? Math.max(...filteredAbsZ)/100 : 1;
  const surfPoints = 15;
  const surfX = linspace(-Math.max(...filteredX,...filteredY,...filteredAbsZ), Math.max(...filteredX,...filteredY,...filteredAbsZ), surfPoints);
  const surfY = linspace(-Math.max(...filteredX,...filteredY,...filteredAbsZ), Math.max(...filteredX,...filteredY,...filteredAbsZ), surfPoints);
  const noisy_water = generateMeshgridWithNoise(0, amplitude, [surfX.length,surfY.length]);
  const water_depth_deg = -1 * water_depth;
  const noisy_seabed = generateMeshgridWithNoise(water_depth_deg, amplitude, [surfX.length,surfY.length]);

  var water = [
    {
      x: surfX,
      y: surfY,
      z: noisy_water,
      type: "surface",
      colorscale:colorscale_ocean,
      showscale: false, 
      opacity: 0.5,
      hoverinfo: 'skip'
    },
  ];

  var seabed = [
    {
      x: surfX,
      y: surfY,
      z: noisy_seabed,
      type: "surface",
      colorscale: colorscale_seabed,
      showscale: false, 
      opacity: 0.5,
      hoverinfo: 'skip'
    },
  ];

  const trajectory_line = {
  type: 'scatter3d',
  mode: 'lines+markers',
  x: [initialX,...filteredX],
  y: [initialY,...filteredY],
  z: [initialZ,...filteredZ],
  line: {
    width: 6,
    // color: c,
    colorscale: "Viridis"},
  marker: {
    size: 3.5,
    // color: c,
    simbol:'triangle',
    colorscale: "Greens",
    cmin: -20,
    cmax: 50
  }}
var data_array = [trajectory_line];

if (water_depth > 0) {
  data_array.push(...water);  
}
data_array.push(...seabed);
return data_array
}

export {
  getGeometryLimits,
  drawAllGeometry,
  drawInternalGeometry,
  drawExternalGeometry,
  drawCentralization,
  draw3DTrajectory,
  drawSeaDepth
};
