import { useContext } from "react";
import { ToastContext } from "../components/contexts/ToastContext";

function useDensityProfileValidation() {
  // Toast Context
  const { toastList, setToastList, showToast } = useContext(ToastContext);

  // Function to call showToast
  function showToastValidation(description, title, type) {
    showToast(
      {
        message: {
          type: type,
          title: title,
          description: description
        },
      },
      toastList,
      setToastList
    );
  }

  // Check if the "Densidade (lb/gal)" input is valid
  function validateThirdInputSection(isCheckedDensity, values) {
    if (
      !isCheckedDensity &&
      !values["fluid_element_item"]["density"].density_value
    ) {
      showToastValidation("O campo 'Densidade (lb/gal)' deve ser preenchido", "Erro de validação", "ERR");
      return false;
    }
    return true;
  }

  // Check if temperature, pressure or value are not filled in the grid
  // The operator "!" verify: "", null, NaN, undefined, false
  // If the json returns temperature, pressure or value as null, the grid input is filled as [object object]. To prevent the user from saving data, typeof temperature === "object" is checked
  // isNaN(Number(temperature)) is used to check if after the conversion temperature, pressure or value is not a number
  // temperature === " " is used to prevent the user from saving the data if they add a line and do not insert the data into the grid
  function validateFields(fields, description, title, type) {
    for (const field of fields) {
      if (!field || typeof field === "object" || isNaN(Number(field)) || field === " ") {
        showToastValidation(description, title, type);
        return false;
      }
    }
    return true;
  }

  function validateDensityProfile(rowsDensity, density_dependence) {

    // Verify if rowsDensity is an array and is empty
    if (!Array.isArray(rowsDensity) || rowsDensity.length === 0) {
      showToastValidation("Adicione uma linha e preencha os campos da tabela para salvar os dados", "Erro de validação", "ERR")
      // Returns false if rowsDensity is an array and is empty
      return false;
    }

    for (const row of rowsDensity) {
      const { temperature, pressure, value } = row;

      // To pressure-profile
      if (density_dependence === "pressure-profile") {
        if (!validateFields([pressure, value], "Preencha todos os campos de pressão e densidade", "Erro de validação", "ERR")) {
          return;
        }
      }

      // To temperature-profile
      if (density_dependence === "temperature-profile") {
        if (!validateFields([temperature, value], "Preencha todos os campos de temperatura e densidade", "Erro de validação", "ERR")) {
          return;
        }
      }

      // To pressure-temperature-profile
      if (density_dependence === "pressure-temperature-profile") {
        if (!validateFields([temperature, pressure, value], "Preencha todos os campos de temperatura, pressão e densidade", "Erro de validação", "ERR")) {
          return;
        }
      }
    }
    // Returns true if there is no validation error
    showToastValidation("Fluido atualizado com sucesso", "Sucesso", "SCS")
    return true;
  }
  return { validateDensityProfile, validateThirdInputSection, validateFields, showToastValidation };
}

export default useDensityProfileValidation;
