//Function to  duplicate row - Pump Sequence and Rheologic Data
import { duplicateFluidsIdRequest, createRheologicData } from "../../services/FluidsRequest";
import { reload } from "./functionSetFocused";

function duplicateLocally(selectedRows, allRows, eid = null, newColor = null, activeRheoData = null) {
  let newData = allRows;
  const columns = Object.keys(allRows[0]);

  for (
    let selectedRow = selectedRows.length - 1;
    selectedRow >= 0;
    selectedRow -= 1
  ) {
    // Initialize duplicate with id and selected columns filled
    let duplicateRow = {
      id: selectedRows[selectedRow].id + 1,
      eid: eid ? eid : null,
      activeEid: activeRheoData ? activeRheoData.eid : null,
      selected: false,
    };

    // Loop over other columns
    columns.map((column) => {
      if (!(column === "id" || column === "selected" || column === "eid")) {
        if (column === "color" && newColor) {
          duplicateRow[column] = newColor;
        } else {
          duplicateRow[column] = selectedRows[selectedRow][column];
        }
      }
    });
    let selectedRowPosition = selectedRows[selectedRow].id - 1;
    for (let row = selectedRowPosition + 1; row < newData.length; row += 1) {
      newData[row].id = newData[row].id + 1;
    }

    let newRows = [...newData, duplicateRow];
    newData = newRows.sort((a, b) => a.id - b.id);
  }
  return newData;
}

/* External Geometry and Internal Geometry - Last Casing, Casing and Work Column*/
export function duplicateRowsCasingAndWorkColumn(
  rowsCasingAndWorkColumn,
  setRowsCasingAndWorkColumn,
  setChangedGrid
) {
  const selectedRows = rowsCasingAndWorkColumn.filter(
    (item) => item.selected === true
  );

  setChangedGrid(true);
  const newData = duplicateLocally(selectedRows, rowsCasingAndWorkColumn);
  setRowsCasingAndWorkColumn(newData);
}

/* External Geometry - Open Role*/
export function duplicateRowOpenRole(
  rowsOpenRole,
  setRowsOpenRole,
  setChangedGrid
) {
  const selectedRows = rowsOpenRole.filter((item) => item.selected === true);

  setChangedGrid(true);
  const newData = duplicateLocally(selectedRows, rowsOpenRole);
  setRowsOpenRole(newData);
}

/* Centralization */
export function duplicateRowsCentralization(
  rowsCentralization,
  setRowsCentralization,
  setChangedGrid
) {
  const selectedRows = rowsCentralization.filter(
    (item) => item.selected === true
  );

  setChangedGrid(true);
  const newData = duplicateLocally(selectedRows, rowsCentralization);
  setRowsCentralization(newData);
}

/* Trajectory */
export function duplicateRowTrajectory(
  rowsTrajectory,
  setRowsTrajectory,
  setChangedGrid
) {
  const selectedRows = rowsTrajectory.filter((item) => item.selected === true);

  setChangedGrid(true);
  const newData = duplicateLocally(selectedRows, rowsTrajectory);
  setRowsTrajectory(newData);
}

/* Pump Sequence - Fluids Sequence*/
export function duplicateRowAnnularAndDisplacement(
  rowsAnnularAndDisplacement,
  setRowsAnnularAndDisplacement,
  setChangedGrid
) {
  // const newData = [];
  // let newRow = [];
  const selectedRows = rowsAnnularAndDisplacement.filter(
    (item) => item.selected === true
  );

  setChangedGrid(true);
  const newData = duplicateLocally(selectedRows, rowsAnnularAndDisplacement);
  setRowsAnnularAndDisplacement(newData);
}

/* Pump Sequence - Density*/
export function duplicateRowDensity(
  rowsDensity,
  setRowsDensity,
  setChangedGrid
) {
  // const newData = [];
  // let newRow = [];
  const selectedRows = rowsDensity.filter((item) => item.selected === true);

  setChangedGrid(true);
  const newData = duplicateLocally(selectedRows, rowsDensity);
  setRowsDensity(newData);
}

/* Thermic Profile - Surface Tempeture */
export function duplicateRowSurfaceTempeture(
  rowsSurfaceTemperature,
  setRowsSurfaceTemperature,
  setChangedGrid
) {
  const selectedRows = rowsSurfaceTemperature.filter(
    (item) => item.selected === true
  );

  setChangedGrid(true);
  const newData = duplicateLocally(selectedRows, rowsSurfaceTemperature);
  setRowsSurfaceTemperature(newData);
}

/* Pump Sequence - Fluids */
export async function duplicateRowFluids(
  rowsFluids,
  setRowsFluids,
  setChangedGrid,
  activeRheoData = null,
  caseId,
  rowEid,
  showToast,
  toastList,
  setToastList
) {
  const selectedRows = rowsFluids.filter((item) => item.selected === true);

  const response = await duplicateFluidsIdRequest(caseId, rowEid);
  const newData = duplicateLocally(
    selectedRows,
    rowsFluids,
    response.data.eid ? response.data.eid : null,
    response.data.fluid_element_item.color
      ? response.data.fluid_element_item.color
      : "#000000"
  );
  
  showToast(response, toastList, setToastList);
  /*   setChangedGrid(true); */
  setRowsFluids(newData);
  reload();
}

/* Pump Sequence - Rheometry Readings*/
export function duplicateRowRheometryReadings(
  rowsRheometry,
  setRowsRheometry,
  setChangedGrid, 
  activeRheoData
) {
  // const newData = [];
  // let newRow = [];
  const selectedRows = rowsRheometry.filter((item) => item.selected === true);
  setChangedGrid(true);
  const newData = duplicateLocally(selectedRows, rowsRheometry, null , null , activeRheoData);
  setRowsRheometry(newData);
}

/* Pump Sequence - Rheologic Properties*/
export async function duplicateRowRheologicProperties(
  rowsRheologicProperties,
  setRowsRheologicProperties,
  setChangedGrid,
  activeRheoData = null,
  caseId,
  rowEid,
  showToast,
  toastList,
  setToastList,
  setChangedValues,
  setUpdatedRheologicData,
  setUpdatedJson
) {
  const selectedRows = rowsRheologicProperties.filter(
    (item) => item.selected === true
  );

  const response = await createRheologicData(caseId, rowEid);
  const newData = duplicateLocally(
    selectedRows,
    rowsRheologicProperties,
    response.data[response.data.length -1].eid ? response.data[response.data.length -1].eid : null
  );
  setChangedValues(false);
  setUpdatedJson([]);
  setUpdatedRheologicData(response.data);
  showToast(response, toastList, setToastList);
  setChangedGrid(false);
  setRowsRheologicProperties(newData);
}

// Simulation - Inlet Flowrate, Outlet Flowrate, Reciprocation Column, Rotation Column
export function duplicateRowSimulation(
  rowSimulation,
  setRowSimulation,
  setChangedGrid
) {
  const selectedRows = rowSimulation.filter((item) => item.selected === true);
  const newData = duplicateLocally(selectedRows, rowSimulation);
  setChangedGrid(true);
  setRowSimulation(newData);
}
