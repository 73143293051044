
// # Main Import
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";

// # Import Component Style
import "./CardButtonsAction.css";

// Navbar React Component Construction
function CardButtonsAction(props) {
  if (props.tooltip.includes("Deletar") || props.locked === false) {
    return (
      <Link to={props.routeURL} onClick={() => props.callback(true)}>
        <button
          key={"action-icon_" + props.key}
          className={"action-icon"}
          style={{ backgroundColor: props.backgroundColor, color: props.color }}
          onClick={() => props.setId(props.id)}
        >
          <p className="tooltip">{props.tooltip}</p>
          <i className={props.icon}></i>
        </button>
      </Link>
    );
  } else if (props.tooltip.includes("Editar") || props.tooltip.includes("Casos") || props.tooltip.includes("Visualizar")) {
    return (
       <Link to={  props.routeURL === "/case/" ?   props.routeURL + props.id + "/general_info" + props.queryParams: props.routeURL + props.id + "/cases"}> 
        <button
          key={"action-icon_" + props.key}
          className={"action-icon"}
          style={{ backgroundColor: props.backgroundColor, color: props.color }}
          onClick={ props.callback? () => props.callback(props.locked,props.id)  : "" }
        >
          <p className="tooltip">{props.tooltip}</p>
          <i className={props.icon}></i>
        </button>
       </Link> 
    );
  } else  {
    return (
      <button
        key={"action-icon_" + props.key}
        className={"action-icon"}
        style={{ backgroundColor: props.backgroundColor, color: props.color }}
        onClick={ props.callback? () => props.callback(props.id)  : "" }
      >
        <p className="tooltip">{props.tooltip}</p>
        <i className={props.icon}></i>
      </button>
    );
  }

}

export default CardButtonsAction;
