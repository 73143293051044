// Color Picker= div with color

// # Main Import
import React from "react";


// # Import Component Style
import "./colorPicker.css";


function ColorPicker(props) {
  const color = props.color;

  return (
    <div style={{background: color}} className="color-picker__container">
        <button className="color-picker__bttn" data-testid={`color-picker-${color}`} />
    </div>
  );
}

export default ColorPicker;
