function validateFieldsGrid(updatedJson, tag, showToast, toastList, setToastList) {

    // Function to call showToast
    function toastValidationGrid(description, title) {
        showToast(
            {
                message: {
                    type: "ERR",
                    title: title,
                    description: description,
                },
            },
            toastList,
            setToastList
        );
    }

    // Standalone validation function
    function validateFields(updatedJson, description, title) {
        for (const field of updatedJson) {
            if (field === "") {
                toastValidationGrid(description, title);
                return false;
            }
        }
        return true;
    }

  function validateFieldGridGeometry() {
    for (const field of updatedJson) {
      // To external geometry - open_hole
      if (tag === "open_hole") {
        const { inside_diameter, measured_depth, segment_length } =
          field.open_hole_element_item;
        if (
          !validateFields(
            [inside_diameter, measured_depth, segment_length],
            `Preencha todos os campos de poço aberto para salvar`,
            "Erro de validação"
          )
        ) {
          return;
        }
      }

      // To external geometry - last_casing
      if (tag === "last_casing") {
        const {
          inside_diameter,
          measured_depth,
          outside_diameter,
          segment_length,
        } = field.last_casing_element_item;
        if (
          !validateFields(
            [inside_diameter, measured_depth, outside_diameter, segment_length],
            `Preencha todos os campos de revestimento anterior para salvar`,
            "Erro de validação"
          )
        ) {
          return;
        }
      }

      // To internal geometry - work_column
      if (tag === "work_column") {
        const {
          inside_diameter,
          measured_depth,
          outside_diameter,
          segment_length,
        } = field.pipe_element_item;
        if (
          !validateFields(
            [inside_diameter, measured_depth, outside_diameter, segment_length],
            `Preencha todos os campos da coluna de trabalho para salvar`,
            "Erro de validação"
          )
        ) {
          return;
        }
      }

      // To internal geometry - casing
      if (tag === "casing") {
        const { measured_depth, outside_diameter, segment_length } =
          field.casing_element_item;
        if (
          !validateFields(
            [
              field.casing_element_item["inside_diameter"],
              measured_depth,
              outside_diameter,
              segment_length,
            ],
            `Preencha todos os campos de revestimento para salvar`,
            "Erro de validação"
          )
        ) {
          return;
        }
      }

      // To centralization
      if (tag === "centralization") {
        const { measured_depth, standoff, standoff_angle } =
          field.centralization_point;
        if (
          !validateFields(
            [measured_depth, standoff, standoff_angle],
            `Preencha todos os campos de centralização para salvar`,
            "Erro de validação"
          )
        ) {
          return;
        }
      }

        // // To trajectory
        // if (tag === "trajectory") {
        //     const { measured_depth, inclination, azymuth, tvd } =
        //       field.trajectory_point;
        //     if (
        //       !validateFields(
        //         [measured_depth, inclination, azymuth, tvd],
        //         `Preencha todos os campos de trajetória para salvar`,
        //         "Erro de validação"
        //       )
        //     ) {
        //       return;
        //     }
        //   }
    }

    return true;
  }

  function validateFieldAmbientTemperature(ambientTemperature) {
    if(!ambientTemperature.ambient_temperature){
      toastValidationGrid("Preencha o campo de temperatura ambiente", "Erro de validação")
      return false;
    }
    return true;
  }

  function validateGridThermalProfile () {
    for (const field of updatedJson) {
      
      //To grid Temperatura da água (ºC)
      if(tag === "water_temperatures") {
        const {temperature, tvd} = field.water_temperature_list_item;
        if(!validateFields([temperature, tvd], "Preencha todos os campos da tabela Temperatura da água para salvar", "Erro de validação")) {
          return;
        }
      }

      //To grid gradiente geotérmico
      if(tag === "formation_temperatures") {
        const {temperature, tvd} = field.formation_temperature_list_item;
        if(!validateFields([temperature, tvd], "Preencha todos os campos do Gradiente geotérmico para salvar", "Erro de validação")) {
          return;
        }
      }
    }
    return true;
  }

  return { validateFieldGridGeometry, validateFieldAmbientTemperature, validateGridThermalProfile };
}

export default validateFieldsGrid