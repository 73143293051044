/* Accordion: must receive JS object as input with tabs labels and rescpective children inside.
- tabs must animate smoothly
- careful with double scrolling. */

// # Main Import
import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import { HistoryContext } from "../../contexts/HistoryContext";

// # Import Component Style
import "./accordion.css";

function AccordionItem(props) {
  const title = props.title;
  const id = props.id;

  const route = props.route;

  const isOpen = props.isOpen;
  const setOpenKey = props.setOpenKey;
  const style = { height: props.maxHeight };
  const historyValue = props.historyValue;

  const history = useHistory();

  // Query Params
  const queryParams = history.location.search;

  // Props data and Context to implement modal changes
  const hasChange = props.hasChange;
  const setIsModalOpen = props.setIsModalOpen;
  const { setHistoryLocation } = useContext(HistoryContext);

  // Variables to get the max height of the accordion container child
  const maxHeightAccordionChild = Object.values(style);
  const [height] = maxHeightAccordionChild;

  // Media queries to the 1920 x 1080 resolution
  const sidebarCssAdjusted = {
    '@media only screen and (max-width: 1920px) and (max-height: 1080px)' : {
        height: height,
      },
  }
 
  // Function responsible to open modal changes
  function openModal(route) {
    setIsModalOpen(true);
    setHistoryLocation(route);
  }

  return (
    <div className="accordion__container--item">
      {route ? (
        // Stay at the same page : {} 
        <Link to={hasChange === true ? {} : route + queryParams}>
          <div
            className="accordion__title"
            onClick={
              hasChange === true ? () => openModal(route) : () => setOpenKey(id)
            }
          >
            <p>{title}</p>
          </div>
        </Link>
      ) : (
        title === "Resultados" ? (
          <Link to={`/case/${historyValue}/results`}>
            <div className="accordion__title" onClick={() => setOpenKey(id)}>
              <p>{title}</p>
            </div>
          </Link>
        ) :
        <div className="accordion__title" onClick={() => setOpenKey(id)}>
          <p>{title}</p>
        </div>
      )}

      {isOpen && (
        <div className="accordion__container--child max__height__accordion__child" style={sidebarCssAdjusted}>
          {props.children.length > 0
            ? props.children.map((child) => {
                if (child.key === id) {
                  return child;
                }
              })
            : props.children}
        </div>
      )}
    </div>
  );
}

function Accordion(props) {
  const accordionData = props.accordionData;

  const openKey = props.openKey;
  const setOpenKey = props.setOpenKey

  const maxHeight = props.maxHeight;

  const children = props.children;

  const hasChange = props.hasChange;
  const setIsModalOpen = props.setIsModalOpen;
  const historyValue = props.historyValue;

  return (
    <ul className="accordion__container">
      {accordionData.map(({ title, id, route }) => (
        <AccordionItem
          id={id}
          title={title}
          children={children}
          isOpen={openKey === id}
          setOpenKey={setOpenKey}
          maxHeight={maxHeight}
          route={route}
          hasChange={hasChange}
          setIsModalOpen={setIsModalOpen}
          historyValue={historyValue}
        />
      ))}
    </ul>
  );
}

export default Accordion;
