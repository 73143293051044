// Function to draw the graph boundary conditions
function drawBoundaryConditions(simulationUpdated, simulationJson) {
    const dataBoundaryConditions = [];

    const inletFlowrateJson =
        simulationJson.boundary_conditions.inlet_flowrate.inlet_flowrate_profile

    const outletPressureJson =
        simulationJson.boundary_conditions.outlet_pressure.outlet_pressure_profile

    const inletFlowrateUpdated = simulationUpdated.inlet_flowrate_profile;

    const outletPressureUpdated = simulationUpdated.outlet_pressure_profile;

    // If receives the data from the grid in inlet flow then inletFlowrateUpdated, otherwise, inletFlowrateJson (the json values)
    const inletFlowrateProfile = inletFlowrateUpdated ? inletFlowrateUpdated : inletFlowrateJson;

    // If receives the data from the grid in outlet Pressure then outletPressureUpdated, otherwise, outletPressureJson (the json values)
    const outletPressureProfile = outletPressureUpdated
        ? outletPressureUpdated
        : outletPressureJson;

    // Array to store the values ​​of the x-axis and y-axis of inlet flow
    let dataXflowrate = [];
    let dataYflowrate = [];

    if (
        inletFlowrateProfile !== undefined &&
        inletFlowrateProfile !== null &&
        inletFlowrateProfile.length !== 0
    ) {
        dataXflowrate.push(
            ...inletFlowrateProfile.map((item) =>
                parseFloat(item.inlet_flowrate_profile_element_item.time)
            )
        );
        dataYflowrate.push(
            ...inletFlowrateProfile.map((item) =>
                parseFloat(item.inlet_flowrate_profile_element_item.value)
            )
        );
    } else {
        // dataXw = [parseFloat(ambient_temperature),];
        // dataYw = [water_depth];
    }

    // Array to store the values ​​of the x-axis and y-axis of outled pressure
    let dataXpressure = [];
    let dataYpressure = [];

    if (
        outletPressureProfile !== undefined &&
        outletPressureProfile !== null &&
        outletPressureProfile.length !== 0
    ) {
        dataXpressure = outletPressureProfile.map((item) =>
            parseFloat(item.outlet_pressure_profile_element_item.time)
        );

        dataYpressure = outletPressureProfile.map((item) =>
            parseFloat(item.outlet_pressure_profile_element_item.value)
        );
    } else {
        dataXpressure = [];
        dataYpressure = [];
    }


    const color1 = "#0000AA"
    const color2 = "#FF0000"

    // Line of inlet flow
    const lineInletFlowrate = {
        x: dataXflowrate,
        y: dataYflowrate,
        name: "Vazão de entrada",
        type: "line",
        mode: "line",
        marker: { color: color1 },
        yaxis: 'y1'
    };

    // Line of outlet pressure
    const lineOutletPressure = {
        x: dataXpressure,
        y: dataYpressure,
        name: "Pressão de Saída",
        type: "line",
        mode: "line",
        marker: { color: color2 },
        yaxis: 'y2'
    };

    dataBoundaryConditions.push(lineInletFlowrate, lineOutletPressure);
    return dataBoundaryConditions;
}

// Function to get the limits of Boundary Conditions
function getBoundaryConditionsLimits(simulationUpdated, simulationJson) {
    const inletFlowrateJson =
        simulationJson.boundary_conditions.inlet_flowrate.inlet_flowrate_profile

    const outletPressureJson =
        simulationJson.boundary_conditions.outlet_pressure.outlet_pressure_profile

    const inletFlowrateUpdated = simulationUpdated.inlet_flowrate_profile;

    const outletPressureUpdated = simulationUpdated.outlet_pressure_profile;

    const inletFlowrateProfile = inletFlowrateUpdated ? inletFlowrateUpdated : inletFlowrateJson;

    const outletPressureProfile = outletPressureUpdated
        ? outletPressureUpdated
        : outletPressureJson;

    let dataXflowrate = [];
    let dataYflowrate = [];

    if (
        inletFlowrateProfile !== undefined &&
        inletFlowrateProfile !== null &&
        inletFlowrateProfile.length !== 0
    ) {
        dataXflowrate.push(
            ...inletFlowrateProfile.map((item) =>
                parseFloat(item.inlet_flowrate_profile_element_item.time)
            )
        );
        dataYflowrate.push(
            ...inletFlowrateProfile.map((item) =>
                parseFloat(item.inlet_flowrate_profile_element_item.value)
            )
        );
    } else {
        dataXflowrate = [];
        dataYflowrate = [0, 1000];
    }

    let dataXpressure = [];
    let dataYpressure = [];

    if (
        outletPressureProfile !== undefined &&
        outletPressureProfile !== null &&
        outletPressureProfile.length !== 0
    ) {
        dataXpressure = outletPressureProfile.map((item) =>
            parseFloat(item.outlet_pressure_profile_element_item.time)
        );

        dataYpressure = outletPressureProfile.map((item) =>
            parseFloat(item.outlet_pressure_profile_element_item.value)
        );
    } else {
        dataXpressure = [];
        dataYpressure = [];
    }

    // Variables that store the limit the x-axis and y-axis the boundary conditions graph
    const xLimitsBoundaryConditons = Math.max([Math.max(...dataXpressure), Math.max(...dataXflowrate)]);
    const yLimitsBoundaryConditons = Math.max([Math.max(...dataYpressure), Math.max(...dataYflowrate)]);
    return [xLimitsBoundaryConditons, yLimitsBoundaryConditons];
}

export { drawBoundaryConditions, getBoundaryConditionsLimits };
