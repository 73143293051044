import { useState, useEffect } from 'react';

const useChartSize = (querySelector, pathName) => {

  const storedChartSize = () => {
    const storedSize = sessionStorage.getItem("chartSize");
    return storedSize ? JSON.parse(storedSize) : { width: 0, height: 0 };
  };

  const [chartSize, setChartSize] = useState(storedChartSize);

  useEffect(() => {
    const handleResize = () => {
      const divSizeTotal = document.querySelector(querySelector);
      if (divSizeTotal) {
        
        // Defines the width and height of the graphs based on the container of the first geometry or simulation graph, since the hook is being used on these pages
        if (["external-geometry", "partial-simulation"].includes(pathName)) {
          setChartSize(prevState => {
            
            // The prevState.width || divSizeTotal.offsetWidth and prevState.height || divSizeTotal.offsetHeight ensures that the width and height do not undergo unwanted changes
            const valueSizeChart = {
              width: prevState.width || divSizeTotal.offsetWidth * 0.7,
              height: prevState.height || divSizeTotal.offsetHeight,
            };

            // If the width and height were not stored in sessionStorage, set them
            // If they have already been defined, just get the values ​​in getItem
            if (!prevState.width || !prevState.height) {
              sessionStorage.setItem('chartSize', JSON.stringify(valueSizeChart));
            }

            return valueSizeChart;
          });
        }
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [querySelector, pathName]);

  return chartSize;
};

export default useChartSize;