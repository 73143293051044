const thermal_profile = {
  ambient_temperature: 80.0,
  water_temperatures: [
    {
      eid: "case0/water_temp1",
      water_temperature_list_item: {
        tvd: 0,
        temperature: 70.0,
      },
    },
    {
      eid: "case0/water_temp2",
      water_temperature_list_item: {
        tvd: 1000,
        temperature: 39.2,
      },
    },
  ],
  formation_temperatures: [
    {
      formation_temperature_list_item: {
        tvd: 3340,
        geothermal_gradient: 2.12,
        temperature: 118.77,
        formation_type_index: "case0/formationtemp1",
      },
      eid: "case0/formationtemp1",
    },
    {
      formation_temperature_list_item: {
        tvd: 4872,
        geothermal_gradient: 0.49,
        formation_type_index: "case0/formationtemp2",
        temperature: 143.4,
      },
      eid: "case0/formationtemp2",
    },
    {
      formation_temperature_list_item: {
        tvd: 5357,
        geothermal_gradient: 1.9,
        temperature: 173.63,
        formation_type_index: "case0/formationtemp3",
      },
      eid: "case0/formationtemp3",
    },
  ],
};

function getThermalProfileLimits(thermal_profile, json) {
  const water_depth = json["scenery"]["water_depth"];

  let {ambient_temperature} = json["thermal_profile"]["ambient_temperature"]
  
  let dataXw = [parseFloat(ambient_temperature)];
  let dataYw = [parseFloat("0.0")];
  
  if (
    thermal_profile.water_temperatures !== undefined &&
    thermal_profile.water_temperatures !== null &&
    thermal_profile.water_temperatures.length !== 0
  ) {
    dataXw.push(...thermal_profile.water_temperatures.map((item) =>
      parseFloat(item.water_temperature_list_item.temperature)
    ));
    dataYw.push(...thermal_profile.water_temperatures.map((item) =>
      parseFloat(item.water_temperature_list_item.tvd)
    ));
  } else {
    dataXw = [parseFloat(ambient_temperature), parseFloat(ambient_temperature)];
    dataYw = [0, 1000];
  }

  let dataXf = [];
  let dataYf = [];
  if (
    thermal_profile.formation_temperatures !== undefined &&
    thermal_profile.formation_temperatures !== null &&
    thermal_profile.formation_temperatures.length !== 0
  ) {
    dataXf = thermal_profile.formation_temperatures.map((item) =>
      parseFloat(item.formation_temperature_list_item.temperature)
    );
    dataYf = thermal_profile.formation_temperatures.map((item) =>
      parseFloat(item.formation_temperature_list_item.tvd)
    );
    } else {
      dataXf = [ambient_temperature, ambient_temperature];
      dataYf = [water_depth, water_depth * 1.1];
    }
    
  const xLimits = Math.max([Math.max(...dataXf), Math.max(...dataXw)]);
  const yLimits = Math.max([Math.max(...dataYf), Math.max(...dataYw)]);
  return [xLimits, yLimits];
}

function drawThermalProfile(thermal_profile, json) {
  let {ambient_temperature} = json["thermal_profile"]["ambient_temperature"]

  const water_depth = json["scenery"]["water_depth"];
  const dataThermalProfile = [];
  const shapeThermalProfile = [];

  const thermalProfileFormation = thermal_profile.formation_temperatures
    ? thermal_profile.formation_temperatures
    : json["thermal_profile"]["formation_temperatures"];

  const thermalProfileWater = thermal_profile.water_temperatures
    ? thermal_profile.water_temperatures
    : json["thermal_profile"]["water_temperatures"];

  let dataXw = [parseFloat(ambient_temperature)];
  let dataYw = [parseFloat(0.0)];
  if (
    thermalProfileWater !== undefined &&
    thermalProfileWater !== null &&
    thermalProfileWater.length !== 0
  ) {
    dataXw.push(...thermalProfileWater.map((item) =>
      parseFloat(item.water_temperature_list_item.temperature)
    ));
    dataYw.push(...thermalProfileWater.map((item) =>
      parseFloat(item.water_temperature_list_item.tvd)
    ));
  } else {
    // dataXw = [parseFloat(ambient_temperature),];
    // dataYw = [water_depth];
  }

  let dataXf = [];
  let dataYf = [];
  if (
    thermalProfileFormation !== undefined &&
    thermalProfileFormation !== null &&
    thermalProfileFormation.length !== 0
  ) {
    dataXf = thermalProfileFormation.map((item) =>
      parseFloat(item.formation_temperature_list_item.temperature)
    );

    dataYf = thermalProfileFormation.map((item) =>
      parseFloat(item.formation_temperature_list_item.tvd)
    );
  } else {
    dataXf = [];
    dataYf = [];
  }
  function zerofy(x){return (Boolean(x) && !isNaN(x.toString())) ? x : 0 ;}
  function clear (arr){
    var stripped = [];
    for (let i = 0; i < arr.length; i++){
      if (zerofy(arr[i])===0 && i>0) {
        stripped.push(arr[i-1]); 
      } else {
       stripped.push(zerofy(arr[i]));
      }
    }
    return stripped;
 }
  
  const dataXall = dataXw.concat(dataXf);
  const dataYall = dataYw.concat(dataYf);

  const dataXNoNaN = clear(dataXall);
  const dataYNoNaN = clear(dataYall);
  
  
  const lineTempeture = {
    x: dataXNoNaN,
    y: dataYNoNaN.map((x) => x * -1),
    type: "scatter",
    mode: "lines",
    line: { color: "#012360" },
  };

  if (dataXNoNaN.length>0){

    const backgroundWater = {
      type: "rect",
      x0: 0,
      y0: 0,
      x1: Math.max(Math.max(...dataXNoNaN), Math.max(...dataXNoNaN)),
      y1: -1 * water_depth,
      line: {
        color: "#8ae1ff",
        width: 1,
      },
      fillcolor: "#8ae1ff",
      opacity: 0.4,
    };
  
    const backgroundFormation = {
      type: "rect",
      x0: 0,
      y0: -1 * water_depth,
      x1: Math.max(Math.max(...dataXNoNaN), Math.max(...dataXNoNaN)),
      y1: -Math.max(...dataYNoNaN),
      line: {
        color: "#937f6a",
        width: 1,
      },
      fillcolor: "#937f6a",
      opacity: 0.4,
    };

    shapeThermalProfile.push(backgroundWater, backgroundFormation);
  }

  dataThermalProfile.push(
    // lineFormationTempeture,
    lineTempeture
  );

  return [shapeThermalProfile, dataThermalProfile];
}

export { drawThermalProfile, getThermalProfileLimits };
