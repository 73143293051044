function drawSpatialDiscretization(simulation, simulationMode) {

  // Object that define rNodes and thetaNodes considering simulation mode (column or annular)
  let objNodes = {
     "column": [simulation["mesh_properties"]["column_num_radial_nodes"], simulation["mesh_properties"]["column_num_azimuthal_nodes"] ],
     "annular": [simulation["mesh_properties"]["num_radial_nodes"], simulation["mesh_properties"]["num_azimuthal_nodes"] ]
  }

  const r = [];
  const theta = [];
  const meshR = [];
  const meshTheta = [];
  const x_points = [];
  const y_points = [];

  //linspace r
  for (let i = 0; i < objNodes[simulationMode][0]; i++) {
    const value = i / (objNodes[simulationMode][0] - 1);
    r.push(value);
  }

  //linspace theta
  for (let i = 0; i < objNodes[simulationMode][1]; i++) {
    const value = (i / (objNodes[simulationMode][1] - 1)) * (2 * Math.PI);
    theta.push(value);
  }

  //Calcular o meshR e meshTheta
  for (let i = 0; i < r.length; i++) {
    for (let j = 0; j < theta.length; j++) {
      meshR.push(r[i]);
      meshTheta.push(theta[j]);
    }
  }

  //Converter pra cartesiano
  for (let i = 0; i < meshR.length; i++) {
    const r_val = meshR[i];
    const theta_val = meshTheta[i];

    const x_point = r_val * Math.cos(theta_val);
    const y_point = r_val * Math.sin(theta_val);

    x_points.push(x_point);
    y_points.push(y_point);
  }

  return { x: x_points, y: y_points };
}

export { drawSpatialDiscretization };
