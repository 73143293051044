// CardProjects = Card composed of Action Button area Description Text box, Created by / date, Modified by / date

// # Main Import
import React from "react";

// # Import Component Style
import "./CardProjects.css";

// # Import Component Card
import Card from "../card";
import CardButtonsAction from "../cardButtonsAction";

function CardProjects(props) {
  const card_data = props.data;
  const buttons = props.actionButtom;
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const optionsDate = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  return (
    <Card data={card_data}>
      <div className="card-body-projects">
        <div className="card-buttons">
          {buttons.actions.map((action, index) => (
            <CardButtonsAction
              rowID={index}
              className={"action_icon_button"}
              tooltip={action.tooltip}
              icon={action.icon}
              key={action.key}
              routeURL={action.route}
              callback={action.callback}
              backgroundColor={action.backgroundColor}
              color={action.color}
              setId={action.setId}
              id={card_data.pk}
              loked={action.locked}
            />
          ))}
        </div>
        <div className="card-informations-projects">
          <textarea
            className="card-description-projects"
            disabled
            rows={6}
            value={card_data.fields.description}
          />
          <h3>Criado por:</h3>
          <p className="card-informations-user">
            {card_data.fields.user.name} {""} em {""}{" "}
            {card_data.fields.created_at}
          </p>
          <h3>Última alteração:</h3>
          <p className="card-informations-user">
            {card_data.fields.updated_at}
          </p>
        </div>
      </div>
    </Card>
  );
}
export default CardProjects;
