// CheckboxAndBttn = Component to render checkbox and a buttom (only when checkbox is checked)

// #  Local SubComponents, utils and data
import PageAndContainerTitle from "../pageTitle";

// # Import Component Style
import "./checkboxAndBttn.css";

function CheckboxAndBttn(props) {
  const isChecked = props.isChecked;

  const isCheckedRheologic = props.isCheckedRheologic;

  const setPage = props.setPage;
  const pageNumber = props.pageNumber;

  const text = props.text;

  const icon = props.icon;

  const constValue = props.constValue;

  const onCheckboxChange = props.onCheckboxChange;
  
  // Query params of route
   const caseMode = props.caseMode;

  return (
    <div className="checkbox-bttn__container">
      {/* {text === "Dependência (P/T)" ? ( */}
        <>
          <input
            type="checkbox"
            className="input-fluid"
            checked={isChecked}
            onChange={onCheckboxChange}
            disabled={caseMode}
          />
          <PageAndContainerTitle text={text} level="2" />

          {isChecked ? (
            <>
              <button
                onClick={() => setPage(pageNumber)}
                className="icon-edit-bttn"
                data-testid="icon-edit-bttn"
                disabled={caseMode}
              >
                <i data-testid={icon} className={icon}></i>
              </button>
            </>
          ) : (
            " "
          )}
        </>
      {/* ) : isCheckedRheologic === false && constValue === "constants" ? (
        ""
      ) : (
        <>
          <button
            onClick={() => setPage(pageNumber)}
            className="icon-edit-bttn"
          >
            <i className={icon}></i>
          </button>
        </>
      )} */}
    </div>
  );
}

export default CheckboxAndBttn;
