// # Main Imports
import React, { useContext, useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import Cookies from 'js-cookie';

// Contexts
import { ToastContext } from "../../contexts/ToastContext";
import { HandleErrorContext } from "../../contexts/HandleErrorContext";
import { ProjectAndCasesContext } from "../../contexts/ProjectAndCasesContext";
import { getI3dAuthToken, validatePocoWebToken, refreshPocoWebToken } from "../../../services/AuthTokenRequests";

function ProtectedRoute(props) {

  // Token data
  const token = sessionStorage.getItem("Token");

  // Poço Web Token
  let pocoWebToken = Cookies.get("pocoweb_token")  
  const pocoWebRefreshToken = Cookies.get("pocoweb_refresh")

  // Authorization URL
  const authUrl = process.env.REACT_APP_AUTH_URL

  // Toast Context
  const { toastList } = useContext(ToastContext);

  // Project And Cases Context
  const { projectById } = useContext(ProjectAndCasesContext);

  // History
  const history = useHistory();

  // Error Context
  const { error } = useContext(HandleErrorContext);

  // Function to check if alert toast exists
  function checkAlertToast(toastList) {
    const alertToast = (
      toastList
        ? toastList.map((item) => item.title === "Edição em andamento")
        : false
    ).includes(true);
    if (alertToast && !history.location.pathname.includes("/cases")) {
      history.push(`/project/${projectById}/cases`);
    }
  }

  // Function to validate and refresh (only if token is expired) Poço Web Token
  function validateAndRefreshPocoWebToken(pocoWebToken) {
    validatePocoWebToken(pocoWebToken).then((res) => {
      if (res.status == 401) {  // token expired 
        // Then use refresh token
        refreshPocoWebToken(pocoWebRefreshToken).then((res) => {
          console.log("cookie before refresh: " + pocoWebToken)
          Cookies.set('pocoweb_token', res['pocoweb_access'], { expires: 365, path: '/' });
          Cookies.set('pocoweb_refresh', res['pocoweb_refresh'], { expires: 365, path: '/' });
          pocoWebToken = Cookies.get("pocoweb_token")  
          getI3dAuthToken(pocoWebToken)  
          setTimeout(() => { window.location.reload()} ,800)
        })          
      } else {      
        getI3dAuthToken(pocoWebToken)  
        setTimeout(() => { window.location.reload()} ,800)   
      }
      // window.location.reload() // reload page after authentication to clean toasts
    })
  }

  // Function to redirect user to auth page 
  function redirectToAuthPage() {
    setTimeout(() => {
      const token = sessionStorage.getItem("Token"); // Check if token was filled by Home after receiving auth code
      if (!token  || error === 401 ) {              
        window.location.href = authUrl;
      } 
      else {
        window.location.href = "/home" // Eliminate failed toasts and auth code from url
      }
    }, 800)
  }


  

  useEffect(() => {
    if (!token  || error === 401) {
      if (pocoWebToken) {
        validateAndRefreshPocoWebToken(pocoWebToken)         
      } else { // Not authenticated anywhere. Redirect to auth page.
        redirectToAuthPage()
      }      
    }
    if (toastList) {
      checkAlertToast(toastList);
    }
  }, [token, error, toastList]);  





  return (
    <Route path={props.path}>
        {props.children}
    </Route>
  )
}

export default ProtectedRoute;
