function getRheometryFluidLimits(rheometerChartData) {
  let dataXr = [];
  let dataYr = [];
  if (
    rheometerChartData !== undefined &&
    rheometerChartData !== null &&
    rheometerChartData !== 0
  ) {
    dataXr = rheometerChartData["rheometer_readings"].x;
    for (let index = 0; index <= dataXr.length; index += 1) {
      if (dataXr[index] !== 0) {
        dataYr.push(rheometerChartData["rheometer_readings"].y[index] / dataXr[index]);
      } else {
        dataYr.push(10 ** 9);
      }
    }
  } else {
    dataXr = [0, 1500];
    dataYr = [0, 10];
  }

  const xLimits = Math.log10(Math.max([Math.max(...dataXr)]));
  const yLimits = Math.log10(Math.max([Math.max(...dataYr)]));
  return [xLimits, yLimits];
}

function drawRheometryFluid(rheometerChartData) {
  const dataRheometryFluid = [];

  let dataXr = [];
  let dataYr = [];

  let dataXfit = [];
  let dataYfit = [];

  if (
    rheometerChartData !== undefined &&
    rheometerChartData !== null &&
    rheometerChartData !== 0
  ) {

    dataXfit = rheometerChartData["fitData"].x
    dataYfit = rheometerChartData["fitData"].yMu

    dataXr = rheometerChartData["rheometer_readings"].x;
    for (let index = 0; index <= dataXr.length; index += 1) {
      if (dataXr[index] !== 0) {
        dataYr.push(
          rheometerChartData["rheometer_readings"].y[index] / dataXr[index]
        );
      } else {
        dataYr.push(10 ** 9);
      }
    }

  } else {
    dataXr = [0, 1500];
    dataYr = [0, 10];
    dataXfit = [0, 1500];
    dataYfit =[0, 10];
  }

  const dataXall = dataXr;
  const dataYall = dataYr;

  const dataXfitAll = dataXfit;
  const dataYfitAll = dataYfit;

  const lineRheometryReadings = {
    x: dataXall,
    y: dataYall,
    mode: rheometerChartData["rheometer_readings"].mode,
    type: rheometerChartData["rheometer_readings"].type,
    marker: rheometerChartData["rheometer_readings"].marker,
    name: `Dados do FAN`,
  };

const lineRheometryFit = {
    x: dataXfitAll,
    y: dataYfitAll,
    mode: 'line',
    line: {shape: 'spline'},
    type: 'scatter',
    name: `Ajuste: ${rheometerChartData?.fitData?.model?.label}`
  }; 

  dataRheometryFluid.push(lineRheometryReadings, lineRheometryFit  );

  return dataRheometryFluid;
}


function drawRheometryFluidWithFanData(rheometerChart, isCheckedRheologic) {
  const dataRheometryFluidWithFan = [];

  let dataXr = [];
  let dataYr = [];

  if (
    rheometerChart !== undefined &&
    rheometerChart !== null &&
    rheometerChart !== 0
  ) {

    dataXr = rheometerChart.map((item) =>
    parseFloat(item.rheometer_reading_item.rotation)
  );

     dataYr = rheometerChart.map((item) =>
    parseFloat(item.rheometer_reading_item.reading)
  );

  } else {
    dataXr = [0, 1500];
    dataYr = [0, 10];
  }

  const dataXall = dataXr;
  const dataYall = dataYr;



  const lineRheometryReadings = {
    x: dataXall,
    y: dataYall,
    mode: 'markers',
    type: 'scatter',
    marker: { color:  isCheckedRheologic === true ? 'blue': '#636363', },
    name: `Dados do FAN`,
  };



  dataRheometryFluidWithFan.push(lineRheometryReadings);

  return dataRheometryFluidWithFan;
}

export { getRheometryFluidLimits, drawRheometryFluid,drawRheometryFluidWithFanData};
