// Main import of React
import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { useQuery } from "react-query";
import { Route } from "react-router";

// #  Local SubComponents & utils
import {
  PlotlyChart,
  ActionFabEditableGrid,
  Tabs,
  FormContainer,
  FormInput,
  DropletLoadingSpinner,
} from "../components/common";

// Functions to draw Column Movement and Boundary Conditions
import {
  drawColumnMovement,
  drawBoundaryConditions,
  getColumnMovementLimits,
  getBoundaryConditionsLimits,
  reload,
} from "../components/utils";

import {
  defaultValueReciprocation,
  defaultValueRotation,
} from "../data/simulationDefaultData";
import {
  mergedObject,
  minMaxMdInitialAndFinal,
  resetLimitInitialPartialSimulation,
  resetLimitFinalPartialSimulation,
} from "../components/utils";

// Function to draw Simulation Graphs
import {
  drawAllGeometryWithInitialAndFinalMd,
  drawSpatialDiscretization,
  drawTimePass,
  getGeometryLimits,
} from "../components/utils";

// Data to populate headers of grids - Simulation Params
import {
  headerRotationColumn,
  headerEntranceFlow,
  headerOutPressure,
  headerReciprocationColumn,
} from "../data/headersForGrids";

// Requests
import { getTimeStepProfileCombo } from "../services/SimulationRequests";
import { calcPumpSequenceChart } from "../services/ChartsRequests";

//Functions to  create rows - Geometry
import { createRows } from "../components/utils";

//Functions to add rows - Geometry
import { addTableRows } from "../components/utils/functionsToAddRows";

//Functions to delete rows - Geometry
import { deleteTableRows } from "../components/utils/functionsToDeleteRows";

//Functions to  duplicate rows - Geometry
import { duplicateRowSimulation } from "../components/utils/functionToDuplicateRows";

// # Context
import { UpdatedJsonContext } from "../components/contexts/UpdatedJsonContext";
import { HistoryContext } from "../components/contexts/HistoryContext";

// Hooks
import useChartSize from "../hooks/useChartSize";

function SimulationGraphs(props) {
  const json = props.json;

  // Conditional to avoid create diferent function to draw fluid space
  const inverted_simulation_mode_json = JSON.parse(JSON.stringify(json));
  inverted_simulation_mode_json.scenery.simulation_mode = json.scenery.simulation_mode.value === "annular" ? 
                                                          {label: "Coluna", value: "column"} : 
                                                          {label: "Anular", value: "annular"}

  // Variables to obtain the path name
  const history = props.history;
  const pathName = history.location.pathname.split("/")[4];

  // Variable to access the new width and height of the chart
  const chartSize = useChartSize(".plotly__container", pathName);


  // Updated Rows and Tags
  const { updatedJson, simulationBody, tag, setSimulationBody } = useContext(UpdatedJsonContext);

  // Define pump sequence chart data state
  const [pumpSequenceChartData, setPumpSequenceChartData] = useState();

  // Defining constants - simulation types
  const reciprocation_type = simulationBody
    ? simulationBody.boundary_conditions.column_movement.column_reciprocation
        .use_reciprocation
    : false;
  const rotation_type = simulationBody
    ? simulationBody.boundary_conditions.column_movement.column_rotation
        .use_rotation
    : false;

  const [xLimits, yLimits] = getColumnMovementLimits(
    Object.keys(updatedJson).length > 0 ? updatedJson : [],
    json["simulation"]
  );

  const dataColumnMovement = drawColumnMovement(
    Object.keys(updatedJson).length > 0 ? updatedJson : [],
    json["simulation"],
    rotation_type,
    reciprocation_type
  );

  // Function that return the limits of Boundary Conditions
  const [xLimitsBoundaryConditons, yLimitsBoundaryConditons] =
    getBoundaryConditionsLimits(
      Object.keys(updatedJson).length > 0 ? updatedJson : [],
      json["simulation"]
    );

  // Function that return the data from PlotlyChart of Boundary Conditions
  const dataBoundaryConditions = drawBoundaryConditions(
    Object.keys(updatedJson).length > 0 ? updatedJson : [],
    json["simulation"]
  );

  // Function that return the limits of Geometry with Initial And Final MD
  let [rLimits, zLimits] = getGeometryLimits(
    json["internal_geometry"],
    json["external_geometry"],
    json["scenery"]
  );
  const partialSimChartData = drawAllGeometryWithInitialAndFinalMd(
    json["internal_geometry"],
    json["external_geometry"],
    json["scenery"],
    simulationBody ? simulationBody : json["simulation"],
    pumpSequenceChartData
  );
  
  // Simulation - Json
  const simulation = json["simulation"];

  const mdInitial = simulationBody
  ? simulationBody["initial_measured_depth"]
  : simulation["initial_measured_depth"];
  
  const mdFinal = simulationBody
  ? simulationBody["final_measured_depth"]
  : simulation["final_measured_depth"];

  // Object to store initial and final md of json
  const jsonInitialAndFinalMd = {
    "initial_measured_depth" : json["simulation"]["initial_measured_depth"],
    "final_measured_depth" : json["simulation"]["final_measured_depth"]
  }

  // Object to store initial and final md of simulationBody
  const simulationBodyInitialAndFinalMd = {
    "initial_measured_depth" : mdInitial,
    "final_measured_depth" : mdFinal
  }

  // Function that compare if the properties values of jsonInitialAndFinalMd and simulationBodyInitialAndFinalMd are equals
  function checkPropertiesValuesEquals(object1, object2) {
    const keys1 = Object.keys(object1);
    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  }

  const isPropertiesValuesEqual = checkPropertiesValuesEquals(jsonInitialAndFinalMd, simulationBodyInitialAndFinalMd);
  // Define useEffect policy
    useEffect(() => {
      if (json) {
        calcPumpSequenceChart(mergedObject(inverted_simulation_mode_json, updatedJson)).then((res) => {
          if (res) {
            setPumpSequenceChartData(res);
          }
        });
      }
      reload();
      if(isPropertiesValuesEqual === false) {
        setSimulationBody(simulation);
      }
    }, [updatedJson, tag, json]);

  // Layout to draw Geometry with Initial And Final MD */
  const layoutPumpSequenceWithDepth = {
    width: 450,
    height: 550,
    plot_bdcolor: "black",
    showlegend: true,
    title: `<b>Região simulada: ${json["scenery"]["simulation_mode"]["label"]}</b>`,
    font: {
      family: "Arial",
      size: 12,
      color: "black",
    },

    xaxis: {
      range: rLimits,
      showgrid: false,
      title: "<b>Raio (pol)<b>",
      color: "black",
    },

    yaxis: {
      range: zLimits,
      showgrid: false,
      title: "<b>Prof. Medida (m)<b>",
      color: "black",
    },

    legend: {
      x: 0.3,
      y: -0.4,
      font: {
        size: 14,
      },
    },

    shapes: partialSimChartData[1],
  };

  const layout_boundary_conditions = {
    width: chartSize ? chartSize.width : 650,
    height: 600,
    showlegend: false,
    plot_bgcolor: "#ffffff",
    title: "<b>Condições de Contono</b>",
    font: {
      family: "Arial",
      size: 16,
      color: "black",
    },
    xaxis: {
      title: "<b>Tempo (s)</b>",
      range: [0, xLimitsBoundaryConditons],
      zeroline: false,
    },
    yaxis: {
      title: "<b>Vazão de entrada (bpm)</b>",
      range: [0, yLimitsBoundaryConditons],
      zeroline: false,
      titlefont: {
        color: "#0000AA",
      },
    },
    yaxis2: {
      title: "<b>Pressão na Saída (psi)</b>",
      range: [0, yLimitsBoundaryConditons],
      overlaying: "y",
      side: "right",
      titlefont: {
        color: "#FF0000",
      },
    },
  };

  const layout_column_movement = {
    width: 500,
    height: 600,
    plot_bgcolor: "#ffffff",
    title: "<b>Movimentação de Coluna</b>",
    font: {
      family: "Arial",
      size: 16,
      color: "black",
    },
    xaxis: {
      title: "<b>Tempo (s)</b>",
      range: [0, xLimits],
      zeroline: false,
    },
    yaxis: {
      title: "<b>Rotação (RPM)</b>",
      range: [0, yLimits],
      zeroline: false,
    },
    legend: { x: 0.15, y: -0.4 },
  };

  // Data and Layout to draw Spatial Discretization
  const dataSpatialDiscretization = [
    {
      x: drawSpatialDiscretization(
        simulationBody ? simulationBody : json["simulation"],json["scenery"]["simulation_mode"]["value"]
      ).x,
      y: drawSpatialDiscretization(
        simulationBody ? simulationBody : json["simulation"], json["scenery"]["simulation_mode"]["value"]
      ).y,
      mode: "markers",
      marker: { color: "#0000AA", size: 3 },
    },
  ];

  const layoutSpatialDiscretization = {
    width: chartSize ? chartSize.width: 500,
    height: 500,
    plot_bgcolor: "#ffffff",
    title: "<b>Discretização Espacial</b>",
    font: {
      family: "Arial",
      size: 16,
      color: "black",
    },
    xaxis: {
      zeroline: true,
      scaleanchor: "y",
      scaleratio: 1,
      showgrid: false,
      showline: false,
      tickvals: [0],
      ticktext: ["270°"],
    },
    yaxis: {
      zeroline: true,
      scaleanchor: "x",
      scaleratio: 1,
      showgrid: false,
      showline: false,
      tickvals: [0],
      ticktext: ["90°"],
    },
  };

  // Data and Layout to draw Time Pass
  const Values = drawTimePass(
    simulationBody ? simulationBody : json["simulation"]
  );
  const XValues = Values[0];
  const YValues = Values[1];

  const dataTimePass = [
    {
      x: XValues,
      y: YValues,
      mode: "markers",
      marker: { color: "#0000AA" },
    },
  ];

  const layoutTimePass = {
    width: chartSize ? chartSize.width: 600,
    height: 300,
    plot_bgcolor: "#ffffff",
    title: "<b>Discretização Temporal</b>",
    font: {
      family: "Arial",
      size: 16,
      color: "black",
    },
    xaxis: {
      title: "<b>Tempo de Simulação (min)</b>",
      zeroline: false,
    },
    yaxis: {
      title: "<b>Velocidades</b>",
      showticklabels: false,
      zeroline: false,
    },
  };

  return (
    <div className="plotly__container">
      <Route exact path="/case/:id/simulation/partial-simulation">

        {partialSimChartData[0] && layoutPumpSequenceWithDepth ? (
          <div className="plotly__chart">
            <PlotlyChart
              data={partialSimChartData[0]}
              layout={layoutPumpSequenceWithDepth}
            />
          </div>
        ) : (
          <DropletLoadingSpinner spinnerPosition={"40%"} />
        )}
      </Route>
      <Route exact path="/case/:id/simulation/boundary-conditions">
        {dataBoundaryConditions && layout_boundary_conditions ? (
          <div className="plotly__chart">
            <PlotlyChart
              data={dataBoundaryConditions}
              layout={layout_boundary_conditions}
            />
          </div>
        ) : (
          <DropletLoadingSpinner spinnerPosition={"40%"} />
        )}
      </Route>
      <Route exact path="/case/:id/simulation/column-movement">
        {dataColumnMovement && layout_column_movement ? (
          <div className="plotly__chart">
            <PlotlyChart
              data={dataColumnMovement}
              layout={layout_column_movement}
            />
          </div>
        ) : (
          <DropletLoadingSpinner spinnerPosition={"40%"} />
        )}
      </Route>
      <Route exact path="/case/:id/simulation/advanced-params">
        {dataSpatialDiscretization && layoutSpatialDiscretization && dataTimePass && layoutTimePass ? (
          <div className="plotly__chart">
{/*             {num_radial_nodes < 4 || num_radial_nodes > 16 || num_azimuthal_nodes < 21 || num_azimuthal_nodes > 180 ? (
              " "
            ) : ( */}
              <PlotlyChart
                data={dataSpatialDiscretization}
                layout={layoutSpatialDiscretization}
              />
{/*             )} */}
{/*               <PlotlyChart data={dataTimePass} layout={layoutTimePass} /> */}
          </div>
          ) : (
            <DropletLoadingSpinner spinnerPosition={"40%"} />
          )
        }
      </Route>
    </div>
  );
}

function SimulationAccordionChild(props) {
  const history = useHistory();

  // Simulation - Json
  const simulation = props.casePropsById.simulation;

  // Defining height for Grid Fab Container
  const gridContainerHeight = "55vh";

  const tags = [
    "inlet_flowrate_profile",
    "outlet_pressure_profile",
    "rotation_profile",
    "reciprocation_profile",
  ];
  const tagsItems = [
    "inlet_flowrate_profile_element_item",
    "outlet_pressure_profile_element_item",
    "rotation_profile_element_item",
    "reciprocation_profile_element_item",
  ];

  
  // Defining constants - simulation properties
  const reciprocation =
  simulation.boundary_conditions.column_movement.column_reciprocation
  .reciprocation_profile;
  const rotation =
  simulation.boundary_conditions.column_movement.column_rotation
  .rotation_profile;
  const inletFlow =
  simulation.boundary_conditions.inlet_flowrate.inlet_flowrate_profile;
  const outletPressure =
  simulation.boundary_conditions.outlet_pressure.outlet_pressure_profile;
  
  // Defining constants - simulation types
  const reciprocation_type =
  simulation.boundary_conditions.column_movement.column_reciprocation
  .use_reciprocation;
  const rotation_type =
  simulation.boundary_conditions.column_movement.column_rotation.use_rotation;

  /* States to control checkbox - Rotation and Reciprocation */
  const [isCheckedRotation, setIsCheckedRotation] = useState(rotation_type);
  const [isCheckedReciprocation, setIsCheckedReciprocation] = useState(reciprocation_type);
  
  /* States to control Reciprocation Column Grid with FAB */
  const [rowsReciprocation, setRowsReciprocation] = useState(
    createRows(reciprocation, headerReciprocationColumn)
  );
  
  /* States to control Rotation Column Grid with FAB */
  const [rowsRotation, setRowsRotation] = useState(
    createRows(rotation, headerRotationColumn)
  );
  
  /* States to control Outlet Flowrate Grid with FAB */
  const [rowsFlow, setRowsFlow] = useState(
    createRows(inletFlow, headerEntranceFlow)
  );

  /* States to control  Inlet Flowrate Grid with FAB */
  const [rowsPressure, setRowsPressure] = useState(
    createRows(outletPressure, headerOutPressure)
  );

  /* State to populate Time Step Combo */
  const [timeStepCombo, setTimeStepCombo] = useState();

 // State to control Tabs Component
 const [activeSimulationTab, setActiveSimulationTab] = useState("");


  /* State to set History Location Pathname and possible changes at onChange function */
  const { setHasChange } = useContext(HistoryContext);

  // State to Update Simulation Partial and Advanced parameters - simulation
  const {
    simulationBody,
    setSimulationBody,
    tag,
    setChangedSimulationCheck
  } = useContext(UpdatedJsonContext);

  // Define useEffect policy
  useEffect(() => {
    if (simulation) {
      setSimulationBody(simulation);
      getTimeStepProfileCombo().then((res) => {
        if (res) {
          setTimeStepCombo(res);
        }
      });
    }
    if (history.location.pathname.endsWith("/boundary-conditions")) {
      setActiveSimulationTab("Vazão de entrada");
    }
    if (history.location.pathname.endsWith("/column-movement")) {
      setActiveSimulationTab("Rotação de Coluna");
      if (rotation_type === true) {
        setIsCheckedRotation(true);
      }
      if (reciprocation_type === true) {
        setIsCheckedReciprocation(true);
      }
    }
  }, []);

  // Destructuring of internal_geometry, external_geometry and scenery objects
  const { internal_geometry, external_geometry, scenery } = props.casePropsById;

  const mdInitial = simulationBody
    ? simulationBody["initial_measured_depth"]
    : simulation["initial_measured_depth"];

  const mdFinal = simulationBody
    ? simulationBody["final_measured_depth"]
    : simulation["final_measured_depth"];

  // Parameter passage to minMaxMdInitialAndFinal
  const { minMdInitial, maxMdInitial, minMdFinal, maxMdFinal } =
    minMaxMdInitialAndFinal(
      internal_geometry,
      external_geometry,
      scenery,
      mdInitial,
      mdFinal
    );
  
  // Function to get the value and name attribute of sixthInputSection - id 7 and id 8
  function getValueAndNameAttribute () {
    let object = {
   "column": [simulationBody && simulationBody["mesh_properties"]["column_num_radial_nodes"] ? 
    simulationBody["mesh_properties"]["column_num_radial_nodes"] 
    : simulation["mesh_properties"]["column_num_radial_nodes"],simulationBody ? 
    simulationBody["mesh_properties"]["column_num_azimuthal_nodes"]
    : simulation["mesh_properties"]["column_num_azimuthal_nodes"],
    "column_num_radial_nodes", 
    "column_num_azimuthal_nodes"
  ],
  
  "annular": [simulationBody && simulationBody["mesh_properties"]["num_radial_nodes"] ? 
      simulationBody["mesh_properties"]["num_radial_nodes"]
      : simulation["mesh_properties"]["num_radial_nodes"], simulationBody ? 
      simulationBody["mesh_properties"]["num_azimuthal_nodes"]
      : simulation["mesh_properties"]["num_azimuthal_nodes"], 
      "num_radial_nodes", 
      "num_azimuthal_nodes"
    ]
  }
    return object;
  }
  
  // Destructuring of attribute name and value - sixthInputSection id 7 and id 8
  const [radial_nodes, azimuthal_nodes, nameAttributeRadialNodes, nameAttributeAzimuthalNodes ] = getValueAndNameAttribute()[scenery.simulation_mode.value];

  // Error message for MD Initial and MD Final
   const errorMesssage  = {
    "initial_measured_depth":  isFinite(minMdInitial/maxMdInitial) ? `O valor deve ser um número entre ${minMdInitial} e  ${maxMdInitial}.` : "Entre com os valores corretos de geometria para calcular o valor de MD Inicial",
    "final_measured_depth": isFinite(minMdFinal/maxMdFinal) ? `O valor deve ser um número entre ${minMdFinal} e  ${maxMdFinal}.` : "Entre com os valores corretos de geometria para calcular o valor de MD Final"
  }

  // Input Sections Metadata
  const firstInputSection = [
    {
      id: 1,
      name: "initial_measured_depth",
      value: mdInitial,
      type: "number",
      placeholder: "Valor",
      errorMessage: errorMesssage["initial_measured_depth"] ,
      label: "MD Inicial [m]",
      required: true,
      min:  !isFinite(minMdInitial)? -1 :  minMdInitial,
      max:  !isFinite(maxMdInitial)? -1 :  maxMdInitial ,
    },
  ];

  const secondInputSection = [
    {
      id: 2,
      name: "final_measured_depth",
      value: mdFinal,
      type: "number",
      placeholder: "Valor",
      errorMessage: errorMesssage["final_measured_depth"],
      label: "MD Final [m]",
      required: true,
      min: !isFinite(minMdFinal)? -1 : minMdFinal,
      max: !isFinite(maxMdFinal)? -1 : maxMdFinal ,
    },
  ];

  const thirdInputSection = [
    {
      id: 3,
      name: "gravity",
      value: simulationBody
        ? simulationBody["physical_model"]["gravity"]
        : simulation["physical_model"]["gravity"],
      type: "number",
      placeholder: "Valor",
      label: "Aceleração da gravidade [m/s²]",
      errorMessage: "O valor deve ser um número maior que 0",
      /*       pattern: "[0-9]{2}",  */
      required: true,
      min: "0",
      max: "> 0",
    },
    {
      id: 4,
      name: "pressure_convergence_tolerance",
      value: simulationBody
        ? simulationBody["pressure_convergence_tolerance"]
        : simulation["pressure_convergence_tolerance"],
      type: "number",
      placeholder: "Valor",
      label: "Tolerância do loop de pressão",
      errorMessage: "O valor deve ser um número entre 1e-10 e 1e-3",
      /*       pattern: "^[A-Za-z0-9]{3,16}$",  */
      required: true,
      min: "0.0000000001",
      max: "0.001",
    },
  ];

  const fourthInputSection = [
    {
      id: 5,
      name: "timestep_profile",
      value: simulationBody
        ? simulationBody["timestep_profile"].value
        : simulation["timestep_profile"].value,
      type: "select",
      placeholder: "Perfil de Passo de Tempo",
      options: timeStepCombo,
      required: true,
    },
  ];

  const fifthInputSection = [
    {
      id: 6,
      name: "fixed_timestep",
      value: simulationBody
        ? simulationBody["fixed_timestep"]
        : simulation["fixed_timestep"],
      type: "number",
      placeholder: "Valor",
      label: "Base do Passo de Tempo [s]",
      errorMessage: "O valor deve ser um número entre 0.00001 e 1",
      /*       pattern: "^[A-Za-z0-9]{3,16}$",  */
      required: true,
      min: "0.00001",
      max: "1",
    },
  ];

  const sixthInputSection = [
    {
      id: 7,
      name: nameAttributeRadialNodes,
      value: radial_nodes,
      type: "number",
      placeholder: "Valor",
      label: "Número de nós radiais",
      errorMessage: "O valor deve ser um número maior que 0",
      /*       pattern: "^[A-Za-z0-9]{3,16}$", */
      required: true,
      min: "0",
      max: "> 0",
    },

    {
      id: 8,
      name: nameAttributeAzimuthalNodes,
      value: azimuthal_nodes,
      type: "number",
      placeholder: "Valor",
      label: "Número de nós azimutais",
      errorMessage: "O valor deve ser um número maior que zero",
      /*       pattern: "^[A-Za-z0-9]{3,16}$", */
      required: true,
      min: "0",
      max: "> 0",
    },

    {
      id: 9,
      name: "pressure_max_iterations",
      value: simulationBody
        ? simulationBody["pressure_max_iterations"]
        : simulation["pressure_max_iterations"],
      type: "number",
      placeholder: "Valor",
      label: "Número máximo de iterações",
      errorMessage: "O valor deve ser um número entre 100 e 10000",
      /*       pattern: "^[A-Za-z0-9]{3,16}$", */
      required: true,
      min: "100",
      max: "10000",
    },
  ];

  const seventhInputSection = [
    {
      id: 10,
      name: "time_between_saves",
      value: simulationBody
        ? simulationBody["save_time"]["time_between_saves"]
        : simulation["save_time"]["time_between_saves"],
      type: "number",
      placeholder: "Valor",
      label: "Tempo de salvamento [s]",
      errorMessage: "O valor deve ser um número maior que 10",
      /*       pattern: "^[A-Za-z0-9]{3,16}$", */
      required: true,
      min: "10",
      max: "> 0",
    },
  ];

  const eighthInputSection = [
    {
      id: 11,
      name: "time_between_logs",
      type: "number",
      value: simulationBody
        ? simulationBody["log_time"]["time_between_logs"]
        : simulation["log_time"]["time_between_logs"],
      placeholder: "Valor",
      label: "Tempo de log [s]",
      errorMessage: "O valor deve ser um número maior que 10",
      /*       pattern: "^[A-Za-z0-9]{3,16}$", */
      required: true,
      min: "10",
      max: "> 0",
    },
  ];

  const nineInputSection = [
    {
      id: 12,
      name: "average_cell_width",
      type: "number",
      value: simulationBody
        ? simulationBody["mesh_properties"]["average_cell_width"]
        : simulation["mesh_properties"]["average_cell_width"],
      placeholder: "Valor",
      label: "Comprimento médio da célula [m]",
      errorMessage: "O valor deve ser um número maior que 0",
      /*       pattern: "^[A-Za-z0-9]{3,16}$", */
      required: true,
      min: "0",
      max: "> 0",
    },
  ];

  function onChange(e) {
    let changedValues = {};
    if (e.target.options) {
      const selectValue =
        e.target.options[e.target.options.selectedIndex].value;
      const selectLabel =
        e.target.options[e.target.options.selectedIndex].innerText;
      changedValues = mergedObject(simulationBody, {
        [e.target.name]: { value: selectValue, label: selectLabel },
      });
    } else {
      changedValues = mergedObject(simulationBody, {
        [e.target.name]: e.target.value,
      });
    }
    setSimulationBody(mergedObject(simulationBody, changedValues));
    setHasChange(true);
  }

  function changeSimulationType(isChecked, tag) {
    let tagType = tag.replace("_profile", "");
    if (isChecked === true) {
      setSimulationBody(
        mergedObject(simulationBody, { ["use_" + tagType]: true })
      );
    }
    if (isChecked === false) {
      setSimulationBody(
        mergedObject(simulationBody, { ["use_" + tagType]: false })
      );
    }
  }

  function onClick(setIsChecked, isChecked, tag,  setChangedSimulationCheck) {
    setIsChecked(!isChecked);
    changeSimulationType(!isChecked, tag);
    setChangedSimulationCheck(true);
    
  }

  // Tabs Data
  const dataTabSequenceBoundaryCondition = [
    {
      id: "Vazão de entrada",
      description: "Vazão de entrada",
      name: "Vazão de entrada",
    },
    {
      id: "Pressão de Saída",
      description: "Pressão de Saída",
      name: "Pressão de Saída",
    },
  ];

  const dataTabSequenceColumnMovement = [
    {
      id: "Rotação de Coluna",
      description: "Rotação de Coluna",
      name: "Rotação de Coluna",
    },
    {
      id: "Reciprocação de Coluna",
      description: "Reciprocação de Coluna",
      name: "Reciprocação de Coluna",
    },
  ];
  // Function to change id Tab
  const handleClick = (id) => {
    setActiveSimulationTab(id);
  };

  return simulationBody && timeStepCombo ? (
    <>
      <div key={1}>
        <Route exact path="/case/:id/simulation/partial-simulation">
          {/* First Section Form Container */}
          <FormContainer mode="grid">
            {firstInputSection.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={input.value}
                onChange={onChange}
                className="first-inputs"
              />
            ))}
            {secondInputSection.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={input.value}
                onChange={onChange}
                className="first-inputs"
              />
            ))}
          </FormContainer>
        </Route>
      </div>
      <div key={2}>
        <Route exact path="/case/:id/simulation/boundary-conditions">
          <Tabs
            data={dataTabSequenceBoundaryCondition}
            activeTab={activeSimulationTab}
            setActiveTab={setActiveSimulationTab}
            callback={handleClick}
          >
            <div key={"Vazão de entrada"}>
              <ActionFabEditableGrid
                metaData={headerEntranceFlow}
                rows={rowsFlow}
                oldRows={createRows(inletFlow, headerEntranceFlow)}
                tags={tags[0]}
                tagsItems={tagsItems[0]}
                setRows={setRowsFlow}
                dataJson={inletFlow}
                /*               activeRowId={activeRowId}
              setActiveRowId={setActiveRowId} */
                addTableRows={addTableRows}
                deleteTableRows={deleteTableRows}
                duplicateRow={duplicateRowSimulation}
                gridContainerHeight={gridContainerHeight}
              />
            </div>
            <div key={"Pressão de Saída"}>
              <ActionFabEditableGrid
                metaData={headerOutPressure}
                oldRows={createRows(outletPressure, headerOutPressure)}
                tags={tags[1]}
                tagsItems={tagsItems[1]}
                rows={rowsPressure}
                dataJson={outletPressure}
                setRows={setRowsPressure}
                /*               activeRowId={activeRowId}
              setActiveRowId={setActiveRowId} */
                addTableRows={addTableRows}
                deleteTableRows={deleteTableRows}
                duplicateRow={duplicateRowSimulation}
                gridContainerHeight={gridContainerHeight}
              />
            </div>
          </Tabs>
        </Route>
      </div>
      <div key={3}>
        <Route exact path="/case/:id/simulation/column-movement">
          <Tabs
            data={dataTabSequenceColumnMovement}
            activeTab={activeSimulationTab}
            setActiveTab={setActiveSimulationTab}
            callback={handleClick}
          >
            <div key={"Rotação de Coluna"}>
              <div className="checkbox-simulation__container">
                <input
                  type="checkbox"
                  className="input-simulation"
                  checked={isCheckedRotation}
                  onClick={() =>
                    onClick(setIsCheckedRotation, isCheckedRotation,tag, setChangedSimulationCheck)
                  }
                />
                <p>Habilitar</p>
              </div>
              <ActionFabEditableGrid
                metaData={headerRotationColumn}
                oldRows={createRows(rotation, headerRotationColumn)}
                tags={tags[2]}
                tagsItems={tagsItems[2]}
                rows={isCheckedRotation ? rowsRotation : defaultValueRotation}
                dataJson={rotation}
                setRows={setRowsRotation}
                /*               activeRowId={activeRowId}
              setActiveRowId={setActiveRowId} */
                addTableRows={addTableRows}
                deleteTableRows={deleteTableRows}
                duplicateRow={duplicateRowSimulation}
                isChecked={isCheckedRotation}
                gridContainerHeight={gridContainerHeight}
              />
            </div>
            <div key={"Reciprocação de Coluna"}>
              <div className="checkbox-simulation__container">
                <input
                  type="checkbox"
                  className="input-simulation"
                  checked={isCheckedReciprocation}
                  onClick={() =>
                    onClick(
                      setIsCheckedReciprocation,
                      isCheckedReciprocation,
                      tag, 
                      setChangedSimulationCheck
                    )
                  }
                />
                <p>Habilitar</p>
              </div>
              <ActionFabEditableGrid
                metaData={headerReciprocationColumn}
                oldRows={createRows(reciprocation, headerReciprocationColumn)}
                tags={tags[3]}
                tagsItems={tagsItems[3]}
                rows={
                  isCheckedReciprocation
                    ? rowsReciprocation
                    : defaultValueReciprocation
                }
                dataJson={reciprocation}
                setRows={setRowsReciprocation}
                /*               activeRowId={activeRowId}
              setActiveRowId={setActiveRowId} */
                addTableRows={addTableRows}
                deleteTableRows={deleteTableRows}
                duplicateRow={duplicateRowSimulation}
                isChecked={isCheckedReciprocation}
                gridContainerHeight={gridContainerHeight}
              />
            </div>
          </Tabs>
        </Route>
      </div>
      <div key={4}>
        <Route exact path="/case/:id/simulation/advanced-params">
          <div className="scroll-form">
            <FormContainer>
              <p className="paragraph-simulation-advanced-form">
                Os parâmetros abaixo possuem valores pré-definidos. Sua
                modificação pode ocasionar resultados não otimizados na
                simulação
              </p>
              <form>
                {/* First Section Form Container */}
                <FormContainer>
                  <FormContainer mode="grid">
                    {thirdInputSection.map((input) => (
                      <FormInput
                        key={input.id}
                        {...input}
                        value={input.value}
                        onChange={onChange}
                        className="first-inputs"
                      />
                    ))}
                  </FormContainer>
                 {/*  <FormContainer mode="space-between">
                    {fourthInputSection.map((input) => (
                      <FormInput
                        key={input.id}
                        {...input}
                        value={input.value}
                        onChange={onChange}
                        className="first-inputs"
                      />
                    ))}

                    {simulationBody["timestep_profile"].value === "fixed"
                      ? fifthInputSection.map((input) => (
                          <FormInput
                            key={input.id}
                            {...input}
                            value={input.value}
                            onChange={onChange}
                            className="first-inputs"
                          />
                        ))
                      : ""}
                  </FormContainer> */}
                  <FormContainer>
                    {sixthInputSection.map((input) => (
                      <FormInput
                        key={input.id}
                        {...input}
                        value={input.value}
                        onChange={onChange}
                        className="first-inputs"
                      />
                    ))}
                  </FormContainer>
                  <FormContainer>
                    {seventhInputSection.map((input) => (
                      <FormInput
                        key={input.id}
                        {...input}
                        value={input.value}
                        onChange={onChange}
                        className="first-inputs"
                      />
                    ))}
                  </FormContainer>
                  <FormContainer>
                    {eighthInputSection.map((input) => (
                      <FormInput
                        key={input.id}
                        {...input}
                        value={input.value}
                        onChange={onChange}
                        className="first-inputs"
                      />
                    ))}
                  </FormContainer>
                  <FormContainer>
                    {nineInputSection.map((input) => (
                      <FormInput
                        key={input.id}
                        {...input}
                        value={input.value}
                        onChange={onChange}
                        className="first-inputs"
                      />
                    ))}
                  </FormContainer>
                </FormContainer>
              </form>
            </FormContainer>
          </div>
        </Route>
      </div>
    </>
  ) : (
    <DropletLoadingSpinner />
  );
}

export { SimulationGraphs, SimulationAccordionChild };