// Generic Card = Card with a Title receiving Children Components (CardCases and CardProjects)
// # Main Import
import React from "react";
import { LoadCanvasTemplate } from "react-simple-captcha";

// # Import Component Style
import "./Captcha.css";

function Captcha(props) {
  const doSubmit = props.doSubmit;

  return (
    <div>
      <div className="container">
        <div className="form-group">
          <div className="col mt-3">
            <LoadCanvasTemplate />
          </div>
          <div className="col mt-3">
            <div>
              <input
                className="login-form"
                placeholder="Digite o captcha da imagem"
                id="user_captcha_input"
                name="user_captcha_input"
                type="text"
              ></input>
            </div>
          </div>
          <div className="col mt-3">
            <div>
              <button className="bttn-style-login" onClick={() => doSubmit()}>
                <p className="bttn-text-login">Entrar</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Captcha;
