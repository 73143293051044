// # Main Imports
import { useHistory } from "react-router";
import { useContext, useState, useEffect } from "react";

// Local components import
import SideBar from "../components/common/sideBar";
import { CurrentLevel, TreeComponent } from "../components/common";

//Requests
import { getCaseAndCasePropsByIdRequest } from "../services/CasesRequests";
import { createAuthToken } from "../services/AuthTokenRequests";

// Pages for SmallFrame
import {
  FormNewProject,
  FormNewCase,
  FormEditProject,
} from "../components/forms";
import AccordionSmallFrame from "./AccordionSmallFrame";

// Style import
import "./style/Home.css";

// # Context
import { ProjectAndCasesContext } from "../components/contexts/ProjectAndCasesContext";
import { SearchContext } from "../components/contexts/SearchContext";
import { FormsContext } from "../components/contexts/FormsContext";
import { HistoryContext } from "../components/contexts/HistoryContext";
import { ModalContext } from "../components/contexts/ModalContext";
import { AccordionSmallFrameContext } from "../components/contexts/AccordionSmallFrameContext";
import { HandleErrorContext } from "../components/contexts/HandleErrorContext";

function Home() {
  const history = useHistory();

  const query = new URLSearchParams(history.location.search);
  
  const code = query.get("code");

  const { projectById, setProjectById } = useContext(ProjectAndCasesContext);

  const { treeData, updateTree, wordValue } = useContext(SearchContext);

  const { errorInfo, errorScenery } = useContext(FormsContext);

  const [valuesForEditProject, setValuesForEditProject] = useState({});

  const [isCaseById, setIsCaseById] = useState([]);

  /* State to set History Location Pathname and possible changes at onChange function */
  const { setHistoryLocation, hasChange, setHasChange, setOpenKey } =
    useContext(HistoryContext);

  // Handle Error Context
  const { setError } = useContext(HandleErrorContext);

  /* State to control Modal */
  const { setIsModalOpen } = useContext(ModalContext);

  // ActiveName at AccordionSmallFrame
  const { setActiveName } = useContext(AccordionSmallFrameContext);

  // Data for Tree Component
  const dados = {
    name: "UOs",
    icon: "",
    info: wordValue.length > 2 ? updateTree() : treeData,
  };

  let historySplit = history.location.pathname.split("/");

  // Well Name or Case ID,
  let historyValue = historySplit[2];

  // Query params
  const queryParams = history.location.search;

  // Define useEffect policy
  useEffect(() => {
    if (history.location.pathname.startsWith("/case")) {
      getCaseAndCasePropsByIdRequest(historyValue, setError).then((res) => {
        if (res) {
          setIsCaseById(res);
        }
      });
    }
 // Logic for create I3D and Poço Web Token 
    if (history.location.pathname.startsWith("/home")) {
      if (code) {
        createAuthToken(code).then((res) => {
          if (res) {
            history.push("/home");
          }
        });
      }
    }
  }, [history.location.pathname]);

  // Params to implements at "/well/nomedopoço/projects",
  const paramsForProjects = {
    text: `Poço: ${historyValue}`,
    route: "/home",
  };
  // Params to implements at "/project/idDoProjeto/cases"
  const paramsForCases = {
    text: projectById ? `Projeto: ${projectById.name}` : "",
    route: projectById ? `/well/${projectById.well_id}/projects` : "",
  };

  // Params to implements at "/case/id/caseProps"
  const paramsForCaseProps = {
    text: isCaseById.case ? `Caso: ${isCaseById.case.name} ` : "",
    route: isCaseById.case ? `/project/${isCaseById.case.project}/cases` : "",
  };

  // Data for placeHolder - SearchBox
  const placeholderSearchBox = {
    tree: "Buscar campos",
    projects: "Buscar projetos",
    cases: "Buscar casos",
    caseProps: "Buscar propriedades do caso ",
  };

  //Simulation Request
  const [dataSimulation, setDataSimulation] = useState([]);

  /*Accordion Data - SmallFrame*/
  const dataStructure = [
    {
      title: "Entrada de dados",
      id: "1",
      inputData: [
        {
          name: "Informações gerais",
          route: `/case/${historyValue}/general_info${queryParams}`,
          error: errorInfo,
        },
        {
          name: "Cenário",
          route: `/case/${historyValue}/scenery${queryParams}`,
          error: errorScenery,
        },
        {
          name: "Geometria",
          route: `/case/${historyValue}/geometry/external-geometry${queryParams}`,
        },
        {
          name: "Fluidos",
          route: `/case/${historyValue}/pumpsequence/fluids${queryParams}`,
        },
        {
          name: "Perfil Térmico",
          route: `/case/${historyValue}/thermal_profile/surface-temperature${queryParams}`,
        },
        {
          name: "Simulação",
          route: `/case/${historyValue}/simulation/partial-simulation${queryParams}`,
        },
      ],
    },
    {
      title: "Resultados",
      id: "2",
      inputDataResults: dataSimulation
        ? [
            {
              name: `Simulação em andamento...  ${dataSimulation.execution_percentage}`,
            },
          ]
        : [{ name: "Nenhuma simulação iniciada" }],
    },
  ];

  // Defining max-heigth of Accordion child. It changes considering the Page
  const maxHeight = "30vh";

  /*Function responsible for change page after click on accordion item and control AlertPageChanges modal*/
  function handleToggle(url, id) {
    //Ballon Notification
    /*     if (id === "Informações gerais") {
          let emptyValues = Object.values(valuesScenery).filter(
            (v) => v === null || v === "" || v === []
          );
          if (emptyValues.length > 0) {
            setErrorScenery(emptyValues.length);
            setErrorInfo(0);
          }
        } else if (id === "Cenário") {
          let emptyValues = Object.values(valuesInfo).filter(
            (v) => v === null || v === "" || v === []
          );
          if (emptyValues.length > 0) {
            setErrorInfo(emptyValues.length);
            setErrorScenery(0);
          }
        } */

    if (hasChange === true && url !== history.location.pathname) {
      setIsModalOpen(true);
      setHistoryLocation(url);
    } else {
      setActiveName(id);
      history.push(url);
    }
    // SetOpenKey - set state to control Accordion Key
    setOpenKey("1");
    setHasChange(false);
  }

  if (history.location.pathname.startsWith("/home")) {
    return (
      <div>
        <SideBar
          placeholderSearchBox={placeholderSearchBox.tree}
          history={history}
        >
          <div className="tree-component__side-bar">
            {treeData ? (
              <TreeComponent name={dados.name} data={dados.info} />
            ) : (
              " "
            )}
          </div>
        </SideBar>
      </div>
    );
  } else if (
    projectById &&
    historyValue &&
    history.location.pathname.startsWith("/well")
  ) {
    return (
      <div>
        <SideBar
          placeholderSearchBox={placeholderSearchBox.projects}
          history={history}
        >
          {/*         Small Frame for Projects By Well */}
          <CurrentLevel data={paramsForProjects} />;
          {history.location.pathname.endsWith("/new_project") ? (
            <FormNewProject />
          ) : (
            " "
          )}
        </SideBar>
      </div>
    );
  } else if (
    history.location.pathname.startsWith("/project") &&
    projectById &&
    paramsForCases
  ) {
    return (
      <div>
        <SideBar
          placeholderSearchBox={placeholderSearchBox.cases}
          history={history}
        >
          {/*         Small Frame for Case By ID Project */}
          <CurrentLevel data={paramsForCases} />;
          {history.location.pathname.endsWith("/new_case") ? (
            <FormNewCase />
          ) : (
            <FormEditProject
              projectById={projectById}
              setProjectById={setProjectById}
              valuesForEditProject={valuesForEditProject}
              setValuesForEditProject={setValuesForEditProject}
            />
          )}
        </SideBar>
      </div>
    );
  } else if (
    isCaseById &&
    historyValue &&
    history.location.pathname.startsWith("/case")
  ) {
    return (
      <div>
        <SideBar
          placeholderSearchBox={placeholderSearchBox.caseProps}
          history={history}
        >
          {/*         Small Frame for Case Props */}
          <CurrentLevel data={paramsForCaseProps} />;
          <AccordionSmallFrame
            accordionData={dataStructure}
            maxHeight={maxHeight}
            handleToggle={handleToggle}
            dataSimulation={dataSimulation}
            setDataSimulation={setDataSimulation}
            setOpenKey={setOpenKey}
            historyValue={historyValue}
          />
        </SideBar>
      </div>
    );
  } else {
    return " ";
  }
}

export default Home;
