// Defining decimal numbers by key - headers
export const decimalNumByKey = {
    "measured_depth": 2,
    "segment_length" : 2,
    "outside_diameter":3,
    "inside_diameter":3,
    "inclination": 2,
    "azimuth": 3,
    "tvd" : 2,
    "standoff":3,
    "standoff_angle":3,
    "volume":2,
    "flow_rate":3,
    "temperature":2,
    "pressure":2,
    "value":2,
    "rotation": 2,
    "reading": 2,
    "rheometry_temperature":3,
    "rheometry_pressure":3,
    "tau_y": 3,
    "k": 3,
    "n": 3,
    "plastic_viscosity": 3,
    "viscosity":3,
    "time": 2
  }
  