// FAB = Floating Action Buttons
// # Main Import
import { useContext } from "react";
import { useHistory } from "react-router";

// # Import Component Style
import "./FAB.css";
import { darkColors, lightColors } from "../MaterialColors";
import { Link } from "react-router-dom";

// Context
import { ToastContext } from "../../contexts/ToastContext";
import { UpdatedJsonContext } from "../../contexts/UpdatedJsonContext";

// # Link SubComponent for FAB Construction
// TODO: Rename to Action Button
function Action(props) {
  // Update Rows and Primary Tags
  const setChangedGrid = props.setChangedGrid;

  // Toast Context
  const { toastList, setToastList, showToast } = useContext(ToastContext);

  // UpdatedJson and Tags
  const { setChangedValues, setUpdatedJson,activeRheoData, setUpdatedRheologicData } = useContext(UpdatedJsonContext);

  const history = useHistory();

  // Query Params
  const queryParams = history.location.search;

  // Variable to control the hiding of FAB actions
  const hideActionGroup = props.hideActionGroup;

  switch (props.tooltip) {
    case "Editar":
    return (
      <Link to={props.href + `/edit/${props.activeRowId}${queryParams}`}>
        <button
          className={"fab-action-button"}
          style={props.styles || defaultItemStyles}
          onClick={props.setIsModalOpen ? () => props.setIsModalOpen(true) : ""}
          type="button"
        >
          <p className="tooltip">{props.tooltip}</p>
          <i className={props.icon} style={props.iconStyles}></i>
          {props.children}
        </button>
      </Link>
    );
    case "Deletar":
    return (
      <li>
        <button
          className={"fab-action-button"}
          style={props.styles || defaultItemStyles}
          onClick={
            props.deleteTableRows
              ? () =>
                  props.deleteTableRows(
                    props.rows,
                    props.setRows,
                    setChangedGrid,
                    props.setSelectAllChecked,
                    // Parameters necessary to update fluid and rheologic properties grids
                    props.caseID,
                    props.rowEid,
                    props.tags,
                    showToast,
                    toastList,
                    setToastList,
                    setChangedValues,
                    activeRheoData,
                    setUpdatedRheologicData,
                    setUpdatedJson
                  )
              : ""
          }
          type="button"
        >
          <p className="tooltip">{props.tooltip}</p>
          <i className={props.icon} style={props.iconStyles}></i>
          {props.children}
        </button>
      </li>
    );
    case "Duplicar":
    return (
      <li>
        <button
          className={"fab-action-button"}
          style={props.styles || defaultItemStyles}
          onClick={
            props.duplicateRow
              ? () =>
                  props.duplicateRow(
                    props.rows,
                    props.setRows,
                    setChangedGrid,
                    // Parameters necessary to update fluid and rheologic properties grids
                    activeRheoData,
                    props.caseID,
                    props.rowEid,
                    showToast,
                    toastList,
                    setToastList,
                    setChangedValues,
                    setUpdatedRheologicData,
                    setUpdatedJson
                  )
              : ""
          }
          type="button"
        >
          <p className="tooltip">{props.tooltip}</p>
          <i className={props.icon} style={props.iconStyles}></i>
          {props.children}
        </button>
      </li>
    );
    case "Adicionar nova linha":
    return (
      <li>
        <button
          className={"fab-action-button"}
          style={props.styles || defaultItemStyles}
          onClick={
            props.callback
              ? () => props.callback(props.rows, props.setRows, setChangedGrid, props.metaData, activeRheoData)
              : ""
          }
          type="button"
        >
          <p className="tooltip">{props.tooltip}</p>
          <i className={props.icon} style={props.iconStyles}></i>
          {props.children}
        </button>
      </li>
    );
    case "Exibir gráfico pequeno":
    return (
      <li>
        {
          (hideActionGroup === 'None') &&
          <button
          className={"fab-action-button"}
          style={props.styles || defaultItemStyles}
          onClick={
            props.callback
              ? () => props.callback()
              : ""
          }
          type="button"
        >
          <p className="tooltip">{props.tooltip}</p>
          <i className={props.icon} style={props.iconStyles}></i>
          {props.children}
        </button>
        }
      </li>
    );
    case "Exibir gráfico médio":
    return (
      <li>
         {(hideActionGroup === 'None') &&
          <button
          className={"fab-action-button"}
          style={props.styles || defaultItemStyles}
          onClick={
            props.callback
              ? () => props.callback()
              : ""
          }
          type="button"
        >
          <p className="tooltip">{props.tooltip}</p>
          <i className={props.icon} style={props.iconStyles}></i>
          {props.children}
        </button>
        }
      </li>
    );
    case "Exibir gráfico grande":
    return (
      <li>
         {
          (hideActionGroup === 'None') &&
          <button
          className={"fab-action-button"}
          style={props.styles || defaultItemStyles}
          onClick={
            props.callback
              ? () => props.callback()
              : ""
          }
          type="button"
        >
          <p className="tooltip">{props.tooltip}</p>
          <i className={props.icon} style={props.iconStyles}></i>
          {props.children}
        </button>
        }
      </li>
    );
    default:
    return (
      <Link to={props.href}>
        <button
          className={"fab-action-button"}
          style={props.styles || defaultItemStyles}
          type="button"
        >
          <p className="tooltip">{props.tooltip}</p>
          <i className={props.icon} style={props.iconStyles}></i>
          {props.children}
        </button>
      </Link>
    );
  }
}

// # ActionButtom SubComponent for FAB Construction
// TODO: Rename to Main Button
function ActionButton(props) {
  
  // Variable to store the css adjusted of AB
  const cssAdjustedFab = props.cssAdjustedFab;

  return (
    <div>
      <button
        className={`fab-main-button ${cssAdjustedFab}`}
        tooltip={props.tooltip}
        style={props.styles || defaultItemStyles}
      >
        {/* <p className="tooltip">{props.tooltip}</p> */}
        <i className={props.icon} style={props.iconStyles}></i>
        {props.children}
      </button>
    </div>
  );
}

// # FAB Component Construction
function FAB(props) {
  const data = props.data;
  const activeRowId = props.activeRowId;

  // Data to populate the Rows
  const rows = props.rows;
  const setRows = props.setRows;
  const setChangedGrid = props.setChangedGrid;

  // Data to populate the Header of Grid
  const metaData = props.metaData;

  // Case ID number
  const caseID = props.caseID;

  // Row Eid number
  const rowEid = props.rowEid;

  // Props for duplicate/delete Fluids and Rheologic Data grids
  const tags = props.tags

  // Variable to store the css adjusted of AB
  const cssAdjustedFab = props.cssAdjustedFab;

  // SetSelectedAllChecked state to control checkbox after delete all rows from grid
  const setSelectAllChecked = props.setSelectAllChecked;

  // Variable to control the hiding of FAB actions
  const hideActionGroup = props.hideActionGroup;

  // Tag Rendering
  return (
    /* Changing the class of the container, it changes the opening direction of the button */
    <div className={data.actionButtom.direction} style={props.styles}>
      {/* CREATE MANUALY */}
      {/* {props.children}   */}
      {/* //Loop over Actions */}
      {/* CREATE AUTOMATICALY FROM DATA*/}
      {/* //Loop through Actions Array */}

      {/* //Main Action Buttom */}
      <ActionButton
        tooltip={data.actionButtom.tooltip}
        icon={data.actionButtom.icon}
        key={data.actionButtom.key}
        rotate={data.actionButtom.rotate}
        styles={{
          backgroundColor: data.actionButtom.backgroundColor,
          color: data.actionButtom.color,
        }}
        cssAdjustedFab = {cssAdjustedFab}
      />
      <ul className="fab-options">
        {data.actions
          ? data.actions.map((action) => (
              // Minor Action Buttons
              <Action
                metaData={metaData}
                tooltip={action.tooltip}
                icon={action.icon}
                key={action.key}
                href={action.url}
                rows={rows}
                setRows={setRows}
                setChangedGrid={setChangedGrid}
                callback={action.callback}
                setIsModalOpen={action.setIsModalOpen}
                deleteTableRows={action.deleteTableRows}
                duplicateRow={action.duplicateRow}
                activeRowId={activeRowId}
                caseID={caseID}
                rowEid={rowEid}
                tags={tags}
                setSelectAllChecked={setSelectAllChecked}
                styles={{
                  backgroundColor: action.backgroundColor,
                  color: action.color,
                }}
                hideActionGroup={hideActionGroup}
              />
            ))
          : ""}
      </ul>
    </div>
  );
}

const defaultItemStyles = {
  backgroundColor: darkColors.lighterRed,
  color: darkColors.white,
  textDecoration: "none",
  border: "none",
};

export { FAB, Action, ActionButton, darkColors, lightColors };
