//Function to reset the initial limits that define a partial simulation
function resetLimitInitialPartialSimulation(
  simulation_mode,
  initialMdAnnular,
  initialMdColumn
) {
  if (simulation_mode === "annular") {
    return initialMdAnnular;
  } else if (simulation_mode === "column") {
    return initialMdColumn;
  } else {
    throw new Error("Simulation mode not available yet");
  }
}

//Function to reset the final limits that define a partial simulation
function resetLimitFinalPartialSimulation(
  simulation_mode,
  finalMdAnnular,
  finalMdColumn
) {
  if (simulation_mode === "annular") {
    return finalMdAnnular;
  } else if (simulation_mode === "column") {
    return finalMdColumn;
  } else {
    throw new Error("Simulation mode not available yet");
  }
}

//Function to reset partial limits and nodes values considering simulation mode
function resetPartialLimitsAndNodesSimulation(
  simulation,
  mergedObject,
  initialMdAnnular,
  initialMdColumn,
  finalMdAnnular,
  finalMdColumn
) {
  let objPartialLimitsAndNodes = {
    "annular": mergedObject(simulation, {
      "initial_measured_depth": initialMdAnnular,
      "final_measured_depth": finalMdAnnular,
      "mesh_properties": {
        "average_cell_width": 10,
        "num_azimuthal_nodes": 42,
        "num_radial_nodes": 8,
      },
    }),
    "column": mergedObject(simulation, {
      "initial_measured_depth": initialMdColumn,
      "final_measured_depth": finalMdColumn,
      "mesh_properties": {
        "average_cell_width": 10,
        "column_num_azimuthal_nodes": 21,
        "column_num_radial_nodes": 8,
      },
    }),
  };

  return objPartialLimitsAndNodes;
}

export { resetLimitInitialPartialSimulation, resetLimitFinalPartialSimulation, resetPartialLimitsAndNodesSimulation };
