
  // Main import of React
import React, { createContext, useState } from 'react';


const VariableRheologyContext = createContext();

function VariableRheologyProvider({ children }) {
  const [rowsRheometry, setRowsRheometry] = useState();

  const [rowsRheometryReadings, setRowsRheometryReadings] = useState();

  const [rheoReadingsError, setRheoReadingsError] = useState();


        

  return (
    <VariableRheologyContext.Provider value={{ rowsRheometry, setRowsRheometry, rowsRheometryReadings, setRowsRheometryReadings, rheoReadingsError, setRheoReadingsError}}>
      {children}
    </VariableRheologyContext.Provider>
  );
}

export { VariableRheologyContext, VariableRheologyProvider };