// Local components import
import {
  PageAndContainerTitle,
  TextAndBackgroundImage,
} from "../components/common";
import background from "../images/background.jpg";

// Style import
import "./style/Welcome.css";

function Welcome() {
  return (
    <div className="welcome-container">
      <PageAndContainerTitle text="Bem-vindo!" level="1" />
      <TextAndBackgroundImage
        text="Selecione um poço na árvore ao lado para iniciar."
        image={background}
      />
    </div>
  );
}

export default Welcome;
