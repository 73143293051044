// Import axios from specific API definition
import axios from "./apis/i3d_sqlAPI";

// Import Authorization Header
import { authorizationHeader } from "./LoginServices";

//GET Method Request for Fluid By ID
async function getFluidsIdRequest(id) {
  try {
    const response = await axios.get(`/${id}/fluid`, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data.data.fluids;
  } catch (error) {
    console.log(error.message);
  }
}

//PUT Method Request for Fluid By ID
async function updateFluidsIdRequest(id, data) {
  try {
    const response = await axios.put(
      `/case/${id}/fluid/`,
      JSON.stringify(data),
      {
        headers: {
          Authorization: authorizationHeader,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}

//POST Method Request for Rheologic Data
async function createRheologicData(id, eid) {
  try {
    const response = await axios.post(
      `/case/${id}/fluid/`, null,
      {
        headers: {
          Authorization: authorizationHeader,
        },
        params: {
          eid: eid[0].eid,
          in_tag: "rheologic_data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}

//DUPLICATE Method Request for Fluid By ID
async function duplicateFluidsIdRequest(id, eid) {
  try {
    const response = await axios.post(
      `/case/${id}/fluid/`, null,
      {
        headers: {
          Authorization: authorizationHeader,
        },
        params: {
          eid: eid[0].eid,
          in_tag: "fluids",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}


//GET Method Request for Combo Fluids By Case ID
async function getComboFluidsByCaseIdRequest(id, setError) {
  try {
    const response = await axios.get(`/case/${id}/fluid/combo/`, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data.data;
  } catch (error) {
    console.log(error.message);
    setError(error.response.status);
  }
}



export {
  getFluidsIdRequest,
  getComboFluidsByCaseIdRequest,
  updateFluidsIdRequest,
  createRheologicData,
  duplicateFluidsIdRequest
};
