function addLocally(selectedRows, allRows, header, activeEid) {
  let newData = allRows.length > 0? allRows : [];
  let headerColumns = header.header.map((item) => item.key);
  const columns = allRows.length > 0? Object.keys(allRows[0]) : headerColumns;

  if (selectedRows.length === 0 && allRows.length !== 0) {
    // Initialize add with id and selected columns filled
    let newRow = {
      id: allRows.length + 1,
      eid: null,
      activeEid: activeEid ? activeEid.eid : null,
      selected: false,
      editable: true,
    };

    // Loop over other columns
    columns.map((column) => {
      if (
        !(
          column == "id" ||
          column == "selected" ||
          column == "eid" ||
          column == "editable" ||
          column == "activeEid" 
        )
      ) {
        newRow[column] = "";
      }
    });
    let insertRowPosition = allRows.length - 1;

    newData[insertRowPosition].id = insertRowPosition + 1;

    let newRows = [...newData, newRow];
    newData = newRows.sort((a, b) => a.id - b.id);
  } else if (allRows.length === 0) {
    let newRow = {
      id: 1,
      eid: null,
      activeEid: activeEid ? activeEid.eid : null,
      selected: false,
      editable: true,
    };

    // Loop over other columns
    headerColumns.map((column) => {
      if (
        !(
          column == "id" ||
          column == "selected" ||
          column == "eid" ||
          column == "editable"||
          column == "activeEid" 
        )
      ) {
        newRow[column] = "";
      }
    });
    newData = [newRow];
  } else {
    for (
      let selectedRow = selectedRows.length - 1;
      selectedRow >= 0;
      selectedRow -= 1
    ) {
      // Initialize add with id and selected columns filled
      let newRow = {
        id: selectedRows[selectedRow].id + 1,
        eid: null,
        activeEid: activeEid ? activeEid.eid : null,
        editable: true,
        selected: false,
      };

      // Loop over other columns
      columns.map((column) => {
        if (
          !(
            column == "id" ||
            column == "selected" ||
            column == "eid" ||
            column == "editable" ||
            column == "activeEid" 
          )
        ) {
          newRow[column] = "";
        }
      });
      let selectedRowPosition = selectedRows[selectedRow].id - 1;
      for (let row = selectedRowPosition + 1; row < newData.length; row += 1) {
        newData[row].id = newData[row].id + 1;
      }

      let newRows = [...newData, newRow];
      newData = newRows.sort((a, b) => a.id - b.id);
    }
  }

  return newData;
}

export function addTableRows(rows, setRows, setChangedGrid, header, activeEid = null) {
  const selectedRows = rows.filter((item) => item.selected === true);
  const newData = addLocally(selectedRows, rows, header, activeEid);
  setChangedGrid(true);
  setRows(newData);
}

/* Pump Sequence - Density */
export function addTableRowsDensity(
  rowsDensity,
  setRowsDensity,
  setChangedGrid,
) {
  const defaultRowsDensityValues = setDefaultRowsDensityValue();
  const rowsInput = {
    id: rowsDensity.length + 1,
    ...defaultRowsDensityValues,
    eid: null,
    editable: true,
    selected: false,
  };
  setChangedGrid(true);
  setRowsDensity([...rowsDensity, rowsInput]);
}

/* Pump Sequence - Rheometry */
export function addTableRowsRheometry(rowsRheometry, setRowsRheometry, setChangedGrid) {
  const rowsInput = {
    id: rowsRheometry.length + 1,
    rotation: " ",
    reading: " ",
    eid: null,
    editable: true,
    selected: false,
  };
  setChangedGrid(true);
  setRowsRheometry([...rowsRheometry, rowsInput]);
}

// Function to set default values of temperature and ​pressure ​based on density_dependence
function setDefaultRowsDensityValue() {
  const defaultTemperatureValue = "25";
  const defaultPressureValue = "14.69";

  return {
    value: "11.3",
    temperature: defaultTemperatureValue,
    pressure: defaultPressureValue,
  };
}
