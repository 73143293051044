// Import axios from specific API definition
import axios from "./apis/i3d_sqlAPI";

// Import Authorization Header
import { authorizationHeader } from "./LoginServices";

// GET Method Request for Cases
async function getAllCases() {
  try {
    const response = await axios.get(`cases/`, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data.data;
  } catch (error) {
    console.log(error.message);
  }
}

// GET Method Request for Cases By Project ID
async function getCasesByProjectRequest(id, setError) {
  try {
    const response = await axios.get(`cases/project/${id}/`, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data.data ? response.data.data : response.data.message;
  } catch (error) {
    console.log(error);
    setError(error.response.status);
  }
}

// POST Method Request for Case By ID Project
async function createCaseByIdProjectRequest(id, data) {
  try {
    const response = await axios.post(
      `case/project/${id}/`,
      JSON.stringify(data),
      {
        headers: {
          Authorization: authorizationHeader,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}

// POST Method Request for copy Case By ID
async function copyCaseById(id) {
  try {
    const response = await axios.post(`case/copy/${id}/`, null, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}

// GET Method Request for Case By ID
async function getCaseByIdRequest(id) {
  try {
    const response = await axios.get(`case/${id}/`, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data.data;
  } catch (error) {
    console.log(error.message);
  }
}

// PATCH Method Request for Case By ID
async function upadateCaseByIdRequest(id, data) {
  try {
    const response = await axios.patch(`case/${id}/`, JSON.stringify(data), {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}

// DELETE Method Request by ProjectID
async function deleteCaseByIdRequest(id, signal) {
  try {
    const response = await axios.delete(`case/${id}/`, {
      headers: {
        Authorization: authorizationHeader,
      },
      signal,
    });
    return response;
  } catch (error) {
    console.log("Não foi possivel deletar esse caso!");
  }
}

// GET Method Request for Case By ID - SQL and Mongo data
async function getCaseAndCasePropsByIdRequest(id, setError = null) {
  if (id !== undefined) {
    try {
      const response = await axios.get(`case/case-props/${id}/`, {
        headers: {
          Authorization: authorizationHeader,
        },
      });
      return response.data.data;
    } catch (error) {
      console.log(error.message);
      setError(error.response.status);
    }
  } else {
    return null;
  }
}

// POST Method Request for Case By ID Case
const sendPCODataByCaseIdRequest = async (id, data) => {
  try {
    const texto = data;
    const response = await axios.post(`import-pco/${id}/`, texto, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro na solicitação:", error);
    return error.response.data;
  }
};

async function updateLockCase(id, data) {
  try {
    const response = await axios.patch(
      `case/checkUserLocked/${id}/`,
      JSON.stringify(data),
      {
        headers: {
          Authorization: authorizationHeader,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}

export {
  getAllCases,
  getCasesByProjectRequest,
  createCaseByIdProjectRequest,
  getCaseByIdRequest,
  upadateCaseByIdRequest,
  deleteCaseByIdRequest,
  getCaseAndCasePropsByIdRequest,
  sendPCODataByCaseIdRequest,
  updateLockCase,
  copyCaseById,
};
