// # Main Import
import React from "react";

// #  Local SubComponents & utils
import PlotlyChart from "../plotlyChart";

// # Import Component Style
import "./conditionalPlotly.css";

export default function ConditionalPlotly(props) {
  const variableDensity = props.variableDensity;

  const dataPlotlyPressure = props.dataPlotlyPressure;
  const dataPlotlyTempeture = props.dataPlotlyTempeture;
  const dataPlotlyPressureWithTemperature = props.dataPlotlyPressureWithTemperature;

  const layoutPressure = props.layoutPressure;
  const layoutTemperature = props.layoutTemperature;
  const layoutTemperatureWithPressure = props.layoutTemperatureWithPressure;

  return (
    <div className="plotly-rheometry">
      {(() => {
        if (variableDensity === "pressure-profile") {
          return (
            <PlotlyChart data={dataPlotlyPressure} layout={layoutPressure} />
          );
        } else if (variableDensity === "temperature-profile") {
          return (
            <PlotlyChart
              data={dataPlotlyTempeture}
              layout={layoutTemperature}
            />
          );
        } else {
          return (
            <PlotlyChart
              data={dataPlotlyPressureWithTemperature}
              layout={layoutTemperatureWithPressure}
            />
          );
        }
      })()}
    </div>
  );
}
