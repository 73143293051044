// # Main import of React
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import { useQuery } from "react-query";
import { useEffect } from "react";

// Style import
import "./style/TutorialsAndNotifications.css";

// #  Local SubComponents & utils
import {
  DropletLoadingSpinner,
  PageAndContainerTitle,
  FullyEditableGrid,
} from "../components/common";

// Request
import { getNotificationsByUser } from "../services/NotificationsRequests";

// Routes for Notifications
import { notificationRoutes } from "../data/routes";

// Data to populate headers of grid - Notifications
import { headerNotifications } from "../data/headersForGrids";

export default function Notifications() {

  const [rowsNotification, setRowsNotification] = useState();
/* 
  function filterNotification(rowsNotification) {
    let newArr = [];
    
    if (rowsNotification) {
      let newRow = {object_id: rowsNotification[0].object_id,
                object : rowsNotification[0].object,
                created_at : rowsNotification[0].created_at,
                user : rowsNotification[0].user,
                description : rowsNotification[0].description}
      newArr.push(newRow)
      for (let row = 1; row < rowsNotification.length; row += 1) {
        for (let newrow = 0; newrow < newArr.length; newrow += 1) {
          if (rowsNotification[row].object === newArr[newrow].object &&
            rowsNotification[row].object_id === newArr[newrow].object_id &&
            rowsNotification[row].created_at >= newArr[newrow].created_at) {
            newArr[newrow] = rowsNotification[row]
          } else if (rowsNotification[row].object === newArr[newrow].object &&
            rowsNotification[row].object_id === newArr[newrow].object_id) { 
            }
          else {
            newArr.push(rowsNotification[row])
          }
        }
      }
    }
    
    return newArr
  } */

  // Define useEffect policy
  useEffect(() => {
    getNotificationsByUser().then((res) => {
      if (res) {
        let mappedNotifications = res
          ? res.map((item) => ({
              ...item,
              user: item.user.name,
            }))
          : [];

        setRowsNotification(mappedNotifications);
        // filterNotification(rowsNotification)
      }
    });
  }, []);



  // ActiveRowId for ActionFabGrid - CheckboxAction
  const [activeRowId, setActiveRowId] = useState("");

  /* States to control Checkbox Header */
  const [isSelectAllChecked, setSelectAllChecked] = useState(false);

  // Page Render
  return (
    <Route exact path={[notificationRoutes]}>
      {rowsNotification ? (
        <div className="notifications__container">
          <header>
            <PageAndContainerTitle text="Notificações" level="1" />
          </header>
          <main>
            <FullyEditableGrid
              metaData={headerNotifications}
              rows={rowsNotification}
              isSelectAllChecked={isSelectAllChecked}
              setSelectAllChecked={setSelectAllChecked}
              setActiveRowId={setActiveRowId}
            />
          </main>
        </div>
      ) : (
        // Show DropletLoadingSpinner
        <DropletLoadingSpinner />
      )}
    </Route>
  );
}