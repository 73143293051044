// DropletLoadingSpinner = component to be displayed when the page is loading

// # Main Import
import React from "react";

// # Import Component Style
import "./DropletLoadingSpinner.css";

function DropletLoadingSpinner(props) {

  return (
    <>
    <div className="spinner-wrapper" style={{left:props.spinnerPosition? props.spinnerPosition: "55%" }}>
      <div className="spinner">
        <div className="droplet" style={{animationDelay:"-250ms"}}></div>
		    <div className="droplet" style={{animationDelay:"0ms"}}></div>
        <div className="droplet" style={{animationDelay:"250ms"}}></div>
        <div className="droplet" style={{animationDelay:"500ms"}}></div>
        <div className="droplet" style={{animationDelay:"750ms"}}></div>
        <div className="droplet" style={{animationDelay:"1000ms"}}></div>
      </div>
    </div>

    <svg className="svg-filter">
      <defs>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" stdDeviation="6" result="blur" />
          <feColorMatrix in="blur" mode="matrix" values="
                                                        1 0 0 0 0
                                                        0 1 0 0 0
                                                        0 0 1 0 0
                                                        0 0 0 50 -16
                                                        " result="goo" />
          <feComposite in="SourceGraphic" in2="goo" operator="atop" />
        </filter>
      </defs>
    </svg>
    </>
  );
}

export default DropletLoadingSpinner;
