import React, { createContext, useState } from "react";

// Create Context Object
const ResultsContext = createContext();

// Hook
export default function useResults() {
    
    // State to store the graphs selecteds
    const [graphSelectedResults, setGraphSelectedResults] = useState([]);

    // State to control the checkbox selecteds
    const [checkboxResults, setCheckboxResults] = useState(false);

    // Function to add a graph
    const addGraphResults = (graph) => {
        setGraphSelectedResults((prevGraphs) => [...prevGraphs, graph]);
    };

    // Function to remove a graph
    const removeGraphResults = (graphId) => {
        setGraphSelectedResults((prevGraphs) => prevGraphs.filter((graph) => graph.id !== graphId));
    };

    // Function to handle the checkbox selecteds
    const handleCheckboxResults = (graphId) => {
        setCheckboxResults((prevCheckboxResults) => ({
            ...prevCheckboxResults,
            [graphId]: !prevCheckboxResults[graphId]
        }));
    }

    return { graphSelectedResults, addGraphResults, removeGraphResults, checkboxResults, handleCheckboxResults };
}

// Specific Context Provider
function ResultsProvider({ children }) {
    const { graphSelectedResults, addGraphResults, removeGraphResults, checkboxResults, handleCheckboxResults } =
        useResults();

    return (
        <ResultsContext.Provider
            value={{
                graphSelectedResults,
                addGraphResults,
                removeGraphResults,
                checkboxResults,
                handleCheckboxResults
            }}
        >
            {children}
        </ResultsContext.Provider>
    );
}

export { ResultsContext, ResultsProvider };