


export const principalTagsInfo = [
    {
      route: `general_info`,
      tags: [
        "Informações Gerais",
        "Nome do Campo",
        "Nome do Poço",
        "Nome da Sonda",
        "Nome do Projeto Relacionado",
        "Nome do Caso",
      ],
    },
  ];



  export const principalTagsScenery = [
    {
      route: `scenery`,
      tags: [
        "Cenário",
        "Ambiente",
        "Lâmina d'Água",
        "Air Gap",
        "Operação",
        "Riser",
        "OD",
        "ID",
      ],
    },
  ];


  export const principalTagsGeometria = [
    {
      route: `geometry/external-geometry`,
      tags: [
        "Geometria",
        "Gemotria Interna",
        "Coluna de Trabalho",
        "Revestimento",
        "Geometria Externa",
        "Revestimento anterior",
        "Poço Aberto",
        "Centralização",
        "Ângulo Standoff",
        "Trajetória",
      ],
    },
  ];


  export const principalTagsPumpSequence = [
    {
      route: `pumpsequence/fluids`,
      tags: [
        "Sequência de Bombeio",
        "Vazão",
        "Cadastro de Fluidos",
        "Sequência de Bombeio",
      ],
    },
  ];


  export const principalTagsThermalProfile = [
    {
      route: `thermal_profile/surface-temperature`,
      tags: [
        "Perfil Térmico",
        "Temperatura ambiente",
        "Água",
        "Superfície",
        "TVD",
        "Gradiente geotérmico",
      ],
    },
  ];


  export const principalTagsSimulation = [
    {
      route: `simulation`,
      tags: [
        "Simulação",
        "Parâmetros Básicos",
        "Parâmetros Avançados",
        "MD",
        "Vazão de Entrada",
        "Pressão de Saída",
        "Rotação de Coluna",
        "Tempo",
        "Aceleração da gravidade",
        "Loop de pressão",
        "radiais",
        "azimutais",
      ],
    },
  ];



