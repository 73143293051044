// Function responsible for treat integer numbers considering value and decimal number
export function treatInteger(value, decimalNum) {
  if (!decimalNum) {
    decimalNum = 1;
  }
  return (
    Math.round(parseFloat(value) * Math.pow(10, decimalNum)) /
    Math.pow(10, decimalNum)
  );
}
