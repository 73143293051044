// #  Main Imports
import React, { useContext } from "react";

//# Requests
import { deleteCaseByIdRequest } from "../../services/CasesRequests";

// #  Local SubComponents & utils
import {
  StyledButton,
  FormContainer,
  PageAndContainerTitle,
} from "../../components/common";
import { reload } from "../../components/utils";

// # Import Component Style
import "./deleteConfirmation.css";

// # Contexts
import { ModalContext } from "../../components/contexts/ModalContext";
import { ToastContext } from "../../components/contexts/ToastContext";

function CaseDeletionConfirmation(props) {

  const { setIsModalOpen, caseId, closeModal } = useContext(ModalContext);

  // Toast Context
  const { toastList, setToastList, showToast } = useContext(ToastContext);


  // Case Deletion function
  async function deleteCase() {
    const response = await deleteCaseByIdRequest(caseId);
    setIsModalOpen(false);
    showToast(response.data, toastList, setToastList);
    reload()
  }

  // Buttons to render using StyledButton component
  const bttns = [
    {
      type: "button",
      callback: closeModal,
      color: "#c62828",
      icon: "fas fa-times",
      text: "Cancelar",
    },
    {
      type: "submit",
      callback: deleteCase,
      color: "#1b5e20",
      icon: "fas fa-check",
      text: "Confirmar",
    },
  ];

  return (
    <div className="delete-modal__container">
      <div className="close-modal__container">
        <button onClick={closeModal} className="close-modal__bttn">
          <i className="fas fa-times" />
        </button>
      </div>
      <div class="text-modal">
        <PageAndContainerTitle
          text="Você tem certeza que deseja excluir esse Caso ?"
          level="2"
        />
      </div>
      <div className="bttn-modal__container">
        <FormContainer mode="space-between">
          {bttns.map((item) => (
            <StyledButton data={item} />
          ))}
        </FormContainer>
      </div>
    </div>
  );
}

export default CaseDeletionConfirmation;
