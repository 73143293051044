// # Main Import
import React, { useContext } from "react";

// # Contexts
import { SearchContext } from "../../contexts/SearchContext";


// # Import Component Style
import "./SearchBar.css";

function SearchBar(props) {
  const { wordValue, setWordValue } = useContext(SearchContext);

  const placeholderSearchBox = props.placeholderSearchBox;

  //  Function that cleans the Input and renews the States after the click
  const clearInput = () => {
    setWordValue("");
  };

  return (
    <div className="search-controller">
      <button className="search-btn">
        {wordValue.length === 0 ? (
          <i className="fas fa-search"></i>
        ) : (
          <i className="far fa-times-circle" onClick={clearInput}></i>
        )}
      </button>
      <div className="searchInputs">
        <input
          type="text"
          value={wordValue}
          placeholder={placeholderSearchBox}
          onChange={({ target }) => {
            setWordValue(target.value);
            /*        upgradeStates(target.value); */
          }}
        />
      </div>
    </div>
  );
}

export default SearchBar;
