// Current Level = tab with button  < + Text

// # Main Import
import React from "react";

// Local components import
import DropletLoadingSpinner from "../dropletLoadingSpinner";

// # Import Component Style
import "./currentLevel.css";

function CurrentLevel({ data }) {
  return data ? (
    <div className="current-level__container">
      <a data-testid="current-level__link" href={data.route}>
        <button className="current-level__bttn">
          <i data-testid="fas fa-chevron-left" className="fas fa-chevron-left"></i>
        </button>
      </a>
      <p data-testid="current-level__text" className="current-level__text">{data.text}</p>
    </div>
  ) : (
    <DropletLoadingSpinner />
  );
}

export default CurrentLevel;
