// Main import of React
import { useState, useEffect, useContext } from "react";
import { Route } from "react-router";

// #  Local SubComponents & utils
import {
  PlotlyChart,
  ActionFabEditableGrid,
  FormContainer,
  FormInput,
  PageAndContainerTitle,
  DropletLoadingSpinner,
} from "../components/common";
import { mergedObject, reload } from "../components/utils";

// To draw Pump Sequence
import {
  drawThermalProfile,
  getThermalProfileLimits,
  // drawThermalProfileBackground,
} from "../components/utils/drawThermalProfile";

// Data to populate headers of grids - Thermal Profile
import { headersSurfaceAndFormationTempeture } from "../data/headersForGrids";

//Functions to  create rows - Thermal Profile
import { createRows } from "../components/utils";

//Functions to add rows - Thermal Profile
import { addTableRows } from "../components/utils/functionsToAddRows";

//Functions to delete rows - Thermal Profile
import { deleteTableRows } from "../components/utils/functionsToDeleteRows";

//Functions to  duplicate rows - Thermal Profile
import { duplicateRowSurfaceTempeture } from "../components/utils/functionToDuplicateRows";

//Contexts
import { UpdatedJsonContext } from "../components/contexts/UpdatedJsonContext";
import { HistoryContext } from "../components/contexts/HistoryContext";

function ThermalProfileGraphs(props) {
  let json = props.json;

  // Updated Rows and Tags
  const { updatedJson, tag } = useContext(UpdatedJsonContext);

  const [xLimits, yLimits] = getThermalProfileLimits(
    Object.keys(updatedJson).length > 0 ? updatedJson : json["thermal_profile"],
    json
  );

  const [shapeThermalProfile, dataThermalProfile] = drawThermalProfile(
    Object.keys(updatedJson).length > 0 ? updatedJson : json["thermal_profile"],
    json
  );

  // Define useEffect policy
  useEffect(() => {
    // update json with updatedJson changes for the graphs
    json = mergedObject(json, updatedJson);
    /*     countGridLines(tag, updatedJson, json); */
    reload();
  }, [updatedJson, tag]);

  const layout_surface_tempeture = {
    width: 450,
    height: 550,
    showlegend: false,
    font: {
      family: "Arial",
      size: 16,
      color: "black",
    },

    xaxis: {
      title: "<b>Tempertura (ºC)</b>",
      range: [0, xLimits],
      zeroline: false,
    },
    yaxis: {
      title: "<b>TVD (m)</b>",
      range: [-yLimits, 0],

      zeroline: false,
    },
    shapes: shapeThermalProfile,
  };

  return (
    <div className="plotly__container">
      <Route
        path={[
          "/case/:id/thermal_profile/surface-temperature",
          "/case/:id/thermal_profile/geothermal-gradient",
        ]}
      >
        <div className="plotly__chart">
          {dataThermalProfile && layout_surface_tempeture ? (
            <PlotlyChart
              data={dataThermalProfile}
              layout={layout_surface_tempeture}
            />
          ) : (
            <DropletLoadingSpinner spinnerPosition={"40%"} />
          )}
        </div>
      </Route>
    </div>
  );
}

function ThermalProfileAccordionChild(props) {
  const tags = ["water_temperatures", "formation_temperatures"];
  const tagsItems = [
    "water_temperature_list_item",
    "formation_temperature_list_item",
  ];

  let waterTemperatures = [];
  if (
    props.casePropsById.thermal_profile.water_temperatures !== undefined &&
    props.casePropsById.thermal_profile.water_temperatures !== null &&
    props.casePropsById.thermal_profile.water_temperatures.length !== 0
  ) {
    waterTemperatures = props.casePropsById.thermal_profile.water_temperatures;
  }

  let formationTemperatures = [];
  if (
    props.casePropsById.thermal_profile.formation_temperatures !== undefined &&
    props.casePropsById.thermal_profile.formation_temperatures !== null &&
    props.casePropsById.thermal_profile.formation_temperatures.length !== 0
  ) {
    formationTemperatures =
      props.casePropsById.thermal_profile.formation_temperatures;
  }
  let ambientTemperature = props.casePropsById.thermal_profile
    .ambient_temperature.ambient_temperature
    ? props.casePropsById.thermal_profile.ambient_temperature
        .ambient_temperature
    : props.casePropsById.thermal_profile.ambient_temperature;

  /* States to control Surface Tempeture Grid with FAB */
  const [rowsTempeture, setRowsTempeture] = useState(
    createRows(waterTemperatures, headersSurfaceAndFormationTempeture)
  );

  /* States to control Formation Tempeture Grid with FAB */
  const [rowsFormationTempeture, setRowsFormationTempeture] = useState(
    createRows(formationTemperatures, headersSurfaceAndFormationTempeture)
  );

  // States to Update  AmbientTemperature - thermal profile
  const {
    valueAmbientTemperature,
    setValueAmbientTemperature,
  } = useContext(UpdatedJsonContext);

  /* State to set possible changes at onChange function */
  const { setHasChange } = useContext(HistoryContext);

  // Defining height for Grid Fab Container
  const gridContainerHeight = "60vh";
  const gridContainerHeightGeothermalGradient = "65vh";

  // Input Sections Metadata
  const firstInputSection = [
    {
      id: 1,
      name: "ambient_temperature",
      type: "number",

      label: "Temperatura ambiente (ºC)",
      errorMessage: "O valor deve ser um número entre 0 e 100",
      /*        pattern: "^[A-Za-z0-9]{3,16}$", */
      required: true,
      min: "0",
      max: "100",
    },
  ];

  const onChange = (e) => {
    setValueAmbientTemperature({
      ...setValueAmbientTemperature,
      [e.target.name]: e.target.value,
    });
    setHasChange(true);
  };

  // Define useEffect policy
  useEffect(() => {
    setValueAmbientTemperature({
      ambient_temperature: ambientTemperature,
    });
  }, []);

  return waterTemperatures && valueAmbientTemperature ? (
    <>
      <div key={1}>
        <Route exact path="/case/:id/thermal_profile/surface-temperature">
          <FormContainer mode="space-between" margin="0em 0em 2em 0em">
            {firstInputSection.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={valueAmbientTemperature[input.name]}
                onChange={onChange}
                className="first-inputs"
              />
            ))}
          </FormContainer>
          <PageAndContainerTitle text="Temperatura da água (ºC)" level="2" />
          <ActionFabEditableGrid
            metaData={headersSurfaceAndFormationTempeture}
            tagsItems={tagsItems[0]}
            tags={tags[0]}
            rows={rowsTempeture ? rowsTempeture : []}
            oldRows={createRows(
              waterTemperatures,
              headersSurfaceAndFormationTempeture
            )}
            dataJson={waterTemperatures}
            setRows={setRowsTempeture}
            addTableRows={addTableRows}
            deleteTableRows={deleteTableRows}
            duplicateRow={duplicateRowSurfaceTempeture}
            gridContainerHeight={gridContainerHeight}
          />
        </Route>
      </div>
      <div key={2}>
        <Route exact path="/case/:id/thermal_profile/geothermal-gradient">
          <ActionFabEditableGrid
            metaData={headersSurfaceAndFormationTempeture}
            tagsItems={tagsItems[1]}
            tags={tags[1]}
            rows={rowsFormationTempeture ? rowsFormationTempeture : []}
            oldRows={createRows(
              formationTemperatures,
              headersSurfaceAndFormationTempeture
            )}
            dataJson={formationTemperatures}
            setRows={setRowsFormationTempeture}
            addTableRows={addTableRows}
            deleteTableRows={deleteTableRows}
            duplicateRow={duplicateRowSurfaceTempeture}
            gridContainerHeight={gridContainerHeightGeothermalGradient}
          />
        </Route>
      </div>
    </>
  ) : (
    <DropletLoadingSpinner />
  );
}

export { ThermalProfileGraphs, ThermalProfileAccordionChild };
