// # TextAndBackgroundImage = a component that receives a background image with text 

// # Main Import
import React from "react";

// # Import Component Style
import "./textAndBackgroundImage.css";

function TextAndBackgroundImage(props) {
  const text = props.text;
  const image = props.image;

  return (
    <div className="text-background-image__container">
      <img
      className="image-background"
        src={image}
        alt="Description"
      ></img>
      <h2 data-testid="test-text-background" className="text-background">{text}</h2>
    </div>
  );
}

export default TextAndBackgroundImage;
