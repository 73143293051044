// Import axios from specific API definition
import axios from "./apis/i3d_sqlAPI";

// Import Authorization Header
import { authorizationHeader } from "./LoginServices";

// POST Method Request to calculate Centralization Chart
async function calcCentralizationChart(centralizationData) {
  try {
    const response = await axios.post(
      `/chart/centralization/`,
      JSON.stringify(centralizationData),
      {
        headers: {
          Authorization: authorizationHeader,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    console.log(error.message);
  }
}

// POST Method Request for calculating PumpSequence Chart
async function calcPumpSequenceChart(pumpingSequenceData) {
  try {
    const response = await axios.post(
      `/chart/pumpsequence/`,
      JSON.stringify(pumpingSequenceData),
      {
        headers: {
          Authorization: authorizationHeader,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    console.log(error.message);
  }
}

// POST Method Request to calculate Trajectory Chart
async function calcTrajectoryChart(trajectoryData) {
  try {
    const response = await axios.post(
      `chart/trajectory/`,
      JSON.stringify(trajectoryData),
      {
        headers: {
          Authorization: authorizationHeader,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    console.log(error.message);
  }
}

// POST Method Request to calculate Rheometer Readings By Case ID
async function calcRheometryChart(rheometryData) {
  try {
    const response = await axios.post(
      `chart/rheo-readings/`,
      JSON.stringify(rheometryData),
      {
        headers: {
          Authorization: authorizationHeader,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}

export {
  calcCentralizationChart,
  calcPumpSequenceChart,
  calcRheometryChart,
  calcTrajectoryChart,
};
