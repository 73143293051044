// Import axios from specific API definition
import axios from "./apis/i3d_sqlAPI";
import Cookies from 'js-cookie';

// POST Method Request - Send code to backend for token exchange
async function createAuthToken(code) {
    try {
      const response = await axios.post(
        `exchange_code/`,
        JSON.stringify({code}),
      );
      Cookies.set('pocoweb_token', response.data['pocoweb_access'], { expires: 365, path: '/' });
      Cookies.set('pocoweb_refresh', response.data['pocoweb_refresh'], { expires: 365, path: '/' });
      return sessionStorage.setItem("Token", response.data['access']);
    } catch (error) {
      console.log(error.message);
    }
}

async function getI3dAuthToken(pocoWebToken) {
  try {
    const response = await axios.post(
      `getI3dAuthToken/`,
      JSON.stringify({pocoWebToken})
    );
    return sessionStorage.setItem("Token", response.data['access']);
  } catch (error) {
    console.log(error)
  }
}

async function validatePocoWebToken(pocoWebToken) {
    const response = await axios.post(
      `validatePocoWebToken/`,
      JSON.stringify({pocoWebToken}),
    );
    return response.data;
}

async function refreshPocoWebToken(pocoWebRefreshToken) {
  try {
    const response = await axios.post(
      `refreshPocoWebToken/`,
      JSON.stringify({pocoWebRefreshToken})
    );
    return response.data
  } catch (error) {
    console.log(error.message)
  }
}



const authorizationHeader = "Bearer " + sessionStorage.getItem("Token");

export {createAuthToken, getI3dAuthToken, validatePocoWebToken, refreshPocoWebToken, authorizationHeader}