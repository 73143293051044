function drawColumnMovement(
  simulationUpdated,
  simulationJson,
  rotationType,
  reciprocationType
) {
  let dataColumnMovement = [];
  let dataXro = [];
  let dataYro = [];

  const defaultValueRotation = [
    {
      eid: null,
      rotation_profile_element_item: {
        time: "0",
        value: "0",
      },
    },
    {
      eid: null,
      rotation_profile_element_item: {
        time: "100",
        value: "0",
      },
    },
  ];

  const defaultValueReciprocation = [
    {
      eid: null,
      reciprocation_profile_element_item: {
        time: "0",
        value: "0",
      },
    },
    {
      eid: null,
      reciprocation_profile_element_item: {
        time: "100",
        value: "0",
      },
    },
  ];

  const rotationJson =
    simulationJson.boundary_conditions.column_movement.column_rotation
      .rotation_profile;

  const reciprocationJson =
    simulationJson.boundary_conditions.column_movement.column_reciprocation
      .reciprocation_profile;

  const rotationUpdated = simulationUpdated.rotation_profile;

  const reciprocationUpdated = simulationUpdated.reciprocation_profile;

  const rotationProfile = rotationUpdated ? rotationUpdated : rotationJson;

  const reciprocationProfile = reciprocationUpdated
    ? reciprocationUpdated
    : reciprocationJson;

  const finalRotationProfile = rotationType ? rotationProfile : defaultValueRotation;

  const finalReciprocationProfile = reciprocationType ? reciprocationProfile : defaultValueReciprocation;

  if (
    rotationProfile !== undefined &&
    rotationProfile !== null &&
    rotationProfile.length !== 0
  ) {
    dataXro.push(
      ...finalRotationProfile.map((item) =>
        parseFloat(item.rotation_profile_element_item.time)
      )
    );
    dataYro.push(
      ...finalRotationProfile.map((item) =>
        parseFloat(item.rotation_profile_element_item.value)
      )
    );
  } else {
    dataXro = [0, 100];
    dataYro = [0, 100];
  }

  let dataXre = [];
  let dataYre = [];

  if (
    reciprocationProfile !== undefined &&
    reciprocationProfile !== null &&
    reciprocationProfile.length !== 0
  ) {
    dataXre = finalReciprocationProfile.map((item) =>
      parseFloat(item.reciprocation_profile_element_item.time)
    );

    dataYre = finalReciprocationProfile.map((item) =>
      parseFloat(item.reciprocation_profile_element_item.value)
    );
  } else {
    dataXre = [0, 100];
    dataYre = [0, 100];
  }

  const lineRotation = {
    x: dataXro,
    y: dataYro,
    name: "Rotação da Coluna",
    type: "scatter",
    mode: "line",
    marker: { color: "#0000AA" },
  };

  const lineReciprocation = {
    x: dataXre,
    y: dataYre,
    name: "Reciprocação da Coluna",
    type: "scatter",
    mode: "line",
    marker: { color: "#FF0000" },
  };

  dataColumnMovement.push(lineRotation, lineReciprocation);

  return dataColumnMovement;
}

function getColumnMovementLimits(simulationUpdated, simulationJson) {
  const rotationJson =
    simulationJson.boundary_conditions.column_movement.column_rotation
      .rotation_profile;

  const reciprocationJson =
    simulationJson.boundary_conditions.column_movement.column_reciprocation
      .reciprocation_profile;

  const rotationUpdated = simulationUpdated.rotation_profile;

  const reciprocationUpdated = simulationUpdated.reciprocation_profile;

  const rotationProfile = rotationUpdated ? rotationUpdated : rotationJson;

  const reciprocationProfile = reciprocationUpdated
    ? reciprocationUpdated
    : reciprocationJson;

  let dataXro = [];
  let dataYro = [];

  if (
    rotationProfile !== undefined &&
    rotationProfile !== null &&
    rotationProfile.length !== 0
  ) {
    dataXro.push(
      ...rotationProfile.map((item) =>
        parseFloat(item.rotation_profile_element_item.time)
      )
    );
    dataYro.push(
      ...rotationProfile.map((item) =>
        parseFloat(item.rotation_profile_element_item.value)
      )
    );
  } else {
    dataXro = [0, 100];
    dataYro = [0, 100];
  }

  let dataXre = [];
  let dataYre = [];

  if (
    reciprocationProfile !== undefined &&
    reciprocationProfile !== null &&
    reciprocationProfile.length !== 0
  ) {
    dataXre = reciprocationProfile.map((item) =>
      parseFloat(item.reciprocation_profile_element_item.time)
    );

    dataYre = reciprocationProfile.map((item) =>
      parseFloat(item.reciprocation_profile_element_item.value)
    );
  } else {
    dataXre = [0, 100];
    dataYre = [0, 100];
  }

  const xLimits = Math.max([Math.max(...dataXre), Math.max(...dataXro)]);
  const yLimits = Math.max([Math.max(...dataYre), Math.max(...dataYro)]);
  return [xLimits, yLimits];
}

export { drawColumnMovement, getColumnMovementLimits };
