// #  Main Imports
import React, { useState, useEffect, useContext } from "react";

// #  Local SubComponents & utils
import { FormInput } from "../dynamicForm";

// Contexts
import { UpdatedJsonContext } from "../../contexts/UpdatedJsonContext";

// # Import Component Style
import "./selectFluid.css";

const FluidSelect = (props) => {
  // Update Rows and Tags
  const { setValueWellFluid, updatedJson, setUpdatedJson} = useContext(UpdatedJsonContext);

  // Fluid Combo with all possible options for select input
  const fluidsCombo = props.fluidsCombo;

  // Well fluid_id from pumping sequence
  const wellFluidId = props.wellFluidId;

  const [values, setValues] = useState({ fluid_id: wellFluidId.fid });

  useEffect(() => {
    let filterFid = fluidsCombo.filter((item) => item.eid === values.fluid_id);
    if (filterFid !== undefined && filterFid !== null && filterFid.length > 0) {
      setValueWellFluid({
        fid: filterFid[0].eid,
        label: filterFid[0].fluid_element_item.fluid_name,
      });
    } else {
      setValueWellFluid({
        fid: "",
        label: "Escolha um fluido",
      });
    }
  }, [fluidsCombo, setValueWellFluid, values]);

  const onChange = (e) => {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value });
    const fid = e.target.options[e.target.options.selectedIndex].value
    const label = e.target.options[e.target.options.selectedIndex].innerText
    const well_fluid = 
                      {
                        "fid": fid,
                        "label": label
                      };
    setUpdatedJson({...updatedJson, well_fluid});
  };

  function optionsTrasform(fluids) {
    let newOption = [];
    let emptyField = {
      value: "",
      label: "Escolha um fluido",
      disabled: true,
      selected: false,
    };

    for (let index = 0; index < fluids.length; index += 1) {
      let option = { value: fluids[index].eid, label: fluids[index].fluid_element_item.fluid_name };
      newOption.push(option);
    }
    newOption.unshift(emptyField);
    return newOption;
  }

  const comboInputs = [
    {
      id: 1,
      name: "fluid_id",
      type: "select",
      placeholder: "",
      options: optionsTrasform(fluidsCombo),
      required: true,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="container-outside-form-pump">
      <div className="container-form-pump">
        <form onSubmit={handleSubmit} className="generic-form-pump">
          <label className="label-input-pump">Fluido no poço:</label>
          <div className="container-input-btn-pump">
            {comboInputs.map((input) => (
              <FormInput
                className="input-pump"
                key={input.id}
                {...input}
                value={values[input.name]}
                disabled={props.disabled}
                onChange={onChange}
              />
            ))}
          </div>
        </form>
      </div>
    </div>
  );
};

export default FluidSelect;
