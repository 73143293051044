// Main import
import React, { useContext, useState } from 'react'

// Local components import
import { PlotlyChart, FAB, darkColors, lightColors } from '../index';

// Style import
import '../cardResults/CardResults.css'

// Context
import { ResultsContext } from '../../contexts/ResultsContext';

function CardResults({ graph, resizingWidthChart, resizingHeightChart, setResizingWidthChart, setResizingHeightChart, calculateSizeChart, element}) {
    const { removeGraphResults, handleCheckboxResults } = useContext(ResultsContext);
    
    // Destructuring of calculateSizeChart
    const { smallWidth, mediumWidth, largeWidth, smallHeight, mediumHeight, largeHeight, hideActionGroup } = calculateSizeChart(element[0].offsetWidth, element[0].offsetHeight);

    // States to control chart sizes - FAB selector
    const [chartSizeWidth, setChartSizeWidth] = useState(mediumWidth);
    const [chartSizeHeight, setChartSizeHeight] = useState(mediumHeight);

    // Functions to handle the chart sizes
    const showSmallChart = () => {
        setResizingWidthChart(undefined);
        setResizingHeightChart(undefined);
        setChartSizeWidth(smallWidth);
        setChartSizeHeight(smallHeight);
    }
    const showMediumChart = () => {
        setResizingWidthChart(undefined);
        setResizingHeightChart(undefined);
        setChartSizeWidth(mediumWidth);
        setChartSizeHeight(mediumHeight);
    }
    const showLargeChart = () => {
        setResizingWidthChart(undefined);
        setResizingHeightChart(undefined);
        setChartSizeWidth(largeWidth);
        setChartSizeHeight(largeHeight);
    }

    // Function to remove and handle checkbox of graph results
    const removeGraphAndHandleCheckboxResults = (graphId) => {
        handleCheckboxResults(graphId);
        removeGraphResults(graphId);
    };

    /* Function responsible for controlling the rendering of the FAB */
    function controlsActionButton() {
        let actionData = {};
        actionData = {
            actionButtom: {
                tootip: "Tamanho do card/gráfico",
                icon: "fas fa-plus",
                key: "Tamanho do card/gráfico",
                rotate: true,
                backgroundColor: darkColors.blue,
                color: lightColors.white,
                /* Direction: Array responsible for determining the opening position of the button */
                direction: ["fab-container-left results-card"],
            },
            actions: [
                {
                    tooltip: "Exibir gráfico pequeno",
                    icon: "fas fa-compress-alt",
                    key: "Exibir gráfico pequeno",
                    rotate: true,
                    callback: showSmallChart,
                    backgroundColor: darkColors.grey,
                    color: lightColors.white,
                },
                {
                    tooltip: "Exibir gráfico médio",
                    icon: "fas fa-compress",
                    key: "Exibir gráfico médio",
                    rotate: true,
                    callback: showMediumChart,
                    backgroundColor: darkColors.green,
                    color: lightColors.white,
                },
                {
                    tooltip: "Exibir gráfico grande",
                    icon: "fas fa-expand",
                    key: "Exibir gráfico grande",
                    rotate: true,
                    callback: showLargeChart,
                    backgroundColor: darkColors.yellow,
                    color: lightColors.white,
                }
            ],
        };
        return actionData;
    }

    return (
        <div className="results-card-container">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                    className="close-button"
                    onClick={() => removeGraphAndHandleCheckboxResults(graph.id)}
                >
                    <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                </button>
            </div>
            <PlotlyChart key={graph.id} data={graph.data} layout={{ ...graph.layout, width: resizingWidthChart ? resizingWidthChart : chartSizeWidth, height: resizingHeightChart ? resizingHeightChart : chartSizeHeight }} />
            <FAB data={controlsActionButton()} hideActionGroup={hideActionGroup} />
        </div>
    );
}
export default CardResults