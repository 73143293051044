import axios from "./apis/i3d_sqlAPI";



async function Login (user,password)  {

    
  // Envia uma requisição post
 await axios({
    method: "post",
    url: "login/",
    data:{ "username" : user,
    "password" : password}
  }).then(function (response) {
    return sessionStorage.setItem("Token",/* "Token " +  */response.data.access);
  });

}

const authorizationHeader = "Bearer " + sessionStorage.getItem("Token");

export {Login,authorizationHeader}

