import { useContext } from "react";
import { ToastContext } from "../components/contexts/ToastContext";

function useRheologicalParametersValidation() {
  // Toast Context
  const { toastList, setToastList, showToast } = useContext(ToastContext);

  // Function to call showToast
  function showToastValidation(description, title, type) {
    showToast(
      {
        message: {
          type: type,
          title: title,
          description: description,
        },
      },
      toastList,
      setToastList
    );
  }

  function validateRheologicalParameters(values, currentRheologicModel) {
    let currentModel = currentRheologicModel.replace("_model_subject", "");

    if (currentModel === "powerlaw") {
      currentModel = "power_law";
    }
    let obj = {
      bingham: [values.bingham.plastic_viscosity, values.bingham.tau_y].some(
        (item) => item === ""
      ),
      herschel_bulkley: [
        values.herschel_bulkley.tau_y,
        values.herschel_bulkley.k,
        values.herschel_bulkley.n,
      ].some((item) => item === ""),
      newtonian: [values.newtonian.viscosity].some((item) => item === ""),
      power_law: [values.power_law.k, values.power_law.n].some(
        (item) => item === ""
      ),
    };
    if (obj[currentModel]) {
      showToastValidation(
        "Os parâmetros reológicos devem ser preenchidos.",
        "Erro de validação",
        "ERR"
      );
      return false;
    } 
    return true;
  }

  return { validateRheologicalParameters };
}

export default useRheologicalParametersValidation;
