export const defaultValueReciprocation = [
  {
    editable: true,
    eid: null,
    id: 1,
    selected: false,
    time: 0,
    value: 0
  },
  {
    editable: true,
    eid: null,
    id: 2,
    selected: false,
    time: 100,
    value: 0
  },
];

export const defaultValueRotation = [
  {
    editable: true,
    eid: null,
    id: 1,
    selected: false,
    time: 0,
    value: 0
  },
  {
    editable: true,
    eid: null,
    id: 2,
    selected: false,
    time: 100,
    value: 0
  },
];
