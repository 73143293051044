// Main import of React
import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";

// #  Local SubComponents & utils
import { DropletLoadingSpinner } from "../components/common";
import { mergedObject, reload } from "../components/utils";

// # Requests
import { updateTagByCaseId } from "../services/TagsRequests";
import { getCaseAndCasePropsByIdRequest } from "../services/CasesRequests";
import { getSimulationModeCombo } from "../services/SimulationRequests";
import { getOperationTypeCombo } from "../services/SimulationRequests";
import { getEnvironmentTypeCombo } from "../services/SimulationRequests";

// # Context
import { SearchContext } from "../components/contexts/SearchContext";
import { HistoryContext } from "../components/contexts/HistoryContext";
import { ToastContext } from "../components/contexts/ToastContext";
import { HandleErrorContext } from "../components/contexts/HandleErrorContext";
import { ProjectAndCasesContext } from "../components/contexts/ProjectAndCasesContext";

// # Local Form or Pop-up import
import { FormScenery } from "../components/forms";

// Style import
import "./style/Scenery.css";


function Scenery() {
  const { id } = useParams();
  const history = useHistory();

  /* State to set ID(MONGO) at one Case - responsible to manage SearchBox*/
  const { setId, setWordValue } = useContext(SearchContext);

  /* State to set History Location Pathname and possible changes at onChange function */
  const { setHistoryLocation, setHasChange } = useContext(HistoryContext);

  // Toast Context
  const { toastList, setToastList, showToast } = useContext(ToastContext);

  // Handle Error Context
  const { setError } = useContext(HandleErrorContext);

  const { setProjectById } = useContext(ProjectAndCasesContext);


  // Values to fill Scenery page
  const [values, setValues] = useState();

  // Simulation Combo
  const [simulationMode, setSimulationMode] = useState();

  // Environment Type
  const [environmentType, setEnvironmentType] = useState();

  // Operation Type
  const [operationType, setOperationType] = useState();

    // Values to fill CasePropsById
    const [casePropsById, setCasePropsById] = useState();

  // Define useEffect policy
  useEffect(() => {
    getCaseAndCasePropsByIdRequest(id, setError).then((res) => {
      if (res) {
        setValues(res);
        setCasePropsById(res)
        setProjectById(res.case.project)
        reload()
      }
    });
    getEnvironmentTypeCombo().then((res) => {
      if (res) {
        setEnvironmentType(res);
      }
    });
    getOperationTypeCombo().then((res) => {
      if (res) {
        setOperationType(res);
      }
    });
    getSimulationModeCombo().then((res) => {
      if (res) {
        setSimulationMode(res);
      }
    });
    setWordValue("");
    setHistoryLocation(history.location.pathname);
    setId(id);
  }, []);


  // Define HandleSubmit, Redirect and onChange functions
  async function handleSubmit(e) {
    e.preventDefault();
    const response = await updateTagByCaseId(id, "scenery", {
      environment_type: values["scenery"].environment_type ? values["scenery"].environment_type : values["scenery"].environment_type.value,
      water_depth: values["scenery"].environment_type.value === "land_land_platform" ? 0 : values["scenery"].water_depth,
      air_gap: values["scenery"].air_gap,
      riser_od: values["scenery"].riser_od,
      riser_id: values["scenery"].riser_id,
      collar_measured_depth: 0,
      casing_shoe_measured_depth: 0,
      operation_type: values["scenery"].operation_type ? values["scenery"].operation_type : values["scenery"].operation_type.value ,
      simulation_mode: values["scenery"].simulation_mode
    });
    showToast(response, toastList, setToastList);
    setHasChange(false);
    //! Do not jump to the next screen
    // setActiveName("Geometria");
    // history.push(`/case/${id}/geometry/external-geometry`);
  }

  // Function responsible for change values - Scenery
  function onChange(e) {
    e.preventDefault();
    let changedValues = {};
    if (e.target.options) {
      const selectValue = e.target.options[e.target.options.selectedIndex].value
      const selectLabel = e.target.options[e.target.options.selectedIndex].innerText
      changedValues = mergedObject(values, {
        [e.target.name]: {value: selectValue,
                          label: selectLabel},
      });
    }
    else {
      changedValues = mergedObject(values, {
        [e.target.name]: e.target.value,
      });
    }

    setValues(mergedObject(values, changedValues));
    // if (
    //   //Comparing two objects
    //   JSON.stringify(values["scenery"]) !==
    //   JSON.stringify(casePropsById["scenery"])
    // ) {
    setHasChange(true);
  }


  // Function responsible for cancel request
  async function cancelRequest() {
    setValues(casePropsById);
  }

  // Page Render
  return values  && casePropsById && simulationMode && environmentType && operationType ? (
    <div className="scenery-container">
      <FormScenery
        values={values}
        casePropsById={casePropsById}
        simulationMode={simulationMode}
        environmentType={environmentType}
        operationType={operationType}
        handleSubmit={handleSubmit}
        cancelRequest={cancelRequest}
        onChange={onChange}
        id={id}
      />
    </div>
  ) : (
    // Show error message
    <DropletLoadingSpinner />
  );
}

// Page Export
export default Scenery;
