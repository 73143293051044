// # Main Imports
import { useContext, useEffect, useState } from "react";

// #  Local SubComponents & utils
import { CardResults, FormContainer } from "../components/common";

// Context
import { ResultsContext } from "../components/contexts/ResultsContext";

// Style import
import "./style/Results.css";

function Results() {
    const { graphSelectedResults } = useContext(ResultsContext);

    // Get element with classname "page-results-container"
    let element = document.getElementsByClassName('page-results-container');

    // States to control the resizing of the charts 
    const [resizingWidthChart, setResizingWidthChart] = useState(0);
    const [resizingHeightChart, setResizingHeightChart] = useState(0);

    // Limit to stop chart resizing
    const limitWidht = 700;
    const limitHeight = 900;

    // Function to calculate the sizes of the charts
    function calculateSizeChart(width, height) {
        const showFabSizeSelectors = width >= limitWidht || height >= limitHeight;
        const smallRatio = 3.2;
        const mediumRatio = 2.2; 
        const largeRatio = 1.2; 
        
        // Variable to control the hiding of FAB actions
        let hideActionGroup = 'None';
    
        let smallWidth = 0;
        let mediumWidth = 0;
        let largeWidth = 0;
        let smallHeight = 0;
        let mediumHeight = 0;
        let largeHeight = 0;
        
        if (showFabSizeSelectors) {
            smallWidth = width / smallRatio;
            mediumWidth = width / mediumRatio;
            largeWidth =  width/ largeRatio;
            smallHeight = width / smallRatio;
            mediumHeight = height / mediumRatio;
            largeHeight =  height / largeRatio;
            return { smallWidth, mediumWidth, largeWidth, smallHeight, mediumHeight, largeHeight, showFabSizeSelectors, mediumWidth, mediumHeight, hideActionGroup };
        } else {
            hideActionGroup = 'imageSize'
            mediumWidth = 320; 
            mediumHeight = 320;
            return { smallWidth, smallHeight, showFabSizeSelectors, mediumWidth, mediumHeight, hideActionGroup };
        }
    }
    
    const useScreenSize = () => {
        const [screenSize, setScreenSize] = useState({
            width: element[0]?.offsetWidth,
            height: element[0]?.offsetHeight,
        });

        useEffect(() => {
            const handleResize = () => {
                setScreenSize({
                    width: element[0]?.offsetWidth,
                    height: element[0]?.offsetHeight,
                });
                setResizingWidthChart(screenSize.width <= limitWidht ? 320 : screenSize.width / 1.5)
                setResizingHeightChart(screenSize.width <= limitHeight ? 320 : screenSize.height / 1.5);
            };
            window.addEventListener('resize', handleResize);
            
            // Clean up the event listener when the component unmounts
            return () => {
                window.removeEventListener('resize', handleResize)
            };
        }, [screenSize]);
        return screenSize;
    };
    
    const screenSize = useScreenSize();

    return (
        <div className={`page-results-container`}>
            <FormContainer mode="center-flex" margin="0em">
                {graphSelectedResults.map((graph) => (
                    <CardResults 
                    key={graph.id} 
                    graph={graph}
                    resizingWidthChart={resizingWidthChart}
                    resizingHeightChart={resizingHeightChart}
                    setResizingWidthChart={setResizingWidthChart}
                    setResizingHeightChart={setResizingHeightChart}
                    calculateSizeChart={calculateSizeChart}
                    element={element}
                    />
                ))}
            </FormContainer>
        </div>
    );
}

export default Results;