// Main import of React
import { useContext, useEffect, useState } from "react";

// Local SubComponents & utils
import { Tabs } from "../../common";
import RheologicModelSelector from "../rheologicModelSelector";
import GridGraphCombo from "../gridGraphCombo";
import VariableGrid from "../variableGrid";
import { createRows, renameCurrentRheologicModel } from "../../utils";
import { headersRheometryModel, headersRheometry } from "../../../data/headersForGrids";

// Requests
import { getOneTagByCaseIdAndEid } from "../../../services/TagsRequests";

// Context
import { VariableRheologyContext } from "../../contexts/VariableRheologyContext";
import { UpdatedJsonContext } from "../../contexts/UpdatedJsonContext";

// Import Component Style
import "./RheologicModel.css";

export default function RheologicModel(props) {
  
  const { rowsRheometry, setRowsRheometry, rowsRheometryReadings, setRowsRheometryReadings } = useContext(
    VariableRheologyContext
  );

  // UpdatedJson and Active Rheological Data
  const { activeRheoData, setActiveRheoData, updatedJson, updatedRheologicData } = useContext(UpdatedJsonContext);

  // State to control displayed Page
  const pageState = props.pageState
  
  // Form Props
  const values = props.values;
  const onChange = props.onChange;
  
  //  Rheologic
  const isChecked = props.isChecked;
  const setIsChecked = props.setIsChecked;
  
  // Data for Create Rows function and UpdateRows
  const rheometryProps = props.rheometryProps;

  // Original rheologic data
  const [rheologicData, setRheologicData] = useState(values["fluid_element_item"]["rheologic_data"]);

  // Case mode
  const caseMode = props.caseMode;
  
  // Current rheologic model
  const currentRheologicModel =
  values["fluid_element_item"]["current_rheologic_model_id"].eid;
  const currentModel = renameCurrentRheologicModel(currentRheologicModel)
  
  // Case ID
  const caseId = props.caseId;
  
  // State to control Tabs Component
  const [activeFluidTab, setActiveFluidTab] = useState(values["fluid_element_item"]["property_dependencies"]["value"]);

  const useViscosimeterReadings =
  values["fluid_element_item"]["use_viscosimeter_readings"];

  if(activeRheoData === undefined || activeRheoData === "" || Object.keys(activeRheoData).length === 0 || activeRheoData === null){
    setActiveRheoData(rheologicData[0])
  }

  // Constants to check if values of roation and readings exists
  const checkEmptyRotationFields = updatedJson["rheometer_readings"] && pageState === 0
      ? updatedJson["rheometer_readings"]
      .some(item => item.rheometer_reading_item.rotation === "")
      : rheometryProps
      .some(item =>  item.rheometer_reading_item.rotation === "")
    
  const checkEmptyReadingsFields =  updatedJson["rheometer_readings"] && pageState === 0
      ? updatedJson["rheometer_readings"]
      .some(item => item.rheometer_reading_item.reading
        === "")
      : rheometryProps
      .some(item => item.rheometer_reading_item.reading  === "")
    


  // Define useEffect policy
  useEffect(() => { 
  if(activeRheoData) {
    // Upadate rheometry properties rows and rheologic data after change current rheologic model or activeRheoData 
      getOneTagByCaseIdAndEid(caseId,"fluids", values.eid).then((res) => {
        if (res) {
          setRowsRheometry(createRows(res["fluid_element_item"]["rheologic_data"], headersRheometryModel(activeFluidTab, currentRheologicModel) ));
          setRheologicData(res["fluid_element_item"]["rheologic_data"])
        }
      });
      // Update rheometry readings rows after change current rheologic model or activeRheoData 
      getOneTagByCaseIdAndEid(caseId,"rheologic_data", activeRheoData.eid ? activeRheoData.eid: rheologicData[0].eid).then((res) => {
        if (res) {
          setRowsRheometryReadings(createRows(res["rheologic_data_item"]["rheometer_readings"], headersRheometry, activeRheoData));
        }
      });
     
    }
    
  }, [currentRheologicModel, activeRheoData]);


  // Function responsible for update rheometer readings considering the activeTab
  function updateRheometerReadings() {
    let rheometerReadings = []
    // When property dependencies !== "constants"
    if(Object.keys(updatedRheologicData).length && activeFluidTab !== "constants") {
      let copyUpdatedRheologicData = updatedRheologicData
       rheometerReadings = copyUpdatedRheologicData.filter(item => item.eid === activeRheoData.eid)[0]["rheologic_data_item"]
    } 
    // When property dependencies === "constants"
    else  {
      rheometerReadings = updatedJson;
    }
    return rheometerReadings

  }

  // Data for calculate rheologic properties after change rheometer readings
  const rheometryJson = {
    current_rheologic_model_id: { eid: currentRheologicModel },
    rheometer_readings: Object.keys(updateRheometerReadings()).length > 0 ? updateRheometerReadings()["rheometer_readings"] : rheologicData[0]["rheologic_data_item"]["rheometer_readings"],
  };
  
  // Data for GridGraphCombo component
    const copyRowsRheometry = rowsRheometry;
    let rheometryTemperature = Object.keys(activeRheoData).length > 0 && rowsRheometry ? copyRowsRheometry.filter((item)=> item.eid === activeRheoData.eid)[0]["rheometry_temperature"] : rheologicData[0]["rheologic_data_item"]["rheometry_temperature"]
    let rheometryPressure = Object.keys(activeRheoData).length > 0 && rowsRheometry ?  copyRowsRheometry.filter((item)=> item.eid === activeRheoData.eid)[0]["rheometry_pressure"] : rheologicData[0]["rheologic_data_item"]["rheometry_pressure"]
  
    const gridTextRheometry ={
      "constants": ``,
      "temperature" :`T = ${rheometryTemperature} ºC`,
      "pressure": `P = ${rheometryPressure}`,
      "pressure_temperature": `T = ${rheometryTemperature} ºC, P = ${rheometryPressure} psi`,
  }

  // Tabs Data
  const tabData = [
    {
      id: "constants",
      description: "Independentes de Temperatura e Pressão",
      name: "Constante",
    },
    {
      id: "temperature",
      description: "Dependentes de Temperatura",
      name: "Varia com T",
    },
    {
      id: "pressure",
      description: "Dependentes de Pressão",
      name: "Varia com P",
    },
    {
      id: "pressure_temperature",
      description: "Dependentes de Pressão e Temperatura",
      name: "Varia com P e T",
    },
  ];

  // Function to change property dependencies
function changePropertyDependencie(id){
  const copyTabData = tabData;
   onChange({
    target: {
      name: "property_dependencies",
      value: { label: copyTabData.filter((item) => item.id === id)[0]["description"] ,"value": id },
    },
  });
}

  // Function to change id Tab
  function handleClick(id){
    if (activeFluidTab === "constants") {
      setActiveRheoData(rheologicData[0]);
    }
    setActiveFluidTab(id);
    changePropertyDependencie(id)
  };

  return (
    <>
      <Tabs
        data={tabData}
        activeTab={activeFluidTab}
        setActiveTab={setActiveFluidTab}
        callback={handleClick}
      >
        <div key={"constants"} className="rheologic-model__container">
          <RheologicModelSelector
            values={values}
            onChange={onChange}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            pageState={pageState}
            activeTab={activeFluidTab}
            rheometryJson={rheometryJson}
            checkEmptyReadingsFields={checkEmptyReadingsFields}
            checkEmptyRotationFields={checkEmptyRotationFields}
            currentModel={currentModel}
            useViscosimeterReadings={useViscosimeterReadings}
          />
          <GridGraphCombo
            rheometryProps={rheometryProps}
            rheometryJson={rheometryJson}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            onChange={onChange}
            activeTab={activeFluidTab}
            rheologicData={rheologicData}
            rowsRheometryReadings={rowsRheometryReadings}
            setRowsRheometryReadings={setRowsRheometryReadings}
            gridTextRheometry={gridTextRheometry}
            currentModel={currentModel}
            caseMode={caseMode}
          />
        </div>

        <div key={"temperature"} className="rheologic-model__container">
          <VariableGrid
            values={values}
            rheologicData={rheologicData}
            rheometryProps={rheometryProps}
            isChecked={!isChecked}
            activeTab={activeFluidTab}
            rowsRheometry={rowsRheometry}
            setRowsRheometry={setRowsRheometry}
            rheometryJson={rheometryJson}
            checkEmptyReadingsFields={checkEmptyReadingsFields}
            checkEmptyRotationFields={checkEmptyRotationFields}
            currentRheologicModel={currentRheologicModel}
            useViscosimeterReadings={useViscosimeterReadings}
            caseMode={caseMode}
          />
          <GridGraphCombo
            rheometryProps={rheometryProps}
            rheometryJson={rheometryJson}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            onChange={onChange}
            activeTab={activeFluidTab}
            rheologicData={rheologicData}
            rowsRheometryReadings={rowsRheometryReadings}
            setRowsRheometryReadings={setRowsRheometryReadings}
            gridTextRheometry={gridTextRheometry}
            currentModel={currentModel}
            caseMode={caseMode}
          />
        </div>

        <div key={"pressure"} className="rheologic-model__container">
          <VariableGrid
            values={values}
            rheologicData={rheologicData}
            rheometryProps={rheometryProps}
            isChecked={!isChecked}
            activeTab={activeFluidTab}
            rowsRheometry={rowsRheometry}
            setRowsRheometry={setRowsRheometry}
            rheometryJson={rheometryJson}
            checkEmptyReadingsFields={checkEmptyReadingsFields}
            checkEmptyRotationFields={checkEmptyRotationFields}
            currentRheologicModel={currentRheologicModel}
            useViscosimeterReadings={useViscosimeterReadings}
            caseMode={caseMode}
          />
          <GridGraphCombo
            rheometryProps={rheometryProps}
            rheometryJson={rheometryJson}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            onChange={onChange}
            activeTab={activeFluidTab}
            rheologicData={rheologicData}
            rowsRheometryReadings={rowsRheometryReadings}
            setRowsRheometryReadings={setRowsRheometryReadings}
            gridTextRheometry={gridTextRheometry}
            currentModel={currentModel}
            caseMode={caseMode}
          />
        </div>
        <div
          key={"pressure_temperature"}
          className="rheologic-model__container"
        >
          <VariableGrid
            values={values}
            rheologicData={rheologicData}
            rheometryProps={rheometryProps}
            isChecked={!isChecked}
            activeTab={activeFluidTab}
            rowsRheometry={rowsRheometry}
            setRowsRheometry={setRowsRheometry}
            rheometryJson={rheometryJson}
            checkEmptyReadingsFields={checkEmptyReadingsFields}
            checkEmptyRotationFields={checkEmptyRotationFields}
            currentRheologicModel={currentRheologicModel}
            useViscosimeterReadings={useViscosimeterReadings}
            caseMode={caseMode}
          />
          <GridGraphCombo
            rheometryProps={rheometryProps}
            rheometryJson={rheometryJson}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            onChange={onChange}
            activeTab={activeFluidTab}
            rheologicData={rheologicData}
            rowsRheometryReadings={rowsRheometryReadings}
            setRowsRheometryReadings={setRowsRheometryReadings}
            gridTextRheometry={gridTextRheometry}
            currentModel={currentModel}
            caseMode={caseMode}
          />
        </div>
      </Tabs>
    </>
  );
}
