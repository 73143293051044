// Main import of React
import React, { createContext,  useState } from 'react';

// Create Context Object
const ModalContext = createContext();

// Hook
export default function useModal() {
  // Toast states
  const [isModalOpen,setIsModalOpen ] = useState(false);
  const [caseId,setCaseId ] = useState([]);
  const [projectId,setProjectId ] = useState([]);

    // Function to close modal
    function closeModal(){
      setIsModalOpen(!isModalOpen);
    };
  
    
  return { isModalOpen, setIsModalOpen, caseId, setCaseId, projectId, setProjectId, closeModal };
}

// Specific Context Provider
function ModalProvider({ children }) {

  const { isModalOpen, setIsModalOpen, caseId, setCaseId, projectId, setProjectId, closeModal} = useModal();


  return (
    <ModalContext.Provider value={{ isModalOpen, setIsModalOpen, caseId, setCaseId, projectId, setProjectId, closeModal}}>
      {children}
    </ModalContext.Provider>
  );
}


export { ModalContext, ModalProvider };