// Main import of React
import { useState, useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { Route, useParams, useHistory } from "react-router";

// #  Local SubComponents & utils
import {
  PlotlyChart,
  ActionFabEditableGrid,
  FluidSelect,
  DropletLoadingSpinner,
} from "../components/common";

import { mergedObject } from "../components/utils/mergeObjects";
import { reload } from "../components/utils/functionSetFocused";

// Requests
import { calcPumpSequenceChart } from "../services/ChartsRequests";

// Draw Pump Sequence utils
import { drawAllPumpSequence, getGeometryLimits } from "../components/utils";

// Data to populate headers of grids - Pump Sequence
import {
  headersFluids,
  headersAnnularAndDisplacement,
} from "../data/headersForGrids";

//Functions to  create rows - Pump Sequence
import { createRows } from "../components/utils";

//Functions to add rows - Pump Sequence
import { addTableRows } from "../components/utils/functionsToAddRows";

//Functions to delete rows - Pump Sequence
import { deleteTableRows } from "../components/utils/functionsToDeleteRows";

//Functions to  duplicate rows - Pump Sequence
import {
  duplicateRowFluids,
  duplicateRowAnnularAndDisplacement,
} from "../components/utils/functionToDuplicateRows";

// # Context
import { ModalContext } from "../components/contexts/ModalContext";

//Contexts
import { UpdatedJsonContext } from "../components/contexts/UpdatedJsonContext";

function PumpSequenceGraphs(props) {
  // Updated Rows and Tags
  const { updatedJson, tag } = useContext(UpdatedJsonContext);

  // baseObject and changesInObject definition
  let json = props.json;

  let [rLimits, zLimits] = getGeometryLimits(
    mergedObject(json["internal_geometry"], updatedJson),
    mergedObject(json["external_geometry"], updatedJson),
    json["scenery"]
  );

  // Define pump sequence chart data state
  const [pumpSequenceChartData, setPumpSequenceChartData] = useState();

  // Define useEffect policy
  useEffect(() => {
    if (json) {
      calcPumpSequenceChart(mergedObject(json, updatedJson)).then((res) => {
        if (res) {
          setPumpSequenceChartData(res);
        }
      });
    }
    reload();
  }, [updatedJson, tag, json]);

  /*    Pump Sequence data */
  const layout_pump_sequence = {
    width: 450,
    height: 550,
    plot_bdcolor: "black",
    title: "<b>Relação de Diâmetros (Pump Sequence)<b>",
    font: {
      family: "Arial",
      size: 12,
      color: "black",
    },

    xaxis: {
      range: rLimits,
      showgrid: false,
      title: "<b>Raio (pol)<b>",
      color: "black",
    },

    yaxis: {
      range: zLimits,
      showgrid: false,
      title: "<b>Prof. Medida (m)<b>",
      color: "black",
    },

    shapes: drawAllPumpSequence(
      json["internal_geometry"],
      json["external_geometry"],
      json["scenery"],
      pumpSequenceChartData
    ),
  };

  return (
    <div className="plotly__container">
      <Route path={["/case/:id/pumpsequence/"]}>
        <div className="plotly__chart">
          {pumpSequenceChartData && layout_pump_sequence ? (
            <PlotlyChart layout={layout_pump_sequence} />
          ) : (
            <DropletLoadingSpinner spinnerPosition={"40%"} />
          )}
        </div>
      </Route>
      {/* <Route exact path="/case/:id/pumpsequence/pumped-sequence">
        <div className="plotly__chart">
          <PlotlyChart data={data} layout={layout} />
        </div>
      </Route> */}
    </div>
  );
}

function PumpSequenceAccordionChild(props) {
  const fluids = props.casePropsById.fluids;

  const fluidSequence = props.casePropsById.pumping_sequence.pumped_fluids;

  const wellFluidId = props.casePropsById.pumping_sequence.well_fluid;

  // Hook gives access to the history instance
  const history = useHistory();

  // Query params of route
  const caseMode = history.location.search.includes("?view=true");

  const caseId = history.location.pathname.split("/")[2];

  const pathnameEditFluid = history.location.pathname.substring(0, history.location.pathname.indexOf("/edit"));
  
  const tags = ["pumped_fluids", "fluids"];
  const tagsItems = ["pumped_fluids_element_item"];

  // Defining height for Grid Fab Container
  const gridContainerHeight = "65vh";
  const gridContainerHeightPumpSequence = "60vh";
  
  // Query params
  const queryParams = history.location.search;

  /* States to control Fluids Grid with FAB */
  const [rowsFluids, setRowsFluids] = useState();

  /* States to control Anular Fluids Grid with FAB */
  const [rowsAnular, setRowsAnular] = useState();

  /* State to control Modal */
  const { setIsModalOpen, isModalOpen } = useContext(ModalContext);

 // Define useEffect policy
  useEffect(() => {
    if (fluids) {
      setRowsFluids(createRows(fluids, headersFluids))
      setRowsAnular(createRows(fluidSequence, headersAnnularAndDisplacement(fluids)))
    }
    if(pathnameEditFluid && isModalOpen === false) {
      const pathName = `/case/${caseId}/pumpsequence/fluids${queryParams}`;
      history.replace(pathName);
    }
  }, [fluids, isModalOpen]);

  return (
    <>
      <div key={1}>
        <Route path={["/case/:id/pumpsequence/fluids"]}>
          <ActionFabEditableGrid
            metaData={headersFluids}
            tags={tags[1]}
            rows={rowsFluids ? rowsFluids : []}
            oldRows={createRows(fluids, headersFluids)}
            dataJson={fluids}
            setRows={setRowsFluids}
            setIsModalOpen={setIsModalOpen}
            addTableRows={addTableRows}
            deleteTableRows={deleteTableRows}
            duplicateRow={duplicateRowFluids}
            gridContainerHeight={gridContainerHeight}
          />
        </Route>
      </div>
      <div key={2}>
        <Route exact path="/case/:id/pumpsequence/well-fluid">
          <FluidSelect fluidsCombo={fluids} wellFluidId={wellFluidId} disabled={caseMode} />
        </Route>
      </div>
      <div key={3}>
        <Route exact path="/case/:id/pumpsequence/pumped-sequence">
          {/* <FluidSelect fluidsCombo={fluidsCombo} wellFluidId={wellFluidId} /> */}
          <ActionFabEditableGrid
            metaData={headersAnnularAndDisplacement(fluids)}
            tagsItems={tagsItems[0]}
            tags={tags[0]}
            rows={rowsAnular ? rowsAnular : []}
            oldRows={createRows(
              fluidSequence,
              headersAnnularAndDisplacement(fluids)
            )}
            dataJson={fluidSequence}
            setRows={setRowsAnular}
            addTableRows={addTableRows}
            deleteTableRows={deleteTableRows}
            duplicateRow={duplicateRowAnnularAndDisplacement}
            gridContainerHeight={gridContainerHeightPumpSequence}
          />
        </Route>
      </div>
    </>
  );
}

export { PumpSequenceGraphs, PumpSequenceAccordionChild };
