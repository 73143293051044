// Main imports
import { useState } from "react";
import { useHistory } from "react-router-dom";

// Local SubComponents & utils
import { StyledButton, ProgressBar } from "../../common";
import { downloadFile } from "../../../services/DownloadRequests";
import { reload } from "../../utils";

// Requests
import { createSimulationRequest } from "../../../services/SimulationRequests";
import { getOneSimulation } from "../../../services/SimulationRequests";

// # Import Component Style
import "./simulationProgress.css";

export default function SimulationProgress(props) {
  const history = useHistory();

  const completed = props.completed;
  const stopped = props.stopped;
  const setStopped = props.setStopped;

  const showToast = props.showToast;
  const toastList = props.toastList;
  const setToastList = props.setToastList;

  const setButtonDisabled = props.setButtonDisabled;
  const showError = props.showError;
  const setShowError = props.setShowError;
  const setCompleted = props.setCompleted;
  const case_id = props.case_id;

  const getSimulationData = props.getSimulationData;
  const cancelSimulation = props.cancelSimulation;

  const buttonDisabled = props.buttonDisabled;

  const [lastSimulationCompleted, setLastSimulationCompleted] = useState(false);

  // Function responsible for create one simulation by Case ID
  async function startSimulation() {
    const case_id = history.location.pathname.split("/")[2];
    const data = {
      execution_percentage: 0,
      message: "teste",
    };
    createSimulationRequest(data, case_id).then((res) => {
      if (res) {
        showToast(res, toastList, setToastList);
        setButtonDisabled(true);
        setShowError(false);
        setCompleted(false);
        setStopped(!stopped);
        setLastSimulationCompleted(false);
        getSimulationData(case_id);
      }
    });
  }

  async function initDownload(e) {
    e.preventDefault();
    const case_id = history.location.pathname.split("/")[2];
    await downloadFile(case_id);
    setTimeout(function () {
      window.location.reload(true);
    }, 500);
  }

  async function refreshFunction() {
    getOneSimulation(case_id).then((res) => {
      setCompleted(res.execution_percentage);
      if (completed === 100 && !lastSimulationCompleted && !showError) {
        setLastSimulationCompleted(true);
      } else if (res.message.type === "ERR"){
        showToast(res,toastList,setToastList)
        setShowError(true)
        setButtonDisabled(false)
      }
      reload();
    } )
  }
  // Buttons to render using StyledButton component
  const bttnForSimulate = {
    type: "button",
    callback: startSimulation,
    color: "#17365c",
    icon: "fas fa-play",
    text:
    buttonDisabled && completed < 100 && !stopped ? "Simulando" : "Simular",
  };

  const bttnForDownload = {
    type: "button",
    callback: initDownload,
    color: "#0c9e6f",
    icon: "fas fa-download",
    text: "Download PDF",
  };

  return (
    <>
      {/*  <ProgressBar completed={completed} /> */}
      <div className="bttn-case__container-simulate">
        <StyledButton data={bttnForSimulate} buttonDisabled={buttonDisabled} />

        <div className="simulation-progress__container">
          {completed === 100 && !showError && !stopped && (
            <div className="progress-bar__container">
              <StyledButton data={bttnForDownload} />
            </div>
          )}
          {buttonDisabled && completed < 100 && !stopped && (
            <div className="progress-bar__container">
              <ProgressBar completed={completed} />
              <button
                class={"fab-action-button"}
                onClick={refreshFunction}
                style={{
                  backgroundColor: "rgb(13, 71, 161)",
                  color: "rgb(245, 245, 245)",
                  height: "24px",
                }}
              >
                <i class="fas fa-sync"></i>
              </button>
              <button
                class={"fab-action-button"}
                onClick={cancelSimulation}
                style={{
                  backgroundColor: "rgb(198, 40, 40)",
                  color: "rgb(245, 245, 245)",
                  width: "32px",
                  height: "24px",
                }}
              >
                <i class="far fa-window-close"></i>
              </button>
            </div>
          )}
        </div>

        {showError === true && (
          <span style={{ color: "red", textAlign: "center", width: "100%" }}>
            Erro na simulação!
          </span>
        )}

        {stopped && (
          <span style={{ color: "red", textAlign: "center", width: "100%" }}>
            Simulação cancelada!
          </span>
        )}
      </div>
    </>
  );
}
