// Import axios from specific API definition
import axios from "./apis/i3d_sqlAPI";

// Import Authorization Header
import { authorizationHeader } from "./LoginServices";

// GET Method Request for Simulations
async function getAllSimulations() {
  try {
    const response = await axios.get(`simulations/`, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data.data;
  } catch (error) {
    console.log(error.message);
  }
}

// POST Method Request by  CaseId
async function createSimulationRequest(data, id) {
  try {
    const response = await axios.post(
      `simulation/case/${id}/`,
      JSON.stringify(data),
      {
        headers: {
          Authorization: authorizationHeader,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}

// PUT Method Request by CaseId
async function updateSimulationRequest(id, data) {
  try {
    const response = await axios.put(
      `case/${id}/simulation/`,
      JSON.stringify(data),
      {
        headers: {
          Authorization: authorizationHeader,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}

// GET Method Request for Simulation
async function getOneSimulation(id) {
  try {
    const response = await axios.get(`simulation/case-id/${id}/`, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data.data !== "" ? response.data.data: response.data ;
  } catch (error) {
    console.log(error.message);
  }
}

// POST Method Request for Simulation - Stopped
async function cancelOneSimulation(id) {
  try {
    const response = await axios.post(
      `/simulation/kill-sim/${id}/`,
      null,
      {
        headers: {
          Authorization: authorizationHeader,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}

// GET Method Request for Simulation Mode Combo
async function getSimulationModeCombo(){
  try {
    const response = await axios.get(`simulation-modes/`, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};

// GET Method Request for Environment Type Combo
async function getEnvironmentTypeCombo(){
  try {
    const response = await axios.get(`environment-types/`, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};

// GET Method Request for Operation Type Combo
async function getOperationTypeCombo(){
  try {
    const response = await axios.get(`operation-types/`, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};


// GET Method Request for Times Step Profile - Combo
async function getTimeStepProfileCombo(){
  try {
    const response = await axios.get(`timesteps/`, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};



export {
  getAllSimulations,
  createSimulationRequest,
  getOneSimulation,
  updateSimulationRequest,
  cancelOneSimulation,
  getSimulationModeCombo,
  getTimeStepProfileCombo,
  getEnvironmentTypeCombo,
  getOperationTypeCombo
};
