  // Function responsible for rounding the values ​​of rheological properties
  export function roundRheoPropsValues(rheoPropsObj) {
    let roundedRheoValuesObj = Object.keys(rheoPropsObj)
    // Remove some keys (name and standard_deviation)
      .filter((key) => key !== "name" && key !== "standard_deviation")
    // Rounding values for each key
      .reduce((obj, key) => {
        obj[key] = String(Math.round(1000 * Number(rheoPropsObj[key])) / 1000);
        return obj;
      }, {});
    return roundedRheoValuesObj;
  }