// Page Title  = responsible for rendering the title of pages and containers

// # Main Import
import React from "react";

// # Import Component Style
import "./pageAndContainerTitle.css";


function PageAndContainerTitle(props) {

  return (
      <h1  data-testid="title_level" className={`title_level_${props.level}`}>{props.text}</h1>
  );
}

export default PageAndContainerTitle;