import React from "react";

// # Import Component Style
import "./editFluidFirstStyle.css";

// # Local Form or Pop-up import
import { FormEditFluid } from "../../components/forms";

function EditFluidFirstPage(props) {
  /* State to control form values  */
  const valuesEditFluid = props.valuesEditFluid;

  /* State to control rheologic checkbox  */
  const isCheckedRheologic = props.isCheckedRheologic;
  const setIsCheckedRheologic = props.setIsCheckedRheologic;
  
  /* State to control Save Button */
  const changedValues = props.changedValues;

  /* OnChange function  */
  const onChange = props.onChange;

  /* State to control displayed Page  */
  const pageState = props.pageState
  const setPage = props.setPage;

  /* Functions to control Modal and Update Fluid  */
  const closeModal = props.closeModal;
  const updateFluid = props.updateFluid;

  // Page Title
  const editFluidTitle = props.editFluidTitle;

  // Data for Create Rows function and UpdateRows
  const rheometryProps = props.rheometryProps;

  // Case ID
  const caseId = props.caseId;


  return (
    <div className="first-modal__container-fluids">
      <FormEditFluid
        editFluidTitle={editFluidTitle}        
        values={valuesEditFluid}
        isCheckedRheologic={isCheckedRheologic}
        setIsCheckedRheologic={setIsCheckedRheologic}
        changedValues={changedValues}
        updateFluid={updateFluid}
        closeModal={closeModal}
        onChange={onChange}
        pageState={pageState}
        setPage={setPage}
        rheometryProps={rheometryProps}
        caseId={caseId}
      />
    </div>
  );
}

export default EditFluidFirstPage;
