// Main import of React
import React, { createContext, useState } from "react";

// Create Context Object
const ProjectAndCasesContext = createContext();

// Hook
export default function useProjectAndCases() {
  const [projectById, setProjectById] = useState([]);

  const [isCaseById, setIsCaseById] = useState([]);

  return {
    projectById,
    setProjectById,
    isCaseById,
    setIsCaseById,
  };
}

// Specific Context Provider
function ProjectAndCasesProvider({ children }) {
  const { projectById, setProjectById, isCaseById, setIsCaseById } =
    useProjectAndCases();

  return (
    <ProjectAndCasesContext.Provider
      value={{
        projectById,
        setProjectById,
        isCaseById,
        setIsCaseById,
      }}
    >
      {children}
    </ProjectAndCasesContext.Provider>
  );
}

export { ProjectAndCasesContext, ProjectAndCasesProvider };
