// Main imports
import { darkColors, lightColors } from "../common";

// Function responsible for render action buttom (FAB) considering parameters 
export default function returnActionBttn(
  updatedJson,
  tags,
  rows,
  history,
  isSelectAllChecked,
  countSelectRows,
  addTableRows,
  duplicateRow,
  deleteTableRows,
  setIsModalOpen,
  isChecked = null
) {
  let actionObj = {
    [!isSelectAllChecked && !countSelectRows  && tags !== "fluids"]: [
      {
        tooltip: "Adicionar nova linha",
        icon: "fas fa-plus",
        key: "action1",
        callback: addTableRows,
        backgroundColor: darkColors.black,
        color: lightColors.white,
      },
    ],
    [!isSelectAllChecked && !countSelectRows  && tags === "fluids"]: [],

    [(isSelectAllChecked || countSelectRows === 1) && tags === "fluids"]: [
        {
          tooltip: "Duplicar",
          icon: "fas fa-copy",
          key: "action1",
          duplicateRow: duplicateRow,
          backgroundColor: darkColors.blue,
          color: lightColors.white,
        },
        {
          tooltip: "Deletar",
          icon: "fas fa-trash",
          key: "action0",
          deleteTableRows: deleteTableRows,
          backgroundColor: darkColors.red,
          color: lightColors.white,
        },
        {
          tooltip: "Editar",
          icon: "fas fa-edit",
          key: "action2",
          url: history.location.pathname,
          setIsModalOpen: setIsModalOpen,
          backgroundColor: darkColors.yellow,
          color: lightColors.white,
        },
      ],

      [(isSelectAllChecked || countSelectRows > 1) && tags === "fluids"]: [
        {
          tooltip: "Deletar",
          icon: "fas fa-trash",
          key: "action0",
          deleteTableRows: deleteTableRows,
          backgroundColor: darkColors.red,
          color: lightColors.white,
        },
      ],

    [(isSelectAllChecked || countSelectRows) && tags !== "fluids"]: [
      {
        tooltip: "Adicionar nova linha",
        icon: "fas fa-plus",
        key: "action1",
        callback: addTableRows,
        backgroundColor: darkColors.black,
        color: lightColors.white,
      },
      {
        tooltip: "Deletar",
        icon: "fas fa-trash",
        key: "action0",

        deleteTableRows: deleteTableRows,
        backgroundColor: darkColors.red,
        color: lightColors.white,
      },
      {
        tooltip: "Duplicar",
        icon: "fas fa-copy",
        key: "action1",
        duplicateRow: duplicateRow,
        backgroundColor: darkColors.blue,
        color: lightColors.white,
      },
    ],
   
    [!countSelectRows && rows.length === 1 && tags === "fluids"]: [],
    [countSelectRows && rows.length === 1 && tags === "fluids"]: [
        {
          tooltip: "Duplicar",
          icon: "fas fa-copy",
          key: "action1",
          duplicateRow: duplicateRow,
          backgroundColor: darkColors.blue,
          color: lightColors.white,
        },
        {
          tooltip: "Editar",
          icon: "fas fa-edit",
          key: "action2",
          url: history.location.pathname,
          setIsModalOpen: setIsModalOpen,
          backgroundColor: darkColors.yellow,
          color: lightColors.white,
        },
      ],
      [!isChecked && tags === "rheologic_data"]: [],
      [!isChecked && tags === "rheometer_readings"]: [],
      [isChecked && tags === "rheologic_data" && countSelectRows === 0 ]: [],
      [Object.keys(updatedJson).includes("rheometer_readings") && tags === "rheologic_data" ]: [ {
        tooltip: "Para duplicar/deletar alguma linha do grid, salve as modificações anteriores",
        icon: "fas fa-exclamation",
        key: "action0",

        deleteTableRows: "",
        backgroundColor: darkColors.red,
        color: lightColors.white,
      }],
      [ isChecked && Object.keys(updatedJson).includes("rheometer_readings") && tags === "rheologic_data" && (countSelectRows > 1 || isSelectAllChecked)   ]: [ {
        tooltip: "Para duplicar/deletar alguma linha do grid, salve as modificações anteriores",
        icon: "fas fa-exclamation",
        key: "action0",

        deleteTableRows: "",
        backgroundColor: darkColors.red,
        color: lightColors.white,
      }],
      
      [isChecked && tags === "rheologic_data" && (countSelectRows === 1 || isSelectAllChecked) && !Object.keys(updatedJson).includes("rheometer_readings")]: [ 
        {
        tooltip: "Deletar",
        icon: "fas fa-trash",
        key: "action0",

        deleteTableRows: deleteTableRows,
        backgroundColor: darkColors.red,
        color: lightColors.white,
      },
      {
        tooltip: "Duplicar",
        icon: "fas fa-copy",
        key: "action1",
        duplicateRow: duplicateRow,
        backgroundColor: darkColors.blue,
        color: lightColors.white,
      }
    ],
    [isChecked && tags === "rheologic_data" && (countSelectRows > 1 || isSelectAllChecked) && !Object.keys(updatedJson).includes("rheometer_readings") ]: []

    
  };
  return actionObj;
}
