// #  Main Imports
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

// #  Local SubComponents & utils
import { FormInput, StyledButton, PageAndContainerTitle } from "../../common";
import { createCaseByIdProjectRequest } from "../../../services/CasesRequests";
import { updateLockCase } from "../../../services/CasesRequests";

// Contexts
import { ToastContext } from "../../contexts/ToastContext";

// # Import Component Style
import "./FormNewCase.css";

const FormNewCase = (props) => {
  const history = useHistory();

  // Toast Context
  const { toastList, setToastList, showToast } = useContext(ToastContext);

  const [values, setValues] = useState({
    name: "",
    description: "",
    case_fluid: null,
    /* TODO: user_id - Petrobras */
  });

  const [onClick, setOnClick] = useState(false);

  const projectID = history.location.pathname.split("/");

  const inputsNewCase = [
    {
      id: 1,
      name: "name",
      type: "textarea",
      placeholder: "Digite um nome para o novo caso",
      errorMessage: "Este campo deve conter 3 a 150 caracteres!",
      label: "Nome",
      maxlength: "150",
      required: true,
      pattern: "^[a-zA-Z0-9_ ]{3,149}$",
      rows: 2,
      cols: 18,
    },
    {
      id: 2,
      name: "description",
      type: "textarea",
      placeholder: "Preencha uma descrição para o novo caso",
      errorMessage: "Este campo deve conter 3 a 500 caracteres!",
      label: "Descrição",
      maxlength: "500",
      pattern: "^[a-zA-Z0-9_ ]{3,499}$",
      required: true,
      rows: 8,
      cols: 18,
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (values.name.trim().length >= 3 && values.description.trim().length >= 3) {
      setOnClick(true);
      const response = await createCaseByIdProjectRequest(projectID[2], values);
      showToast(response, toastList, setToastList);
      if(response !== undefined) {
        await updateLockCase(response.data.id, { locked: true });
        history.push(`/case/${response.data.id}/general_info`);
          setTimeout(function () {
            window.location.reload(true);
          }, 90);
      }
    } else {
      setOnClick(false);
    }
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const redirectToCases = () => {
    history.push(`/project/${projectID[2]}/cases`);
  };

  // Buttons to render using StyledButton component
  const bttnsForNewCase = [
    {
      type: "button",
      callback: redirectToCases,
      color: "#c62828",
      icon: "fas fa-times",
      text: "Cancelar",
    },
    {
      type: "submit",
      callback: handleSubmit,
      color: onClick ?  "#a59c9cfd": "#1b5e20",
      icon: "fas fa-save",
      disabled: onClick ?  true: false,
      text: onClick ? "Criando..."
      : "Salvar",
    },
  ];

  return (
    <div className="container-form-new-case">
      <PageAndContainerTitle text="Novo Caso" level="3_forms" />
      <form className="form-new-case">
        {inputsNewCase.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={values[input.name]}
            onChange={onChange}
          />
        ))}

        <div className="bttn-new-case__container">
          {bttnsForNewCase.map((item, index) => (
            <StyledButton data={item} key={index} />
          ))}
        </div>
      </form>
    </div>
  );
};

export default FormNewCase;
