// ActionFabGrid = Component responsible for controlling the ExpandableGrid and FAB

// #  Main Imports
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";

// #  Local SubComponents & utils
import { FullyEditableGrid, FAB } from "../index";
import { returnActionBttn } from "../../utils";

import { HistoryContext } from "../../contexts/HistoryContext";
import { UpdatedJsonContext } from "../../contexts/UpdatedJsonContext";

// # Import Component Style
import { darkColors, lightColors } from "../MaterialColors";
import "./actionFabEditableGrid.css";

function ActionFabEditableGrid(props) {
  const history = useHistory();
  
  // Query params of route
  const caseMode = history.location.search.includes("?view=true");

 // ViewBttnOnClick function - active rheologic data
  const viewBttnOnClick = props.viewBttnOnClick

  // Case ID number
  const caseID = history.location.pathname.split("/")[2];

  // State to set History Location Pathname and possible changes at onChange function */
  const { setHasChange } = useContext(HistoryContext);

  // ActiveRowId for ActionFabGrid - CheckboxAction
  const [activeRowId, setActiveRowId] = useState("");

  // Updated Grid Rows and Primary Tags
  const {
    updatedGridRows,
    updatedJson,
    setUpdatedJson,
    setTag,
    changedGrid,
    setChangedGrid,
    rowEid,
    setEid,
    setRowEid
  } = useContext(UpdatedJsonContext);

  // Eid - Rheologic Data Item
  const eid = props.eid;

  // Defining height for Grid Fab Container
  const gridContainerHeight = props.gridContainerHeight;

  // Primary Tags and TagsItems
  const tags = props.tags;

  const tagsItems = props.tagsItems;

  // States to control checkbox - Rotation, Reciprocation, Rheometry */
  const isChecked = props.isChecked;

  // Callback to open Modal - Edit Fluid or Delete a Row
  const setIsModalOpen = props.setIsModalOpen;

  // Functions to Add, Delete or Duplicates Rows
  const addTableRows = props.addTableRows;
  const deleteTableRows = props.deleteTableRows;
  const duplicateRow = props.duplicateRow;

  // Data to populate the Header of Grid
  const metaData = props.metaData;

  // States to control Checkbox Header
  const [isSelectAllChecked, setSelectAllChecked] = useState(false);

  // Data for count select rows
  const [countSelectRows, setCountSelectRows] = useState("");

  const [dataJson, setDataJson] = useState(props.dataJson);

  // Data to populate the Rows
  const rows = props.rows;
  const setRows = props.setRows;

  // First Line Dependecy Structure
  let dependencyStructure = props.dependencyStructure;


  // Variable to store the value of the tag that will be used when adjusting the FAB css
  const cssAdjustedFab = tags;

  function compareRows() {
    if (
      //If something changes in the Grid
      changedGrid
    ) {
      setHasChange(true);
    } else {
      setHasChange(false);
    }
  }
  function onChanged() {
      setDataJson(updatedGridRows(tagsItems, dataJson, rows));
      setUpdatedJson({
        ...updatedJson,
        [tags]: updatedGridRows(tagsItems, dataJson, rows),
      });

      
      setChangedGrid(!changedGrid);
    }

   
  useEffect(() => {
    /*     Function responsible for counting the selected lines */
    function countSelect() {
      const count = rows.filter((item) => item.selected === true);
      setCountSelectRows(count.length);
    }
    countSelect();
    controlsActionButton();
    compareRows();
    setTag(tags);
    // Eid - Rheologic Data Item
    setEid(eid);
    if (changedGrid) {
      onChanged();
    }
  }, [rows]);


  /* Function responsible for controlling the rendering of the FAB */
  function controlsActionButton() {
      let actionData = {
        actionButtom: {
          tootip: "Grupo de Ações",
          icon: "fas fa-plus",
          rotate: true,
          backgroundColor: darkColors.blue,
          color: lightColors.white,
          /* Direction: Array responsible for determining the opening position of the button */
          direction: ["fab-container-left"],
        },
        actions: returnActionBttn(updatedJson,tags,rows,history,isSelectAllChecked,countSelectRows,addTableRows,duplicateRow, deleteTableRows, setIsModalOpen, isChecked)[true]
      };
    
    return actionData;
  }
  return (
    <div
      className="grid-fab__container"
      style={{ height: gridContainerHeight }}
    >
      <FullyEditableGrid
        metaData={metaData}
        setRows={setRows}
        rows={rows}
        isSelectAllChecked={isSelectAllChecked}
        setSelectAllChecked={setSelectAllChecked}
        setActiveRowId={setActiveRowId}
        isChecked={isChecked}
        dependencyStructure={dependencyStructure}
        setIsModalOpen={setIsModalOpen}
        setRowEid={setRowEid}
        disabled={caseMode}
        viewBttnOnClick={viewBttnOnClick}
      />
      {!caseMode && (
      <FAB
        data={controlsActionButton()}
        metaData={metaData}
        activeRowId={activeRowId}
        setChangedGrid={setChangedGrid}
        rows={rows}
        setRows={setRows}
        caseID={caseID}
        rowEid={rowEid}
        cssAdjustedFab = {cssAdjustedFab}
        setSelectAllChecked={setSelectAllChecked}
        tags={tags}
      />
      )}
    </div>
  );
}

export default ActionFabEditableGrid;
