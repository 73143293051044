// Main import
import React from "react";

// Style
import "./ProgressBar.css";

const ProgressBar = (props) => {
  const { completed } = props;

  return (
    <div className="container__bar">
      <span className="progress-text">{`${completed}%`}</span>
      <div
        style={{ width: `${completed}%` }}
        data-testid="progressbar-container"
        className="progressbar-container"
      ></div>
    </div>
  );
};

export default ProgressBar;
