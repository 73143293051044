// Import axios from specific API definition
import axios from "./apis/i3d_MongoAPI";

// Import Authorization Header
import { authorizationHeader } from "./LoginServices";

// PUT Method Request to update Tag Items By eid - Mongo data
async function updateTagByCaseId(id, tag, data) {
  try {
    const response = await axios.put(`/${id}/tag/`, JSON.stringify(data), {
      headers: {
        Authorization: authorizationHeader,
      },
      params: {
        in_tag: tag,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}

// GET Method Request to update one Tag By Case Id - Mongo data
async function getOneTagByCaseId(id, tag) {
  try {
    const response = await axios.get(`/${id}/tag/`, {
      headers: {
        Authorization: authorizationHeader,
      },
      params: {
        in_tag: tag,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}

// GET Method Request to get one Tag By Case Id and Eid - Mongo data
async function getOneTagByCaseIdAndEid(id, tag, eid) {
  try {
    const response = await axios.get(`/${id}/tag/`, {
      headers: {
        Authorization: authorizationHeader,
      },
      params: {
        in_tag: tag,
        eid: eid
      },
    });
    return response.data.data;
  } catch (error) {
    console.log(error.message);
  }
}

//DELETE Method Request to delete one Tag By Case Id
async function deleteTagByCaseId(id, tag, data) {
  try {
    const response = await axios.delete(`/${id}/tag/`, {
      headers: {
        Authorization: authorizationHeader,
      },
      params: {
        in_tag: tag,
      },
      data,
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}

export {
  updateTagByCaseId,
  getOneTagByCaseId,
  getOneTagByCaseIdAndEid,
  deleteTagByCaseId,
};
