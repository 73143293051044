// FixedInput = input with number of decimal places

// # Main Import
import React, {useState} from "react";

// Utils
import { treatInteger } from "../../utils";

// # Import Component Style
import "./FixedInput.css";


function FixedInput(props) {

  const decimalNum = props.decimalNum


  const [showValue, setShowValue] = useState(treatInteger(props.value,decimalNum));

  function handleBlur(event) {
    setShowValue(treatInteger(event.target.placeholder, decimalNum));
  }

  function handleFocus(event) {
    event.target.value = event.target.placeholder;
    setShowValue(event.target.value);
  }

  function allowOnlyPositiveNumberAndDot(value) {
    return /^\d*\.?\d*$/.test(value);
  }

  function handleChange(event) {
    if (allowOnlyPositiveNumberAndDot(event.target.value)) {
      setShowValue(event.target.value)
      props.onChange(event);
    }
  }

  return (
    <input
      className={props.className}
      type={props.type}
      min={props.min ? props.min : ""}
      max={props.max ? props.max : ""}
      pattern={props.pattern ? props.pattern : ""}
      value={treatInteger(props.value, decimalNum) === showValue ? showValue: props.value}
      placeholder={props.value}
      onBlur={handleBlur}
  /*     onFocus={handleFocus} */
      disabled={props.disabled}
      onChange={handleChange}
      step="any" 
    />
  );
}
export default FixedInput;
