// Import axios from specific API definition
import axios from "./apis/i3d_sqlAPI";

// Import Authorization Header
import {authorizationHeader} from "./LoginServices";

async function downloadFile(id) {
    
    const downloadUrl = `download/${id}/`;  // API Route to download File
  
    axios.get(downloadUrl, {
      
      headers: {
        Authorization: authorizationHeader,
      },
      responseType: 'blob',  // Define type response
    })
      .then(response => {
        const url = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${id}.pdf`;
        link.click();
      })
      .catch(error => {
        console.error('Erro durante o download:', error);
      });
  }

  async function downloadPco(id) {
      // Chama a API para iniciar a geração do arquivo
      const response = await axios.get(`export-pco/${id}/`, {
        headers: {
          Authorization: authorizationHeader,
        },
        responseType: 'blob',  // Indica que a resposta é um blob (arquivo binário)
      }).then(response=>{
        const url = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${id}.pco`;
        link.click();
      }).catch (error => {
      console.error('Erro durante o download:', error);
    })
  }
  
  export{downloadFile,downloadPco}