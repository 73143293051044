// CheckboxActionIcon = Component to render the checkboxes

// Main import
import { useContext } from "react";

// # Import Component Style
import "./CheckboxActionIcon.css";

// Context
import { UpdatedJsonContext } from "../../contexts/UpdatedJsonContext";

function CheckboxActionIcon(props) {
  const rows = props.rows;
  const setRows = props.setRows;

  const rowSelected = props.rowSelected;
  const rowID = props.rowID;

  // States to save fluids Eids list and Rheologic Eid - Pumping Sequence
  const { setRowEid, setRheoEid } = useContext(UpdatedJsonContext);

  // ActiveRowId for ActionFabGrid - CheckboxAction
  const setActiveRowId = props.setActiveRowId;

  // Function that save only Eids for selected rows - Fluids and Rheologic grids
  function saveSelectedRows(updatedList) {
    // Defining an array of selected eids
    let selectedEids = updatedList
      .filter((item) => {
        if (item.selected === true) {
          setActiveRowId(item.id);
          setRheoEid(item.eid)
        }
        return item.selected === true;
      })
      .map((item) => item.eid);

    // Defining an object of selected eids
    let listEids = selectedEids.map((o) => ({ eid: o }));
    setRowEid(listEids);
  }

  /*  Function that controls the checkbox(id) (Selected/ Unselected) and change the state of the selected line */
  function handleClickChecked(id) {
    let updatedList = rows.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    // Function that save only Eids for selected rows - Fluids and Rheologic grids
    saveSelectedRows(updatedList, rows);

    setRows(updatedList);
  }

  return (
    <input
      type="checkbox"
      className="input-radio"
      checked={rowSelected}
      onClick={() => handleClickChecked(rowID)}
      data-testid="input-radio-test"
    />
  );
}

export default CheckboxActionIcon;
