// Color Picker Edit = div with input color and text (responsible for change fluid color)

// # Main Import
import React from "react";

// # Import Component Style
import "./colorPickerEdit.css";


function ColorPickerEdit(props) {

  return (
    <div className="color-piker-edit__container">
      <input name="color" type="color" className="input-color" {...props} />
      <input name="color" type="text"  className="input-text" {...props} />
    </div>
  );
}

export default ColorPickerEdit;