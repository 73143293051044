// #  Main Imports
import { useState, useContext } from "react";
import { useHistory } from "react-router";

// #  Local SubComponents & utils
import {
  FormInput,
  StyledButton,
  FormContainer,
  PageAndContainerTitle,
  ColorPickerEdit,
  CheckboxAndBttn,
} from "../../common";
import RheologicModel from "../../fluids/rheologicModel/RheologicModel";

// Context
import { UpdatedJsonContext } from "../../contexts/UpdatedJsonContext";
import { ControlDensityContext } from "../../contexts/ControlDensityGridContext";
import { VariableRheologyProvider } from "../../contexts/VariableRheologyContext";


// Hook
import useDensityProfileValidation from "../../../hooks/useDensityProfileValidation";
import useRheologicalParametersValidation from "../../../hooks/useRheologicalParametersValidation";

// # Import Component Style
import "./FormEditFluid.css";

// Form Declaration
function FormEditFluid(props) {
  // Page Title
  const editFluidTitle = props.editFluidTitle;
  // Function to validate the inputs of density_profile
  const { validateDensityProfile, validateThirdInputSection } =
    useDensityProfileValidation();

  // Function to validate rheological parameters
  const {validateRheologicalParameters } = useRheologicalParametersValidation()
  // Form Props
  const values = props.values;

  // Hook gives access to the history instance
  const history = useHistory();
  
  // OnChange function
  const onChange = props.onChange;

  // State to control Save Button
  const changedValues = props.changedValues;

  // Update Rows and Tags
  const { updatedJson } = useContext(UpdatedJsonContext);


  // Control Density Context
  const { rowsDensity } = useContext(ControlDensityContext);

  // States to control CheckboxAndBttn component */
  const [isCheckedDensity, setIsCheckedDensity] = useState(
    values["fluid_element_item"]["density"].density_dependence !== "constant"
  );

  // Data for Create Rows function and UpdateRows
  const rheometryProps = props.rheometryProps;


  const isCheckedRheologic = props.isCheckedRheologic;
  const setIsCheckedRheologic = props.setIsCheckedRheologic;

  /* State to control displayed Page  */
  const pageState = props.pageState
  const setPage = props.setPage;

  /* Functions to control Modal and Update Fluid  */
  const updateFluid = props.updateFluid;
  const closeModal = props.closeModal;

  // Variable to store the value of density_dependence
  const densityDependence =
    values["fluid_element_item"]["density"].density_dependence;

  // Variable to store the value of density_value
  const densityValue = values["fluid_element_item"]["density"].density_value;

  // Case ID
  const caseId = props.caseId;

  // Case mode
  const caseMode = history.location.search.includes("?view=true");

  // Input Sections Metadata
  const firstInputSection = [
    {
      id: 1,
      name: "fluid_name",
      value: values["fluid_element_item"]["fluid_name"],
      type: "text",
      placeholder: "",
      errorMessage: "Campo obrigatório",
      disabled: false,
      label: "Nome do Fluido",
      pattern: "^[a-zA-Z0-9_ ]{3,149}$",
      required: true,
    },
    {
      id: 2,
      name: "group_type",
      value: values["fluid_element_item"]["group_type"]["eid"],
      type: "select",
      placeholder: "Grupo",
      options: [
        {
          label: "Perfuração",
          value: "DRILLING",
        },

        {
          label: "Cimentação",
          value: "CEMENTING_FLUID",
        },
        {
          label: "Tamponamento",
          value: "CAPPING",
        },
      ],
      required: true,
    },
  ];

  const thirdInputSection = [
    {
      id: 3,
      name: "density_value",
      value:
        densityDependence === "constant" ||
        densityDependence === "temperature-profile"
          ? densityValue
          : "",
      type: "number",
      disabled: isCheckedDensity,
      label: "Densidade (lb/gal)",
      required: true,
    },
  ];
  const fourthInputSection= [
    {
      id: 4,
      name: "current_rheologic_model_id",
      type: "select",
      value: values["fluid_element_item"]["current_rheologic_model_id"].eid,
      options: [
        {
          label: "Newtoniano",
          value: "newtonian_model_subject",
        },
        {
          label: "Power-law",
          value: "powerlaw_model_subject",
        },
        {
          label: "Bingham",
          value: "bingham_model_subject",
        },
        {
          label: "Herschel-Bulkley",
          value: "herschel_bulkley_model_subject",
        },
      ],
      required: true,
    },
  ];
  

  // Function to call validation thirdInputSection, grid density_profile and rheological parameters
  const checkInputValidation = () => {
    if (!validateThirdInputSection(isCheckedDensity, values)) {
      return;
    }
    if (isCheckedDensity) {
      if (!validateDensityProfile(rowsDensity)) {
        return;
      }
    }
    if(!validateRheologicalParameters(values["fluid_element_item"]["rheologic_data"][0]["rheologic_data_item"],values["fluid_element_item"]["current_rheologic_model_id"].eid)){
      return;
    }
      // Function to save data
      updateFluid();

  };

  // Buttons to render using StyledButton component
  const bttns = [
    {
      type: "button",
      callback: closeModal,
      color: "#c62828",
      icon: Object.keys(updatedJson).includes("rheologic_data") || Object.keys(updatedJson).includes("rheometer_readings") || changedValues
      ? "fas fa-times": "fas fa-sign-out-alt",
      text:  Object.keys(updatedJson).includes("rheologic_data") || Object.keys(updatedJson).includes("rheometer_readings") || changedValues
      ? "Cancelar" : "Sair",
    },
    {
      type: "button",
      callback: checkInputValidation,
      icon: "fas fa-save",
      color:
      Object.keys(updatedJson).includes("rheologic_data") || Object.keys(updatedJson).includes("rheometer_readings") || changedValues
          ? "#1b5e20"
          : "#a59c9cfd",
      disabled:
      Object.keys(updatedJson).includes("rheologic_data") || Object.keys(updatedJson).includes("rheometer_readings")  || changedValues ? false : true,
      text:
      Object.keys(updatedJson).includes("rheologic_data") || Object.keys(updatedJson).includes("rheometer_readings") || changedValues
          ? "Salvar"
          : "Salvo",
      margin_left: "1rem",
    },
  ];

  const handleCheckboxChange = () => {
    // Change the state of isCheckedDensity when the user click in checkbox and value to "pressure" or "constant"
    setIsCheckedDensity(!isCheckedDensity);
    if (isCheckedDensity) {
      onChange({
        target: {
          name: "density_dependence",
          value: "constant",
        },
      });
    } else {
      onChange({
        target: {
          name: "density_dependence",
          value: "pressure-profile",
        },
      });
    }
  };

  return (
    // Outer Form Container
    <VariableRheologyProvider>
    <FormContainer margin="0">
      <PageAndContainerTitle text={editFluidTitle} level="1" />
      <button onClick={closeModal} className="close-modal-fluid__bttn" >
        <i className="fas fa-times fa-lg" />
      </button>
      {/* Form */}
      <form>
        {/* First Section Form Container */}
        <FormContainer
          mode="grid"
          borderColor="#17365c"
          borderStyle="solid"
          borderWidth="1px"
          borderRadius="0.5rem"
          padding="0 1em"
        >
          <FormContainer mode="space-between">
            {firstInputSection.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={input.value}
                disabled={caseMode}
                onChange={onChange}
                className="first-inputs"
              />
            ))}
            <div>
              <PageAndContainerTitle text="Cor:" level="2" />
              <ColorPickerEdit
                onChange={onChange}
                value={values["fluid_element_item"].color}
              />
            </div>
          </FormContainer>
        </FormContainer>
        {/* Second Section Form Container */}
        <FormContainer
          mode="space-between"
          borderColor="#17365c"
          borderStyle="solid"
          borderWidth="1px"
          borderRadius="0.5rem"
          padding="1em 1em"
        >
          <div>
            {thirdInputSection.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={input.value}
                disabled={caseMode}
                onChange={onChange}
                className="first-inputs"
              />
            ))}
          </div>
          <div>
            <CheckboxAndBttn
              text="Dependência (P/T)"
              icon="fas fa-edit"
              isChecked={
                densityDependence === "constant" ? isCheckedDensity : true
              }
              isCheckedRheologic={isCheckedRheologic}
              setIsChecked={setIsCheckedDensity}
              setPage={setPage}
              pageNumber={1}
              onCheckboxChange={handleCheckboxChange}
              caseMode={caseMode}
            />
          </div>
        </FormContainer>
        {/* Third Section Form Container */}
        <FormContainer
          mode="space-between"
          fitContent="true"
        >
        <PageAndContainerTitle text="Modelo Reológico: " level="2" />
        {fourthInputSection.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={input.value}
            disabled={caseMode}
            onChange={onChange}
            className="first-inputs"
          />
        ))}
         </FormContainer>
        <FormContainer
          mode="grid-center"
          borderColor="#17365c"
          borderStyle="solid"
          borderWidth="1px"
          borderRadius="0.5rem"
          padding="0 1em"
        >

          <RheologicModel  
          values={values}
          onChange={onChange}
          isChecked={isCheckedRheologic}
          setIsChecked={setIsCheckedRheologic}
          rheometryProps={rheometryProps} 
          caseId={caseId}
          pageState={pageState}
          caseMode={caseMode}
          />

        </FormContainer>
        {/* Form Buttons Container */}
        {!caseMode && (
        <FormContainer mode="end" margin="1em 0em 0em 0em">
          {bttns.map((item, index) => (
            <StyledButton data={item} key={index} />
          ))}
        </FormContainer>
        )}
      </form>
    </FormContainer>
    </VariableRheologyProvider>
  );
}

// Export
export default FormEditFluid;
