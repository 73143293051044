// # Main Import
import { useEffect, useContext } from "react";

// #  Local SubComponents & utils
import { FormContainer, FormInput } from "../../common";
import { roundRheoPropsValues } from "../../utils";

// Requests
import { calcRheometryChart } from "../../../services/ChartsRequests";

// Contexts
import { UpdatedJsonContext } from "../../contexts/UpdatedJsonContext";
import { VariableRheologyContext } from "../../contexts/VariableRheologyContext";

// Import Component Style
import "./RheologicModelSelector.css";

export default function RheologicModelSelector(props) {
  // UpdatedJson
  const { updatedJson } = useContext(UpdatedJsonContext);

  // Set state to populate rheometer readings error
  const { setRheoReadingsError } = useContext(VariableRheologyContext);

  // Form Props
  const values = props.values;
  const onChange = props.onChange;

  // Rheometry Json
  const rheometryJson = props.rheometryJson;

  //  Rheologic
  const isChecked = props.isChecked;
  const setIsChecked = props.setIsChecked;

  // Constants to check if values of roation and readings exists
  const checkEmptyReadingsFields = props.checkEmptyReadingsFields;
  const checkEmptyRotationFields = props.checkEmptyRotationFields;

  // ActiveTab
  const activeTab = props.activeTab;

  // Current Rheologic Model
  const currentModel = props.currentModel;

  const useViscosimeterReadings = props.useViscosimeterReadings;

  // Function responsible for reset rheological parameters values
  function resetRheologicProperties(
    rheological_parameters,
    valuesEditFluid,
    currentModel
  ) {
    if (currentModel === "powerlaw") {
      valuesEditFluid["fluid_element_item"]["rheologic_data"][0][
        "rheologic_data_item"
      ].power_law = rheological_parameters;
    } else {
      valuesEditFluid["fluid_element_item"]["rheologic_data"][0][
        "rheologic_data_item"
      ][currentModel] = rheological_parameters;
    }
  }

  // Define useEffect policy
  useEffect(() => {
    if (
      !checkEmptyReadingsFields &&
      !checkEmptyRotationFields &&
      activeTab === "constants" &&
      Object.keys(updatedJson).length > 0
    ) {
      setRheoReadingsError()
      calcRheometryChart(rheometryJson).then((res) => {
        if (Object.keys(res.data).length > 0 && useViscosimeterReadings) {
          resetRheologicProperties(
            res.data.rheological_parameters,
            values,
            currentModel
          );
        } else {
          setRheoReadingsError(res)
        }
      });
    }
  }, [updatedJson, currentModel]);

  // Rheo properties for Power-law, Herschel_bulkley, Bingham  and Newtonian model
  let rheoProps = {
    herschel_bulkley: roundRheoPropsValues(
      values["fluid_element_item"]["rheologic_data"][0]["rheologic_data_item"]
        .herschel_bulkley
    ),
    power_law: roundRheoPropsValues(
      values["fluid_element_item"]["rheologic_data"][0]["rheologic_data_item"]
        .power_law
    ),
    bingham: roundRheoPropsValues(
      values["fluid_element_item"]["rheologic_data"][0]["rheologic_data_item"]
        .bingham
    ),
    newtonian: roundRheoPropsValues(
      values["fluid_element_item"]["rheologic_data"][0]["rheologic_data_item"]
        .bingham
    ),
  };

  const dataInputNewtonian = [
    {
      id: 5,
      name: "viscosity",
      value: rheoProps.newtonian.viscosity,
      type: "number",
      placeholder: "Coeficiente μ",
      errorMessage: "O valor deve ser maior que 0.",
      min: "0",
      max: "> 0",
      disabled: isChecked ? true : false,
      label: "μ (Pa.s)",
      required: true,
    },
  ];

  const dataInputBingham = [
    {
      id: 6,
      name: "plastic_viscosity",
      value: rheoProps.bingham.plastic_viscosity,
      type: "number",
      placeholder: "Coeficiente η",
      errorMessage: "O valor deve ser maior que 0.",
      min: "0",
      max: "> 0",
      disabled: isChecked ? true : false,
      label: "η (cP)",
      required: true,
    },
    {
      id: 7,
      name: "tau_y",
      value: rheoProps.bingham.tau_y,
      type: "number",
      placeholder: "Coeficiente τy",
      errorMessage: "O valor deve ser maior que 0.",
      min: "0",
      max: "> 0",
      disabled: isChecked ? true : false,
      label: "Limite de escoamento (lbf/100ft²)",
      required: true,
    },
  ];

  const dataInputPowerLaw = [
    {
      id: 8,
      name: "k",
      value: rheoProps.power_law.k,
      type: "number",
      placeholder: "Coeficiente k",
      errorMessage: "O valor deve ser maior que 0.",
      min: "0",
      max: "> 0",
      disabled: isChecked ? true : false,
      label: "k (lbf.s^n/100ft²)",
      required: true,
    },
    {
      id: 9,
      name: "n",
      value: rheoProps.power_law.n,
      type: "number",
      placeholder: "Coeficiente n",
      errorMessage: "O valor deve ser maior que 0.",
      min: "0",
      max: "> 0",
      disabled: isChecked ? true : false,
      label: "n",
      required: true,
    },
  ];

  const dataInputHerschelBulkley = [
    {
      id: 10,
      name: "tau_y",
      value: rheoProps.herschel_bulkley.tau_y,
      type: "number",
      placeholder: "Coeficiente τy",
      errorMessage: "O valor deve ser maior que 0.",
      min: "0",
      max: "> 0",
      disabled: isChecked ? true : false,
      label: "Limite de escoamento (lbf/100ft²)",
      required: true,
    },
    {
      id: 11,
      name: "k",
      value: rheoProps.herschel_bulkley.k,
      type: "number",
      placeholder: "Coeficente k",
      errorMessage: "O valor deve ser maior que 0.",
      min: "0",
      max: "> 0",
      disabled: isChecked ? true : false,
      label: "k (lbf.s^n/100ft²)",
      required: true,
    },
    {
      id: 12,
      name: "n",
      value: rheoProps.herschel_bulkley.n,
      type: "number",
      placeholder: "Coeficiente n",
      errorMessage: "O valor deve ser maior que 0.",
      disabled: isChecked ? true : false,
      min: "0",
      max: "> 0",
      label: "n",
      required: true,
    },
  ];

  // Handle checkbox function
  function handleCheckboxChange() {
    // Change the state of isCheckedRheologic when the user click in checkbox and value to "true" or "false" (use_viscosimeter_readings)
    setIsChecked(!isChecked);
    onChange({
      target: {
        name: "use_viscosimeter_readings",
        value: !isChecked,
      },
    });
  }

  return (
    <div className="rheologic-property__container">
      <FormContainer mode="grid" fitContent="true">
        {/* {fifthInputSection.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={input.value}
            onChange={onChange}
            className="first-inputs"
          />
        ))}
 */}
        {(() => {
          if (
            values["fluid_element_item"]["current_rheologic_model_id"].eid ===
            "newtonian_model_subject"
          ) {
            return (
              <FormContainer mode="grid" fitContent="true">
                {dataInputNewtonian.map((input) => (
                  <FormInput
                    key={input.id}
                    {...input}
                    value={input.value}
                    onChange={onChange}
                    className="first-inputs"
                  />
                ))}
              </FormContainer>
            );
          } else if (
            values["fluid_element_item"]["current_rheologic_model_id"].eid ===
            "powerlaw_model_subject"
          ) {
            return (
              <FormContainer mode="grid">
                {dataInputPowerLaw.map((input) => (
                  <FormInput
                    key={input.id}
                    {...input}
                    value={input.value}
                    onChange={onChange}
                    className="first-inputs"
                  />
                ))}
              </FormContainer>
            );
          } else if (
            values["fluid_element_item"]["current_rheologic_model_id"].eid ===
            "bingham_model_subject"
          ) {
            return (
              <FormContainer mode="grid">
                {dataInputBingham.map((input) => (
                  <FormInput
                    key={input.id}
                    {...input}
                    value={input.value}
                    onChange={onChange}
                    className="first-inputs"
                  />
                ))}
              </FormContainer>
            );
          } else {
            return (
              <FormContainer mode="grid" fitContent="true">
                {dataInputHerschelBulkley.map((input) => (
                  <FormInput
                    key={input.id}
                    {...input}
                    value={input.value}
                    onChange={onChange}
                    className="first-inputs"
                  />
                ))}
              </FormContainer>
            );
          }
        })()}
        {/*  <div className="checkbox-bttn__container">
          <input
            type="checkbox"
            className="input-fluid"
            checked={isChecked}
            onClick={handleCheckboxChange}
          />
          <PageAndContainerTitle text={"Usar medidas de reometria"} level="2" />
        </div> */}
      </FormContainer>
    </div>
  );
}
