//Toast possible alert types
// Function to check API data response and throw a Toast alert
let toastProperties = null;
function showToast(data, toastList, setToastList) {

  if(!data || (typeof data === 'string' && data.includes("Proxy error"))) {
    toastProperties = {
      id: toastList.length + 1,
      title: "Falha na comunicação com a API",
      description: "Servidor fora do ar. Tente novamente",
      backgroundColor: "#f95a25",
    };
    setToastList([...toastList, toastProperties]);
  }
  else {

    switch (data.message.type) {
      case "SCS":
        toastProperties = {
          id: toastList.length + 1,
          title: data.message.title,
          description: data.message.description,
          backgroundColor: "#5cb85c",
        };
        break;
      case "ERR":
        toastProperties = {
          id: toastList.length + 1,
          title: data.message.title,
          description: data.message.description,
          backgroundColor: "#d9534f",
        };
        break;
      case "ALT":
        toastProperties = {
          id: toastList.length + 1,
          title: data.message.title,
          description: data.message.description,
          backgroundColor: "#f9a825",
        };
        break;
      case "warning":
        toastProperties = {
          id: toastList.length + 1,
          title: data.title,
          description: data.description,
          backgroundColor: "#f0ad4e",
        };
    
        break;
  
  
      default:
        toastProperties = [];
    }
    setToastList([...toastList, toastProperties]);
  }
}

export { showToast };
