// Generic Card = Card with a Title receiving Children Components (CardCases and CardProjects)
// # Main Import
import React from "react";

// # Import Component Style
import "./Card.css";

function Card(props) {
  const data = props.data;

  return (
      <div  data-testid="card__container" className={ data.model === "projects.project" ? "card-projects" : "card-cases" }>
        <h2 className="card-title">{data.fields.name}</h2>
        {props.children}
      </div>
  );
}

export default Card;
