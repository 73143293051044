export const pumpSequenceRoutes = [
  "/case/:id/pumpsequence/fluids",
  "/case/:id/pumpsequence/well-fluid",
  "/case/:id/pumpsequence/pumped-sequence",
];
export const thermalProfileRoutes = [
  "/case/:id/thermal_profile/surface-temperature",
  "/case/:id/thermal_profile/geothermal-gradient",
];

export const simulationRoutes = [
  "/case/:id/simulation/partial-simulation",
  "/case/:id/simulation/boundary-conditions",
  "/case/:id/simulation/column-movement",
  "/case/:id/simulation/advanced-params",
];

export const geometryRoutes = [
  "/case/:id/geometry/external-geometry",
  "/case/:id/geometry/internal-geometry",
  "/case/:id/geometry/centralization",
  "/case/:id/geometry/trajectory",
];

export const tutorialRoutes = [
  "/home/tutorials",
  "/sobre/tutorials",
  "/well/:id/projects/tutorials",
  "/well/:id/new_project/tutorials",
  "/project/:id/cases/tutorials",
  "/project/:id/new_case/tutorials",
  "/case/:id/general_info/tutorials",
  "/case/:id/scenery/tutorials",
  "/case/:id/pumpsequence/fluids/tutorials",
  "/case/:id/pumpsequence/pumped-sequence/tutorials",
  "/case/:id/thermal_profile/surface-temperature/tutorials",
  "/case/:id/thermal_profile/geothermal-gradient/tutorials",
  "/case/:id/geometry/external-geometry/tutorials",
  "/case/:id/geometry/internal-geometry/tutorials",
  "/case/:id/geometry/centralization/tutorials",
  "/case/:id/geometry/trajectory/tutorials",
  "/case/:id/simulation/tutorials",
];

export const notificationRoutes = [
  "/home/notifications",
  "/sobre/notifications",
  "/well/:id/projects/notifications",
  "/well/:id/new_project/notifications",
  "/project/:id/cases/notifications",
  "/project/:id/new_case/notifications",
  "/case/:id/general_info/notifications",
  "/case/:id/scenery/notifications",
  "/case/:id/pumpsequence/fluids/notifications",
  "/case/:id/pumpsequence/pumped-sequence/notifications",
  "/case/:id/thermal_profile/surface-temperature/notifications",
  "/case/:id/thermal_profile/geothermal-gradient/notifications",
  "/case/:id/geometry/external-geometry/notifications",
  "/case/:id/geometry/internal-geometry/notifications",
  "/case/:id/geometry/centralization/notifications",
  "/case/:id/geometry/trajectory/notifications",
  "/case/:id/simulation/notifications",
];
