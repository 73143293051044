// #  Main Imports
import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useQuery } from "react-query";

// # Requests
import { getCaseAndCasePropsByIdRequest } from "../../services/CasesRequests";
import { getOneTagByCaseIdAndEid} from "../../services/TagsRequests";

// #  Local SubComponents, utils and data
import EditFluidFirstPage from "./editFluidFirstPage";
import EditFluidSecondPage from "./editFluidSecondPage";
import EditFluidThirdPage from "./editFluidThirdPage";
import { mergedObject, createRows} from "../../components/utils";
import { headersRheometry } from "../../data/headersForGrids";

// # Contexts
import { ModalContext } from "../../components/contexts/ModalContext";
import { ToastContext } from "../../components/contexts/ToastContext";
import { HandleErrorContext } from "../../components/contexts/HandleErrorContext";
import { UpdatedJsonContext } from "../../components/contexts/UpdatedJsonContext";
import { VariableRheologyContext } from "../../components/contexts/VariableRheologyContext";

// # Requests
import { updateFluidsIdRequest } from "../../services/FluidsRequest";

// # Provider
import { ControlDensityProvider } from "../../components/contexts/ControlDensityGridContext";

function EditFluid() {

  // Using for redirect EditFluid page to Fluids and to get the correct Case ID
  const history = useHistory();

  // Using to get the correct Fluid ID using params (URL)
  const { id } = useParams();

  // Case ID
  const caseId = history.location.pathname.split("/")[2];

  // Handle Error Context
  const { setError } = useContext(HandleErrorContext);

  // State to control Modal
  const { setIsModalOpen } = useContext(ModalContext);

  // Toast Context
  const { toastList, setToastList, showToast } = useContext(ToastContext);

  // UpdatedJson and Tags
  const { updatedJson, setUpdatedJson, updatedRheologicData, setUpdatedRheologicData, changedValues, setChangedValues, activeRheoData, setActiveRheoData  } = useContext(UpdatedJsonContext);



  // State to control displayed Page
  const [pageState, setPageState] = useState(0);

  // Request to fill valuesEditFluid with casePropsById (fluids)
  const {
    data: casePropsById,
    isLoading: casePropsByIdLoading,
    error: casePropsByIdError,
  } = useQuery("casePropsById", () =>
    getCaseAndCasePropsByIdRequest(caseId, setError)
  );

  
  // State to fill and control form values  - First Page
  const [valuesEditFluid, setValuesEditFluid] = useState(
    casePropsById["fluids"][id - 1]
  );
  

  // Properties to calculate rheometry chart data
   const rheometryProps =
    valuesEditFluid["fluid_element_item"]["rheologic_data"][0][
      "rheologic_data_item"
    ]["rheometer_readings"];


  /* States to control CheckboxAndBttn component */
  const [isCheckedRheologic, setIsCheckedRheologic] = useState(
    valuesEditFluid["fluid_element_item"]["use_viscosimeter_readings"]
  );

  // Page Title
  const editFluidTitle = "Editar um Fluido";


  // Function to close modal
  function closeModal() {
    // Cleaning states after close modal
    setValuesEditFluid([]);
    setActiveRheoData("");
    setUpdatedRheologicData([]);
    setUpdatedJson([]);
    setChangedValues(false); 
    setIsModalOpen(false);
    history.goBack();
  }

  // Function responsible for update information in inputs data
  function onChange(e) {
    let changedValues = {};
    if (e.target.options) {
      const selectValue =
        e.target.options[e.target.options.selectedIndex].value;
      const selectLabel =
        e.target.options[e.target.options.selectedIndex].innerText;

      if (
        e.target.name === "current_rheologic_model_id" ||
        e.target.name === "group_type"
      ) {
        changedValues = mergedObject(valuesEditFluid, {
          [e.target.name]: { eid: selectValue, label: selectLabel },
        });
      } else {
        changedValues = mergedObject(valuesEditFluid, {
          [e.target.name]: selectValue,
        });
      }
    } else {
      changedValues = mergedObject(valuesEditFluid, {
        [e.target.name]: e.target.value,
      });
    }
    setChangedValues(true);
    setValuesEditFluid(mergedObject(valuesEditFluid, changedValues));
  }


  // Function to updateFluid
  async function updateFluid() {
    let updatedFluidBody = mergedObject(valuesEditFluid, valuesEditFluid["fluid_element_item"]["property_dependencies"]["value"] !== "constants" ? {"rheologic_data":  updatedRheologicData}: updatedJson);

    const response = await updateFluidsIdRequest(caseId, {
      ...updatedFluidBody,
    });

    if(Object.keys(response.data).length > 0) {
      // Update Fluid Values
      setValuesEditFluid(response.data)
  
      // Update active rheologic data and graph after save fluid
      setActiveRheoData(Object.assign({}, ...response.data.fluid_element_item.rheologic_data.filter((item)=> item.eid === activeRheoData.eid ))) 

  
      // Clean updatedRheologicData and updatedJson states
      setUpdatedRheologicData([]);
      setUpdatedJson([]);
  
      // Set changed values at form as false 
      setChangedValues(false); 
    }

    // Toast 
    showToast(response, toastList, setToastList);

  }


  // Grid - Rows
  const densityJsonProfile = valuesEditFluid["fluid_element_item"]["density"]
    .density_profile
    ? valuesEditFluid["fluid_element_item"]["density"].density_profile
    : [];

  // Grid - Header
  const densityHeader =
    valuesEditFluid["fluid_element_item"]["density"].density_dependence;

  return (
    <ControlDensityProvider
      densityJsonProfile={densityJsonProfile}
      densityHeader={densityHeader}
    >
      {pageState === 0 && valuesEditFluid && (
        <EditFluidFirstPage
          editFluidTitle={editFluidTitle}
          valuesEditFluid={valuesEditFluid}
          setValuesEditFluid={setValuesEditFluid}
          changedValues={changedValues}
          onChange={onChange}
          pageState={pageState}
          setPage={setPageState}
          updateFluid={updateFluid}
          closeModal={closeModal}
          isCheckedRheologic={isCheckedRheologic}
          setIsCheckedRheologic={setIsCheckedRheologic}
          rheometryProps={rheometryProps}
          caseId={caseId}
        />
      )}

      {pageState === 1 && valuesEditFluid && (
        <EditFluidSecondPage
          editFluidTitle={editFluidTitle}
          valuesEditFluid={valuesEditFluid}
          setValuesEditFluid={setValuesEditFluid}
          changedValues={changedValues}
          setChangedValues={setChangedValues}
          onChange={onChange}
          setPage={setPageState}
          updateFluid={updateFluid}
          caseId={caseId}
        />
      )}

      {/* {pageState === 2 && valuesEditFluid && (
        <EditFluidThirdPage
          editFluidTitle={editFluidTitle}
          valuesEditFluid={valuesEditFluid}
          setValuesEditFluid={setValuesEditFluid}
          changedValues={changedValues}
          setChangedValues={setChangedValues}
          onChange={onChange}
          setPage={setPageState}
          rheometryProps={rheometryProps}
          rheologicData={rheologicData}
          setRheologicData={setRheologicData}
          isCheckedRheologic={isCheckedRheologic}
          setIsCheckedRheologic={setIsCheckedRheologic}
          caseId={caseId}
        />
      )} */}
    </ControlDensityProvider>
  );
}

export default EditFluid;
