// Main import of React
import { useContext, useEffect } from "react";

// Local SubComponents & utils
import { PlotlyChart, ActionFabEditableGrid, PageAndContainerTitle } from "../../common";
import { headersRheometry } from "../../../data/headersForGrids";
import { drawRheometryFluidWithFanData } from "../../utils/drawRheometryFluid";
import { createRows, mergeRheologicData } from "../../utils";
import { addTableRows } from "../../utils/functionsToAddRows";
import { duplicateRowRheometryReadings } from "../../utils/functionToDuplicateRows";
import { deleteTableRows } from "../../utils/functionsToDeleteRows";

// Context
import { UpdatedJsonContext } from "../../contexts/UpdatedJsonContext";
import { VariableRheologyContext } from "../../contexts/VariableRheologyContext";

// Import Component Style
import "./GridGraphCombo.css";

export default function GridGraphCombo(props) {

// Update rheologic data
const { updatedRheologicData, setUpdatedRheologicData, activeRheoData } = useContext(UpdatedJsonContext);

  // States to populate rheometer readings (rows and error) and rheometry properties rows
  const {rowsRheometry, rowsRheometryReadings, setRowsRheometryReadings, rheoReadingsError } = useContext(VariableRheologyContext);

// Defining Tags and Tags Items - updatedJson
const tags = ["rheometer_readings"];
const tagsItems = ["rheometer_reading_item"];

// Original rheologic data
const rheologicData = props.rheologicData;

// Current Rheologic Model
const currentModel = props.currentModel

const gridTextRheometry = props.gridTextRheometry

// Data for Create Rows function and UpdateRows
const rheometryProps = props.rheometryProps;

// Data to populate rheometer readings chart considering active tab
const rheometryJson = props.rheometryJson

//  Rheologic
const isChecked = props.isChecked;
const setIsChecked = props.setIsChecked;

// ActiveTab
const activeTab = props.activeTab;

// Form Props
const onChange = props.onChange;

// Query params of route
const caseMode = props.caseMode;


 // Define useEffect policy
 useEffect(() => {
    if(rowsRheometry !== undefined && rowsRheometryReadings !== undefined && activeTab !== "constants" ) {
        mergeRheologicData(Object.keys(updatedRheologicData).length >= Object.keys(rheologicData).length ? updatedRheologicData: rheologicData, rowsRheometry, rowsRheometryReadings, currentModel, setUpdatedRheologicData, activeRheoData)
    }
}, [rowsRheometry, rowsRheometryReadings, activeTab])
 


// Defining height for Grid Fab Container
const gridContainerHeight = "30vh";

// Shapes and Limits to draw Rheometry Fluid Chart
const dataFluidRheometry = drawRheometryFluidWithFanData(rheometryJson["rheometer_readings"], isChecked);

let tickvals = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000];
let ticktext = ['1', '', '', '', '', '', '', '', '', '10', '', '', '', '', '', '', '', '', '100', '', '', '', '', '', '', '', '', '1000'];
const layout = {
        width: 450,
        height: 450,
        title: "<b>Reometria</b>",
        showlegend: true,
        font: {
            family: "Arial",
            color: isChecked === true ? 'black' : '#636363',
        },
        xaxis: {
            title: "Rotação [RPM]",
            type: 'log',
            range: [Math.log10(1), Math.log10(1000)],
            tickvals: tickvals,
            ticktext: ticktext,
            ticks: 'outside',
            showgrid: true,
            showline: true,
            mirror: 'ticks',
            linecolor: isChecked === true ? 'black' : '#636363',
            linewidth: 3,
        },
        yaxis: {
            title: "Grau [º]",
            type: 'log',
            range: [Math.log10(1), Math.log10(1000)],
            tickvals: tickvals,
            ticktext: ticktext,
            ticks: 'outside',
            showgrid: true,
            showline: true,
            mirror: 'ticks',
            linecolor: isChecked === true ? 'black' : '#636363',
            linewidth: 3,
        },
        legend: {
            xanchor: "center",
            yanchor: "top",
            y: -0.20,
            x: 0.45
        },
    };
// Handle checkbox function
  function handleCheckboxChange() {
    // Change the state of isCheckedRheologic when the user click in checkbox and value to "true" or "false" (use_viscosimeter_readings)
    setIsChecked(!isChecked);
    onChange({
      target: {
        name: "use_viscosimeter_readings",
        value: !isChecked,
      },
    });
  };

  return (
    <>
      <div className="checkbox-grid__container">
        <div className="checkbox__container">
          <div className="checkbox-input__container">
            <input
              type="checkbox"
              className="input-fluid"
              checked={isChecked}
              onClick={handleCheckboxChange}
              disabled={caseMode}
            />
            <div className="text__container">
              <PageAndContainerTitle text={"Usar medidas de reometria"} level="3"/>
            </div>
          </div>
          {rheoReadingsError && isChecked === true &&
          <span className="rheo-message__span">Não foi possível ajustar as propriedades reológicas a partir das medidas de reometria.</span>

          }
        </div>
        <div className="text__container">
          <PageAndContainerTitle text={gridTextRheometry[activeTab]} level="3" />
        </div>
        <ActionFabEditableGrid
          isChecked={isChecked}
          metaData={headersRheometry}
          tagsItems={tagsItems[0]}
          tags={tags[0]}
          rows={rowsRheometryReadings ? rowsRheometryReadings : []}
          oldRows={createRows(rheometryProps, headersRheometry)}
          dataJson={rheometryProps}
          setRows={setRowsRheometryReadings}
          addTableRows={addTableRows}
          deleteTableRows={deleteTableRows}
          duplicateRow={duplicateRowRheometryReadings}
          gridContainerHeight={gridContainerHeight}
          caseMode={caseMode}
        />
      </div>

      <PlotlyChart data={dataFluidRheometry} layout={layout} />
    </>
  );
}
