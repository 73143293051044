import React, { createContext, useState } from "react";

// Create Context Object
const UpdatedJsonContext = createContext();

// Hook
export default function useUpdatedJson() {
  // States to Updated Rows
  const [updatedRows, setUpdatedRows] = useState([]);

  // States to Updated Body JSON
  const [updatedJson, setUpdatedJson] = useState({});

  // States to Updated Rheologic Data
  const [updatedRheologicData, setUpdatedRheologicData] = useState([])

  // States to save active rheologic data
  const [activeRheoData, setActiveRheoData] = useState({})

  // States to save rheologic Eid
  const [rheoEid, setRheoEid] = useState()

  // States to Update  Primary Tag - last_casing, casing, work_column
  const [tag, setTag] = useState();

  const [valueAmbientTemperature, setValueAmbientTemperature] = useState();

  // States to Update Simulation Partial, Advanced parameters and Boundary Condition - simulation
  const [changedSimulationCheck, setChangedSimulationCheck] = useState(false);
  const [simulationBody, setSimulationBody] = useState();

// States to Update Trajectory Body - Geometry
  const [trajectoryBody, setTrajectoryBody] = useState();

  // States to Update  Well Fluid - Pumping Sequence
  const [valueWellFluid, setValueWellFluid] = useState();

  // States to save Eid - Fluids and Rheologic Data grids
  const [rowEid, setRowEid] = useState(false);

  // States to Save Eid - Rheologic Data item
  const [eid, setEid] = useState()

  // States to Delete Fluid - Pumping Sequence
  const [changedGrid, setChangedGrid] = useState(false);

    // State to control Save Button
    const [changedValues, setChangedValues] = useState(false);


  // Function responsible for converting ROWS to BODY(PUT/PATCH)
  function updatedGridRows(tag, jsonArray, row) {
    let newJson = [];

    // Sweep through json itens
    for (let index = 0; index < jsonArray.length; index += 1) {
      let eid = jsonArray[index].eid;
      // Evaluate only the non-null eid json itens
      let currentRow = row.filter((item) => eid != null && item.eid === eid);
      if (currentRow[0]) {
        let innerObject = {};

        Object.keys(currentRow[0]).map((key) => {
          if (
            key !== "eid" &&
            key !== "selected" &&
            key !== "editable" &&
            key !== "id" && 
            key !== "activeEid"
          ) {
            innerObject[key] = currentRow[0][key];
          }
          return innerObject;
        });
        // Incorporate changes in the non-null eid json itens
        newJson.push({ [tag]: innerObject, eid: eid });
      }
    }
    // Evaluate only the null eid itens in rows
    let newRows = row.filter((item) => item.eid === null);

    if (newRows) {
      let innerObject = {};
      for (let index = 0; index < newRows.length; index += 1) {
        innerObject = {};
        Object.keys(newRows[index]).map((key) => {
          if (
            key !== "eid" &&
            key !== "selected" &&
            key !== "editable" &&
            key !== "id" && 
            key !== "activeEid"
          ) {
            innerObject[key] = newRows[index][key];
          }
        });
        // Incorporate new rows in json
        if (newJson.length < row.length) {
          newJson.splice(newRows[index].id - 1, 0, {
            [tag]: innerObject,
            eid: null,
          });
        }
      }
    }
    return newJson;
  }

  return {
    updatedRows,
    setUpdatedRows,
    updatedRheologicData, 
    setUpdatedRheologicData,
    rheoEid, 
    setRheoEid,
    activeRheoData, 
    setActiveRheoData,
    updatedGridRows,
    updatedJson,
    setUpdatedJson,
    eid, 
    setEid,
    tag,
    setTag,
    trajectoryBody, 
    setTrajectoryBody,
    valueAmbientTemperature,
    simulationBody,
    setValueAmbientTemperature,
    setSimulationBody,
    valueWellFluid,
    setValueWellFluid,
    rowEid, 
    setRowEid,
    changedGrid,
    setChangedGrid,
    changedSimulationCheck, 
    setChangedSimulationCheck,
    changedValues, 
    setChangedValues
  };
}

// Specific Context Provider
function UpdatedJsonProvider({ children }) {
  const {
    updatedRows,
    setUpdatedRows,
    updatedRheologicData, 
    setUpdatedRheologicData,
    rheoEid, 
    setRheoEid,
    activeRheoData, 
    setActiveRheoData,
    updatedGridRows,
    updatedJson,
    setUpdatedJson,
    eid, 
    setEid,
    tag,
    setTag,
    valueAmbientTemperature,
    trajectoryBody, 
    setTrajectoryBody,
    simulationBody,
    setValueAmbientTemperature,
    setSimulationBody,
    valueWellFluid,
    setValueWellFluid,
    rowEid, 
    setRowEid,
    changedGrid,
    setChangedGrid,
    changedSimulationCheck, 
    setChangedSimulationCheck,
    changedValues, 
    setChangedValues
  } = useUpdatedJson();

  return (
    <UpdatedJsonContext.Provider
      value={{
        updatedRows,
        setUpdatedRows,
        updatedRheologicData, 
        setUpdatedRheologicData,
        rheoEid, 
        setRheoEid,
        activeRheoData, 
        setActiveRheoData,
        updatedGridRows,
        updatedJson,
        setUpdatedJson,
        eid, 
        setEid,
        tag,
        setTag,
        valueAmbientTemperature,
        trajectoryBody, 
        setTrajectoryBody,
        simulationBody,
        setValueAmbientTemperature,
        setSimulationBody,
        valueWellFluid,
        setValueWellFluid,
        rowEid, 
        setRowEid,
        changedGrid,
        setChangedGrid,
        changedSimulationCheck, 
        setChangedSimulationCheck,
        changedValues, 
        setChangedValues
      }}
    >
      {children}
    </UpdatedJsonContext.Provider>
  );
}

export { UpdatedJsonContext, UpdatedJsonProvider };
