// Style Button = component with button that receives type, function, color, icon, text.

// # Main Import
import React from "react";

// # Import Component Style
import "./styledButton.css";

function StyledButton(props) {
  const data = props.data;
  const buttonDisabled = props.buttonDisabled;

  return (
    <button
      className={data.text === "Simulando" ? "bttn-style-disable" : "bttn-style"}
      type={data.type}
      onClick={data.callback}
      style={{ backgroundColor: data.color, marginLeft: data.margin_left }}
      disabled={buttonDisabled === true || data.disabled === true ? true : false}
    >
      <i data-testid={data.icon} className={data.icon}></i>
      <p className="bttn-text">{data.text}</p>
    </button>
  );
}

export default StyledButton;
