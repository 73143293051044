// #  Main Imports
import { useContext } from "react";
import { useHistory } from "react-router";

// #  Local SubComponents & utils
import {
  FormInput,
  StyledButton,
  ImportData,
  FormContainer,
  PageAndContainerTitle,
} from "../../common";

// # Import Component Style
import "./FormGeneralInfo.css";
import { HistoryContext } from "../../contexts/HistoryContext";

// Form Declaration
function FormGeneralInfo(props) {
  
  /* State to set History Location Pathname and possible changes at onChange function */
  const { hasChange } = useContext(HistoryContext)

  // Form Props
  const values = props.values;

  const handleSubmit = props.handleSubmit;

  const casePropsById = props.casePropsById;

  const cancelRequest = props.cancelRequest;
  const onChange = props.onChange;

  const id = props.id;

  // Hook gives access to the history instance
  const history = useHistory();

  // Query params of route
  const caseMode = history.location.search.includes("?view=true");

  // Input Sections Metadata
  const firstInputSection = [
    {
      id: 1,
      name: "field",
      value: casePropsById["general_information"]["field"],
      type: "text",
      placeholder: `${casePropsById["general_information"]["field"]}`,
      disabled: true,
      label: "Nome do Campo",
    },
    {
      id: 2,
      name: "well_name",
      value: casePropsById["general_information"].well_name,
      type: "text",
      placeholder: `${casePropsById["general_information"]["well_name"]}`,
      disabled: true,
      label: "Nome do Poço",
    },
    {
      id: 3,
      name: "rig_name",
      value: casePropsById["general_information"]["rig_name"],
      type: "text",
      placeholder: `${casePropsById["general_information"]["rig_name"]}`,
      disabled: true,
      label: "Nome da Sonda",
    },
  ];

  const secondInputSection = [
    {
      id: 4,
      name: "project_name",
      value: casePropsById["project"]["name"],
      type: "text",
      placeholder: casePropsById["project"]["name"],
      disabled: true,
      label: "Nome do Projeto Relacionado",
    },
    {
      id: 5,
      name: "name",
      value: values["case"].name,
      type: "textarea",
      errorMessage: "Este campo deve conter 3 a 150 caracteres!",
      maxlength: "150",
      required: true,
      pattern: "^[a-zA-Z0-9_ ]{3,150}$",
      disabled: false,
      label: "Nome do Caso",
      rows: 1,
    },
  ];

  const thirdInputSection = [
    {
      id: 6,
      name: "description",
      value: values["case"]["description"],
      type: "textarea",
      errorMessage: "Este campo deve conter 3 a 500 caracteres!",
      pattern: "^[a-zA-Z0-9_ ]{3,500}$",
      maxlength: "500",
      disabled: false,
      label: "Descrição",
      required: true,
    },
  ];

  // Buttons to render using StyledButton component
  const bttns = [
    {
      type: "button",
      callback: cancelRequest,
      color: "#c62828",
      icon: "fas fa-times",
      text: "Cancelar",
    },
    {
      type: "submit",
      callback: handleSubmit,
      color:
        hasChange
          ? "#1b5e20"
          : "#a59c9cfd",
      disabled:
         hasChange
          ? false
          : true,
      icon: "fas fa-save",
      text:  hasChange
        ? "Salvar"
        : "Salvo",
    },
  ];

  return (
    // Outer Form Container
    <FormContainer>
      {/* Genenal Information form main title */}
      <PageAndContainerTitle text="Informações Gerais" level="1" />
      {/* Form */}
      <form>
        {/* First Section Form Container */}
        <FormContainer mode="space-between">
          {firstInputSection.map((input) => (
            <FormInput
              key={input.id}
              {...input}
              value={input.value}
              onChange={onChange}
              disabled={caseMode}
              className="first-inputs"
            />
          ))}
        </FormContainer>

        {/* Second Section Form Container */}
        <FormContainer mode="grid" fitContent="true">
          {secondInputSection.map((input) => (
            <FormInput
              key={input.id}
              {...input}
              value={input.value}
              onChange={onChange}
              disabled={caseMode}
            />
          ))}
        </FormContainer>

        {/* Third Section Form Container */}
        <FormContainer>
          {thirdInputSection.map((input) => (
            <FormInput
              key={input.id}
              {...input}
              value={input.value}
              onChange={onChange}
              disabled={caseMode}
            />
          ))}
        </FormContainer>

        {!caseMode && (
        <div>
        <PageAndContainerTitle text="Importação de Dados" level="2" />

        {/* Import Data Form Container */}
        <FormContainer
          mode="grid"
          fitContent="true"
          borderColor="#17365c"
          borderStyle="solid"
          borderWidth="1px"
          borderRadius="0.5rem"
          padding="0.8em"
        >
          <ImportData id={id} />
        </FormContainer>
          </div>
        )}

        {/* Form Buttons Container */}
        {!caseMode && (
        <FormContainer mode="space-between" margin="3em 0em">
          {bttns.map((item, index) => (
            <StyledButton data={item} key={index} />
          ))}
        </FormContainer>
        )}
      </form>
    </FormContainer>
  );
}

// Export
export default FormGeneralInfo;
