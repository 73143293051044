// Main imports
import { useContext } from "react";

// Contexts
import { HistoryContext } from "../contexts/HistoryContext";
import { UpdatedJsonContext } from "../contexts/UpdatedJsonContext";

// Requests
import {
  updateTagByCaseId,
  deleteTagByCaseId,
} from "../../services/TagsRequests";

// Local components import
import { mergedObject } from "./mergeObjects";

// Utils
import {
  validateFieldsGrid,
  minMaxMdInitialAndFinal,
  validateFieldsSimulation,
  resetLimitInitialPartialSimulation,
  resetLimitFinalPartialSimulation,
} from "./index";



// Function to cancel request - Geometry, Pump Sequence and Thermal Profile
export async function cancelRequest(showToast, toastList, setToastList, setUpdatedJson) {
  const data = [
    {
      message: {
        type: "ERR",
        title: "Alterações Canceladas",
        description: "Suas alterações não foram salvas.",
      },
    },
  ];
  setUpdatedJson([]);
  showToast(data[0], toastList, setToastList);
  setTimeout(() => {
    window.location.reload(true);
  }, 500);
}

// Function to save Geometry Properties - Update Grids
export async function saveGeometryTags(
  id,
  tag,
  updatedJson,
  setUpdatedJson,
  setHasChange,
  showToast,
  toastList,
  setToastList,
  casePropsById,
  trajectoryBody
) {
  // Parameter passage to minMaxMdInitialAndFinal
  const { initialMdAnnular, finalMdAnnular, initialMdColumn, finalMdColumn } =
    minMaxMdInitialAndFinal(
      casePropsById.internal_geometry,
      casePropsById.external_geometry,
      casePropsById.scenery,
      casePropsById.simulation.initial_measured_depth,
      casePropsById.simulation.final_measured_depth
    );

  const limitMdInitial = resetLimitInitialPartialSimulation(
    casePropsById["scenery"].simulation_mode.value,
    initialMdAnnular,
    initialMdColumn
  );
  const limitMdFinal = resetLimitFinalPartialSimulation(
    casePropsById["scenery"].simulation_mode.value,
    finalMdAnnular,
    finalMdColumn
  );
  const tagsAllowedToResetLimit = [
    "open_hole",
    "last_casing",
    "work_column",
    "casing",
  ];

  const { validateFieldGridGeometry } = validateFieldsGrid(
    updatedJson,
    tag,
    showToast,
    toastList,
    setToastList
  );

  if (!validateFieldGridGeometry()) {
    return;
  }

  if(tag === 'trajectory') {
    const response = await updateTagByCaseId(id, "well_trajectory", {trajectory: [...updatedJson], 
      initial_azymuth: trajectoryBody? trajectoryBody.initial_azymuth : 0, 
      initial_inclination:trajectoryBody? trajectoryBody.initial_inclination : 0,
      initial_tvd:trajectoryBody? trajectoryBody.initial_tvd : 0  });
    showToast(response, toastList, setToastList);
  } else {
    const response = await updateTagByCaseId(id, tag, updatedJson);
    showToast(response, toastList, setToastList);
  }
  setHasChange(false);
  setUpdatedJson([]);
}

// Function to save Pump Sequence Properties - Update Grids
export async function savePumpSequenceTags(
  id,
  key,
  updatedJson,
  setUpdatedJson,
  rowEid,
  setHasChange,
  showToast,
  toastList,
  setToastList,
) {
  if (key === "pumped_fluids") {
  let pumpedFluidsArray = [];
  // TO DO: remove this structure
  for (let pumpedItem = 0; pumpedItem < updatedJson.length; pumpedItem += 1) {
      let pumpedFluidsObj = {
        eid: updatedJson[pumpedItem]["eid"],
        pumped_fluids_element_item: {
          label:updatedJson[pumpedItem]["pumped_fluids_element_item"]["fluid_id"]["label"],
          entry_type: "volume",
          annulus_length: "0",
          volume: updatedJson[pumpedItem]["pumped_fluids_element_item"]["volume"], 
          top: "0",
          flow_rate:updatedJson[pumpedItem]["pumped_fluids_element_item"]["flow_rate"],
          fluid_id:updatedJson[pumpedItem]["pumped_fluids_element_item"]["fluid_id"],
        },
      };
      pumpedFluidsArray.push(pumpedFluidsObj)
    } 
    const response = await updateTagByCaseId(id, key, pumpedFluidsArray)
    showToast(response, toastList, setToastList);
  }

  if (key === "well_fluid") {
    const response = await updateTagByCaseId(id, "well_fluid", updatedJson);
    showToast(response, toastList, setToastList);
  }

  if (key === "fluids") {
    let bodyJsonFluidEid = JSON.stringify(rowEid);
    const response = await deleteTagByCaseId(id, key, bodyJsonFluidEid);
    showToast(response, toastList, setToastList);
  }
  setHasChange(false);
  setUpdatedJson([]);
}

// Function to save Thermal Profile Properties - Update Grids
export async function saveThermalProfileTags(
  id,
  tag,
  updatedJson,
  setUpdatedJson,
  setHasChange,
  showToast,
  toastList,
  setToastList,
  valueAmbientTemperature,
  casePropsById
) {
  // const {validateGridThermalProfile, validateFieldAmbientTemperature} = validateFieldsGrid(updatedJson, key, showToast, toastList, setToastList);

  // if(!validateGridThermalProfile()) {
  //   return;
  // }

  // if (key === "water_temperatures" && !validateFieldAmbientTemperature(ambientTemperature)) {
  //   return;
  // }
  let mergedThermalProfileBody = {}
  if(updatedJson) {
    mergedThermalProfileBody = mergedObject(casePropsById["thermal_profile"], { ambient_temperature: valueAmbientTemperature["ambient_temperature"], [tag]: updatedJson })
  } else {
    mergedThermalProfileBody = mergedObject(casePropsById["thermal_profile"], { ambient_temperature: valueAmbientTemperature["ambient_temperature"] })
  }
  const response = await updateTagByCaseId(id, "thermal_profile", mergedThermalProfileBody);

  showToast(response, toastList, setToastList);
  setHasChange(false);
  setUpdatedJson([]);
}

// Function to save Thermal Profile Properties - Update Ambient Temperature
/* export async function saveAmbientTemperature(
  id,
  valueAmbientTemperature,
  setUpdatedJson,
  setHasChange,
  showToast,
  toastList,
  setToastList
) {
  // const {validateFieldAmbientTemperature} = validateFieldsGrid(null, null, showToast, toastList, setToastList);

  // if(!validateFieldAmbientTemperature(ambientTemperature)) {
  //   return;
  // }

   const response = await updateTagByCaseId(
    id,
    "ambient_temperature",
    valueAmbientTemperature
  );

  showToast(response, toastList, setToastList);
  setHasChange(false);
  setUpdatedJson([]);
} */

// Function to save Simulation Properties - Inputs
export async function saveSimulationInputs(
  id,
  setUpdatedJson,
  simulationBody,
  setHasChange,
  showToast,
  toastList,
  setToastList,
  casePropsById,
  setChangedSimulationCheck
) {
  /*   // Parameter passage to minMaxMdInitialAndFinal
  const { minMdInitial, maxMdInitial, minMdFinal, maxMdFinal } =
    minMaxMdInitialAndFinal(
      casePropsById.internal_geometry,
      casePropsById.external_geometry,
      casePropsById.scenery,
      casePropsById.simulation.initial_measured_depth,
      casePropsById.simulation.final_measured_depth
    );

  const { validateMdInitialAndMdFinal } = validateFieldsSimulation(
    simulationBody,
    showToast,
    toastList,
    setToastList
  ); */

  // if (!validateMdInitialAndMdFinal( minMdInitial, maxMdInitial, minMdFinal, maxMdFinal)) {
  //   return;
  // }

  const response = await updateTagByCaseId(id, "simulation", simulationBody);
  showToast(response, toastList, setToastList);
  setHasChange(false);
  setChangedSimulationCheck(false)
  setUpdatedJson([]);
}

// Function to save Simulation Properties - Grids
export async function saveSimulationGrids(
  id,
  updatedJson,
  setUpdatedJson,
  simulationBody,
  setHasChange,
  showToast,
  toastList,
  setToastList,
  setChangedSimulationCheck
) {
  let changedGridRows = mergedObject(simulationBody, updatedJson);
  const response = await updateTagByCaseId(id, "simulation", changedGridRows);
  showToast(response, toastList, setToastList);
  setHasChange(false);
  setChangedSimulationCheck(false)
  setUpdatedJson([]);
}

// Buttons to render using StyledButton component - Geometry
export function bttnsForGeometry(
  id,
  updatedJson,
  setUpdatedJson,
  setHasChange,
  showToast,
  toastList,
  setToastList,
  casePropsById,
  trajectoryBody
) {
  const btts = [
    {
      type: "button",
      callback: () => cancelRequest(showToast, toastList, setToastList, setUpdatedJson),
      color: "#c62828",
      icon: "fas fa-times",
      text: "Cancelar",
    },
    {
      type: "button",
      callback: () => {
        Object.keys(updatedJson).map((key) => {
          saveGeometryTags(
            id,
            key,
            updatedJson[key],
            setUpdatedJson,
            setHasChange,
            showToast,
            toastList,
            setToastList,
            casePropsById,
            trajectoryBody
          );
        });
      },
      color: Object.keys(updatedJson).length > 0 ? "#1b5e20" : "#a59c9cfd",
      disabled: Object.keys(updatedJson).length === 0 ? true : false,
      icon: "fas fa-save",
      text: Object.keys(updatedJson).length === 0 ? "Salvo" : "Salvar",
      margin_left: "1rem",
    },
  ];

  return btts;
}

// Buttons to render using StyledButton component - Pump Sequence
export function bttnsForPumpSequence(
  id,
  updatedJson,
  setUpdatedJson,
  rowEid,
  setHasChange,
  showToast,
  toastList,
  setToastList,
) {
  const btts = [
    {
      type: "button",
      callback: () => cancelRequest(showToast, toastList, setToastList, setUpdatedJson),
      color: "#c62828",
      icon: "fas fa-times",
      text: "Cancelar",
    },
    {
      type: "submit",
      callback:
       () => {
              Object.keys(updatedJson).map((key) => {
                savePumpSequenceTags(
                  id,
                  key,
                  updatedJson[key],
                  setUpdatedJson,
                  rowEid,
                  setHasChange,
                  showToast,
                  toastList,
                  setToastList,
                );
              });
            },
      color: Object.keys(updatedJson).length > 0 && !Object.keys(updatedJson).includes("rheologic_data") && !Object.keys(updatedJson).includes("rheometer_readings") ? "#1b5e20": "#a59c9cfd",
      icon: "fas fa-save",
      text: Object.keys(updatedJson).length > 0 && !Object.keys(updatedJson).includes("rheologic_data") && !Object.keys(updatedJson).includes("rheometer_readings") ? "Salvar" : "Salvo",
      margin_left: "1rem",
    },
  ];

  return btts;
}

// Buttons to render using StyledButton component - Thermal Profile
export function BttnsForThermalProfile(
  id,
  updatedJson,
  tag,
  setUpdatedJson,
  valueAmbientTemperature,
  setHasChange,
  showToast,
  toastList,
  setToastList, 
  casePropsById
) {

  // Context item that get changes at ambient temperature input 
  const { hasChange } = useContext(HistoryContext);

  const btts = [
    {
      type: "button",
      callback: () => cancelRequest(showToast, toastList, setToastList, setUpdatedJson),
      color: "#c62828",
      icon: "fas fa-times",
      text: "Cancelar",
    },
    {
      type: "submit",
      callback:() => {
                saveThermalProfileTags(
                  id,
                  tag,
                  updatedJson[tag],
                  setUpdatedJson,
                  setHasChange,
                  showToast,
                  toastList,
                  setToastList,
                  valueAmbientTemperature,
                  casePropsById
                );
            },
         /*  : () =>
              saveAmbientTemperature(
                id,
                valueAmbientTemperature,
                setUpdatedJson,
                setHasChange,
                showToast,
                toastList,
                setToastList
              ), */
      color:
        hasChange  || Object.keys(updatedJson).length > 0 
          ? "#1b5e20"
          : "#a59c9cfd",
      disabled:
      hasChange  || Object.keys(updatedJson).length > 0 
          ? false
          : true,
      icon: "fas fa-save",
      text:
      hasChange  || Object.keys(updatedJson).length > 0 
          ? "Salvar"
          : "Salvo",
      margin_left: "1rem",
    },
  ];

  return btts;
}

// Buttons to render using StyledButton component - Simulation
export function BttnsForSimulation(
  id,
  updatedJson,
  setUpdatedJson,
  simulationBody,
  setHasChange,
  showToast,
  toastList,
  setToastList,
  casePropsById
) {

  // Context items that get changes at simulation inputs, rotation and reciprocation checkbox
  const { hasChange } = useContext(HistoryContext);
  const { changedSimulationCheck, setChangedSimulationCheck } = useContext(UpdatedJsonContext)


  const btts = [
    {
      type: "button",
      callback: () => cancelRequest(showToast, toastList, setToastList, setUpdatedJson),
      color: "#c62828",
      icon: "fas fa-times",
      text: "Cancelar",
    },
    {
      type: "button",
      callback:
        Object.keys(updatedJson).length > 0
          ? () => {
              Object.keys(updatedJson).map((key) => {
                saveSimulationGrids(
                  id,
                  updatedJson,
                  setUpdatedJson,
                  simulationBody,
                  setHasChange,
                  showToast,
                  toastList,
                  setToastList,
                  setChangedSimulationCheck
                );
              });
            }
          : () =>
              saveSimulationInputs(
                id,
                setUpdatedJson,
                simulationBody,
                setHasChange,
                showToast,
                toastList,
                setToastList,
                casePropsById,
                setChangedSimulationCheck
              ),
      color: hasChange  || Object.keys(updatedJson).length > 0 || changedSimulationCheck   ? "#1b5e20" : "#a59c9cfd",
      disabled: hasChange  || Object.keys(updatedJson).length > 0 || changedSimulationCheck   ? false : true,
      icon: "fas fa-save",
      text: hasChange  || Object.keys(updatedJson).length > 0 || changedSimulationCheck  ? "Salvar" : "Salvo",
      margin_left: "1rem",
    },
  ];

  return btts;
}
