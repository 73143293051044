// ViewActionIcon = Component to render view bttn - Edit Fluid


// Import Component Style
import "./ViewActionIcon.css";

function ViewActionIcon(props) {


  const rowID = props.rowID;

  const viewBttn = props.viewBttn;

  const viewBttnOnClick = props.viewBttnOnClick


  return (
      <button
        className={
          viewBttn ? "view-bttn__grid--visible" : "view-bttn__grid--invisible"
        }
        onClick={() => viewBttnOnClick(rowID)}
        type="button"
      >
        <p className="tooltip">{"Visualizar"}</p>
        <i className={"fas fa-eye"}></i>
        {props.children}
      </button>
  );
}

export default ViewActionIcon;