// # Main Import
import React, { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// # Import datas of Projects and Cases
import dataProjects from "../../data/projects";
import { dataCases } from "../../data/cases";
import { treeInfo } from "../../data/treeInfo";
import {
  principalTagsInfo,
  principalTagsScenery,
  principalTagsGeometria,
  principalTagsPumpSequence,
  principalTagsSimulation,
  principalTagsThermalProfile,
} from "../../data/tagsPages";

// Create Context Object
const SearchContext = createContext();

// Hook
export default function useSearch() {
  // Projects, Cases and Word Value States
  const [projectsData, setProjectsData] = useState([]);
  const [casesData, setCasesData] = useState([]);
  const [wordValue, setWordValue] = useState("");

  const [treeData, setTreeData] = useState(treeInfo);

  const [id, setId] = useState([]);

  // Constant responsible for bringing the Page Route
  const location = useLocation();

  // Function responsible for bringing all Projects related to the Well
  function getProjects() {
    const projectsResponse = dataProjects;
    setProjectsData([...projectsResponse]);
  }

  // Function responsible for bringing all Cases related to the Project
  function getCases() {
    const casesReponse = dataCases;
    setCasesData([...casesReponse]);
  }

  // Functions responsible for bringing the Projects and Cases filtered from the Input Value
  /*   function updateStateProjects(value) {
    const searchWord = value;
    setWordValue(searchWord);
    const newFilter = projectsData.filter((value) =>
      value.title.toLowerCase().includes(searchWord.toLowerCase())
    );
    setProjectsData(newFilter);
    if (!searchWord) {
      setProjectsData(dataProjects);
    }
  }

  function updateStateCases(value) {
    const searchWord = value;
    setWordValue(searchWord);
    const newFilter = casesData.filter((value) =>
      value.title.toLowerCase().includes(searchWord.toLowerCase())
    );
    setCasesData(newFilter);
    if (!searchWord) {
      setCasesData(dataCases);
    }
  }
 */


  const updateCasesProps = () => {
    const allTags = principalTagsInfo.concat(
      principalTagsScenery,
      principalTagsGeometria,
      principalTagsPumpSequence, principalTagsThermalProfile, principalTagsSimulation
    );
    if (wordValue || id) {
      allTags.filter((item) => {
        if (wordValue === "") {
          return item;
        } else if (
          wordValue.length > 4 &&
          item.tags
            .toString()
            .toLowerCase()
            .normalize("NFD")
            .replace(/[^a-z]/g, "")
            .includes(
              wordValue
                .toLowerCase()
                .normalize("NFD")
                .replace(/[^a-z]/g, "")
            )
        ) {
          window.location.href = `/case/${id}/${item.route}`;
        }
      });
    }
  };

  const updateTree = () => {
    if (wordValue || treeData) {
      const getNodes = (result, object) => {
        if (
          object.name
            .toLowerCase()
            .normalize("NFD")
            .includes(wordValue.toLowerCase().normalize("NFD"))
        ) {
          result.push(object);
          return result;
        }
        if (Array.isArray(object.info)) {
          const info = object.info.reduce(getNodes, []);
          if (info.length) result.push({ ...object, info });
        }
        return result;
      };
      return treeData.reduce(getNodes, []);
    }
  };

  useEffect(() => {
    getProjects();
    getCases();
    if (location.pathname.startsWith === "/project") {
      /*       updateStateCases(); */
    } else if (location.pathname.startsWith === "/well") {
      /*     updateStateProjects(); */
    } else if (location.pathname.startsWith("/case")) {
      updateCasesProps();
    }
  }, [wordValue]);

  return {
    projectsData,
    setProjectsData,
    casesData,
    setCasesData,
    /*     updateStateProjects,
    updateStateCases, */
    wordValue,
    setWordValue,
    setId,
    treeData,
    setTreeData,
    updateTree,
  };
}

// Specific Context Provider
function SearchProvider({ children }) {
  const {
    projectsData,
    setProjectsData,
    casesData,
    setCasesData,
    updateStateProjects,
    updateStateCases,
    wordValue,
    setWordValue,
    setId,
    treeData,
    setTreeData,
    updateTree,
  } = useSearch();

  return (
    <SearchContext.Provider
      value={{
        projectsData,
        setProjectsData,
        casesData,
        setCasesData,
        updateStateProjects,
        updateStateCases,
        wordValue,
        setWordValue,
        setId,
        treeData,
        setTreeData,
        updateTree,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export { SearchContext, SearchProvider };
