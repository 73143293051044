export function createRows(json, headers, activeRheoData = null) {
  let gridRow = [];

  // Return an empty array if json is empty
  if (json.length === 0) {
    return gridRow;
  }

  // Defining tag name
  const tagName = Object.keys(json[0]).filter((item) => item !== "eid" && item !== "use_rheometer_readings" );

  // Defining headers' column names
  const columns = headers.header.map((item) => item.key);


  // Only for Edit Fluid page - section for rheologic model
  let rheologicModel = headers.model


  for (let row = 0; row < json.length; row += 1) {
    // Initialize row with id, eid, editable and selected properties
    let newRow = {
      id: row + 1,
      eid: json[row].eid ? json[row].eid.toString() : null,
      activeEid: activeRheoData ? activeRheoData.eid : null, 
      editable: true,
      selected: false,
    };

    // Loop over other columns
    columns.map((column) => {
      if (
        !(
          column == "id" ||
          column == "selected" ||
          column == "eid" ||
          column == "editable"
        )
      ) {
        if(rheologicModel && column !== "rheometry_temperature" && column !== "rheometry_pressure") {
          newRow[column] = json[row][tagName][rheologicModel][column];
        
        } else {
          newRow[column] = json[row][tagName][column];
        }
      }
    });

    gridRow.push(newRow);
  }
  return gridRow;
}

