// Import Data = Component that import json file

// # Main Import
import React, { useState, useContext } from "react";
import { sendPCODataByCaseIdRequest } from "../../../services/CasesRequests";

// Contexts
import { ToastContext } from "../../contexts/ToastContext";

// # Import Component Style
import "./importData.css";
import axios from "axios";

function ImportData(props) {
  /* State that loads the imported json information */
  const [files, setFiles] = useState([]);

  /* State to import errors */
  const [error, setErrorData] = useState([]);

  // Toast Context
  const { toastList, setToastList, showToast } = useContext(ToastContext);

  const id = props.id;

  /* Function that reads JSON files and stores in State   */

  function readFile(uploadedFile) {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      try {
        //verify the extension file
        var extension = uploadedFile.name.split(".").pop();

        if (extension == "json") {
          setFiles(JSON.parse(fileReader.result));
          setErrorData(null);
        } else if (extension == "pco") {
          sendPCODataByCaseIdRequest(id, fileReader.result)
            .then((response) => {
              showToast(
                response,
                toastList,
                setToastList
              );
            })
            .catch((e) => {
              showToast(
                {
                  message: {
                    type: "ERR",
                    title: "Erro ao importar arquivo",
                    description: "Ocorreu um erro ao importar o arquivo .pco",
                  },
                },
                toastList,
                setToastList
              );
            });
        }
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);
      } catch (e) {
        setErrorData("**Not valid JSON file!**");
        setFiles([]);
        showToast(
          {
            message: {
              type: "ERR",
              title: "Erro ao importar arquivo",
              description: "",
            },
          },
          toastList,
          setToastList
        );
      }
    };
    if (uploadedFile !== undefined) fileReader.readAsText(uploadedFile);
  }

  return (
    <form action={(e) => readFile(e.target.files[0])} method="post">
      <input
        class="custom-file-input"
        type="file"
        enctype="multipart/form-data"
        accept=".pco"
        onChange={(e) => readFile(e.target.files[0])}
      />
    </form>
  );
}

export default ImportData;
