// Main import of React
import { useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "react-query";

//# Local Imports
import { getProjectsByWellRequest } from "../services/ProjectsRequests";

// Local components import
import {
  CarouselComponent,
  FAB,
  CardProjects,
  PageAndContainerTitle,
  DropletLoadingSpinner,
  TextAndBackgroundImage,
} from "../components/common";
import background from "../images/background.jpg";

// # Context
import { ModalContext } from "../components/contexts/ModalContext";
import { SearchContext } from "../components/contexts/SearchContext";
import { HandleErrorContext } from "../components/contexts/HandleErrorContext";

// Style imports
import "./style/ProjectsCases.css";
import { darkColors, lightColors } from "../components/common";

function Projects(props) {
  const { id } = useParams();

  const { setIsModalOpen, setProjectId } = useContext(ModalContext);

  const { wordValue, setWordValue } = useContext(SearchContext);

  // Handle Error Context
  const { setError } = useContext(HandleErrorContext);


  //Define States for Projects - Using React Query
  const {
    data: projectsByWell,
    isLoading: projectsByWellLoading,
    error: projectsByWellError,
  } = useQuery("projectsByWell", () =>
    getProjectsByWellRequest(id, setError)
  );

  useEffect(() => {
    if (projectsByWellError) {
      console.log("Alguma coisa deu errada");
      return <h1>Ops! Algum erro inesperado aconteceu</h1>;
    }

    if (projectsByWell === null) {
      return <h1>Informações não encontradas!</h1>;
    }

    if (projectsByWellLoading) {
      return <DropletLoadingSpinner />;
    }
    setWordValue("");
  }, [
    projectsByWell,
    projectsByWellError,
    projectsByWellLoading,
    setWordValue,
  ]);

  // Set Actions Buttons Card
  const actionButtomCard = {
    actions: [
      {
        tooltip: "Ver Casos",
        icon: "fas fa-edit",
        key: "edit_case_button_",
        callback: "",
        route: `/project/`,
        backgroundColor: darkColors.yellow,
        color: darkColors.white,
        setId: "",
        locked: false,
        edit: "",
      },
      {
        tooltip: "Deletar Projeto",
        icon: "fas fa-trash",
        key: "delete_case_button_",
        callback: setIsModalOpen,
        route: `/well/${id}/projects`,
        backgroundColor: darkColors.red,
        color: darkColors.white,
        setId: setProjectId,
        locked: false,
        edit: "",
      },
    ]
  };

  /* Function responsible for controlling the rendering of the FAB */
  function controlsActionButton() {
    let actionData = {};
    actionData = {
      actionButtom: {
        tootip: "Adicionar um novo projeto",
        icon: "fas fa-plus",
        rotate: true,
        backgroundColor: darkColors.blue,
        color: lightColors.white,
        /* Direction: Array responsible for determining the opening position of the button */
        direction: ["fab-container-up"],
      },
      actions: [
        {
          tooltip: "Adicionar novo projeto",
          icon: "fas fa-plus",
          key: "action1",
          url: `/well/${id}/new_project`,
          backgroundColor: darkColors.black,
          color: lightColors.white,
        },
      ],
    };
    return actionData;
  }

  return projectsByWell ? (
    <div className="projects-cases__container">
      <PageAndContainerTitle text="Projetos" level="1" />
      <CarouselComponent>
        {projectsByWell
          .filter((item) => {
            if (wordValue === "") {
              return item;
            } else if (
              item.fields.name.toLowerCase().includes(wordValue.toLowerCase())
            ) {
              return item;
            }
          })
          .map((item, index) => (
            <div className="page" key={index}>
              <CardProjects data={item} actionButtom={actionButtomCard} />
            </div>
          ))}
      </CarouselComponent>
      <div className="bttn-add__container">
        <FAB data={controlsActionButton()} />
      </div>
    </div>
  ) : (
    <div className="projects-cases__empty">
      <PageAndContainerTitle text="Projetos" level="1" />
      <TextAndBackgroundImage
        text="Para criar um projeto clique no botão +"
        image={background}
      />
      <div className="bttn-add__empty">
        <FAB data={controlsActionButton()} />
      </div>
    </div>
  );
}
export default Projects;
