// Import axios from specific API definition
import axios from "./apis/i3d_sqlAPI";

// Import Login function to create Token Authorization
import { authorizationHeader } from "./LoginServices";

// GET Method Request for Projects from specific Well
async function getProjectsByWellRequest(id, setError) {
  try {
    const response = await axios.get(`projects/well/${id}/`,  {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data.data;
  } catch (error) {
    console.log(error);
    setError(error.response.status)
  }
}

// GET Method Request by ProjectID
async function getProjectsByIdRequest(id, setError) {
  try {
    const response = await axios.get(`project/${id}/`, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data.data;
  } catch (error) {
    console.log(error.message);
    setError(error.response.status)
  }
}

// POST Method Request by ProjectID
async function createProjectsRequest(value) {
  try {
    const response = await axios.post(`project/`, JSON.stringify(value), {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}

// PATCH Method Request by ProjectID
async function updateProjectsByIdRequest(id, data) {
  try {
    const response = await axios.patch(`project/${id}/`, JSON.stringify(data), {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}

// DELETE Method Request by ProjectID
async function deleteProjectsByIdRequest(id) {
  try {
    const response = await axios.delete(`project/${id}/`, {
      headers: {
        Authorization: authorizationHeader,
      },
    });
    return response;
  } catch (error) {
    console.log(error.message);
  }
}

export {
  /* getProjectsRequest, */
  getProjectsByWellRequest,
  getProjectsByIdRequest,
  createProjectsRequest,
  updateProjectsByIdRequest,
  deleteProjectsByIdRequest,
};
