// # Main import of React
import React, { useContext, useEffect, useState } from "react";
import { Route } from "react-router";
import { useQuery } from "react-query";
import { useParams, useHistory } from "react-router";

// #  Local SubComponents, utils and data
import {
  StyledButton,
  SlidingPanel,
  PageAndContainerTitle,
  Accordion,
  DropletLoadingSpinner,
} from "../components/common";
import { returnTextAndGridLines } from "../components/utils/functionToReturnTextAndGridLines";

// # Requests
import { getCaseAndCasePropsByIdRequest } from "../services/CasesRequests";
import { getComboFluidsByCaseIdRequest } from "../services/FluidsRequest";

// # Context
import { SearchContext } from "../components/contexts/SearchContext";
import { ProjectAndCasesContext } from "../components/contexts/ProjectAndCasesContext";
import { HistoryContext } from "../components/contexts/HistoryContext";
import { ModalContext } from "../components/contexts/ModalContext";
import { UpdatedJsonContext } from "../components/contexts/UpdatedJsonContext";
import { ToastContext } from "../components/contexts/ToastContext";
import { HandleErrorContext } from "../components/contexts/HandleErrorContext";

// Style import
import "./style/SlidingStructure.css";

function SlidingStructure(props) {
  const { id } = useParams();
  const history = useHistory();

  // Query params of route
  const caseMode = history.location.search.includes("?view=true");

  const Graphs = props.Graphs;

  const Child = props.Child;

  const routes = props.routes;

  const bttns = props.bttns;

  const accordionData = props.accordionData;

  const accordionDataNoCentral = accordionData(id).filter((item) =>
    ["1", "2", "4"].includes(item.id)
  );

  // Defining Accordion Open Key. It changes considering the Page
  const accordionKeyFilter = accordionData(id).filter((item) =>
    history.location.pathname.includes(item.route)
  );

  // Update Rows and Tags
  const {
    updatedJson,
    setUpdatedJson,
    tag,
    valueAmbientTemperature,
    rowEid,
    simulationBody,
    trajectoryBody
  } = useContext(UpdatedJsonContext);

  // Toast Context
  const { toastList, setToastList, showToast } = useContext(ToastContext);

  /* State to set ID(MONGO) at one Case - responsible to manage SearchBox*/
  const { setId } = useContext(SearchContext);

  /* State to set CurrentLevel values for GeneralInfo Page*/
  const { setIsCaseById, setProjectById} = useContext(ProjectAndCasesContext);

  /* State to set History Location Pathname and possible changes at onChange function */
  const { setHistoryLocation, hasChange, setHasChange, openKey, setOpenKey } =
    useContext(HistoryContext);

  /* State to control Modal */
  const { setIsModalOpen } = useContext(ModalContext);

  // Handle Error Context
  const { setError } = useContext(HandleErrorContext);

  /*   // State to define number of grid lines and text for empty grids
  const [textAndLineNumbers, setTextAndLineNumbers] = useState(); */

  //Define States for Geometry, PumpSEquence and Thermal Profile using CaseAndCaseProps route - React Query
  const {
    data: casePropsById,
    isLoading: casePropsByIdLoading,
    error: casePropsByIdError,
  } = useQuery("casePropsById", () =>
    getCaseAndCasePropsByIdRequest(id, setError), {
      onSuccess: (casePropsById) => setProjectById(casePropsById.case.project)
    }
  );

  // Define useEffect policy
  useEffect(() => {
    if (casePropsByIdError) {
      console.log("Alguma coisa deu errada");
      return <h1>Ops! Algum erro inesperado aconteceu</h1>;
    }
    if (casePropsById === null) {
      return <h1>Informações não encontradas!</h1>;
    }
    if (casePropsByIdLoading) {
      return <DropletLoadingSpinner />;
    }
    setOpenKey(accordionKeyFilter[0].id);
    setId(id);
  }, [
    id,
    casePropsById,
    casePropsByIdError,
    casePropsByIdLoading,
    setId,
    setIsCaseById,
    setHistoryLocation,
    history.location.pathname
  ]);

  
  // Defining max-heigth of Accordion child. It changes considering the Page
  const maxHeight = "72vh";

  /*   function countGridLines(tag, updatedJson = {}, json = []) {
    let textAndNumberOfLinesJson = returnTextAndGridLines(tag, json);
    let textAndNumberOfLines = {
      text: textAndNumberOfLinesJson[0],
      number:
        updatedJson[tag] !== undefined
          ? updatedJson[tag].length
          : textAndNumberOfLinesJson[1].length,
    };
    setTextAndLineNumbers(textAndNumberOfLines);
  } */

  if (
    history.location.pathname.endsWith("/notifications") ||
    history.location.pathname.endsWith("/tutorials")
  ) {
    return <></>;
  } else {
    return casePropsById && accordionKeyFilter ? (
      <>
        <main className="sliding-structure__container">
          <PageAndContainerTitle text={accordionKeyFilter[0].title} level="1" />
          <Graphs
            json={casePropsById}
            history={history}
            /*   countGridLines={countGridLines}
            textAndLineNumbers={textAndLineNumbers} */
          />
        </main>
        <aside>
          <SlidingPanel history={history}>
            <div className="sliding-structure__accordion">
              <Route path={routes}>
                <Accordion
                  accordionData={
                    casePropsById.scenery.simulation_mode.value === "column" && history.location.pathname.includes("geometry")
                      ? accordionDataNoCentral
                      : accordionData(id)
                  }
                  maxHeight={maxHeight}
                  openKey={openKey}
                  setOpenKey={setOpenKey}
                  hasChange={hasChange}
                  setIsModalOpen={setIsModalOpen}
                >
                  <Child
                    casePropsById={casePropsById}
                  />
                </Accordion>
              </Route>
              {!caseMode && (
              <div className="sliding-structure-bttns__container">
                {(() => {
                  // Thermal Profile
                  if (
                    valueAmbientTemperature && 
                    history.location.pathname.includes("/thermal_profile")
                  ) {
                    return bttns(
                      id,
                      updatedJson,
                      tag,
                      setUpdatedJson,
                      valueAmbientTemperature,
                      setHasChange,
                      showToast,
                      toastList,
                      setToastList, 
                      casePropsById
                    ).map((item, index) => (
                      <StyledButton data={item} key={index} />
                    ));
                    // Pump Sequence
                  } else if (
                    tag === "pumped_fluids" ||
                    tag === "fluids" || tag === "well_fluid"
                  ) {
                    return bttns(
                      id,
                      updatedJson,
                      setUpdatedJson,
                      rowEid,
                      setHasChange,
                      showToast,
                      toastList,
                      setToastList,
                    ).map((item, index) => (
                      <StyledButton data={item} key={index} />
                    ));
                    // Simulation
                  } else if (
                    history.location.pathname.includes("/simulation")
                  ) {
                    return bttns(
                      id,
                      updatedJson,
                      setUpdatedJson,
                      simulationBody,
                      setHasChange,
                      showToast,
                      toastList,
                      setToastList,
                      casePropsById
                    ).map((item, index) => (
                      <StyledButton data={item} key={index} />
                    ));
                  }
                  // Geometry
                  else {
                    return bttns(
                      id,
                      updatedJson,
                      setUpdatedJson,
                      setHasChange,
                      showToast,
                      toastList,
                      setToastList,
                      casePropsById,
                      trajectoryBody
                    ).map((item, index) => (
                      <StyledButton data={item} key={index} />
                    ));
                  }
                })()}
              </div>
              )}
            </div>
          </SlidingPanel>
        </aside>
      </>
    ) : (
      <p>{casePropsByIdError}</p>
    );
  }
}

export default SlidingStructure;
