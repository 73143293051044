function getFluidDensityLimits(fluid_density) {
  let dataXf = [];
  let dataYf = [];
  if (
    fluid_density.density_profile !== undefined &&
    fluid_density.density_profile !== null &&
    fluid_density.density_profile.length !== 0
  ) {
    dataXf = fluid_density.density_profile.map((item) =>
      parseFloat(
        item.density_profile_element_item.temperature
      )
    );
    dataYf = fluid_density.density_profile.map((item) =>
      parseFloat(item.density_profile_element_item.value)
    );
  } else {
    dataXf = [];
    dataYf = [];
  }


  const xLimits = [Math.min([Math.min(...dataXf)]), Math.max([Math.max(...dataXf)])];
  const yLimits = [Math.min([Math.min(...dataYf)]), Math.max([Math.max(...dataYf)])];
  return [xLimits, yLimits];
}

function getFluidDensityAndTemperatureLimits(fluid_density) {
  let dataXf = [];
  let dataYf = [];
  if (
    fluid_density.density_profile !== undefined &&
    fluid_density.density_profile !== null &&
    fluid_density.density_profile.length !== 0
  ) {
    dataXf = fluid_density.density_profile.map((item) =>
      parseFloat(item.density_profile_element_item.pressure)
    );
    dataYf = fluid_density.density_profile.map((item) =>
      parseFloat(item.density_profile_element_item.value)
    );
  } else {
    dataXf = [];
    dataYf = [];
  }

  const xLimits = [Math.min([Math.min(...dataXf)]), Math.max([Math.max(...dataXf)])];
  const yLimits = [Math.min([Math.min(...dataYf)]), Math.max([Math.max(...dataYf)])];
  return [xLimits, yLimits];
}

function drawFluidDensityPressure(fluid_density, density) {
  const  dataFluidDensityPressure = [];


  let dataXf = [];
  let dataYf = [];
  if (
    fluid_density.density_profile !== undefined &&
    fluid_density.density_profile !== null &&
    fluid_density.density_profile.length !== 0
  ) {
    dataXf = fluid_density.density_profile.map((item) =>
      parseFloat(
        item.density_profile_element_item.pressure !== " "
          ? item.density_profile_element_item.pressure
          : " "
      )
    );
    dataYf = fluid_density.density_profile.map((item) =>
      parseFloat(item.density_profile_element_item.value)
    );
  } else {
    dataXf = [0];
    dataYf = [density];
  }

  const dataXall = dataXf;
  const dataYall = dataYf;

  const lineDensityPressure = {
    x: dataXall,
    y: dataYall,
    mode: "lines+markers",
    line: { shape: "linear" },
    type: "scatter",
    marker: {
      color: "#0d47a1"
         
    },
  };

  dataFluidDensityPressure.push(lineDensityPressure);

  return dataFluidDensityPressure;
}


function drawFluidDensityTemperature(fluid_density, density) {
  const  dataFluidDensityTemperature = [];

  let dataXf = [];
  let dataYf = [];
  if (
    fluid_density.density_profile !== undefined &&
    fluid_density.density_profile !== null &&
    fluid_density.density_profile.length !== 0
  ) {
    dataXf = fluid_density.density_profile.map((item) =>
      parseFloat(
        item.density_profile_element_item.temperature !== " "
          ? item.density_profile_element_item.temperature
          : " "
      )
    );
    dataYf = fluid_density.density_profile.map((item) =>
      parseFloat(item.density_profile_element_item.value)
    );
  } else {
    dataXf = [0];
    dataYf = [density];
  }

  const dataXall = dataXf;
  const dataYall = dataYf;

  const lineDensityTempeture = {
    x: dataXall,
    y: dataYall,
    mode: "lines+markers",
    line: { shape: "linear" },
    type: "scatter",
    marker: {
      color: "#FF0000",
    },
  };

  dataFluidDensityTemperature.push(lineDensityTempeture);

  return dataFluidDensityTemperature;
}

function drawFluidDensityPressureTemperature(fluid_density, density) {
  const dataFluidDensity = [];

  let dataDistinctTemperature = [];
  let dataXfiltered = [];
  let dataYfiltered = [];
  let lineDensityITHTempeture = {};

  const colors = ["red", "blue", "green", "dark-yellow", "purple", "orange"];

  if (
    fluid_density.density_profile !== undefined &&
    fluid_density.density_profile !== null &&
    fluid_density.density_profile.length !== 0
  ) {
    dataDistinctTemperature = [
      ...new Set(
        fluid_density.density_profile.map(
          (x) => x.density_profile_element_item.temperature
        )
      ),
    ];
    for (let index = 0; index < dataDistinctTemperature.length; index += 1) {
      dataYfiltered = fluid_density.density_profile
        .filter(
          (item) =>
            parseFloat(item.density_profile_element_item.temperature) ===
            parseFloat(dataDistinctTemperature[index])
        )
        .map((item) => parseFloat(item.density_profile_element_item.value));

      dataXfiltered = fluid_density.density_profile
        .filter(
          (item) =>
            parseFloat(item.density_profile_element_item.temperature) ===
            parseFloat(dataDistinctTemperature[index])
        )
        .map((item) => parseFloat(item.density_profile_element_item.pressure));

      lineDensityITHTempeture = {
        x: dataXfiltered,
        y: dataYfiltered,
        mode: "lines+markers",
        name: dataDistinctTemperature[index],
        line: { shape: "linear" },
        type: "scatter",
        marker: { color: colors[index % colors.length] },
      };

      dataFluidDensity.push(lineDensityITHTempeture);
    }
  } else {
    dataXfiltered = [0];
    dataYfiltered = [density];

    lineDensityITHTempeture = {
      x: dataXfiltered,
      y: dataYfiltered,
      mode: "lines+markers",
      line: { shape: "linear" },
      type: "scatter",
      marker: { color: colors[2] },
    };
   
    dataFluidDensity.push(lineDensityITHTempeture);
  }
  return dataFluidDensity;
}

export {
  drawFluidDensityPressure,
  drawFluidDensityTemperature,
  drawFluidDensityPressureTemperature,
  getFluidDensityLimits,
  getFluidDensityAndTemperatureLimits,
};
