// Main import of React
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { useContext } from "react";

// #  Local SubComponents & utils
import { DropletLoadingSpinner } from "../components/common";
import { mergedObject, reload } from "../components/utils";

import {
  getCaseAndCasePropsByIdRequest,
  upadateCaseByIdRequest,
} from "../services/CasesRequests";

// # Context
import { SearchContext } from "../components/contexts/SearchContext";
import { AccordionSmallFrameContext } from "../components/contexts/AccordionSmallFrameContext";
import { HistoryContext } from "../components/contexts/HistoryContext";
import { ToastContext } from "../components/contexts/ToastContext";
import { HandleErrorContext } from "../components/contexts/HandleErrorContext";
import { ProjectAndCasesContext } from "../components/contexts/ProjectAndCasesContext";

// # Local Form or Pop-up import
import { FormGeneralInfo } from "../components/forms";

// Style import
import "./style/GeneralInfo.css";

function GeneralInfo() {
  const { id } = useParams();
  const history = useHistory();

  // ActiveName at AccordionSmallFrame
  const { setActiveName } = useContext(AccordionSmallFrameContext);

  /* State to set ID(MONGO) at one Case - responsible to manage SearchBox*/
  const { setId, setWordValue } = useContext(SearchContext);

  /* State to set History Location Pathname and possible changes at onChange function */
  const { setHistoryLocation, setHasChange } = useContext(HistoryContext);

  // Toast Context
  const { toastList, setToastList, showToast } = useContext(ToastContext);

  // Handle Error Context
  const { setError } = useContext(HandleErrorContext);

  // Project And Cases Context
  const { setProjectById } = useContext(ProjectAndCasesContext);

  // Values to fill General Info page
  const [values, setValues] = useState();

  // Values to fill CasePropsById
  const [casePropsById, setCasePropsById] = useState();

  // Define useEffect policy
  useEffect(() => {
    getCaseAndCasePropsByIdRequest(id, setError).then((res) => {
      if (res) {
        setValues(res);
        setCasePropsById(res);
        setProjectById(res.case.project);
        reload();
      }
    });

    setWordValue("");
    setHistoryLocation(history.location.pathname);
    setId(id);
  }, []);

  // Define HandleSubmit, Redirect and onChange functions
  async function handleSubmit(e) {
    e.preventDefault();
    if (
      values["case"].name.length >= 3 &&
      values["case"].description.length >= 3
    ) {
      const response = await upadateCaseByIdRequest(id, {
        name: values["case"].name,
        description: values["case"].description,
      });
      showToast(response, toastList, setToastList);
      setHasChange(false);
    }
  }

  // Function responsible for cancel request
  async function cancelRequest() {
    setValues(casePropsById);
  }

  // Function responsible for change values - General Information
  async function onChange(e) {
    let changedValues = mergedObject(values, {
      [e.target.name]: e.target.value,
    });

    setValues(mergedObject(values, changedValues));
    if (
      //Comparing two objects
      values !== casePropsById
    ) {
      setHasChange(true);
    }
  }

  // Page Render
  return values && casePropsById ? (
    <div className="general-info-page">
      <FormGeneralInfo
        values={values}
        casePropsById={casePropsById}
        handleSubmit={handleSubmit}
        cancelRequest={cancelRequest}
        onChange={onChange}
        id={id}
      />
    </div>
  ) : (
    <DropletLoadingSpinner />
  );
}

// Page Export
export default GeneralInfo;
