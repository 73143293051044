import { useRef, useEffect, useState } from "react";

function useCloseAuto(pathName = "") {

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  // Function to check the resolution of window
  const windowResolution = () => windowSize.current[0] <= 1280 && windowSize.current[1] <= 720;

  // Variable store true or false
  const isResolutionSmall = windowResolution();

  // Variable that check if the pathName to be in the list to close the SideBar
  const isCloseSideBar = isResolutionSmall && ["geometry", "pumpsequence", "thermal_profile", "simulation"].includes(pathName);
  
  // State responsible for controlling the opening/closing of the sidebar */
    const [isOpen, setIsOpen] = useState(!isCloseSideBar);

  // State responsible for controlling the opening/closing sliding */
    const [openSliding, setOPenSliding] = useState(!isCloseSideBar);

    // Side effect to close or open sideBar and slidingPanel
    useEffect(() => {
      if (isCloseSideBar) {
        setIsOpen(false);
        setOPenSliding(false)
      } else {
        setIsOpen(true);
        setOPenSliding(true)
      }
    }, [pathName]);

  return {  isOpen, setIsOpen, openSliding, setOPenSliding   };
}

export default useCloseAuto;