import React, { createContext, useState } from "react";

// Create Context Object
const HistoryContext = createContext();

// Hook
export default function useHistory() {
  // History States
  const [historyLocation, setHistoryLocation] = useState();

  const [hasChange, setHasChange] = useState();

  const [openKey, setOpenKey] = useState("1");

  return { historyLocation, setHistoryLocation, hasChange, setHasChange, openKey, setOpenKey };
}

// Specific Context Provider
function HistoryProvider({ children }) {
  const { historyLocation, setHistoryLocation, hasChange, setHasChange, openKey, setOpenKey } = useHistory();

  return (
    <HistoryContext.Provider
      value={{ historyLocation, setHistoryLocation, hasChange, setHasChange, openKey, setOpenKey }}
    >
      {children}
    </HistoryContext.Provider>
  );
}

export { HistoryContext, HistoryProvider };
