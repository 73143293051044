// #  Main Imports
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

// #  Local SubComponents & utils
import { FormInput, StyledButton, PageAndContainerTitle } from "../../common";
import { createProjectsRequest } from "../../../services/ProjectsRequests";

// Context
import { ToastContext } from "../../contexts/ToastContext";

// # Import Component Style
import "./FormNewProject.css";

const FormNewProject = (props) => {
  const history = useHistory();

  const wellName = history.location.pathname.split("/");

  // Toast Context
  const { toastList, setToastList, showToast } = useContext(ToastContext);

  const [values, setValues] = useState({
    name: "",
    description: "",
    well_id: wellName[2],
  });

  const inputsNewProject = [
    {
      id: 1,
      name: "name",
      type: "textarea",
      placeholder: "Digite um nome para o novo projeto",
      errorMessage: "Este campo deve conter 3 a 150 caracteres!",
      label: "Nome",
      maxlength: "150",
      required: true,
      pattern: "^[a-zA-Z0-9_ ]{3,150}$",
      rows: 2,
      cols: 18,
    },
    {
      id: 2,
      name: "description",
      type: "textarea",
      placeholder: "Preencha uma descrição para o novo projeto",
      errorMessage: "Este campo deve conter 3 a 500 caracteres!",
      label: "Descrição",
      maxlength: "500",
      pattern: "^[a-zA-Z0-9_ ]{3,500}$",
      required: true,
      rows: 8,
      cols: 18,
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (values.name.length >= 3 && values.description.length >= 3) {
      const response = await createProjectsRequest(values);
      showToast(response, toastList, setToastList )
      if(response !== undefined) {
        history.push(`/project/${response.data.id}/cases`);
      }
    }
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const redirectToProjects = () => {
    /* TODO: user_id - Petrobras */
    history.push(`/well/${wellName[2]}/projects`);
  };

  // Buttons to render using StyledButton component
  const bttnsForNewProject = [
    {
      type: "button",
      callback: redirectToProjects,
      color: "#c62828",
      icon: "fas fa-times",
      text: "Cancelar",
    },
    {
      type: "submit",
      callback: handleSubmit,
      color: "#1b5e20",
      icon: "fas fa-save",
      text: "Salvar",
    },
  ];

  return (
    <div className="container-form-new-project">
      <PageAndContainerTitle text="Novo Projeto" level="3_forms" />
      <form className="form-new-project">
        {inputsNewProject.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={values[input.name]}
            onChange={onChange}
          />
        ))}

        <div className="bttn-new-project__container">
          {bttnsForNewProject.map((item, index) => (
            <StyledButton data={item} key={index} />
          ))}
        </div>
      </form>
    </div>
  );
};

export default FormNewProject;
