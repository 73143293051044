const dataProjects = [
  {
    id: 1,
    title: "Projeto 1",
    description: "Projeto de cimentação do Trecho 1",
    card_name: "card1",
    created_name: "Teste 1",
    modified_name: "Teste 1",
    created_data: "01/10/2022",
    modified_data: "18/10/2022",
  },
  {
    id: 2,
    title: "Projeto 2",
    description: "Projeto de cimentação do Trecho 2",
    card_name: "card2",
    created_name: "Teste 2",
    modified_name: "Teste 2",
    created_data: "10/12/2022",
    modified_data: "20/12/2022",
  },
  {
    id: 3,
    title: "Projeto 3",
    description: "Projeto de cimentação do Trecho 3",
    card_name: "card3",
    created_name: "Teste 3",
    modified_name: "Teste 3",
    created_data: "25/12/2022",
    modified_data: "30/12/2022",
  },
  {
    id: 4,
    title: "Projeto 4",
    description: "Projeto de cimentação do Trecho 3",
    card_name: "card3",
    created_name: "Teste 3",
    modified_name: "Teste 3",
    created_data: "25/12/2022",
    modified_data: "30/12/2022",
  },
];

export default dataProjects;
